.brand-purple h1,
.brand-purple h2,
.brand-purple h3,
.brand-purple h4,
.brand-purple h5,
.brand-purple h6,
.brand-purple .h1,
.brand-purple .h2,
.brand-purple .h3,
.brand-purple .h4,
.brand-purple .h5,
.brand-purple .h6 {
  color: #3C1053;
}
.brand-purple a,
.brand-purple a:link,
.brand-purple a:visited {
  color: #3C1053;
}
.brand-purple a:hover,
.brand-purple a:focus,
.brand-purple a:active {
  color: #000000;
}
.brand-purple .popover button.close {
  color: #3C1053;
}
.brand-purple .popover button.close:hover {
  color: #000000;
}
.brand-purple a:hover .new-window-link,
.brand-purple a:hover .insecure-link {
  color: #000000;
}
.brand-purple .btn-link {
  color: #3C1053;
}
.brand-purple .btn-link:hover,
.brand-purple .btn-link:focus {
  color: #000000;
}
.brand-purple a,
.brand-purple a:link,
.brand-purple a:visited {
  -webkit-text-decoration-color: #775887;
  text-decoration-color: #775887;
}
.brand-purple a:hover,
.brand-purple a:focus,
.brand-purple a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-purple blockquote.quotes {
  color: #000000;
}
.brand-purple blockquote.quotes::before {
  color: #000000;
}
.brand-purple .brand-bg {
  background: #3C1053;
  color: white;
}
.brand-purple .brand-bg a,
.brand-purple .brand-bg a:link,
.brand-purple .brand-bg a:visited {
  color: white !important;
}
.brand-purple .brand-bg a:hover,
.brand-purple .brand-bg a:focus,
.brand-purple .brand-bg a:active {
  color: #cccccc !important;
}
.brand-purple .brand-bg .popover button.close {
  color: white !important;
}
.brand-purple .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-purple .brand-bg a:hover .new-window-link,
.brand-purple .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-purple .brand-bg .btn-link {
  color: white !important;
}
.brand-purple .brand-bg .btn-link:hover,
.brand-purple .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-purple .brand-bg a,
.brand-purple .brand-bg a:link,
.brand-purple .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-purple .brand-bg a:hover,
.brand-purple .brand-bg a:focus,
.brand-purple .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-purple .brand-text {
  color: #3C1053;
}
.brand-purple .brand-text a,
.brand-purple .brand-text a:link,
.brand-purple .brand-text a:visited {
  color: #3C1053 !important;
}
.brand-purple .brand-text a:hover,
.brand-purple .brand-text a:focus,
.brand-purple .brand-text a:active {
  color: #000000 !important;
}
.brand-purple .brand-text .popover button.close {
  color: #3C1053 !important;
}
.brand-purple .brand-text .popover button.close:hover {
  color: #000000 !important;
}
.brand-purple .brand-text a:hover .new-window-link,
.brand-purple .brand-text a:hover .insecure-link {
  color: #000000 !important;
}
.brand-purple .brand-text .btn-link {
  color: #3C1053 !important;
}
.brand-purple .brand-text .btn-link:hover,
.brand-purple .brand-text .btn-link:focus {
  color: #000000 !important;
}
.brand-purple .brand-text a,
.brand-purple .brand-text a:link,
.brand-purple .brand-text a:visited {
  -webkit-text-decoration-color: #775887 !important;
  text-decoration-color: #775887 !important;
}
.brand-purple .brand-text a:hover,
.brand-purple .brand-text a:focus,
.brand-purple .brand-text a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-purple .brand-border {
  border-color: #3C1053;
}
.brand-purple .btn-brand {
  color: white;
  background-color: #3C1053;
  border-color: #2d0c3e;
  color: white !important;
}
.brand-purple .btn-brand:focus,
.brand-purple .btn-brand.focus {
  color: white;
  background-color: #1d0828;
  border-color: #000000;
}
.brand-purple .btn-brand:hover {
  color: white;
  background-color: #1d0828;
  border-color: #07020a;
}
.brand-purple .btn-brand:active,
.brand-purple .btn-brand.active,
.open > .dropdown-toggle.brand-purple .btn-brand {
  color: white;
  background-color: #1d0828;
  background-image: none;
  border-color: #07020a;
}
.brand-purple .btn-brand:active:hover,
.brand-purple .btn-brand.active:hover,
.open > .dropdown-toggle.brand-purple .btn-brand:hover,
.brand-purple .btn-brand:active:focus,
.brand-purple .btn-brand.active:focus,
.open > .dropdown-toggle.brand-purple .btn-brand:focus,
.brand-purple .btn-brand:active.focus,
.brand-purple .btn-brand.active.focus,
.open > .dropdown-toggle.brand-purple .btn-brand.focus {
  color: white;
  background-color: #07020a;
  border-color: #000000;
}
.brand-purple .btn-brand.disabled:hover,
.brand-purple .btn-brand[disabled]:hover,
fieldset[disabled] .brand-purple .btn-brand:hover,
.brand-purple .btn-brand.disabled:focus,
.brand-purple .btn-brand[disabled]:focus,
fieldset[disabled] .brand-purple .btn-brand:focus,
.brand-purple .btn-brand.disabled.focus,
.brand-purple .btn-brand[disabled].focus,
fieldset[disabled] .brand-purple .btn-brand.focus {
  background-color: #3C1053;
  border-color: #2d0c3e;
}
.brand-purple .btn-brand .badge {
  color: #3C1053;
  background-color: white;
}
.brand-purple .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-purple .id7-utility-masthead {
  background-color: #775887;
}
.brand-purple .id7-utility-masthead::after {
  background-color: #3C1053;
}
body.id7-point-1 .brand-purple .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-purple .id7-navigation .navbar-primary {
  background-color: #3C1053;
}
.brand-purple .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-purple .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #775887;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #300d42;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #775887;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-purple .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-purple .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #300d42;
}
.brand-purple .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-purple .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-purple .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-purple .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #300d42;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #3C1053;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #300d42;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #300d42;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #300d42;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #300d42;
  background-image: none;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #775887;
}
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-purple .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #300d42;
}
.brand-purple .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .btn-link:hover,
.brand-purple .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-purple .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-purple .id7-navigation .navbar-primary .btn-link:hover,
.brand-purple .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-purple .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-purple .navbar-primary .navbar-brand {
  background-color: #3C1053;
}
.brand-purple .navbar-secondary {
  background-color: #775887;
}
.brand-purple .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-purple .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-purple .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-purple .navbar-secondary .navbar-text {
  color: white;
}
.brand-purple .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-purple .navbar-secondary .navbar-nav > li {
  color: white;
}
.brand-purple .navbar-secondary .navbar-nav > li:hover,
.brand-purple .navbar-secondary .navbar-nav > li:focus {
  color: white;
  background-color: #5f466c;
}
.brand-purple .navbar-secondary .navbar-nav > li > a,
.brand-purple .navbar-secondary .navbar-nav > li > a:hover,
.brand-purple .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-purple .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-purple .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-purple .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-purple .navbar-secondary .navbar-nav > .disabled,
.brand-purple .navbar-secondary .navbar-nav > .disabled:hover,
.brand-purple .navbar-secondary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #5f466c;
}
.brand-purple .navbar-secondary .navbar-toggle {
  border: none;
  color: white;
}
.brand-purple .navbar-secondary .navbar-toggle:hover,
.brand-purple .navbar-secondary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-purple .navbar-secondary .navbar-nav > .open,
.brand-purple .navbar-secondary .navbar-nav > .open:hover,
.brand-purple .navbar-secondary .navbar-nav > .open:focus {
  color: white;
  background-color: #5f466c;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #775887;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #5f466c;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #5f466c;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #5f466c;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #5f466c;
  background-image: none;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-purple .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #5f466c;
}
.brand-purple .navbar-secondary .navbar-link {
  color: white;
}
.brand-purple .navbar-secondary .navbar-link:hover {
  color: white;
}
.brand-purple .navbar-secondary .btn-link {
  color: white;
}
.brand-purple .navbar-secondary .btn-link:hover,
.brand-purple .navbar-secondary .btn-link:focus {
  color: white;
}
.brand-purple .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-purple .navbar-secondary .btn-link:hover,
.brand-purple .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-purple .navbar-secondary .btn-link:focus {
  color: white;
}
.brand-purple .navbar-secondary .navbar-nav > li::after {
  color: #3C1053;
}
.brand-purple .navbar-secondary .navbar-nav > li:first-child {
  background-color: #3C1053;
  color: white;
}
.brand-purple .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #3C1053;
}
.brand-purple .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #3C1053;
}
.brand-purple .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-purple .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-purple .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-purple .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-purple .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-purple .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-purple .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-purple .navbar-tertiary .navbar-nav > li:hover,
.brand-purple .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-purple .navbar-tertiary .navbar-nav > li > a,
.brand-purple .navbar-tertiary .navbar-nav > li > a:hover,
.brand-purple .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-purple .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-purple .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-purple .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-purple .navbar-tertiary .navbar-nav > .disabled,
.brand-purple .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-purple .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-purple .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-purple .navbar-tertiary .navbar-toggle:hover,
.brand-purple .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-purple .navbar-tertiary .navbar-nav > .open,
.brand-purple .navbar-tertiary .navbar-nav > .open:hover,
.brand-purple .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-purple .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-purple .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-purple .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-purple .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-purple .navbar-tertiary .btn-link:hover,
.brand-purple .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-purple .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-purple .navbar-tertiary .btn-link:hover,
.brand-purple .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-purple .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-purple.bordered .id7-left-border,
.brand-purple.bordered .id7-right-border {
  border-color: rgba(60, 16, 83, 0.3);
}
.brand-purple .id7-page-footer .id7-app-footer {
  background: #3C1053;
  color: white;
}
.brand-purple .id7-page-footer .id7-app-footer h1,
.brand-purple .id7-page-footer .id7-app-footer h2,
.brand-purple .id7-page-footer .id7-app-footer h3,
.brand-purple .id7-page-footer .id7-app-footer h4,
.brand-purple .id7-page-footer .id7-app-footer h5,
.brand-purple .id7-page-footer .id7-app-footer h6,
.brand-purple .id7-page-footer .id7-app-footer .h1,
.brand-purple .id7-page-footer .id7-app-footer .h2,
.brand-purple .id7-page-footer .id7-app-footer .h3,
.brand-purple .id7-page-footer .id7-app-footer .h4,
.brand-purple .id7-page-footer .id7-app-footer .h5,
.brand-purple .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-purple .id7-page-footer .id7-app-footer a,
.brand-purple .id7-page-footer .id7-app-footer a:link,
.brand-purple .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-purple .id7-page-footer .id7-app-footer a:hover,
.brand-purple .id7-page-footer .id7-app-footer a:focus,
.brand-purple .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-purple .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-purple .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-purple .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-purple .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-purple .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-purple .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-purple .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-purple .id7-page-footer .id7-app-footer a,
.brand-purple .id7-page-footer .id7-app-footer a:link,
.brand-purple .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-purple .id7-page-footer .id7-app-footer a:hover,
.brand-purple .id7-page-footer .id7-app-footer a:focus,
.brand-purple .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-purple .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #3C1053;
}
.brand-purple .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-purple .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-purple .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-purple .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-purple .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-purple .id7-search .fa,
.brand-purple .id7-search .fas {
  color: #3C1053;
  color: rgba(60, 16, 83, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-purple .id7-search .form-control:hover + .fa,
.brand-purple .id7-search .typeahead:hover + .fa,
.brand-purple .id7-search .fa:hover,
.brand-purple .id7-search .form-control:hover + .fas,
.brand-purple .id7-search .typeahead:hover + .fas,
.brand-purple .id7-search .fas:hover {
  color: #3C1053;
}
.brand-purple .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #3C1053;
  color: white;
}
.brand-purple .form-control:focus {
  border-color: #3C1053;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(60, 16, 83, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(60, 16, 83, 0.6);
}
.brand-purple .carousel .carousel-indicators li::after {
  color: #3C1053;
  color: rgba(60, 16, 83, 0.3);
}
.brand-purple .carousel .carousel-indicators li.active::after {
  color: #3C1053;
}
@media (max-width: 767px) {
  .brand-purple .boxstyle_.box1,
  .brand-purple .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-purple .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-purple .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-purple .boxstyle_.box1,
.brand-purple .boxstyle-xs.box1,
.brand-purple .boxstyle-sm.box1,
.brand-purple .boxstyle-md.box1,
.brand-purple .boxstyle-lg.box1 {
  border: 0;
  background: #e2dbe5;
  color: #202020;
}
@media print {
  .brand-purple .boxstyle_.box1,
  .brand-purple .boxstyle-xs.box1,
  .brand-purple .boxstyle-sm.box1,
  .brand-purple .boxstyle-md.box1,
  .brand-purple .boxstyle-lg.box1 {
    border: 1px solid #e2dbe5;
  }
}
.brand-purple .boxstyle_.box1 h1,
.brand-purple .boxstyle-xs.box1 h1,
.brand-purple .boxstyle-sm.box1 h1,
.brand-purple .boxstyle-md.box1 h1,
.brand-purple .boxstyle-lg.box1 h1,
.brand-purple .boxstyle_.box1 h2,
.brand-purple .boxstyle-xs.box1 h2,
.brand-purple .boxstyle-sm.box1 h2,
.brand-purple .boxstyle-md.box1 h2,
.brand-purple .boxstyle-lg.box1 h2,
.brand-purple .boxstyle_.box1 h3,
.brand-purple .boxstyle-xs.box1 h3,
.brand-purple .boxstyle-sm.box1 h3,
.brand-purple .boxstyle-md.box1 h3,
.brand-purple .boxstyle-lg.box1 h3,
.brand-purple .boxstyle_.box1 h4,
.brand-purple .boxstyle-xs.box1 h4,
.brand-purple .boxstyle-sm.box1 h4,
.brand-purple .boxstyle-md.box1 h4,
.brand-purple .boxstyle-lg.box1 h4,
.brand-purple .boxstyle_.box1 h5,
.brand-purple .boxstyle-xs.box1 h5,
.brand-purple .boxstyle-sm.box1 h5,
.brand-purple .boxstyle-md.box1 h5,
.brand-purple .boxstyle-lg.box1 h5,
.brand-purple .boxstyle_.box1 h6,
.brand-purple .boxstyle-xs.box1 h6,
.brand-purple .boxstyle-sm.box1 h6,
.brand-purple .boxstyle-md.box1 h6,
.brand-purple .boxstyle-lg.box1 h6,
.brand-purple .boxstyle_.box1 .h1,
.brand-purple .boxstyle-xs.box1 .h1,
.brand-purple .boxstyle-sm.box1 .h1,
.brand-purple .boxstyle-md.box1 .h1,
.brand-purple .boxstyle-lg.box1 .h1,
.brand-purple .boxstyle_.box1 .h2,
.brand-purple .boxstyle-xs.box1 .h2,
.brand-purple .boxstyle-sm.box1 .h2,
.brand-purple .boxstyle-md.box1 .h2,
.brand-purple .boxstyle-lg.box1 .h2,
.brand-purple .boxstyle_.box1 .h3,
.brand-purple .boxstyle-xs.box1 .h3,
.brand-purple .boxstyle-sm.box1 .h3,
.brand-purple .boxstyle-md.box1 .h3,
.brand-purple .boxstyle-lg.box1 .h3,
.brand-purple .boxstyle_.box1 .h4,
.brand-purple .boxstyle-xs.box1 .h4,
.brand-purple .boxstyle-sm.box1 .h4,
.brand-purple .boxstyle-md.box1 .h4,
.brand-purple .boxstyle-lg.box1 .h4,
.brand-purple .boxstyle_.box1 .h5,
.brand-purple .boxstyle-xs.box1 .h5,
.brand-purple .boxstyle-sm.box1 .h5,
.brand-purple .boxstyle-md.box1 .h5,
.brand-purple .boxstyle-lg.box1 .h5,
.brand-purple .boxstyle_.box1 .h6,
.brand-purple .boxstyle-xs.box1 .h6,
.brand-purple .boxstyle-sm.box1 .h6,
.brand-purple .boxstyle-md.box1 .h6,
.brand-purple .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-purple .boxstyle_.box1 blockquote.quotes,
.brand-purple .boxstyle-xs.box1 blockquote.quotes,
.brand-purple .boxstyle-sm.box1 blockquote.quotes,
.brand-purple .boxstyle-md.box1 blockquote.quotes,
.brand-purple .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-purple .boxstyle_.box1 a,
.brand-purple .boxstyle-xs.box1 a,
.brand-purple .boxstyle-sm.box1 a,
.brand-purple .boxstyle-md.box1 a,
.brand-purple .boxstyle-lg.box1 a,
.brand-purple .boxstyle_.box1 a:link,
.brand-purple .boxstyle-xs.box1 a:link,
.brand-purple .boxstyle-sm.box1 a:link,
.brand-purple .boxstyle-md.box1 a:link,
.brand-purple .boxstyle-lg.box1 a:link,
.brand-purple .boxstyle_.box1 a:visited,
.brand-purple .boxstyle-xs.box1 a:visited,
.brand-purple .boxstyle-sm.box1 a:visited,
.brand-purple .boxstyle-md.box1 a:visited,
.brand-purple .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-purple .boxstyle_.box1 a:hover,
.brand-purple .boxstyle-xs.box1 a:hover,
.brand-purple .boxstyle-sm.box1 a:hover,
.brand-purple .boxstyle-md.box1 a:hover,
.brand-purple .boxstyle-lg.box1 a:hover,
.brand-purple .boxstyle_.box1 a:focus,
.brand-purple .boxstyle-xs.box1 a:focus,
.brand-purple .boxstyle-sm.box1 a:focus,
.brand-purple .boxstyle-md.box1 a:focus,
.brand-purple .boxstyle-lg.box1 a:focus,
.brand-purple .boxstyle_.box1 a:active,
.brand-purple .boxstyle-xs.box1 a:active,
.brand-purple .boxstyle-sm.box1 a:active,
.brand-purple .boxstyle-md.box1 a:active,
.brand-purple .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-purple .boxstyle_.box1 .popover button.close,
.brand-purple .boxstyle-xs.box1 .popover button.close,
.brand-purple .boxstyle-sm.box1 .popover button.close,
.brand-purple .boxstyle-md.box1 .popover button.close,
.brand-purple .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-purple .boxstyle_.box1 .popover button.close:hover,
.brand-purple .boxstyle-xs.box1 .popover button.close:hover,
.brand-purple .boxstyle-sm.box1 .popover button.close:hover,
.brand-purple .boxstyle-md.box1 .popover button.close:hover,
.brand-purple .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-purple .boxstyle_.box1 a:hover .new-window-link,
.brand-purple .boxstyle-xs.box1 a:hover .new-window-link,
.brand-purple .boxstyle-sm.box1 a:hover .new-window-link,
.brand-purple .boxstyle-md.box1 a:hover .new-window-link,
.brand-purple .boxstyle-lg.box1 a:hover .new-window-link,
.brand-purple .boxstyle_.box1 a:hover .insecure-link,
.brand-purple .boxstyle-xs.box1 a:hover .insecure-link,
.brand-purple .boxstyle-sm.box1 a:hover .insecure-link,
.brand-purple .boxstyle-md.box1 a:hover .insecure-link,
.brand-purple .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-purple .boxstyle_.box1 .btn-link,
.brand-purple .boxstyle-xs.box1 .btn-link,
.brand-purple .boxstyle-sm.box1 .btn-link,
.brand-purple .boxstyle-md.box1 .btn-link,
.brand-purple .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-purple .boxstyle_.box1 .btn-link:hover,
.brand-purple .boxstyle-xs.box1 .btn-link:hover,
.brand-purple .boxstyle-sm.box1 .btn-link:hover,
.brand-purple .boxstyle-md.box1 .btn-link:hover,
.brand-purple .boxstyle-lg.box1 .btn-link:hover,
.brand-purple .boxstyle_.box1 .btn-link:focus,
.brand-purple .boxstyle-xs.box1 .btn-link:focus,
.brand-purple .boxstyle-sm.box1 .btn-link:focus,
.brand-purple .boxstyle-md.box1 .btn-link:focus,
.brand-purple .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-purple .boxstyle_.box1 a,
.brand-purple .boxstyle-xs.box1 a,
.brand-purple .boxstyle-sm.box1 a,
.brand-purple .boxstyle-md.box1 a,
.brand-purple .boxstyle-lg.box1 a,
.brand-purple .boxstyle_.box1 a:link,
.brand-purple .boxstyle-xs.box1 a:link,
.brand-purple .boxstyle-sm.box1 a:link,
.brand-purple .boxstyle-md.box1 a:link,
.brand-purple .boxstyle-lg.box1 a:link,
.brand-purple .boxstyle_.box1 a:visited,
.brand-purple .boxstyle-xs.box1 a:visited,
.brand-purple .boxstyle-sm.box1 a:visited,
.brand-purple .boxstyle-md.box1 a:visited,
.brand-purple .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-purple .boxstyle_.box1 a:hover,
.brand-purple .boxstyle-xs.box1 a:hover,
.brand-purple .boxstyle-sm.box1 a:hover,
.brand-purple .boxstyle-md.box1 a:hover,
.brand-purple .boxstyle-lg.box1 a:hover,
.brand-purple .boxstyle_.box1 a:focus,
.brand-purple .boxstyle-xs.box1 a:focus,
.brand-purple .boxstyle-sm.box1 a:focus,
.brand-purple .boxstyle-md.box1 a:focus,
.brand-purple .boxstyle-lg.box1 a:focus,
.brand-purple .boxstyle_.box1 a:active,
.brand-purple .boxstyle-xs.box1 a:active,
.brand-purple .boxstyle-sm.box1 a:active,
.brand-purple .boxstyle-md.box1 a:active,
.brand-purple .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-purple .boxstyle_.box2,
  .brand-purple .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-purple .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-purple .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-purple .boxstyle_.box2,
.brand-purple .boxstyle-xs.box2,
.brand-purple .boxstyle-sm.box2,
.brand-purple .boxstyle-md.box2,
.brand-purple .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-purple .boxstyle_.box2,
  .brand-purple .boxstyle-xs.box2,
  .brand-purple .boxstyle-sm.box2,
  .brand-purple .boxstyle-md.box2,
  .brand-purple .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-purple .boxstyle_.box2 h1,
.brand-purple .boxstyle-xs.box2 h1,
.brand-purple .boxstyle-sm.box2 h1,
.brand-purple .boxstyle-md.box2 h1,
.brand-purple .boxstyle-lg.box2 h1,
.brand-purple .boxstyle_.box2 h2,
.brand-purple .boxstyle-xs.box2 h2,
.brand-purple .boxstyle-sm.box2 h2,
.brand-purple .boxstyle-md.box2 h2,
.brand-purple .boxstyle-lg.box2 h2,
.brand-purple .boxstyle_.box2 h3,
.brand-purple .boxstyle-xs.box2 h3,
.brand-purple .boxstyle-sm.box2 h3,
.brand-purple .boxstyle-md.box2 h3,
.brand-purple .boxstyle-lg.box2 h3,
.brand-purple .boxstyle_.box2 h4,
.brand-purple .boxstyle-xs.box2 h4,
.brand-purple .boxstyle-sm.box2 h4,
.brand-purple .boxstyle-md.box2 h4,
.brand-purple .boxstyle-lg.box2 h4,
.brand-purple .boxstyle_.box2 h5,
.brand-purple .boxstyle-xs.box2 h5,
.brand-purple .boxstyle-sm.box2 h5,
.brand-purple .boxstyle-md.box2 h5,
.brand-purple .boxstyle-lg.box2 h5,
.brand-purple .boxstyle_.box2 h6,
.brand-purple .boxstyle-xs.box2 h6,
.brand-purple .boxstyle-sm.box2 h6,
.brand-purple .boxstyle-md.box2 h6,
.brand-purple .boxstyle-lg.box2 h6,
.brand-purple .boxstyle_.box2 .h1,
.brand-purple .boxstyle-xs.box2 .h1,
.brand-purple .boxstyle-sm.box2 .h1,
.brand-purple .boxstyle-md.box2 .h1,
.brand-purple .boxstyle-lg.box2 .h1,
.brand-purple .boxstyle_.box2 .h2,
.brand-purple .boxstyle-xs.box2 .h2,
.brand-purple .boxstyle-sm.box2 .h2,
.brand-purple .boxstyle-md.box2 .h2,
.brand-purple .boxstyle-lg.box2 .h2,
.brand-purple .boxstyle_.box2 .h3,
.brand-purple .boxstyle-xs.box2 .h3,
.brand-purple .boxstyle-sm.box2 .h3,
.brand-purple .boxstyle-md.box2 .h3,
.brand-purple .boxstyle-lg.box2 .h3,
.brand-purple .boxstyle_.box2 .h4,
.brand-purple .boxstyle-xs.box2 .h4,
.brand-purple .boxstyle-sm.box2 .h4,
.brand-purple .boxstyle-md.box2 .h4,
.brand-purple .boxstyle-lg.box2 .h4,
.brand-purple .boxstyle_.box2 .h5,
.brand-purple .boxstyle-xs.box2 .h5,
.brand-purple .boxstyle-sm.box2 .h5,
.brand-purple .boxstyle-md.box2 .h5,
.brand-purple .boxstyle-lg.box2 .h5,
.brand-purple .boxstyle_.box2 .h6,
.brand-purple .boxstyle-xs.box2 .h6,
.brand-purple .boxstyle-sm.box2 .h6,
.brand-purple .boxstyle-md.box2 .h6,
.brand-purple .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-purple .boxstyle_.box2 blockquote.quotes,
.brand-purple .boxstyle-xs.box2 blockquote.quotes,
.brand-purple .boxstyle-sm.box2 blockquote.quotes,
.brand-purple .boxstyle-md.box2 blockquote.quotes,
.brand-purple .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-purple .boxstyle_.box2 a,
.brand-purple .boxstyle-xs.box2 a,
.brand-purple .boxstyle-sm.box2 a,
.brand-purple .boxstyle-md.box2 a,
.brand-purple .boxstyle-lg.box2 a,
.brand-purple .boxstyle_.box2 a:link,
.brand-purple .boxstyle-xs.box2 a:link,
.brand-purple .boxstyle-sm.box2 a:link,
.brand-purple .boxstyle-md.box2 a:link,
.brand-purple .boxstyle-lg.box2 a:link,
.brand-purple .boxstyle_.box2 a:visited,
.brand-purple .boxstyle-xs.box2 a:visited,
.brand-purple .boxstyle-sm.box2 a:visited,
.brand-purple .boxstyle-md.box2 a:visited,
.brand-purple .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-purple .boxstyle_.box2 a:hover,
.brand-purple .boxstyle-xs.box2 a:hover,
.brand-purple .boxstyle-sm.box2 a:hover,
.brand-purple .boxstyle-md.box2 a:hover,
.brand-purple .boxstyle-lg.box2 a:hover,
.brand-purple .boxstyle_.box2 a:focus,
.brand-purple .boxstyle-xs.box2 a:focus,
.brand-purple .boxstyle-sm.box2 a:focus,
.brand-purple .boxstyle-md.box2 a:focus,
.brand-purple .boxstyle-lg.box2 a:focus,
.brand-purple .boxstyle_.box2 a:active,
.brand-purple .boxstyle-xs.box2 a:active,
.brand-purple .boxstyle-sm.box2 a:active,
.brand-purple .boxstyle-md.box2 a:active,
.brand-purple .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-purple .boxstyle_.box2 .popover button.close,
.brand-purple .boxstyle-xs.box2 .popover button.close,
.brand-purple .boxstyle-sm.box2 .popover button.close,
.brand-purple .boxstyle-md.box2 .popover button.close,
.brand-purple .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-purple .boxstyle_.box2 .popover button.close:hover,
.brand-purple .boxstyle-xs.box2 .popover button.close:hover,
.brand-purple .boxstyle-sm.box2 .popover button.close:hover,
.brand-purple .boxstyle-md.box2 .popover button.close:hover,
.brand-purple .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-purple .boxstyle_.box2 a:hover .new-window-link,
.brand-purple .boxstyle-xs.box2 a:hover .new-window-link,
.brand-purple .boxstyle-sm.box2 a:hover .new-window-link,
.brand-purple .boxstyle-md.box2 a:hover .new-window-link,
.brand-purple .boxstyle-lg.box2 a:hover .new-window-link,
.brand-purple .boxstyle_.box2 a:hover .insecure-link,
.brand-purple .boxstyle-xs.box2 a:hover .insecure-link,
.brand-purple .boxstyle-sm.box2 a:hover .insecure-link,
.brand-purple .boxstyle-md.box2 a:hover .insecure-link,
.brand-purple .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-purple .boxstyle_.box2 .btn-link,
.brand-purple .boxstyle-xs.box2 .btn-link,
.brand-purple .boxstyle-sm.box2 .btn-link,
.brand-purple .boxstyle-md.box2 .btn-link,
.brand-purple .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-purple .boxstyle_.box2 .btn-link:hover,
.brand-purple .boxstyle-xs.box2 .btn-link:hover,
.brand-purple .boxstyle-sm.box2 .btn-link:hover,
.brand-purple .boxstyle-md.box2 .btn-link:hover,
.brand-purple .boxstyle-lg.box2 .btn-link:hover,
.brand-purple .boxstyle_.box2 .btn-link:focus,
.brand-purple .boxstyle-xs.box2 .btn-link:focus,
.brand-purple .boxstyle-sm.box2 .btn-link:focus,
.brand-purple .boxstyle-md.box2 .btn-link:focus,
.brand-purple .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-purple .boxstyle_.box2 a,
.brand-purple .boxstyle-xs.box2 a,
.brand-purple .boxstyle-sm.box2 a,
.brand-purple .boxstyle-md.box2 a,
.brand-purple .boxstyle-lg.box2 a,
.brand-purple .boxstyle_.box2 a:link,
.brand-purple .boxstyle-xs.box2 a:link,
.brand-purple .boxstyle-sm.box2 a:link,
.brand-purple .boxstyle-md.box2 a:link,
.brand-purple .boxstyle-lg.box2 a:link,
.brand-purple .boxstyle_.box2 a:visited,
.brand-purple .boxstyle-xs.box2 a:visited,
.brand-purple .boxstyle-sm.box2 a:visited,
.brand-purple .boxstyle-md.box2 a:visited,
.brand-purple .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-purple .boxstyle_.box2 a:hover,
.brand-purple .boxstyle-xs.box2 a:hover,
.brand-purple .boxstyle-sm.box2 a:hover,
.brand-purple .boxstyle-md.box2 a:hover,
.brand-purple .boxstyle-lg.box2 a:hover,
.brand-purple .boxstyle_.box2 a:focus,
.brand-purple .boxstyle-xs.box2 a:focus,
.brand-purple .boxstyle-sm.box2 a:focus,
.brand-purple .boxstyle-md.box2 a:focus,
.brand-purple .boxstyle-lg.box2 a:focus,
.brand-purple .boxstyle_.box2 a:active,
.brand-purple .boxstyle-xs.box2 a:active,
.brand-purple .boxstyle-sm.box2 a:active,
.brand-purple .boxstyle-md.box2 a:active,
.brand-purple .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-purple .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #3C1053;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-purple .boxstyle_.box3,
  .brand-purple .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #3C1053;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-purple .boxstyle-md.box3 {
    background: none;
    border: 1px solid #3C1053;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-purple .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #3C1053;
    color: inherit;
  }
}
.brand-purple .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-purple .boxstyle_.box4,
  .brand-purple .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-purple .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-purple .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-purple .boxstyle_.box5,
  .brand-purple .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-purple .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-purple .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-purple .boxstyle_.box5,
.brand-purple .boxstyle-xs.box5,
.brand-purple .boxstyle-sm.box5,
.brand-purple .boxstyle-md.box5,
.brand-purple .boxstyle-lg.box5 {
  border: 0;
  background: #3C1053;
  color: white;
}
@media print {
  .brand-purple .boxstyle_.box5,
  .brand-purple .boxstyle-xs.box5,
  .brand-purple .boxstyle-sm.box5,
  .brand-purple .boxstyle-md.box5,
  .brand-purple .boxstyle-lg.box5 {
    border: 1px solid #3C1053;
  }
}
.brand-purple .boxstyle_.box5 h1,
.brand-purple .boxstyle-xs.box5 h1,
.brand-purple .boxstyle-sm.box5 h1,
.brand-purple .boxstyle-md.box5 h1,
.brand-purple .boxstyle-lg.box5 h1,
.brand-purple .boxstyle_.box5 h2,
.brand-purple .boxstyle-xs.box5 h2,
.brand-purple .boxstyle-sm.box5 h2,
.brand-purple .boxstyle-md.box5 h2,
.brand-purple .boxstyle-lg.box5 h2,
.brand-purple .boxstyle_.box5 h3,
.brand-purple .boxstyle-xs.box5 h3,
.brand-purple .boxstyle-sm.box5 h3,
.brand-purple .boxstyle-md.box5 h3,
.brand-purple .boxstyle-lg.box5 h3,
.brand-purple .boxstyle_.box5 h4,
.brand-purple .boxstyle-xs.box5 h4,
.brand-purple .boxstyle-sm.box5 h4,
.brand-purple .boxstyle-md.box5 h4,
.brand-purple .boxstyle-lg.box5 h4,
.brand-purple .boxstyle_.box5 h5,
.brand-purple .boxstyle-xs.box5 h5,
.brand-purple .boxstyle-sm.box5 h5,
.brand-purple .boxstyle-md.box5 h5,
.brand-purple .boxstyle-lg.box5 h5,
.brand-purple .boxstyle_.box5 h6,
.brand-purple .boxstyle-xs.box5 h6,
.brand-purple .boxstyle-sm.box5 h6,
.brand-purple .boxstyle-md.box5 h6,
.brand-purple .boxstyle-lg.box5 h6,
.brand-purple .boxstyle_.box5 .h1,
.brand-purple .boxstyle-xs.box5 .h1,
.brand-purple .boxstyle-sm.box5 .h1,
.brand-purple .boxstyle-md.box5 .h1,
.brand-purple .boxstyle-lg.box5 .h1,
.brand-purple .boxstyle_.box5 .h2,
.brand-purple .boxstyle-xs.box5 .h2,
.brand-purple .boxstyle-sm.box5 .h2,
.brand-purple .boxstyle-md.box5 .h2,
.brand-purple .boxstyle-lg.box5 .h2,
.brand-purple .boxstyle_.box5 .h3,
.brand-purple .boxstyle-xs.box5 .h3,
.brand-purple .boxstyle-sm.box5 .h3,
.brand-purple .boxstyle-md.box5 .h3,
.brand-purple .boxstyle-lg.box5 .h3,
.brand-purple .boxstyle_.box5 .h4,
.brand-purple .boxstyle-xs.box5 .h4,
.brand-purple .boxstyle-sm.box5 .h4,
.brand-purple .boxstyle-md.box5 .h4,
.brand-purple .boxstyle-lg.box5 .h4,
.brand-purple .boxstyle_.box5 .h5,
.brand-purple .boxstyle-xs.box5 .h5,
.brand-purple .boxstyle-sm.box5 .h5,
.brand-purple .boxstyle-md.box5 .h5,
.brand-purple .boxstyle-lg.box5 .h5,
.brand-purple .boxstyle_.box5 .h6,
.brand-purple .boxstyle-xs.box5 .h6,
.brand-purple .boxstyle-sm.box5 .h6,
.brand-purple .boxstyle-md.box5 .h6,
.brand-purple .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-purple .boxstyle_.box5 blockquote.quotes,
.brand-purple .boxstyle-xs.box5 blockquote.quotes,
.brand-purple .boxstyle-sm.box5 blockquote.quotes,
.brand-purple .boxstyle-md.box5 blockquote.quotes,
.brand-purple .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-purple .boxstyle_.box5 a,
.brand-purple .boxstyle-xs.box5 a,
.brand-purple .boxstyle-sm.box5 a,
.brand-purple .boxstyle-md.box5 a,
.brand-purple .boxstyle-lg.box5 a,
.brand-purple .boxstyle_.box5 a:link,
.brand-purple .boxstyle-xs.box5 a:link,
.brand-purple .boxstyle-sm.box5 a:link,
.brand-purple .boxstyle-md.box5 a:link,
.brand-purple .boxstyle-lg.box5 a:link,
.brand-purple .boxstyle_.box5 a:visited,
.brand-purple .boxstyle-xs.box5 a:visited,
.brand-purple .boxstyle-sm.box5 a:visited,
.brand-purple .boxstyle-md.box5 a:visited,
.brand-purple .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-purple .boxstyle_.box5 a:hover,
.brand-purple .boxstyle-xs.box5 a:hover,
.brand-purple .boxstyle-sm.box5 a:hover,
.brand-purple .boxstyle-md.box5 a:hover,
.brand-purple .boxstyle-lg.box5 a:hover,
.brand-purple .boxstyle_.box5 a:focus,
.brand-purple .boxstyle-xs.box5 a:focus,
.brand-purple .boxstyle-sm.box5 a:focus,
.brand-purple .boxstyle-md.box5 a:focus,
.brand-purple .boxstyle-lg.box5 a:focus,
.brand-purple .boxstyle_.box5 a:active,
.brand-purple .boxstyle-xs.box5 a:active,
.brand-purple .boxstyle-sm.box5 a:active,
.brand-purple .boxstyle-md.box5 a:active,
.brand-purple .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-purple .boxstyle_.box5 .popover button.close,
.brand-purple .boxstyle-xs.box5 .popover button.close,
.brand-purple .boxstyle-sm.box5 .popover button.close,
.brand-purple .boxstyle-md.box5 .popover button.close,
.brand-purple .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-purple .boxstyle_.box5 .popover button.close:hover,
.brand-purple .boxstyle-xs.box5 .popover button.close:hover,
.brand-purple .boxstyle-sm.box5 .popover button.close:hover,
.brand-purple .boxstyle-md.box5 .popover button.close:hover,
.brand-purple .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-purple .boxstyle_.box5 a:hover .new-window-link,
.brand-purple .boxstyle-xs.box5 a:hover .new-window-link,
.brand-purple .boxstyle-sm.box5 a:hover .new-window-link,
.brand-purple .boxstyle-md.box5 a:hover .new-window-link,
.brand-purple .boxstyle-lg.box5 a:hover .new-window-link,
.brand-purple .boxstyle_.box5 a:hover .insecure-link,
.brand-purple .boxstyle-xs.box5 a:hover .insecure-link,
.brand-purple .boxstyle-sm.box5 a:hover .insecure-link,
.brand-purple .boxstyle-md.box5 a:hover .insecure-link,
.brand-purple .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-purple .boxstyle_.box5 .btn-link,
.brand-purple .boxstyle-xs.box5 .btn-link,
.brand-purple .boxstyle-sm.box5 .btn-link,
.brand-purple .boxstyle-md.box5 .btn-link,
.brand-purple .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-purple .boxstyle_.box5 .btn-link:hover,
.brand-purple .boxstyle-xs.box5 .btn-link:hover,
.brand-purple .boxstyle-sm.box5 .btn-link:hover,
.brand-purple .boxstyle-md.box5 .btn-link:hover,
.brand-purple .boxstyle-lg.box5 .btn-link:hover,
.brand-purple .boxstyle_.box5 .btn-link:focus,
.brand-purple .boxstyle-xs.box5 .btn-link:focus,
.brand-purple .boxstyle-sm.box5 .btn-link:focus,
.brand-purple .boxstyle-md.box5 .btn-link:focus,
.brand-purple .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-purple .boxstyle_.box5 a,
.brand-purple .boxstyle-xs.box5 a,
.brand-purple .boxstyle-sm.box5 a,
.brand-purple .boxstyle-md.box5 a,
.brand-purple .boxstyle-lg.box5 a,
.brand-purple .boxstyle_.box5 a:link,
.brand-purple .boxstyle-xs.box5 a:link,
.brand-purple .boxstyle-sm.box5 a:link,
.brand-purple .boxstyle-md.box5 a:link,
.brand-purple .boxstyle-lg.box5 a:link,
.brand-purple .boxstyle_.box5 a:visited,
.brand-purple .boxstyle-xs.box5 a:visited,
.brand-purple .boxstyle-sm.box5 a:visited,
.brand-purple .boxstyle-md.box5 a:visited,
.brand-purple .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-purple .boxstyle_.box5 a:hover,
.brand-purple .boxstyle-xs.box5 a:hover,
.brand-purple .boxstyle-sm.box5 a:hover,
.brand-purple .boxstyle-md.box5 a:hover,
.brand-purple .boxstyle-lg.box5 a:hover,
.brand-purple .boxstyle_.box5 a:focus,
.brand-purple .boxstyle-xs.box5 a:focus,
.brand-purple .boxstyle-sm.box5 a:focus,
.brand-purple .boxstyle-md.box5 a:focus,
.brand-purple .boxstyle-lg.box5 a:focus,
.brand-purple .boxstyle_.box5 a:active,
.brand-purple .boxstyle-xs.box5 a:active,
.brand-purple .boxstyle-sm.box5 a:active,
.brand-purple .boxstyle-md.box5 a:active,
.brand-purple .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-purple .pagination > li a,
.brand-purple .pagination > li span {
  color: #3C1053;
}
.brand-purple .pagination > li.active > a,
.brand-purple .pagination > li.active > span:not(.sr-only),
.brand-purple .pagination > li.active > a:hover,
.brand-purple .pagination > li.active > span:not(.sr-only):hover,
.brand-purple .pagination > li.active > a:focus,
.brand-purple .pagination > li.active > span:not(.sr-only):focus {
  background-color: #3C1053;
  border-color: #3C1053;
  color: white;
}
.brand-purple .nav-tabs {
  border-bottom-color: #9e88a9;
}
.brand-purple .nav-tabs > li > a {
  background-color: #d8cfdd;
  border-color: #9e88a9;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-purple .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #8a7098;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-purple .nav-tabs > li > a:hover,
.brand-purple .nav-tabs > li > a:focus {
  background-color: #ece7ee;
  border-color: #b19fba #b19fba #9e88a9;
}
.brand-purple .nav-tabs > li > a:hover::before,
.brand-purple .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-purple .nav-tabs > li.open > a,
.brand-purple .nav-tabs > li.open > a:hover,
.brand-purple .nav-tabs > li.open > a:focus {
  background-color: #ece7ee;
  border-color: #b19fba #b19fba #9e88a9;
}
.brand-purple .nav-tabs > li.open > a::before,
.brand-purple .nav-tabs > li.open > a:hover::before,
.brand-purple .nav-tabs > li.open > a:focus::before {
  background: #3C1053;
  width: 100%;
}
.brand-purple .nav-tabs > li.active > a,
.brand-purple .nav-tabs > li.active > a:hover,
.brand-purple .nav-tabs > li.active > a:focus {
  border-color: #9e88a9 #9e88a9 transparent;
}
.brand-purple .nav-tabs > li.active > a::before,
.brand-purple .nav-tabs > li.active > a:hover::before,
.brand-purple .nav-tabs > li.active > a:focus::before {
  background: #3C1053;
  width: 100%;
}
.brand-purple .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-purple .nav-tabs > li.disabled > a:hover,
.brand-purple .nav-tabs > li.disabled > a:focus {
  background-color: #d8cfdd;
}
.brand-purple .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-purple .nav-pills > li.active > a,
.brand-purple .nav-pills > li.active > a:hover,
.brand-purple .nav-pills > li.active > a:focus {
  background-color: #3C1053;
  color: white;
}
.brand-purple .list-group-item.active,
.brand-purple .list-group-item.active:hover,
.brand-purple .list-group-item.active:focus {
  background-color: #3C1053;
  border-color: #3C1053;
  color: white;
}
.brand-purple .id7-koan-spinner__neon--top,
.brand-purple .id7-koan-spinner__neon--bottom {
  stroke: #3C1053;
}
.brand-gray h1,
.brand-gray h2,
.brand-gray h3,
.brand-gray h4,
.brand-gray h5,
.brand-gray h6,
.brand-gray .h1,
.brand-gray .h2,
.brand-gray .h3,
.brand-gray .h4,
.brand-gray .h5,
.brand-gray .h6 {
  color: #58595B;
}
.brand-gray a,
.brand-gray a:link,
.brand-gray a:visited {
  color: #58595B;
}
.brand-gray a:hover,
.brand-gray a:focus,
.brand-gray a:active {
  color: #262627;
}
.brand-gray .popover button.close {
  color: #58595B;
}
.brand-gray .popover button.close:hover {
  color: #262627;
}
.brand-gray a:hover .new-window-link,
.brand-gray a:hover .insecure-link {
  color: #262627;
}
.brand-gray .btn-link {
  color: #58595B;
}
.brand-gray .btn-link:hover,
.brand-gray .btn-link:focus {
  color: #262627;
}
.brand-gray a,
.brand-gray a:link,
.brand-gray a:visited {
  -webkit-text-decoration-color: #8a8b8c;
  text-decoration-color: #8a8b8c;
}
.brand-gray a:hover,
.brand-gray a:focus,
.brand-gray a:active {
  -webkit-text-decoration-color: #262627;
  text-decoration-color: #262627;
}
.brand-gray blockquote.quotes {
  color: #262627;
}
.brand-gray blockquote.quotes::before {
  color: #262627;
}
.brand-gray .brand-bg {
  background: #58595B;
  color: white;
}
.brand-gray .brand-bg a,
.brand-gray .brand-bg a:link,
.brand-gray .brand-bg a:visited {
  color: white !important;
}
.brand-gray .brand-bg a:hover,
.brand-gray .brand-bg a:focus,
.brand-gray .brand-bg a:active {
  color: #cccccc !important;
}
.brand-gray .brand-bg .popover button.close {
  color: white !important;
}
.brand-gray .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-gray .brand-bg a:hover .new-window-link,
.brand-gray .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-gray .brand-bg .btn-link {
  color: white !important;
}
.brand-gray .brand-bg .btn-link:hover,
.brand-gray .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-gray .brand-bg a,
.brand-gray .brand-bg a:link,
.brand-gray .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-gray .brand-bg a:hover,
.brand-gray .brand-bg a:focus,
.brand-gray .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-gray .brand-text {
  color: #58595B;
}
.brand-gray .brand-text a,
.brand-gray .brand-text a:link,
.brand-gray .brand-text a:visited {
  color: #58595B !important;
}
.brand-gray .brand-text a:hover,
.brand-gray .brand-text a:focus,
.brand-gray .brand-text a:active {
  color: #262627 !important;
}
.brand-gray .brand-text .popover button.close {
  color: #58595B !important;
}
.brand-gray .brand-text .popover button.close:hover {
  color: #262627 !important;
}
.brand-gray .brand-text a:hover .new-window-link,
.brand-gray .brand-text a:hover .insecure-link {
  color: #262627 !important;
}
.brand-gray .brand-text .btn-link {
  color: #58595B !important;
}
.brand-gray .brand-text .btn-link:hover,
.brand-gray .brand-text .btn-link:focus {
  color: #262627 !important;
}
.brand-gray .brand-text a,
.brand-gray .brand-text a:link,
.brand-gray .brand-text a:visited {
  -webkit-text-decoration-color: #8a8b8c !important;
  text-decoration-color: #8a8b8c !important;
}
.brand-gray .brand-text a:hover,
.brand-gray .brand-text a:focus,
.brand-gray .brand-text a:active {
  -webkit-text-decoration-color: #262627 !important;
  text-decoration-color: #262627 !important;
}
.brand-gray .brand-border {
  border-color: #58595B;
}
.brand-gray .btn-brand {
  color: white;
  background-color: #58595B;
  border-color: #4b4c4e;
  color: white !important;
}
.brand-gray .btn-brand:focus,
.brand-gray .btn-brand.focus {
  color: white;
  background-color: #3f4041;
  border-color: #0d0d0d;
}
.brand-gray .btn-brand:hover {
  color: white;
  background-color: #3f4041;
  border-color: #2d2e2f;
}
.brand-gray .btn-brand:active,
.brand-gray .btn-brand.active,
.open > .dropdown-toggle.brand-gray .btn-brand {
  color: white;
  background-color: #3f4041;
  background-image: none;
  border-color: #2d2e2f;
}
.brand-gray .btn-brand:active:hover,
.brand-gray .btn-brand.active:hover,
.open > .dropdown-toggle.brand-gray .btn-brand:hover,
.brand-gray .btn-brand:active:focus,
.brand-gray .btn-brand.active:focus,
.open > .dropdown-toggle.brand-gray .btn-brand:focus,
.brand-gray .btn-brand:active.focus,
.brand-gray .btn-brand.active.focus,
.open > .dropdown-toggle.brand-gray .btn-brand.focus {
  color: white;
  background-color: #2d2e2f;
  border-color: #0d0d0d;
}
.brand-gray .btn-brand.disabled:hover,
.brand-gray .btn-brand[disabled]:hover,
fieldset[disabled] .brand-gray .btn-brand:hover,
.brand-gray .btn-brand.disabled:focus,
.brand-gray .btn-brand[disabled]:focus,
fieldset[disabled] .brand-gray .btn-brand:focus,
.brand-gray .btn-brand.disabled.focus,
.brand-gray .btn-brand[disabled].focus,
fieldset[disabled] .brand-gray .btn-brand.focus {
  background-color: #58595B;
  border-color: #4b4c4e;
}
.brand-gray .btn-brand .badge {
  color: #58595B;
  background-color: white;
}
.brand-gray .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-gray .id7-utility-masthead {
  background-color: #8a8b8c;
}
.brand-gray .id7-utility-masthead::after {
  background-color: #58595B;
}
body.id7-point-1 .brand-gray .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-gray .id7-navigation .navbar-primary {
  background-color: #58595B;
}
.brand-gray .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gray .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #b9b9ba;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #464749;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #b9b9ba;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-gray .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-gray .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #464749;
}
.brand-gray .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-gray .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-gray .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-gray .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #464749;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #58595B;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #464749;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #464749;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #464749;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #464749;
  background-image: none;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #b9b9ba;
}
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gray .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #464749;
}
.brand-gray .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .btn-link:hover,
.brand-gray .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-gray .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gray .id7-navigation .navbar-primary .btn-link:hover,
.brand-gray .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gray .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-gray .navbar-primary .navbar-brand {
  background-color: #58595B;
}
.brand-gray .navbar-secondary {
  background-color: #b9b9ba;
}
.brand-gray .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gray .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gray .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gray .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-gray .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-gray .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-gray .navbar-secondary .navbar-nav > li:hover,
.brand-gray .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #cececf;
}
.brand-gray .navbar-secondary .navbar-nav > li > a,
.brand-gray .navbar-secondary .navbar-nav > li > a:hover,
.brand-gray .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gray .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-gray .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gray .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gray .navbar-secondary .navbar-nav > .disabled,
.brand-gray .navbar-secondary .navbar-nav > .disabled:hover,
.brand-gray .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #cececf;
}
.brand-gray .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gray .navbar-secondary .navbar-toggle:hover,
.brand-gray .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gray .navbar-secondary .navbar-nav > .open,
.brand-gray .navbar-secondary .navbar-nav > .open:hover,
.brand-gray .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #cececf;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #b9b9ba;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #949495;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #949495;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #cececf;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #cececf;
  background-image: none;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gray .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #cececf;
}
.brand-gray .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-gray .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-gray .navbar-secondary .btn-link {
  color: #202020;
}
.brand-gray .navbar-secondary .btn-link:hover,
.brand-gray .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-gray .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gray .navbar-secondary .btn-link:hover,
.brand-gray .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gray .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-gray .navbar-secondary .navbar-nav > li::after {
  color: #58595B;
}
.brand-gray .navbar-secondary .navbar-nav > li:first-child {
  background-color: #58595B;
  color: white;
}
.brand-gray .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #58595B;
}
.brand-gray .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #58595B;
}
.brand-gray .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-gray .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gray .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gray .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gray .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-gray .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-gray .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-gray .navbar-tertiary .navbar-nav > li:hover,
.brand-gray .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gray .navbar-tertiary .navbar-nav > li > a,
.brand-gray .navbar-tertiary .navbar-nav > li > a:hover,
.brand-gray .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gray .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-gray .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gray .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gray .navbar-tertiary .navbar-nav > .disabled,
.brand-gray .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-gray .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gray .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gray .navbar-tertiary .navbar-toggle:hover,
.brand-gray .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gray .navbar-tertiary .navbar-nav > .open,
.brand-gray .navbar-tertiary .navbar-nav > .open:hover,
.brand-gray .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gray .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gray .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-gray .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-gray .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-gray .navbar-tertiary .btn-link:hover,
.brand-gray .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-gray .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gray .navbar-tertiary .btn-link:hover,
.brand-gray .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gray .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-gray.bordered .id7-left-border,
.brand-gray.bordered .id7-right-border {
  border-color: rgba(88, 89, 91, 0.3);
}
.brand-gray .id7-page-footer .id7-app-footer {
  background: #58595B;
  color: white;
}
.brand-gray .id7-page-footer .id7-app-footer h1,
.brand-gray .id7-page-footer .id7-app-footer h2,
.brand-gray .id7-page-footer .id7-app-footer h3,
.brand-gray .id7-page-footer .id7-app-footer h4,
.brand-gray .id7-page-footer .id7-app-footer h5,
.brand-gray .id7-page-footer .id7-app-footer h6,
.brand-gray .id7-page-footer .id7-app-footer .h1,
.brand-gray .id7-page-footer .id7-app-footer .h2,
.brand-gray .id7-page-footer .id7-app-footer .h3,
.brand-gray .id7-page-footer .id7-app-footer .h4,
.brand-gray .id7-page-footer .id7-app-footer .h5,
.brand-gray .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-gray .id7-page-footer .id7-app-footer a,
.brand-gray .id7-page-footer .id7-app-footer a:link,
.brand-gray .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-gray .id7-page-footer .id7-app-footer a:hover,
.brand-gray .id7-page-footer .id7-app-footer a:focus,
.brand-gray .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-gray .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-gray .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-gray .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-gray .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-gray .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-gray .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-gray .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-gray .id7-page-footer .id7-app-footer a,
.brand-gray .id7-page-footer .id7-app-footer a:link,
.brand-gray .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-gray .id7-page-footer .id7-app-footer a:hover,
.brand-gray .id7-page-footer .id7-app-footer a:focus,
.brand-gray .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-gray .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #58595B;
}
.brand-gray .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-gray .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-gray .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gray .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-gray .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-gray .id7-search .fa,
.brand-gray .id7-search .fas {
  color: #58595B;
  color: rgba(88, 89, 91, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-gray .id7-search .form-control:hover + .fa,
.brand-gray .id7-search .typeahead:hover + .fa,
.brand-gray .id7-search .fa:hover,
.brand-gray .id7-search .form-control:hover + .fas,
.brand-gray .id7-search .typeahead:hover + .fas,
.brand-gray .id7-search .fas:hover {
  color: #58595B;
}
.brand-gray .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #58595B;
  color: white;
}
.brand-gray .form-control:focus {
  border-color: #58595B;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(88, 89, 91, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(88, 89, 91, 0.6);
}
.brand-gray .carousel .carousel-indicators li::after {
  color: #58595B;
  color: rgba(88, 89, 91, 0.3);
}
.brand-gray .carousel .carousel-indicators li.active::after {
  color: #58595B;
}
@media (max-width: 767px) {
  .brand-gray .boxstyle_.box1,
  .brand-gray .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gray .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gray .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gray .boxstyle_.box1,
.brand-gray .boxstyle-xs.box1,
.brand-gray .boxstyle-sm.box1,
.brand-gray .boxstyle-md.box1,
.brand-gray .boxstyle-lg.box1 {
  border: 0;
  background: #e6e6e6;
  color: #202020;
}
@media print {
  .brand-gray .boxstyle_.box1,
  .brand-gray .boxstyle-xs.box1,
  .brand-gray .boxstyle-sm.box1,
  .brand-gray .boxstyle-md.box1,
  .brand-gray .boxstyle-lg.box1 {
    border: 1px solid #e6e6e6;
  }
}
.brand-gray .boxstyle_.box1 h1,
.brand-gray .boxstyle-xs.box1 h1,
.brand-gray .boxstyle-sm.box1 h1,
.brand-gray .boxstyle-md.box1 h1,
.brand-gray .boxstyle-lg.box1 h1,
.brand-gray .boxstyle_.box1 h2,
.brand-gray .boxstyle-xs.box1 h2,
.brand-gray .boxstyle-sm.box1 h2,
.brand-gray .boxstyle-md.box1 h2,
.brand-gray .boxstyle-lg.box1 h2,
.brand-gray .boxstyle_.box1 h3,
.brand-gray .boxstyle-xs.box1 h3,
.brand-gray .boxstyle-sm.box1 h3,
.brand-gray .boxstyle-md.box1 h3,
.brand-gray .boxstyle-lg.box1 h3,
.brand-gray .boxstyle_.box1 h4,
.brand-gray .boxstyle-xs.box1 h4,
.brand-gray .boxstyle-sm.box1 h4,
.brand-gray .boxstyle-md.box1 h4,
.brand-gray .boxstyle-lg.box1 h4,
.brand-gray .boxstyle_.box1 h5,
.brand-gray .boxstyle-xs.box1 h5,
.brand-gray .boxstyle-sm.box1 h5,
.brand-gray .boxstyle-md.box1 h5,
.brand-gray .boxstyle-lg.box1 h5,
.brand-gray .boxstyle_.box1 h6,
.brand-gray .boxstyle-xs.box1 h6,
.brand-gray .boxstyle-sm.box1 h6,
.brand-gray .boxstyle-md.box1 h6,
.brand-gray .boxstyle-lg.box1 h6,
.brand-gray .boxstyle_.box1 .h1,
.brand-gray .boxstyle-xs.box1 .h1,
.brand-gray .boxstyle-sm.box1 .h1,
.brand-gray .boxstyle-md.box1 .h1,
.brand-gray .boxstyle-lg.box1 .h1,
.brand-gray .boxstyle_.box1 .h2,
.brand-gray .boxstyle-xs.box1 .h2,
.brand-gray .boxstyle-sm.box1 .h2,
.brand-gray .boxstyle-md.box1 .h2,
.brand-gray .boxstyle-lg.box1 .h2,
.brand-gray .boxstyle_.box1 .h3,
.brand-gray .boxstyle-xs.box1 .h3,
.brand-gray .boxstyle-sm.box1 .h3,
.brand-gray .boxstyle-md.box1 .h3,
.brand-gray .boxstyle-lg.box1 .h3,
.brand-gray .boxstyle_.box1 .h4,
.brand-gray .boxstyle-xs.box1 .h4,
.brand-gray .boxstyle-sm.box1 .h4,
.brand-gray .boxstyle-md.box1 .h4,
.brand-gray .boxstyle-lg.box1 .h4,
.brand-gray .boxstyle_.box1 .h5,
.brand-gray .boxstyle-xs.box1 .h5,
.brand-gray .boxstyle-sm.box1 .h5,
.brand-gray .boxstyle-md.box1 .h5,
.brand-gray .boxstyle-lg.box1 .h5,
.brand-gray .boxstyle_.box1 .h6,
.brand-gray .boxstyle-xs.box1 .h6,
.brand-gray .boxstyle-sm.box1 .h6,
.brand-gray .boxstyle-md.box1 .h6,
.brand-gray .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-gray .boxstyle_.box1 blockquote.quotes,
.brand-gray .boxstyle-xs.box1 blockquote.quotes,
.brand-gray .boxstyle-sm.box1 blockquote.quotes,
.brand-gray .boxstyle-md.box1 blockquote.quotes,
.brand-gray .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-gray .boxstyle_.box1 a,
.brand-gray .boxstyle-xs.box1 a,
.brand-gray .boxstyle-sm.box1 a,
.brand-gray .boxstyle-md.box1 a,
.brand-gray .boxstyle-lg.box1 a,
.brand-gray .boxstyle_.box1 a:link,
.brand-gray .boxstyle-xs.box1 a:link,
.brand-gray .boxstyle-sm.box1 a:link,
.brand-gray .boxstyle-md.box1 a:link,
.brand-gray .boxstyle-lg.box1 a:link,
.brand-gray .boxstyle_.box1 a:visited,
.brand-gray .boxstyle-xs.box1 a:visited,
.brand-gray .boxstyle-sm.box1 a:visited,
.brand-gray .boxstyle-md.box1 a:visited,
.brand-gray .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-gray .boxstyle_.box1 a:hover,
.brand-gray .boxstyle-xs.box1 a:hover,
.brand-gray .boxstyle-sm.box1 a:hover,
.brand-gray .boxstyle-md.box1 a:hover,
.brand-gray .boxstyle-lg.box1 a:hover,
.brand-gray .boxstyle_.box1 a:focus,
.brand-gray .boxstyle-xs.box1 a:focus,
.brand-gray .boxstyle-sm.box1 a:focus,
.brand-gray .boxstyle-md.box1 a:focus,
.brand-gray .boxstyle-lg.box1 a:focus,
.brand-gray .boxstyle_.box1 a:active,
.brand-gray .boxstyle-xs.box1 a:active,
.brand-gray .boxstyle-sm.box1 a:active,
.brand-gray .boxstyle-md.box1 a:active,
.brand-gray .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-gray .boxstyle_.box1 .popover button.close,
.brand-gray .boxstyle-xs.box1 .popover button.close,
.brand-gray .boxstyle-sm.box1 .popover button.close,
.brand-gray .boxstyle-md.box1 .popover button.close,
.brand-gray .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-gray .boxstyle_.box1 .popover button.close:hover,
.brand-gray .boxstyle-xs.box1 .popover button.close:hover,
.brand-gray .boxstyle-sm.box1 .popover button.close:hover,
.brand-gray .boxstyle-md.box1 .popover button.close:hover,
.brand-gray .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-gray .boxstyle_.box1 a:hover .new-window-link,
.brand-gray .boxstyle-xs.box1 a:hover .new-window-link,
.brand-gray .boxstyle-sm.box1 a:hover .new-window-link,
.brand-gray .boxstyle-md.box1 a:hover .new-window-link,
.brand-gray .boxstyle-lg.box1 a:hover .new-window-link,
.brand-gray .boxstyle_.box1 a:hover .insecure-link,
.brand-gray .boxstyle-xs.box1 a:hover .insecure-link,
.brand-gray .boxstyle-sm.box1 a:hover .insecure-link,
.brand-gray .boxstyle-md.box1 a:hover .insecure-link,
.brand-gray .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-gray .boxstyle_.box1 .btn-link,
.brand-gray .boxstyle-xs.box1 .btn-link,
.brand-gray .boxstyle-sm.box1 .btn-link,
.brand-gray .boxstyle-md.box1 .btn-link,
.brand-gray .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-gray .boxstyle_.box1 .btn-link:hover,
.brand-gray .boxstyle-xs.box1 .btn-link:hover,
.brand-gray .boxstyle-sm.box1 .btn-link:hover,
.brand-gray .boxstyle-md.box1 .btn-link:hover,
.brand-gray .boxstyle-lg.box1 .btn-link:hover,
.brand-gray .boxstyle_.box1 .btn-link:focus,
.brand-gray .boxstyle-xs.box1 .btn-link:focus,
.brand-gray .boxstyle-sm.box1 .btn-link:focus,
.brand-gray .boxstyle-md.box1 .btn-link:focus,
.brand-gray .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-gray .boxstyle_.box1 a,
.brand-gray .boxstyle-xs.box1 a,
.brand-gray .boxstyle-sm.box1 a,
.brand-gray .boxstyle-md.box1 a,
.brand-gray .boxstyle-lg.box1 a,
.brand-gray .boxstyle_.box1 a:link,
.brand-gray .boxstyle-xs.box1 a:link,
.brand-gray .boxstyle-sm.box1 a:link,
.brand-gray .boxstyle-md.box1 a:link,
.brand-gray .boxstyle-lg.box1 a:link,
.brand-gray .boxstyle_.box1 a:visited,
.brand-gray .boxstyle-xs.box1 a:visited,
.brand-gray .boxstyle-sm.box1 a:visited,
.brand-gray .boxstyle-md.box1 a:visited,
.brand-gray .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gray .boxstyle_.box1 a:hover,
.brand-gray .boxstyle-xs.box1 a:hover,
.brand-gray .boxstyle-sm.box1 a:hover,
.brand-gray .boxstyle-md.box1 a:hover,
.brand-gray .boxstyle-lg.box1 a:hover,
.brand-gray .boxstyle_.box1 a:focus,
.brand-gray .boxstyle-xs.box1 a:focus,
.brand-gray .boxstyle-sm.box1 a:focus,
.brand-gray .boxstyle-md.box1 a:focus,
.brand-gray .boxstyle-lg.box1 a:focus,
.brand-gray .boxstyle_.box1 a:active,
.brand-gray .boxstyle-xs.box1 a:active,
.brand-gray .boxstyle-sm.box1 a:active,
.brand-gray .boxstyle-md.box1 a:active,
.brand-gray .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-gray .boxstyle_.box2,
  .brand-gray .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gray .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gray .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gray .boxstyle_.box2,
.brand-gray .boxstyle-xs.box2,
.brand-gray .boxstyle-sm.box2,
.brand-gray .boxstyle-md.box2,
.brand-gray .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-gray .boxstyle_.box2,
  .brand-gray .boxstyle-xs.box2,
  .brand-gray .boxstyle-sm.box2,
  .brand-gray .boxstyle-md.box2,
  .brand-gray .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-gray .boxstyle_.box2 h1,
.brand-gray .boxstyle-xs.box2 h1,
.brand-gray .boxstyle-sm.box2 h1,
.brand-gray .boxstyle-md.box2 h1,
.brand-gray .boxstyle-lg.box2 h1,
.brand-gray .boxstyle_.box2 h2,
.brand-gray .boxstyle-xs.box2 h2,
.brand-gray .boxstyle-sm.box2 h2,
.brand-gray .boxstyle-md.box2 h2,
.brand-gray .boxstyle-lg.box2 h2,
.brand-gray .boxstyle_.box2 h3,
.brand-gray .boxstyle-xs.box2 h3,
.brand-gray .boxstyle-sm.box2 h3,
.brand-gray .boxstyle-md.box2 h3,
.brand-gray .boxstyle-lg.box2 h3,
.brand-gray .boxstyle_.box2 h4,
.brand-gray .boxstyle-xs.box2 h4,
.brand-gray .boxstyle-sm.box2 h4,
.brand-gray .boxstyle-md.box2 h4,
.brand-gray .boxstyle-lg.box2 h4,
.brand-gray .boxstyle_.box2 h5,
.brand-gray .boxstyle-xs.box2 h5,
.brand-gray .boxstyle-sm.box2 h5,
.brand-gray .boxstyle-md.box2 h5,
.brand-gray .boxstyle-lg.box2 h5,
.brand-gray .boxstyle_.box2 h6,
.brand-gray .boxstyle-xs.box2 h6,
.brand-gray .boxstyle-sm.box2 h6,
.brand-gray .boxstyle-md.box2 h6,
.brand-gray .boxstyle-lg.box2 h6,
.brand-gray .boxstyle_.box2 .h1,
.brand-gray .boxstyle-xs.box2 .h1,
.brand-gray .boxstyle-sm.box2 .h1,
.brand-gray .boxstyle-md.box2 .h1,
.brand-gray .boxstyle-lg.box2 .h1,
.brand-gray .boxstyle_.box2 .h2,
.brand-gray .boxstyle-xs.box2 .h2,
.brand-gray .boxstyle-sm.box2 .h2,
.brand-gray .boxstyle-md.box2 .h2,
.brand-gray .boxstyle-lg.box2 .h2,
.brand-gray .boxstyle_.box2 .h3,
.brand-gray .boxstyle-xs.box2 .h3,
.brand-gray .boxstyle-sm.box2 .h3,
.brand-gray .boxstyle-md.box2 .h3,
.brand-gray .boxstyle-lg.box2 .h3,
.brand-gray .boxstyle_.box2 .h4,
.brand-gray .boxstyle-xs.box2 .h4,
.brand-gray .boxstyle-sm.box2 .h4,
.brand-gray .boxstyle-md.box2 .h4,
.brand-gray .boxstyle-lg.box2 .h4,
.brand-gray .boxstyle_.box2 .h5,
.brand-gray .boxstyle-xs.box2 .h5,
.brand-gray .boxstyle-sm.box2 .h5,
.brand-gray .boxstyle-md.box2 .h5,
.brand-gray .boxstyle-lg.box2 .h5,
.brand-gray .boxstyle_.box2 .h6,
.brand-gray .boxstyle-xs.box2 .h6,
.brand-gray .boxstyle-sm.box2 .h6,
.brand-gray .boxstyle-md.box2 .h6,
.brand-gray .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-gray .boxstyle_.box2 blockquote.quotes,
.brand-gray .boxstyle-xs.box2 blockquote.quotes,
.brand-gray .boxstyle-sm.box2 blockquote.quotes,
.brand-gray .boxstyle-md.box2 blockquote.quotes,
.brand-gray .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-gray .boxstyle_.box2 a,
.brand-gray .boxstyle-xs.box2 a,
.brand-gray .boxstyle-sm.box2 a,
.brand-gray .boxstyle-md.box2 a,
.brand-gray .boxstyle-lg.box2 a,
.brand-gray .boxstyle_.box2 a:link,
.brand-gray .boxstyle-xs.box2 a:link,
.brand-gray .boxstyle-sm.box2 a:link,
.brand-gray .boxstyle-md.box2 a:link,
.brand-gray .boxstyle-lg.box2 a:link,
.brand-gray .boxstyle_.box2 a:visited,
.brand-gray .boxstyle-xs.box2 a:visited,
.brand-gray .boxstyle-sm.box2 a:visited,
.brand-gray .boxstyle-md.box2 a:visited,
.brand-gray .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-gray .boxstyle_.box2 a:hover,
.brand-gray .boxstyle-xs.box2 a:hover,
.brand-gray .boxstyle-sm.box2 a:hover,
.brand-gray .boxstyle-md.box2 a:hover,
.brand-gray .boxstyle-lg.box2 a:hover,
.brand-gray .boxstyle_.box2 a:focus,
.brand-gray .boxstyle-xs.box2 a:focus,
.brand-gray .boxstyle-sm.box2 a:focus,
.brand-gray .boxstyle-md.box2 a:focus,
.brand-gray .boxstyle-lg.box2 a:focus,
.brand-gray .boxstyle_.box2 a:active,
.brand-gray .boxstyle-xs.box2 a:active,
.brand-gray .boxstyle-sm.box2 a:active,
.brand-gray .boxstyle-md.box2 a:active,
.brand-gray .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-gray .boxstyle_.box2 .popover button.close,
.brand-gray .boxstyle-xs.box2 .popover button.close,
.brand-gray .boxstyle-sm.box2 .popover button.close,
.brand-gray .boxstyle-md.box2 .popover button.close,
.brand-gray .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-gray .boxstyle_.box2 .popover button.close:hover,
.brand-gray .boxstyle-xs.box2 .popover button.close:hover,
.brand-gray .boxstyle-sm.box2 .popover button.close:hover,
.brand-gray .boxstyle-md.box2 .popover button.close:hover,
.brand-gray .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-gray .boxstyle_.box2 a:hover .new-window-link,
.brand-gray .boxstyle-xs.box2 a:hover .new-window-link,
.brand-gray .boxstyle-sm.box2 a:hover .new-window-link,
.brand-gray .boxstyle-md.box2 a:hover .new-window-link,
.brand-gray .boxstyle-lg.box2 a:hover .new-window-link,
.brand-gray .boxstyle_.box2 a:hover .insecure-link,
.brand-gray .boxstyle-xs.box2 a:hover .insecure-link,
.brand-gray .boxstyle-sm.box2 a:hover .insecure-link,
.brand-gray .boxstyle-md.box2 a:hover .insecure-link,
.brand-gray .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-gray .boxstyle_.box2 .btn-link,
.brand-gray .boxstyle-xs.box2 .btn-link,
.brand-gray .boxstyle-sm.box2 .btn-link,
.brand-gray .boxstyle-md.box2 .btn-link,
.brand-gray .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-gray .boxstyle_.box2 .btn-link:hover,
.brand-gray .boxstyle-xs.box2 .btn-link:hover,
.brand-gray .boxstyle-sm.box2 .btn-link:hover,
.brand-gray .boxstyle-md.box2 .btn-link:hover,
.brand-gray .boxstyle-lg.box2 .btn-link:hover,
.brand-gray .boxstyle_.box2 .btn-link:focus,
.brand-gray .boxstyle-xs.box2 .btn-link:focus,
.brand-gray .boxstyle-sm.box2 .btn-link:focus,
.brand-gray .boxstyle-md.box2 .btn-link:focus,
.brand-gray .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-gray .boxstyle_.box2 a,
.brand-gray .boxstyle-xs.box2 a,
.brand-gray .boxstyle-sm.box2 a,
.brand-gray .boxstyle-md.box2 a,
.brand-gray .boxstyle-lg.box2 a,
.brand-gray .boxstyle_.box2 a:link,
.brand-gray .boxstyle-xs.box2 a:link,
.brand-gray .boxstyle-sm.box2 a:link,
.brand-gray .boxstyle-md.box2 a:link,
.brand-gray .boxstyle-lg.box2 a:link,
.brand-gray .boxstyle_.box2 a:visited,
.brand-gray .boxstyle-xs.box2 a:visited,
.brand-gray .boxstyle-sm.box2 a:visited,
.brand-gray .boxstyle-md.box2 a:visited,
.brand-gray .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gray .boxstyle_.box2 a:hover,
.brand-gray .boxstyle-xs.box2 a:hover,
.brand-gray .boxstyle-sm.box2 a:hover,
.brand-gray .boxstyle-md.box2 a:hover,
.brand-gray .boxstyle-lg.box2 a:hover,
.brand-gray .boxstyle_.box2 a:focus,
.brand-gray .boxstyle-xs.box2 a:focus,
.brand-gray .boxstyle-sm.box2 a:focus,
.brand-gray .boxstyle-md.box2 a:focus,
.brand-gray .boxstyle-lg.box2 a:focus,
.brand-gray .boxstyle_.box2 a:active,
.brand-gray .boxstyle-xs.box2 a:active,
.brand-gray .boxstyle-sm.box2 a:active,
.brand-gray .boxstyle-md.box2 a:active,
.brand-gray .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gray .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #58595B;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-gray .boxstyle_.box3,
  .brand-gray .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #58595B;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-gray .boxstyle-md.box3 {
    background: none;
    border: 1px solid #58595B;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-gray .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #58595B;
    color: inherit;
  }
}
.brand-gray .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-gray .boxstyle_.box4,
  .brand-gray .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-gray .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-gray .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-gray .boxstyle_.box5,
  .brand-gray .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gray .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gray .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gray .boxstyle_.box5,
.brand-gray .boxstyle-xs.box5,
.brand-gray .boxstyle-sm.box5,
.brand-gray .boxstyle-md.box5,
.brand-gray .boxstyle-lg.box5 {
  border: 0;
  background: #58595B;
  color: white;
}
@media print {
  .brand-gray .boxstyle_.box5,
  .brand-gray .boxstyle-xs.box5,
  .brand-gray .boxstyle-sm.box5,
  .brand-gray .boxstyle-md.box5,
  .brand-gray .boxstyle-lg.box5 {
    border: 1px solid #58595B;
  }
}
.brand-gray .boxstyle_.box5 h1,
.brand-gray .boxstyle-xs.box5 h1,
.brand-gray .boxstyle-sm.box5 h1,
.brand-gray .boxstyle-md.box5 h1,
.brand-gray .boxstyle-lg.box5 h1,
.brand-gray .boxstyle_.box5 h2,
.brand-gray .boxstyle-xs.box5 h2,
.brand-gray .boxstyle-sm.box5 h2,
.brand-gray .boxstyle-md.box5 h2,
.brand-gray .boxstyle-lg.box5 h2,
.brand-gray .boxstyle_.box5 h3,
.brand-gray .boxstyle-xs.box5 h3,
.brand-gray .boxstyle-sm.box5 h3,
.brand-gray .boxstyle-md.box5 h3,
.brand-gray .boxstyle-lg.box5 h3,
.brand-gray .boxstyle_.box5 h4,
.brand-gray .boxstyle-xs.box5 h4,
.brand-gray .boxstyle-sm.box5 h4,
.brand-gray .boxstyle-md.box5 h4,
.brand-gray .boxstyle-lg.box5 h4,
.brand-gray .boxstyle_.box5 h5,
.brand-gray .boxstyle-xs.box5 h5,
.brand-gray .boxstyle-sm.box5 h5,
.brand-gray .boxstyle-md.box5 h5,
.brand-gray .boxstyle-lg.box5 h5,
.brand-gray .boxstyle_.box5 h6,
.brand-gray .boxstyle-xs.box5 h6,
.brand-gray .boxstyle-sm.box5 h6,
.brand-gray .boxstyle-md.box5 h6,
.brand-gray .boxstyle-lg.box5 h6,
.brand-gray .boxstyle_.box5 .h1,
.brand-gray .boxstyle-xs.box5 .h1,
.brand-gray .boxstyle-sm.box5 .h1,
.brand-gray .boxstyle-md.box5 .h1,
.brand-gray .boxstyle-lg.box5 .h1,
.brand-gray .boxstyle_.box5 .h2,
.brand-gray .boxstyle-xs.box5 .h2,
.brand-gray .boxstyle-sm.box5 .h2,
.brand-gray .boxstyle-md.box5 .h2,
.brand-gray .boxstyle-lg.box5 .h2,
.brand-gray .boxstyle_.box5 .h3,
.brand-gray .boxstyle-xs.box5 .h3,
.brand-gray .boxstyle-sm.box5 .h3,
.brand-gray .boxstyle-md.box5 .h3,
.brand-gray .boxstyle-lg.box5 .h3,
.brand-gray .boxstyle_.box5 .h4,
.brand-gray .boxstyle-xs.box5 .h4,
.brand-gray .boxstyle-sm.box5 .h4,
.brand-gray .boxstyle-md.box5 .h4,
.brand-gray .boxstyle-lg.box5 .h4,
.brand-gray .boxstyle_.box5 .h5,
.brand-gray .boxstyle-xs.box5 .h5,
.brand-gray .boxstyle-sm.box5 .h5,
.brand-gray .boxstyle-md.box5 .h5,
.brand-gray .boxstyle-lg.box5 .h5,
.brand-gray .boxstyle_.box5 .h6,
.brand-gray .boxstyle-xs.box5 .h6,
.brand-gray .boxstyle-sm.box5 .h6,
.brand-gray .boxstyle-md.box5 .h6,
.brand-gray .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-gray .boxstyle_.box5 blockquote.quotes,
.brand-gray .boxstyle-xs.box5 blockquote.quotes,
.brand-gray .boxstyle-sm.box5 blockquote.quotes,
.brand-gray .boxstyle-md.box5 blockquote.quotes,
.brand-gray .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-gray .boxstyle_.box5 a,
.brand-gray .boxstyle-xs.box5 a,
.brand-gray .boxstyle-sm.box5 a,
.brand-gray .boxstyle-md.box5 a,
.brand-gray .boxstyle-lg.box5 a,
.brand-gray .boxstyle_.box5 a:link,
.brand-gray .boxstyle-xs.box5 a:link,
.brand-gray .boxstyle-sm.box5 a:link,
.brand-gray .boxstyle-md.box5 a:link,
.brand-gray .boxstyle-lg.box5 a:link,
.brand-gray .boxstyle_.box5 a:visited,
.brand-gray .boxstyle-xs.box5 a:visited,
.brand-gray .boxstyle-sm.box5 a:visited,
.brand-gray .boxstyle-md.box5 a:visited,
.brand-gray .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-gray .boxstyle_.box5 a:hover,
.brand-gray .boxstyle-xs.box5 a:hover,
.brand-gray .boxstyle-sm.box5 a:hover,
.brand-gray .boxstyle-md.box5 a:hover,
.brand-gray .boxstyle-lg.box5 a:hover,
.brand-gray .boxstyle_.box5 a:focus,
.brand-gray .boxstyle-xs.box5 a:focus,
.brand-gray .boxstyle-sm.box5 a:focus,
.brand-gray .boxstyle-md.box5 a:focus,
.brand-gray .boxstyle-lg.box5 a:focus,
.brand-gray .boxstyle_.box5 a:active,
.brand-gray .boxstyle-xs.box5 a:active,
.brand-gray .boxstyle-sm.box5 a:active,
.brand-gray .boxstyle-md.box5 a:active,
.brand-gray .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-gray .boxstyle_.box5 .popover button.close,
.brand-gray .boxstyle-xs.box5 .popover button.close,
.brand-gray .boxstyle-sm.box5 .popover button.close,
.brand-gray .boxstyle-md.box5 .popover button.close,
.brand-gray .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-gray .boxstyle_.box5 .popover button.close:hover,
.brand-gray .boxstyle-xs.box5 .popover button.close:hover,
.brand-gray .boxstyle-sm.box5 .popover button.close:hover,
.brand-gray .boxstyle-md.box5 .popover button.close:hover,
.brand-gray .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-gray .boxstyle_.box5 a:hover .new-window-link,
.brand-gray .boxstyle-xs.box5 a:hover .new-window-link,
.brand-gray .boxstyle-sm.box5 a:hover .new-window-link,
.brand-gray .boxstyle-md.box5 a:hover .new-window-link,
.brand-gray .boxstyle-lg.box5 a:hover .new-window-link,
.brand-gray .boxstyle_.box5 a:hover .insecure-link,
.brand-gray .boxstyle-xs.box5 a:hover .insecure-link,
.brand-gray .boxstyle-sm.box5 a:hover .insecure-link,
.brand-gray .boxstyle-md.box5 a:hover .insecure-link,
.brand-gray .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-gray .boxstyle_.box5 .btn-link,
.brand-gray .boxstyle-xs.box5 .btn-link,
.brand-gray .boxstyle-sm.box5 .btn-link,
.brand-gray .boxstyle-md.box5 .btn-link,
.brand-gray .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-gray .boxstyle_.box5 .btn-link:hover,
.brand-gray .boxstyle-xs.box5 .btn-link:hover,
.brand-gray .boxstyle-sm.box5 .btn-link:hover,
.brand-gray .boxstyle-md.box5 .btn-link:hover,
.brand-gray .boxstyle-lg.box5 .btn-link:hover,
.brand-gray .boxstyle_.box5 .btn-link:focus,
.brand-gray .boxstyle-xs.box5 .btn-link:focus,
.brand-gray .boxstyle-sm.box5 .btn-link:focus,
.brand-gray .boxstyle-md.box5 .btn-link:focus,
.brand-gray .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-gray .boxstyle_.box5 a,
.brand-gray .boxstyle-xs.box5 a,
.brand-gray .boxstyle-sm.box5 a,
.brand-gray .boxstyle-md.box5 a,
.brand-gray .boxstyle-lg.box5 a,
.brand-gray .boxstyle_.box5 a:link,
.brand-gray .boxstyle-xs.box5 a:link,
.brand-gray .boxstyle-sm.box5 a:link,
.brand-gray .boxstyle-md.box5 a:link,
.brand-gray .boxstyle-lg.box5 a:link,
.brand-gray .boxstyle_.box5 a:visited,
.brand-gray .boxstyle-xs.box5 a:visited,
.brand-gray .boxstyle-sm.box5 a:visited,
.brand-gray .boxstyle-md.box5 a:visited,
.brand-gray .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-gray .boxstyle_.box5 a:hover,
.brand-gray .boxstyle-xs.box5 a:hover,
.brand-gray .boxstyle-sm.box5 a:hover,
.brand-gray .boxstyle-md.box5 a:hover,
.brand-gray .boxstyle-lg.box5 a:hover,
.brand-gray .boxstyle_.box5 a:focus,
.brand-gray .boxstyle-xs.box5 a:focus,
.brand-gray .boxstyle-sm.box5 a:focus,
.brand-gray .boxstyle-md.box5 a:focus,
.brand-gray .boxstyle-lg.box5 a:focus,
.brand-gray .boxstyle_.box5 a:active,
.brand-gray .boxstyle-xs.box5 a:active,
.brand-gray .boxstyle-sm.box5 a:active,
.brand-gray .boxstyle-md.box5 a:active,
.brand-gray .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-gray .pagination > li a,
.brand-gray .pagination > li span {
  color: #58595B;
}
.brand-gray .pagination > li.active > a,
.brand-gray .pagination > li.active > span:not(.sr-only),
.brand-gray .pagination > li.active > a:hover,
.brand-gray .pagination > li.active > span:not(.sr-only):hover,
.brand-gray .pagination > li.active > a:focus,
.brand-gray .pagination > li.active > span:not(.sr-only):focus {
  background-color: #58595B;
  border-color: #58595B;
  color: white;
}
.brand-gray .nav-tabs {
  border-bottom-color: #acacad;
}
.brand-gray .nav-tabs > li > a {
  background-color: #dedede;
  border-color: #acacad;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-gray .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #9b9b9d;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-gray .nav-tabs > li > a:hover,
.brand-gray .nav-tabs > li > a:focus {
  background-color: #eeeeef;
  border-color: #bcbdbd #bcbdbd #acacad;
}
.brand-gray .nav-tabs > li > a:hover::before,
.brand-gray .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-gray .nav-tabs > li.open > a,
.brand-gray .nav-tabs > li.open > a:hover,
.brand-gray .nav-tabs > li.open > a:focus {
  background-color: #eeeeef;
  border-color: #bcbdbd #bcbdbd #acacad;
}
.brand-gray .nav-tabs > li.open > a::before,
.brand-gray .nav-tabs > li.open > a:hover::before,
.brand-gray .nav-tabs > li.open > a:focus::before {
  background: #58595B;
  width: 100%;
}
.brand-gray .nav-tabs > li.active > a,
.brand-gray .nav-tabs > li.active > a:hover,
.brand-gray .nav-tabs > li.active > a:focus {
  border-color: #acacad #acacad transparent;
}
.brand-gray .nav-tabs > li.active > a::before,
.brand-gray .nav-tabs > li.active > a:hover::before,
.brand-gray .nav-tabs > li.active > a:focus::before {
  background: #58595B;
  width: 100%;
}
.brand-gray .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-gray .nav-tabs > li.disabled > a:hover,
.brand-gray .nav-tabs > li.disabled > a:focus {
  background-color: #dedede;
}
.brand-gray .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-gray .nav-pills > li.active > a,
.brand-gray .nav-pills > li.active > a:hover,
.brand-gray .nav-pills > li.active > a:focus {
  background-color: #58595B;
  color: white;
}
.brand-gray .list-group-item.active,
.brand-gray .list-group-item.active:hover,
.brand-gray .list-group-item.active:focus {
  background-color: #58595B;
  border-color: #58595B;
  color: white;
}
.brand-gray .id7-koan-spinner__neon--top,
.brand-gray .id7-koan-spinner__neon--bottom {
  stroke: #58595B;
}
.brand-gold h1,
.brand-gold h2,
.brand-gold h3,
.brand-gold h4,
.brand-gold h5,
.brand-gold h6,
.brand-gold .h1,
.brand-gold .h2,
.brand-gold .h3,
.brand-gold .h4,
.brand-gold .h5,
.brand-gold .h6 {
  color: #202020;
}
.brand-gold a,
.brand-gold a:link,
.brand-gold a:visited {
  color: #202020;
}
.brand-gold a:hover,
.brand-gold a:focus,
.brand-gold a:active {
  color: #000000;
}
.brand-gold .popover button.close {
  color: #202020;
}
.brand-gold .popover button.close:hover {
  color: #000000;
}
.brand-gold a:hover .new-window-link,
.brand-gold a:hover .insecure-link {
  color: #000000;
}
.brand-gold .btn-link {
  color: #202020;
}
.brand-gold .btn-link:hover,
.brand-gold .btn-link:focus {
  color: #000000;
}
.brand-gold a,
.brand-gold a:link,
.brand-gold a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold a:hover,
.brand-gold a:focus,
.brand-gold a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold blockquote.quotes {
  color: #835e1a;
}
.brand-gold blockquote.quotes::before {
  color: #835e1a;
}
.brand-gold .brand-bg {
  background: #D69A2D;
  color: #202020;
}
.brand-gold .brand-bg a,
.brand-gold .brand-bg a:link,
.brand-gold .brand-bg a:visited {
  color: #202020 !important;
}
.brand-gold .brand-bg a:hover,
.brand-gold .brand-bg a:focus,
.brand-gold .brand-bg a:active {
  color: #000000 !important;
}
.brand-gold .brand-bg .popover button.close {
  color: #202020 !important;
}
.brand-gold .brand-bg .popover button.close:hover {
  color: #000000 !important;
}
.brand-gold .brand-bg a:hover .new-window-link,
.brand-gold .brand-bg a:hover .insecure-link {
  color: #000000 !important;
}
.brand-gold .brand-bg .btn-link {
  color: #202020 !important;
}
.brand-gold .brand-bg .btn-link:hover,
.brand-gold .brand-bg .btn-link:focus {
  color: #000000 !important;
}
.brand-gold .brand-bg a,
.brand-gold .brand-bg a:link,
.brand-gold .brand-bg a:visited {
  -webkit-text-decoration-color: #636363 !important;
  text-decoration-color: #636363 !important;
}
.brand-gold .brand-bg a:hover,
.brand-gold .brand-bg a:focus,
.brand-gold .brand-bg a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-gold .brand-text {
  color: #D69A2D;
}
.brand-gold .brand-text a,
.brand-gold .brand-text a:link,
.brand-gold .brand-text a:visited {
  color: #D69A2D !important;
}
.brand-gold .brand-text a:hover,
.brand-gold .brand-text a:focus,
.brand-gold .brand-text a:active {
  color: #835e1a !important;
}
.brand-gold .brand-text .popover button.close {
  color: #D69A2D !important;
}
.brand-gold .brand-text .popover button.close:hover {
  color: #835e1a !important;
}
.brand-gold .brand-text a:hover .new-window-link,
.brand-gold .brand-text a:hover .insecure-link {
  color: #835e1a !important;
}
.brand-gold .brand-text .btn-link {
  color: #D69A2D !important;
}
.brand-gold .brand-text .btn-link:hover,
.brand-gold .brand-text .btn-link:focus {
  color: #835e1a !important;
}
.brand-gold .brand-text a,
.brand-gold .brand-text a:link,
.brand-gold .brand-text a:visited {
  -webkit-text-decoration-color: #e2b86c !important;
  text-decoration-color: #e2b86c !important;
}
.brand-gold .brand-text a:hover,
.brand-gold .brand-text a:focus,
.brand-gold .brand-text a:active {
  -webkit-text-decoration-color: #835e1a !important;
  text-decoration-color: #835e1a !important;
}
.brand-gold .brand-border {
  border-color: #D69A2D;
}
.brand-gold .btn-brand {
  color: #202020;
  background-color: #D69A2D;
  border-color: #c38c26;
  color: #202020 !important;
}
.brand-gold .btn-brand:focus,
.brand-gold .btn-brand.focus {
  color: #202020;
  background-color: #ae7c22;
  border-color: #593f11;
}
.brand-gold .btn-brand:hover {
  color: #202020;
  background-color: #ae7c22;
  border-color: #90671c;
}
.brand-gold .btn-brand:active,
.brand-gold .btn-brand.active,
.open > .dropdown-toggle.brand-gold .btn-brand {
  color: #202020;
  background-color: #ae7c22;
  background-image: none;
  border-color: #90671c;
}
.brand-gold .btn-brand:active:hover,
.brand-gold .btn-brand.active:hover,
.open > .dropdown-toggle.brand-gold .btn-brand:hover,
.brand-gold .btn-brand:active:focus,
.brand-gold .btn-brand.active:focus,
.open > .dropdown-toggle.brand-gold .btn-brand:focus,
.brand-gold .btn-brand:active.focus,
.brand-gold .btn-brand.active.focus,
.open > .dropdown-toggle.brand-gold .btn-brand.focus {
  color: #202020;
  background-color: #90671c;
  border-color: #593f11;
}
.brand-gold .btn-brand.disabled:hover,
.brand-gold .btn-brand[disabled]:hover,
fieldset[disabled] .brand-gold .btn-brand:hover,
.brand-gold .btn-brand.disabled:focus,
.brand-gold .btn-brand[disabled]:focus,
fieldset[disabled] .brand-gold .btn-brand:focus,
.brand-gold .btn-brand.disabled.focus,
.brand-gold .btn-brand[disabled].focus,
fieldset[disabled] .brand-gold .btn-brand.focus {
  background-color: #D69A2D;
  border-color: #c38c26;
}
.brand-gold .btn-brand .badge {
  color: #D69A2D;
  background-color: #202020;
}
.brand-gold .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-gold .id7-utility-masthead {
  background-color: #e2b86c;
}
.brand-gold .id7-utility-masthead::after {
  background-color: #D69A2D;
}
body.id7-point-1 .brand-gold .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-gold .id7-navigation .navbar-primary {
  background-color: #D69A2D;
}
.brand-gold .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gold .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .navbar-text {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #eed5a7;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: #202020;
  background-color: #ab7b24;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #eed5a7;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-gold .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-gold .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #ab7b24;
}
.brand-gold .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-gold .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-gold .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-gold .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #ab7b24;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #D69A2D;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #ab7b24;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #ab7b24;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #ab7b24;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #ab7b24;
  background-image: none;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #eed5a7;
}
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gold .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #ab7b24;
}
.brand-gold .id7-navigation .navbar-primary .navbar-link {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .navbar-link:hover {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .btn-link {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .btn-link:hover,
.brand-gold .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-gold .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gold .id7-navigation .navbar-primary .btn-link:hover,
.brand-gold .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gold .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-gold .navbar-primary .navbar-brand {
  background-color: #D69A2D;
}
.brand-gold .navbar-secondary {
  background-color: #eed5a7;
}
.brand-gold .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gold .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gold .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gold .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-gold .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-gold .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-gold .navbar-secondary .navbar-nav > li:hover,
.brand-gold .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #f3e1c1;
}
.brand-gold .navbar-secondary .navbar-nav > li > a,
.brand-gold .navbar-secondary .navbar-nav > li > a:hover,
.brand-gold .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gold .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-gold .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gold .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gold .navbar-secondary .navbar-nav > .disabled,
.brand-gold .navbar-secondary .navbar-nav > .disabled:hover,
.brand-gold .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #f3e1c1;
}
.brand-gold .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gold .navbar-secondary .navbar-toggle:hover,
.brand-gold .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gold .navbar-secondary .navbar-nav > .open,
.brand-gold .navbar-secondary .navbar-nav > .open:hover,
.brand-gold .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #f3e1c1;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #eed5a7;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #beaa85;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #beaa85;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #f3e1c1;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #f3e1c1;
  background-image: none;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gold .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #f3e1c1;
}
.brand-gold .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-gold .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-gold .navbar-secondary .btn-link {
  color: #202020;
}
.brand-gold .navbar-secondary .btn-link:hover,
.brand-gold .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-gold .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gold .navbar-secondary .btn-link:hover,
.brand-gold .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gold .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-gold .navbar-secondary .navbar-nav > li::after {
  color: #D69A2D;
}
.brand-gold .navbar-secondary .navbar-nav > li:first-child {
  background-color: #D69A2D;
  color: white;
}
.brand-gold .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #D69A2D;
}
.brand-gold .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #D69A2D;
}
.brand-gold .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-gold .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gold .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gold .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gold .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-gold .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-gold .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-gold .navbar-tertiary .navbar-nav > li:hover,
.brand-gold .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold .navbar-tertiary .navbar-nav > li > a,
.brand-gold .navbar-tertiary .navbar-nav > li > a:hover,
.brand-gold .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gold .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-gold .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gold .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gold .navbar-tertiary .navbar-nav > .disabled,
.brand-gold .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-gold .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gold .navbar-tertiary .navbar-toggle:hover,
.brand-gold .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gold .navbar-tertiary .navbar-nav > .open,
.brand-gold .navbar-tertiary .navbar-nav > .open:hover,
.brand-gold .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gold .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-gold .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-gold .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-gold .navbar-tertiary .btn-link:hover,
.brand-gold .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-gold .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gold .navbar-tertiary .btn-link:hover,
.brand-gold .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gold .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-gold.bordered .id7-left-border,
.brand-gold.bordered .id7-right-border {
  border-color: rgba(214, 154, 45, 0.3);
}
.brand-gold .id7-page-footer .id7-app-footer {
  background: #D69A2D;
  color: white;
}
.brand-gold .id7-page-footer .id7-app-footer h1,
.brand-gold .id7-page-footer .id7-app-footer h2,
.brand-gold .id7-page-footer .id7-app-footer h3,
.brand-gold .id7-page-footer .id7-app-footer h4,
.brand-gold .id7-page-footer .id7-app-footer h5,
.brand-gold .id7-page-footer .id7-app-footer h6,
.brand-gold .id7-page-footer .id7-app-footer .h1,
.brand-gold .id7-page-footer .id7-app-footer .h2,
.brand-gold .id7-page-footer .id7-app-footer .h3,
.brand-gold .id7-page-footer .id7-app-footer .h4,
.brand-gold .id7-page-footer .id7-app-footer .h5,
.brand-gold .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-gold .id7-page-footer .id7-app-footer a,
.brand-gold .id7-page-footer .id7-app-footer a:link,
.brand-gold .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-gold .id7-page-footer .id7-app-footer a:hover,
.brand-gold .id7-page-footer .id7-app-footer a:focus,
.brand-gold .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-gold .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-gold .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-gold .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-gold .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-gold .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-gold .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-gold .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-gold .id7-page-footer .id7-app-footer a,
.brand-gold .id7-page-footer .id7-app-footer a:link,
.brand-gold .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-gold .id7-page-footer .id7-app-footer a:hover,
.brand-gold .id7-page-footer .id7-app-footer a:focus,
.brand-gold .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-gold .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #D69A2D;
}
.brand-gold .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-gold .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-gold .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-gold .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-gold .id7-search .fa,
.brand-gold .id7-search .fas {
  color: #D69A2D;
  color: rgba(214, 154, 45, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-gold .id7-search .form-control:hover + .fa,
.brand-gold .id7-search .typeahead:hover + .fa,
.brand-gold .id7-search .fa:hover,
.brand-gold .id7-search .form-control:hover + .fas,
.brand-gold .id7-search .typeahead:hover + .fas,
.brand-gold .id7-search .fas:hover {
  color: #D69A2D;
}
.brand-gold .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #D69A2D;
  color: white;
}
.brand-gold .form-control:focus {
  border-color: #D69A2D;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(214, 154, 45, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(214, 154, 45, 0.6);
}
.brand-gold .carousel .carousel-indicators li::after {
  color: #D69A2D;
  color: rgba(214, 154, 45, 0.3);
}
.brand-gold .carousel .carousel-indicators li.active::after {
  color: #D69A2D;
}
@media (max-width: 767px) {
  .brand-gold .boxstyle_.box1,
  .brand-gold .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gold .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gold .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gold .boxstyle_.box1,
.brand-gold .boxstyle-xs.box1,
.brand-gold .boxstyle-sm.box1,
.brand-gold .boxstyle-md.box1,
.brand-gold .boxstyle-lg.box1 {
  border: 0;
  background: #f9f0e0;
  color: #202020;
}
@media print {
  .brand-gold .boxstyle_.box1,
  .brand-gold .boxstyle-xs.box1,
  .brand-gold .boxstyle-sm.box1,
  .brand-gold .boxstyle-md.box1,
  .brand-gold .boxstyle-lg.box1 {
    border: 1px solid #f9f0e0;
  }
}
.brand-gold .boxstyle_.box1 h1,
.brand-gold .boxstyle-xs.box1 h1,
.brand-gold .boxstyle-sm.box1 h1,
.brand-gold .boxstyle-md.box1 h1,
.brand-gold .boxstyle-lg.box1 h1,
.brand-gold .boxstyle_.box1 h2,
.brand-gold .boxstyle-xs.box1 h2,
.brand-gold .boxstyle-sm.box1 h2,
.brand-gold .boxstyle-md.box1 h2,
.brand-gold .boxstyle-lg.box1 h2,
.brand-gold .boxstyle_.box1 h3,
.brand-gold .boxstyle-xs.box1 h3,
.brand-gold .boxstyle-sm.box1 h3,
.brand-gold .boxstyle-md.box1 h3,
.brand-gold .boxstyle-lg.box1 h3,
.brand-gold .boxstyle_.box1 h4,
.brand-gold .boxstyle-xs.box1 h4,
.brand-gold .boxstyle-sm.box1 h4,
.brand-gold .boxstyle-md.box1 h4,
.brand-gold .boxstyle-lg.box1 h4,
.brand-gold .boxstyle_.box1 h5,
.brand-gold .boxstyle-xs.box1 h5,
.brand-gold .boxstyle-sm.box1 h5,
.brand-gold .boxstyle-md.box1 h5,
.brand-gold .boxstyle-lg.box1 h5,
.brand-gold .boxstyle_.box1 h6,
.brand-gold .boxstyle-xs.box1 h6,
.brand-gold .boxstyle-sm.box1 h6,
.brand-gold .boxstyle-md.box1 h6,
.brand-gold .boxstyle-lg.box1 h6,
.brand-gold .boxstyle_.box1 .h1,
.brand-gold .boxstyle-xs.box1 .h1,
.brand-gold .boxstyle-sm.box1 .h1,
.brand-gold .boxstyle-md.box1 .h1,
.brand-gold .boxstyle-lg.box1 .h1,
.brand-gold .boxstyle_.box1 .h2,
.brand-gold .boxstyle-xs.box1 .h2,
.brand-gold .boxstyle-sm.box1 .h2,
.brand-gold .boxstyle-md.box1 .h2,
.brand-gold .boxstyle-lg.box1 .h2,
.brand-gold .boxstyle_.box1 .h3,
.brand-gold .boxstyle-xs.box1 .h3,
.brand-gold .boxstyle-sm.box1 .h3,
.brand-gold .boxstyle-md.box1 .h3,
.brand-gold .boxstyle-lg.box1 .h3,
.brand-gold .boxstyle_.box1 .h4,
.brand-gold .boxstyle-xs.box1 .h4,
.brand-gold .boxstyle-sm.box1 .h4,
.brand-gold .boxstyle-md.box1 .h4,
.brand-gold .boxstyle-lg.box1 .h4,
.brand-gold .boxstyle_.box1 .h5,
.brand-gold .boxstyle-xs.box1 .h5,
.brand-gold .boxstyle-sm.box1 .h5,
.brand-gold .boxstyle-md.box1 .h5,
.brand-gold .boxstyle-lg.box1 .h5,
.brand-gold .boxstyle_.box1 .h6,
.brand-gold .boxstyle-xs.box1 .h6,
.brand-gold .boxstyle-sm.box1 .h6,
.brand-gold .boxstyle-md.box1 .h6,
.brand-gold .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-gold .boxstyle_.box1 blockquote.quotes,
.brand-gold .boxstyle-xs.box1 blockquote.quotes,
.brand-gold .boxstyle-sm.box1 blockquote.quotes,
.brand-gold .boxstyle-md.box1 blockquote.quotes,
.brand-gold .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-gold .boxstyle_.box1 a,
.brand-gold .boxstyle-xs.box1 a,
.brand-gold .boxstyle-sm.box1 a,
.brand-gold .boxstyle-md.box1 a,
.brand-gold .boxstyle-lg.box1 a,
.brand-gold .boxstyle_.box1 a:link,
.brand-gold .boxstyle-xs.box1 a:link,
.brand-gold .boxstyle-sm.box1 a:link,
.brand-gold .boxstyle-md.box1 a:link,
.brand-gold .boxstyle-lg.box1 a:link,
.brand-gold .boxstyle_.box1 a:visited,
.brand-gold .boxstyle-xs.box1 a:visited,
.brand-gold .boxstyle-sm.box1 a:visited,
.brand-gold .boxstyle-md.box1 a:visited,
.brand-gold .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-gold .boxstyle_.box1 a:hover,
.brand-gold .boxstyle-xs.box1 a:hover,
.brand-gold .boxstyle-sm.box1 a:hover,
.brand-gold .boxstyle-md.box1 a:hover,
.brand-gold .boxstyle-lg.box1 a:hover,
.brand-gold .boxstyle_.box1 a:focus,
.brand-gold .boxstyle-xs.box1 a:focus,
.brand-gold .boxstyle-sm.box1 a:focus,
.brand-gold .boxstyle-md.box1 a:focus,
.brand-gold .boxstyle-lg.box1 a:focus,
.brand-gold .boxstyle_.box1 a:active,
.brand-gold .boxstyle-xs.box1 a:active,
.brand-gold .boxstyle-sm.box1 a:active,
.brand-gold .boxstyle-md.box1 a:active,
.brand-gold .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-gold .boxstyle_.box1 .popover button.close,
.brand-gold .boxstyle-xs.box1 .popover button.close,
.brand-gold .boxstyle-sm.box1 .popover button.close,
.brand-gold .boxstyle-md.box1 .popover button.close,
.brand-gold .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-gold .boxstyle_.box1 .popover button.close:hover,
.brand-gold .boxstyle-xs.box1 .popover button.close:hover,
.brand-gold .boxstyle-sm.box1 .popover button.close:hover,
.brand-gold .boxstyle-md.box1 .popover button.close:hover,
.brand-gold .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-gold .boxstyle_.box1 a:hover .new-window-link,
.brand-gold .boxstyle-xs.box1 a:hover .new-window-link,
.brand-gold .boxstyle-sm.box1 a:hover .new-window-link,
.brand-gold .boxstyle-md.box1 a:hover .new-window-link,
.brand-gold .boxstyle-lg.box1 a:hover .new-window-link,
.brand-gold .boxstyle_.box1 a:hover .insecure-link,
.brand-gold .boxstyle-xs.box1 a:hover .insecure-link,
.brand-gold .boxstyle-sm.box1 a:hover .insecure-link,
.brand-gold .boxstyle-md.box1 a:hover .insecure-link,
.brand-gold .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-gold .boxstyle_.box1 .btn-link,
.brand-gold .boxstyle-xs.box1 .btn-link,
.brand-gold .boxstyle-sm.box1 .btn-link,
.brand-gold .boxstyle-md.box1 .btn-link,
.brand-gold .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-gold .boxstyle_.box1 .btn-link:hover,
.brand-gold .boxstyle-xs.box1 .btn-link:hover,
.brand-gold .boxstyle-sm.box1 .btn-link:hover,
.brand-gold .boxstyle-md.box1 .btn-link:hover,
.brand-gold .boxstyle-lg.box1 .btn-link:hover,
.brand-gold .boxstyle_.box1 .btn-link:focus,
.brand-gold .boxstyle-xs.box1 .btn-link:focus,
.brand-gold .boxstyle-sm.box1 .btn-link:focus,
.brand-gold .boxstyle-md.box1 .btn-link:focus,
.brand-gold .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-gold .boxstyle_.box1 a,
.brand-gold .boxstyle-xs.box1 a,
.brand-gold .boxstyle-sm.box1 a,
.brand-gold .boxstyle-md.box1 a,
.brand-gold .boxstyle-lg.box1 a,
.brand-gold .boxstyle_.box1 a:link,
.brand-gold .boxstyle-xs.box1 a:link,
.brand-gold .boxstyle-sm.box1 a:link,
.brand-gold .boxstyle-md.box1 a:link,
.brand-gold .boxstyle-lg.box1 a:link,
.brand-gold .boxstyle_.box1 a:visited,
.brand-gold .boxstyle-xs.box1 a:visited,
.brand-gold .boxstyle-sm.box1 a:visited,
.brand-gold .boxstyle-md.box1 a:visited,
.brand-gold .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold .boxstyle_.box1 a:hover,
.brand-gold .boxstyle-xs.box1 a:hover,
.brand-gold .boxstyle-sm.box1 a:hover,
.brand-gold .boxstyle-md.box1 a:hover,
.brand-gold .boxstyle-lg.box1 a:hover,
.brand-gold .boxstyle_.box1 a:focus,
.brand-gold .boxstyle-xs.box1 a:focus,
.brand-gold .boxstyle-sm.box1 a:focus,
.brand-gold .boxstyle-md.box1 a:focus,
.brand-gold .boxstyle-lg.box1 a:focus,
.brand-gold .boxstyle_.box1 a:active,
.brand-gold .boxstyle-xs.box1 a:active,
.brand-gold .boxstyle-sm.box1 a:active,
.brand-gold .boxstyle-md.box1 a:active,
.brand-gold .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-gold .boxstyle_.box2,
  .brand-gold .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gold .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gold .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gold .boxstyle_.box2,
.brand-gold .boxstyle-xs.box2,
.brand-gold .boxstyle-sm.box2,
.brand-gold .boxstyle-md.box2,
.brand-gold .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-gold .boxstyle_.box2,
  .brand-gold .boxstyle-xs.box2,
  .brand-gold .boxstyle-sm.box2,
  .brand-gold .boxstyle-md.box2,
  .brand-gold .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-gold .boxstyle_.box2 h1,
.brand-gold .boxstyle-xs.box2 h1,
.brand-gold .boxstyle-sm.box2 h1,
.brand-gold .boxstyle-md.box2 h1,
.brand-gold .boxstyle-lg.box2 h1,
.brand-gold .boxstyle_.box2 h2,
.brand-gold .boxstyle-xs.box2 h2,
.brand-gold .boxstyle-sm.box2 h2,
.brand-gold .boxstyle-md.box2 h2,
.brand-gold .boxstyle-lg.box2 h2,
.brand-gold .boxstyle_.box2 h3,
.brand-gold .boxstyle-xs.box2 h3,
.brand-gold .boxstyle-sm.box2 h3,
.brand-gold .boxstyle-md.box2 h3,
.brand-gold .boxstyle-lg.box2 h3,
.brand-gold .boxstyle_.box2 h4,
.brand-gold .boxstyle-xs.box2 h4,
.brand-gold .boxstyle-sm.box2 h4,
.brand-gold .boxstyle-md.box2 h4,
.brand-gold .boxstyle-lg.box2 h4,
.brand-gold .boxstyle_.box2 h5,
.brand-gold .boxstyle-xs.box2 h5,
.brand-gold .boxstyle-sm.box2 h5,
.brand-gold .boxstyle-md.box2 h5,
.brand-gold .boxstyle-lg.box2 h5,
.brand-gold .boxstyle_.box2 h6,
.brand-gold .boxstyle-xs.box2 h6,
.brand-gold .boxstyle-sm.box2 h6,
.brand-gold .boxstyle-md.box2 h6,
.brand-gold .boxstyle-lg.box2 h6,
.brand-gold .boxstyle_.box2 .h1,
.brand-gold .boxstyle-xs.box2 .h1,
.brand-gold .boxstyle-sm.box2 .h1,
.brand-gold .boxstyle-md.box2 .h1,
.brand-gold .boxstyle-lg.box2 .h1,
.brand-gold .boxstyle_.box2 .h2,
.brand-gold .boxstyle-xs.box2 .h2,
.brand-gold .boxstyle-sm.box2 .h2,
.brand-gold .boxstyle-md.box2 .h2,
.brand-gold .boxstyle-lg.box2 .h2,
.brand-gold .boxstyle_.box2 .h3,
.brand-gold .boxstyle-xs.box2 .h3,
.brand-gold .boxstyle-sm.box2 .h3,
.brand-gold .boxstyle-md.box2 .h3,
.brand-gold .boxstyle-lg.box2 .h3,
.brand-gold .boxstyle_.box2 .h4,
.brand-gold .boxstyle-xs.box2 .h4,
.brand-gold .boxstyle-sm.box2 .h4,
.brand-gold .boxstyle-md.box2 .h4,
.brand-gold .boxstyle-lg.box2 .h4,
.brand-gold .boxstyle_.box2 .h5,
.brand-gold .boxstyle-xs.box2 .h5,
.brand-gold .boxstyle-sm.box2 .h5,
.brand-gold .boxstyle-md.box2 .h5,
.brand-gold .boxstyle-lg.box2 .h5,
.brand-gold .boxstyle_.box2 .h6,
.brand-gold .boxstyle-xs.box2 .h6,
.brand-gold .boxstyle-sm.box2 .h6,
.brand-gold .boxstyle-md.box2 .h6,
.brand-gold .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-gold .boxstyle_.box2 blockquote.quotes,
.brand-gold .boxstyle-xs.box2 blockquote.quotes,
.brand-gold .boxstyle-sm.box2 blockquote.quotes,
.brand-gold .boxstyle-md.box2 blockquote.quotes,
.brand-gold .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-gold .boxstyle_.box2 a,
.brand-gold .boxstyle-xs.box2 a,
.brand-gold .boxstyle-sm.box2 a,
.brand-gold .boxstyle-md.box2 a,
.brand-gold .boxstyle-lg.box2 a,
.brand-gold .boxstyle_.box2 a:link,
.brand-gold .boxstyle-xs.box2 a:link,
.brand-gold .boxstyle-sm.box2 a:link,
.brand-gold .boxstyle-md.box2 a:link,
.brand-gold .boxstyle-lg.box2 a:link,
.brand-gold .boxstyle_.box2 a:visited,
.brand-gold .boxstyle-xs.box2 a:visited,
.brand-gold .boxstyle-sm.box2 a:visited,
.brand-gold .boxstyle-md.box2 a:visited,
.brand-gold .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-gold .boxstyle_.box2 a:hover,
.brand-gold .boxstyle-xs.box2 a:hover,
.brand-gold .boxstyle-sm.box2 a:hover,
.brand-gold .boxstyle-md.box2 a:hover,
.brand-gold .boxstyle-lg.box2 a:hover,
.brand-gold .boxstyle_.box2 a:focus,
.brand-gold .boxstyle-xs.box2 a:focus,
.brand-gold .boxstyle-sm.box2 a:focus,
.brand-gold .boxstyle-md.box2 a:focus,
.brand-gold .boxstyle-lg.box2 a:focus,
.brand-gold .boxstyle_.box2 a:active,
.brand-gold .boxstyle-xs.box2 a:active,
.brand-gold .boxstyle-sm.box2 a:active,
.brand-gold .boxstyle-md.box2 a:active,
.brand-gold .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-gold .boxstyle_.box2 .popover button.close,
.brand-gold .boxstyle-xs.box2 .popover button.close,
.brand-gold .boxstyle-sm.box2 .popover button.close,
.brand-gold .boxstyle-md.box2 .popover button.close,
.brand-gold .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-gold .boxstyle_.box2 .popover button.close:hover,
.brand-gold .boxstyle-xs.box2 .popover button.close:hover,
.brand-gold .boxstyle-sm.box2 .popover button.close:hover,
.brand-gold .boxstyle-md.box2 .popover button.close:hover,
.brand-gold .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-gold .boxstyle_.box2 a:hover .new-window-link,
.brand-gold .boxstyle-xs.box2 a:hover .new-window-link,
.brand-gold .boxstyle-sm.box2 a:hover .new-window-link,
.brand-gold .boxstyle-md.box2 a:hover .new-window-link,
.brand-gold .boxstyle-lg.box2 a:hover .new-window-link,
.brand-gold .boxstyle_.box2 a:hover .insecure-link,
.brand-gold .boxstyle-xs.box2 a:hover .insecure-link,
.brand-gold .boxstyle-sm.box2 a:hover .insecure-link,
.brand-gold .boxstyle-md.box2 a:hover .insecure-link,
.brand-gold .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-gold .boxstyle_.box2 .btn-link,
.brand-gold .boxstyle-xs.box2 .btn-link,
.brand-gold .boxstyle-sm.box2 .btn-link,
.brand-gold .boxstyle-md.box2 .btn-link,
.brand-gold .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-gold .boxstyle_.box2 .btn-link:hover,
.brand-gold .boxstyle-xs.box2 .btn-link:hover,
.brand-gold .boxstyle-sm.box2 .btn-link:hover,
.brand-gold .boxstyle-md.box2 .btn-link:hover,
.brand-gold .boxstyle-lg.box2 .btn-link:hover,
.brand-gold .boxstyle_.box2 .btn-link:focus,
.brand-gold .boxstyle-xs.box2 .btn-link:focus,
.brand-gold .boxstyle-sm.box2 .btn-link:focus,
.brand-gold .boxstyle-md.box2 .btn-link:focus,
.brand-gold .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-gold .boxstyle_.box2 a,
.brand-gold .boxstyle-xs.box2 a,
.brand-gold .boxstyle-sm.box2 a,
.brand-gold .boxstyle-md.box2 a,
.brand-gold .boxstyle-lg.box2 a,
.brand-gold .boxstyle_.box2 a:link,
.brand-gold .boxstyle-xs.box2 a:link,
.brand-gold .boxstyle-sm.box2 a:link,
.brand-gold .boxstyle-md.box2 a:link,
.brand-gold .boxstyle-lg.box2 a:link,
.brand-gold .boxstyle_.box2 a:visited,
.brand-gold .boxstyle-xs.box2 a:visited,
.brand-gold .boxstyle-sm.box2 a:visited,
.brand-gold .boxstyle-md.box2 a:visited,
.brand-gold .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold .boxstyle_.box2 a:hover,
.brand-gold .boxstyle-xs.box2 a:hover,
.brand-gold .boxstyle-sm.box2 a:hover,
.brand-gold .boxstyle-md.box2 a:hover,
.brand-gold .boxstyle-lg.box2 a:hover,
.brand-gold .boxstyle_.box2 a:focus,
.brand-gold .boxstyle-xs.box2 a:focus,
.brand-gold .boxstyle-sm.box2 a:focus,
.brand-gold .boxstyle-md.box2 a:focus,
.brand-gold .boxstyle-lg.box2 a:focus,
.brand-gold .boxstyle_.box2 a:active,
.brand-gold .boxstyle-xs.box2 a:active,
.brand-gold .boxstyle-sm.box2 a:active,
.brand-gold .boxstyle-md.box2 a:active,
.brand-gold .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #D69A2D;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-gold .boxstyle_.box3,
  .brand-gold .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #D69A2D;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-gold .boxstyle-md.box3 {
    background: none;
    border: 1px solid #D69A2D;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-gold .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #D69A2D;
    color: inherit;
  }
}
.brand-gold .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-gold .boxstyle_.box4,
  .brand-gold .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-gold .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-gold .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-gold .boxstyle_.box5,
  .brand-gold .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gold .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gold .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gold .boxstyle_.box5,
.brand-gold .boxstyle-xs.box5,
.brand-gold .boxstyle-sm.box5,
.brand-gold .boxstyle-md.box5,
.brand-gold .boxstyle-lg.box5 {
  border: 0;
  background: #D69A2D;
  color: #202020;
}
@media print {
  .brand-gold .boxstyle_.box5,
  .brand-gold .boxstyle-xs.box5,
  .brand-gold .boxstyle-sm.box5,
  .brand-gold .boxstyle-md.box5,
  .brand-gold .boxstyle-lg.box5 {
    border: 1px solid #D69A2D;
  }
}
.brand-gold .boxstyle_.box5 h1,
.brand-gold .boxstyle-xs.box5 h1,
.brand-gold .boxstyle-sm.box5 h1,
.brand-gold .boxstyle-md.box5 h1,
.brand-gold .boxstyle-lg.box5 h1,
.brand-gold .boxstyle_.box5 h2,
.brand-gold .boxstyle-xs.box5 h2,
.brand-gold .boxstyle-sm.box5 h2,
.brand-gold .boxstyle-md.box5 h2,
.brand-gold .boxstyle-lg.box5 h2,
.brand-gold .boxstyle_.box5 h3,
.brand-gold .boxstyle-xs.box5 h3,
.brand-gold .boxstyle-sm.box5 h3,
.brand-gold .boxstyle-md.box5 h3,
.brand-gold .boxstyle-lg.box5 h3,
.brand-gold .boxstyle_.box5 h4,
.brand-gold .boxstyle-xs.box5 h4,
.brand-gold .boxstyle-sm.box5 h4,
.brand-gold .boxstyle-md.box5 h4,
.brand-gold .boxstyle-lg.box5 h4,
.brand-gold .boxstyle_.box5 h5,
.brand-gold .boxstyle-xs.box5 h5,
.brand-gold .boxstyle-sm.box5 h5,
.brand-gold .boxstyle-md.box5 h5,
.brand-gold .boxstyle-lg.box5 h5,
.brand-gold .boxstyle_.box5 h6,
.brand-gold .boxstyle-xs.box5 h6,
.brand-gold .boxstyle-sm.box5 h6,
.brand-gold .boxstyle-md.box5 h6,
.brand-gold .boxstyle-lg.box5 h6,
.brand-gold .boxstyle_.box5 .h1,
.brand-gold .boxstyle-xs.box5 .h1,
.brand-gold .boxstyle-sm.box5 .h1,
.brand-gold .boxstyle-md.box5 .h1,
.brand-gold .boxstyle-lg.box5 .h1,
.brand-gold .boxstyle_.box5 .h2,
.brand-gold .boxstyle-xs.box5 .h2,
.brand-gold .boxstyle-sm.box5 .h2,
.brand-gold .boxstyle-md.box5 .h2,
.brand-gold .boxstyle-lg.box5 .h2,
.brand-gold .boxstyle_.box5 .h3,
.brand-gold .boxstyle-xs.box5 .h3,
.brand-gold .boxstyle-sm.box5 .h3,
.brand-gold .boxstyle-md.box5 .h3,
.brand-gold .boxstyle-lg.box5 .h3,
.brand-gold .boxstyle_.box5 .h4,
.brand-gold .boxstyle-xs.box5 .h4,
.brand-gold .boxstyle-sm.box5 .h4,
.brand-gold .boxstyle-md.box5 .h4,
.brand-gold .boxstyle-lg.box5 .h4,
.brand-gold .boxstyle_.box5 .h5,
.brand-gold .boxstyle-xs.box5 .h5,
.brand-gold .boxstyle-sm.box5 .h5,
.brand-gold .boxstyle-md.box5 .h5,
.brand-gold .boxstyle-lg.box5 .h5,
.brand-gold .boxstyle_.box5 .h6,
.brand-gold .boxstyle-xs.box5 .h6,
.brand-gold .boxstyle-sm.box5 .h6,
.brand-gold .boxstyle-md.box5 .h6,
.brand-gold .boxstyle-lg.box5 .h6 {
  color: #202020;
}
.brand-gold .boxstyle_.box5 blockquote.quotes,
.brand-gold .boxstyle-xs.box5 blockquote.quotes,
.brand-gold .boxstyle-sm.box5 blockquote.quotes,
.brand-gold .boxstyle-md.box5 blockquote.quotes,
.brand-gold .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-gold .boxstyle_.box5 a,
.brand-gold .boxstyle-xs.box5 a,
.brand-gold .boxstyle-sm.box5 a,
.brand-gold .boxstyle-md.box5 a,
.brand-gold .boxstyle-lg.box5 a,
.brand-gold .boxstyle_.box5 a:link,
.brand-gold .boxstyle-xs.box5 a:link,
.brand-gold .boxstyle-sm.box5 a:link,
.brand-gold .boxstyle-md.box5 a:link,
.brand-gold .boxstyle-lg.box5 a:link,
.brand-gold .boxstyle_.box5 a:visited,
.brand-gold .boxstyle-xs.box5 a:visited,
.brand-gold .boxstyle-sm.box5 a:visited,
.brand-gold .boxstyle-md.box5 a:visited,
.brand-gold .boxstyle-lg.box5 a:visited {
  color: #202020;
}
.brand-gold .boxstyle_.box5 a:hover,
.brand-gold .boxstyle-xs.box5 a:hover,
.brand-gold .boxstyle-sm.box5 a:hover,
.brand-gold .boxstyle-md.box5 a:hover,
.brand-gold .boxstyle-lg.box5 a:hover,
.brand-gold .boxstyle_.box5 a:focus,
.brand-gold .boxstyle-xs.box5 a:focus,
.brand-gold .boxstyle-sm.box5 a:focus,
.brand-gold .boxstyle-md.box5 a:focus,
.brand-gold .boxstyle-lg.box5 a:focus,
.brand-gold .boxstyle_.box5 a:active,
.brand-gold .boxstyle-xs.box5 a:active,
.brand-gold .boxstyle-sm.box5 a:active,
.brand-gold .boxstyle-md.box5 a:active,
.brand-gold .boxstyle-lg.box5 a:active {
  color: #000000;
}
.brand-gold .boxstyle_.box5 .popover button.close,
.brand-gold .boxstyle-xs.box5 .popover button.close,
.brand-gold .boxstyle-sm.box5 .popover button.close,
.brand-gold .boxstyle-md.box5 .popover button.close,
.brand-gold .boxstyle-lg.box5 .popover button.close {
  color: #202020;
}
.brand-gold .boxstyle_.box5 .popover button.close:hover,
.brand-gold .boxstyle-xs.box5 .popover button.close:hover,
.brand-gold .boxstyle-sm.box5 .popover button.close:hover,
.brand-gold .boxstyle-md.box5 .popover button.close:hover,
.brand-gold .boxstyle-lg.box5 .popover button.close:hover {
  color: #000000;
}
.brand-gold .boxstyle_.box5 a:hover .new-window-link,
.brand-gold .boxstyle-xs.box5 a:hover .new-window-link,
.brand-gold .boxstyle-sm.box5 a:hover .new-window-link,
.brand-gold .boxstyle-md.box5 a:hover .new-window-link,
.brand-gold .boxstyle-lg.box5 a:hover .new-window-link,
.brand-gold .boxstyle_.box5 a:hover .insecure-link,
.brand-gold .boxstyle-xs.box5 a:hover .insecure-link,
.brand-gold .boxstyle-sm.box5 a:hover .insecure-link,
.brand-gold .boxstyle-md.box5 a:hover .insecure-link,
.brand-gold .boxstyle-lg.box5 a:hover .insecure-link {
  color: #000000;
}
.brand-gold .boxstyle_.box5 .btn-link,
.brand-gold .boxstyle-xs.box5 .btn-link,
.brand-gold .boxstyle-sm.box5 .btn-link,
.brand-gold .boxstyle-md.box5 .btn-link,
.brand-gold .boxstyle-lg.box5 .btn-link {
  color: #202020;
}
.brand-gold .boxstyle_.box5 .btn-link:hover,
.brand-gold .boxstyle-xs.box5 .btn-link:hover,
.brand-gold .boxstyle-sm.box5 .btn-link:hover,
.brand-gold .boxstyle-md.box5 .btn-link:hover,
.brand-gold .boxstyle-lg.box5 .btn-link:hover,
.brand-gold .boxstyle_.box5 .btn-link:focus,
.brand-gold .boxstyle-xs.box5 .btn-link:focus,
.brand-gold .boxstyle-sm.box5 .btn-link:focus,
.brand-gold .boxstyle-md.box5 .btn-link:focus,
.brand-gold .boxstyle-lg.box5 .btn-link:focus {
  color: #000000;
}
.brand-gold .boxstyle_.box5 a,
.brand-gold .boxstyle-xs.box5 a,
.brand-gold .boxstyle-sm.box5 a,
.brand-gold .boxstyle-md.box5 a,
.brand-gold .boxstyle-lg.box5 a,
.brand-gold .boxstyle_.box5 a:link,
.brand-gold .boxstyle-xs.box5 a:link,
.brand-gold .boxstyle-sm.box5 a:link,
.brand-gold .boxstyle-md.box5 a:link,
.brand-gold .boxstyle-lg.box5 a:link,
.brand-gold .boxstyle_.box5 a:visited,
.brand-gold .boxstyle-xs.box5 a:visited,
.brand-gold .boxstyle-sm.box5 a:visited,
.brand-gold .boxstyle-md.box5 a:visited,
.brand-gold .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold .boxstyle_.box5 a:hover,
.brand-gold .boxstyle-xs.box5 a:hover,
.brand-gold .boxstyle-sm.box5 a:hover,
.brand-gold .boxstyle-md.box5 a:hover,
.brand-gold .boxstyle-lg.box5 a:hover,
.brand-gold .boxstyle_.box5 a:focus,
.brand-gold .boxstyle-xs.box5 a:focus,
.brand-gold .boxstyle-sm.box5 a:focus,
.brand-gold .boxstyle-md.box5 a:focus,
.brand-gold .boxstyle-lg.box5 a:focus,
.brand-gold .boxstyle_.box5 a:active,
.brand-gold .boxstyle-xs.box5 a:active,
.brand-gold .boxstyle-sm.box5 a:active,
.brand-gold .boxstyle-md.box5 a:active,
.brand-gold .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold .pagination > li a,
.brand-gold .pagination > li span {
  color: #D69A2D;
}
.brand-gold .pagination > li.active > a,
.brand-gold .pagination > li.active > span:not(.sr-only),
.brand-gold .pagination > li.active > a:hover,
.brand-gold .pagination > li.active > span:not(.sr-only):hover,
.brand-gold .pagination > li.active > a:focus,
.brand-gold .pagination > li.active > span:not(.sr-only):focus {
  background-color: #D69A2D;
  border-color: #D69A2D;
  color: white;
}
.brand-gold .nav-tabs {
  border-bottom-color: #ebcc96;
}
.brand-gold .nav-tabs > li > a {
  background-color: #f7ebd5;
  border-color: #ebcc96;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-gold .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #e6c281;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-gold .nav-tabs > li > a:hover,
.brand-gold .nav-tabs > li > a:focus {
  background-color: #fbf5ea;
  border-color: #efd7ab #efd7ab #ebcc96;
}
.brand-gold .nav-tabs > li > a:hover::before,
.brand-gold .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-gold .nav-tabs > li.open > a,
.brand-gold .nav-tabs > li.open > a:hover,
.brand-gold .nav-tabs > li.open > a:focus {
  background-color: #fbf5ea;
  border-color: #efd7ab #efd7ab #ebcc96;
}
.brand-gold .nav-tabs > li.open > a::before,
.brand-gold .nav-tabs > li.open > a:hover::before,
.brand-gold .nav-tabs > li.open > a:focus::before {
  background: #D69A2D;
  width: 100%;
}
.brand-gold .nav-tabs > li.active > a,
.brand-gold .nav-tabs > li.active > a:hover,
.brand-gold .nav-tabs > li.active > a:focus {
  border-color: #ebcc96 #ebcc96 transparent;
}
.brand-gold .nav-tabs > li.active > a::before,
.brand-gold .nav-tabs > li.active > a:hover::before,
.brand-gold .nav-tabs > li.active > a:focus::before {
  background: #D69A2D;
  width: 100%;
}
.brand-gold .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-gold .nav-tabs > li.disabled > a:hover,
.brand-gold .nav-tabs > li.disabled > a:focus {
  background-color: #f7ebd5;
}
.brand-gold .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-gold .nav-pills > li.active > a,
.brand-gold .nav-pills > li.active > a:hover,
.brand-gold .nav-pills > li.active > a:focus {
  background-color: #D69A2D;
  color: white;
}
.brand-gold .list-group-item.active,
.brand-gold .list-group-item.active:hover,
.brand-gold .list-group-item.active:focus {
  background-color: #D69A2D;
  border-color: #D69A2D;
  color: white;
}
.brand-gold .id7-koan-spinner__neon--top,
.brand-gold .id7-koan-spinner__neon--bottom {
  stroke: #D69A2D;
}
.brand-gold-bright h1,
.brand-gold-bright h2,
.brand-gold-bright h3,
.brand-gold-bright h4,
.brand-gold-bright h5,
.brand-gold-bright h6,
.brand-gold-bright .h1,
.brand-gold-bright .h2,
.brand-gold-bright .h3,
.brand-gold-bright .h4,
.brand-gold-bright .h5,
.brand-gold-bright .h6 {
  color: #202020;
}
.brand-gold-bright a,
.brand-gold-bright a:link,
.brand-gold-bright a:visited {
  color: #202020;
}
.brand-gold-bright a:hover,
.brand-gold-bright a:focus,
.brand-gold-bright a:active {
  color: #000000;
}
.brand-gold-bright .popover button.close {
  color: #202020;
}
.brand-gold-bright .popover button.close:hover {
  color: #000000;
}
.brand-gold-bright a:hover .new-window-link,
.brand-gold-bright a:hover .insecure-link {
  color: #000000;
}
.brand-gold-bright .btn-link {
  color: #202020;
}
.brand-gold-bright .btn-link:hover,
.brand-gold-bright .btn-link:focus {
  color: #000000;
}
.brand-gold-bright a,
.brand-gold-bright a:link,
.brand-gold-bright a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold-bright a:hover,
.brand-gold-bright a:focus,
.brand-gold-bright a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold-bright blockquote.quotes {
  color: #202020;
}
.brand-gold-bright blockquote.quotes::before {
  color: #202020;
}
.brand-gold-bright .brand-bg {
  background: #F1BE48;
  color: #202020;
}
.brand-gold-bright .brand-bg a,
.brand-gold-bright .brand-bg a:link,
.brand-gold-bright .brand-bg a:visited {
  color: #202020 !important;
}
.brand-gold-bright .brand-bg a:hover,
.brand-gold-bright .brand-bg a:focus,
.brand-gold-bright .brand-bg a:active {
  color: #000000 !important;
}
.brand-gold-bright .brand-bg .popover button.close {
  color: #202020 !important;
}
.brand-gold-bright .brand-bg .popover button.close:hover {
  color: #000000 !important;
}
.brand-gold-bright .brand-bg a:hover .new-window-link,
.brand-gold-bright .brand-bg a:hover .insecure-link {
  color: #000000 !important;
}
.brand-gold-bright .brand-bg .btn-link {
  color: #202020 !important;
}
.brand-gold-bright .brand-bg .btn-link:hover,
.brand-gold-bright .brand-bg .btn-link:focus {
  color: #000000 !important;
}
.brand-gold-bright .brand-bg a,
.brand-gold-bright .brand-bg a:link,
.brand-gold-bright .brand-bg a:visited {
  -webkit-text-decoration-color: #636363 !important;
  text-decoration-color: #636363 !important;
}
.brand-gold-bright .brand-bg a:hover,
.brand-gold-bright .brand-bg a:focus,
.brand-gold-bright .brand-bg a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-gold-bright .brand-text {
  color: #F1BE48;
}
.brand-gold-bright .brand-text a,
.brand-gold-bright .brand-text a:link,
.brand-gold-bright .brand-text a:visited {
  color: #F1BE48 !important;
}
.brand-gold-bright .brand-text a:hover,
.brand-gold-bright .brand-text a:focus,
.brand-gold-bright .brand-text a:active {
  color: #c48d0f !important;
}
.brand-gold-bright .brand-text .popover button.close {
  color: #F1BE48 !important;
}
.brand-gold-bright .brand-text .popover button.close:hover {
  color: #c48d0f !important;
}
.brand-gold-bright .brand-text a:hover .new-window-link,
.brand-gold-bright .brand-text a:hover .insecure-link {
  color: #c48d0f !important;
}
.brand-gold-bright .brand-text .btn-link {
  color: #F1BE48 !important;
}
.brand-gold-bright .brand-text .btn-link:hover,
.brand-gold-bright .brand-text .btn-link:focus {
  color: #c48d0f !important;
}
.brand-gold-bright .brand-text a,
.brand-gold-bright .brand-text a:link,
.brand-gold-bright .brand-text a:visited {
  -webkit-text-decoration-color: #f5d27f !important;
  text-decoration-color: #f5d27f !important;
}
.brand-gold-bright .brand-text a:hover,
.brand-gold-bright .brand-text a:focus,
.brand-gold-bright .brand-text a:active {
  -webkit-text-decoration-color: #c48d0f !important;
  text-decoration-color: #c48d0f !important;
}
.brand-gold-bright .brand-border {
  border-color: #F1BE48;
}
.brand-gold-bright .btn-brand {
  color: #202020;
  background-color: #F1BE48;
  border-color: #efb630;
  color: #202020 !important;
}
.brand-gold-bright .btn-brand:focus,
.brand-gold-bright .btn-brand.focus {
  color: #202020;
  background-color: #edad19;
  border-color: #956b0b;
}
.brand-gold-bright .btn-brand:hover {
  color: #202020;
  background-color: #edad19;
  border-color: #d29810;
}
.brand-gold-bright .btn-brand:active,
.brand-gold-bright .btn-brand.active,
.open > .dropdown-toggle.brand-gold-bright .btn-brand {
  color: #202020;
  background-color: #edad19;
  background-image: none;
  border-color: #d29810;
}
.brand-gold-bright .btn-brand:active:hover,
.brand-gold-bright .btn-brand.active:hover,
.open > .dropdown-toggle.brand-gold-bright .btn-brand:hover,
.brand-gold-bright .btn-brand:active:focus,
.brand-gold-bright .btn-brand.active:focus,
.open > .dropdown-toggle.brand-gold-bright .btn-brand:focus,
.brand-gold-bright .btn-brand:active.focus,
.brand-gold-bright .btn-brand.active.focus,
.open > .dropdown-toggle.brand-gold-bright .btn-brand.focus {
  color: #202020;
  background-color: #d29810;
  border-color: #956b0b;
}
.brand-gold-bright .btn-brand.disabled:hover,
.brand-gold-bright .btn-brand[disabled]:hover,
fieldset[disabled] .brand-gold-bright .btn-brand:hover,
.brand-gold-bright .btn-brand.disabled:focus,
.brand-gold-bright .btn-brand[disabled]:focus,
fieldset[disabled] .brand-gold-bright .btn-brand:focus,
.brand-gold-bright .btn-brand.disabled.focus,
.brand-gold-bright .btn-brand[disabled].focus,
fieldset[disabled] .brand-gold-bright .btn-brand.focus {
  background-color: #F1BE48;
  border-color: #efb630;
}
.brand-gold-bright .btn-brand .badge {
  color: #F1BE48;
  background-color: #202020;
}
.brand-gold-bright .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-gold-bright .id7-utility-masthead {
  background-color: #f5d27f;
}
.brand-gold-bright .id7-utility-masthead::after {
  background-color: #F1BE48;
}
body.id7-point-1 .brand-gold-bright .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-gold-bright .id7-navigation .navbar-primary {
  background-color: #F1BE48;
}
.brand-gold-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gold-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-text {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #f9e4b2;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c1983a;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #f9e4b2;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c1983a;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c1983a;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #F1BE48;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c1983a;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #c1983a;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c1983a;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c1983a;
  background-image: none;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #f9e4b2;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gold-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c1983a;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-link {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .navbar-link:hover {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .btn-link {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-gold-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-gold-bright .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gold-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-gold-bright .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gold-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-gold-bright .navbar-primary .navbar-brand {
  background-color: #F1BE48;
}
.brand-gold-bright .navbar-secondary {
  background-color: #f9e4b2;
}
.brand-gold-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gold-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gold-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li:hover,
.brand-gold-bright .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #fbecc9;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li > a,
.brand-gold-bright .navbar-secondary .navbar-nav > li > a:hover,
.brand-gold-bright .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gold-bright .navbar-secondary .navbar-nav > .disabled,
.brand-gold-bright .navbar-secondary .navbar-nav > .disabled:hover,
.brand-gold-bright .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #fbecc9;
}
.brand-gold-bright .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gold-bright .navbar-secondary .navbar-toggle:hover,
.brand-gold-bright .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gold-bright .navbar-secondary .navbar-nav > .open,
.brand-gold-bright .navbar-secondary .navbar-nav > .open:hover,
.brand-gold-bright .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #fbecc9;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #f9e4b2;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c7b68f;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #c7b68f;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #fbecc9;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #fbecc9;
  background-image: none;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gold-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #fbecc9;
}
.brand-gold-bright .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .btn-link {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .btn-link:hover,
.brand-gold-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gold-bright .navbar-secondary .btn-link:hover,
.brand-gold-bright .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gold-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li::after {
  color: #F1BE48;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li:first-child {
  background-color: #F1BE48;
  color: white;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #F1BE48;
}
.brand-gold-bright .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #F1BE48;
}
.brand-gold-bright .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-gold-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-gold-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > li:hover,
.brand-gold-bright .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > li > a,
.brand-gold-bright .navbar-tertiary .navbar-nav > li > a:hover,
.brand-gold-bright .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > .disabled,
.brand-gold-bright .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-gold-bright .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold-bright .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .navbar-toggle:hover,
.brand-gold-bright .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-gold-bright .navbar-tertiary .navbar-nav > .open,
.brand-gold-bright .navbar-tertiary .navbar-nav > .open:hover,
.brand-gold-bright .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-gold-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-gold-bright .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .btn-link:hover,
.brand-gold-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-gold-bright .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-gold-bright .navbar-tertiary .btn-link:hover,
.brand-gold-bright .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-gold-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-gold-bright.bordered .id7-left-border,
.brand-gold-bright.bordered .id7-right-border {
  border-color: rgba(241, 190, 72, 0.3);
}
.brand-gold-bright .id7-page-footer .id7-app-footer {
  background: #F1BE48;
  color: #202020;
}
.brand-gold-bright .id7-page-footer .id7-app-footer h1,
.brand-gold-bright .id7-page-footer .id7-app-footer h2,
.brand-gold-bright .id7-page-footer .id7-app-footer h3,
.brand-gold-bright .id7-page-footer .id7-app-footer h4,
.brand-gold-bright .id7-page-footer .id7-app-footer h5,
.brand-gold-bright .id7-page-footer .id7-app-footer h6,
.brand-gold-bright .id7-page-footer .id7-app-footer .h1,
.brand-gold-bright .id7-page-footer .id7-app-footer .h2,
.brand-gold-bright .id7-page-footer .id7-app-footer .h3,
.brand-gold-bright .id7-page-footer .id7-app-footer .h4,
.brand-gold-bright .id7-page-footer .id7-app-footer .h5,
.brand-gold-bright .id7-page-footer .id7-app-footer .h6 {
  color: #202020;
}
.brand-gold-bright .id7-page-footer .id7-app-footer a,
.brand-gold-bright .id7-page-footer .id7-app-footer a:link,
.brand-gold-bright .id7-page-footer .id7-app-footer a:visited {
  color: #202020;
}
.brand-gold-bright .id7-page-footer .id7-app-footer a:hover,
.brand-gold-bright .id7-page-footer .id7-app-footer a:focus,
.brand-gold-bright .id7-page-footer .id7-app-footer a:active {
  color: #000000;
}
.brand-gold-bright .id7-page-footer .id7-app-footer .popover button.close {
  color: #202020;
}
.brand-gold-bright .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #000000;
}
.brand-gold-bright .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-gold-bright .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #000000;
}
.brand-gold-bright .id7-page-footer .id7-app-footer .btn-link {
  color: #202020;
}
.brand-gold-bright .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-gold-bright .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #000000;
}
.brand-gold-bright .id7-page-footer .id7-app-footer a,
.brand-gold-bright .id7-page-footer .id7-app-footer a:link,
.brand-gold-bright .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold-bright .id7-page-footer .id7-app-footer a:hover,
.brand-gold-bright .id7-page-footer .id7-app-footer a:focus,
.brand-gold-bright .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold-bright .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #F1BE48;
}
.brand-gold-bright .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-gold-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-gold-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-gold-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-gold-bright .id7-search .fa,
.brand-gold-bright .id7-search .fas {
  color: #F1BE48;
  color: rgba(241, 190, 72, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-gold-bright .id7-search .form-control:hover + .fa,
.brand-gold-bright .id7-search .typeahead:hover + .fa,
.brand-gold-bright .id7-search .fa:hover,
.brand-gold-bright .id7-search .form-control:hover + .fas,
.brand-gold-bright .id7-search .typeahead:hover + .fas,
.brand-gold-bright .id7-search .fas:hover {
  color: #F1BE48;
}
.brand-gold-bright .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #F1BE48;
  color: #202020;
}
.brand-gold-bright .form-control:focus {
  border-color: #F1BE48;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(241, 190, 72, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(241, 190, 72, 0.6);
}
.brand-gold-bright .carousel .carousel-indicators li::after {
  color: #F1BE48;
  color: rgba(241, 190, 72, 0.3);
}
.brand-gold-bright .carousel .carousel-indicators li.active::after {
  color: #F1BE48;
}
@media (max-width: 767px) {
  .brand-gold-bright .boxstyle_.box1,
  .brand-gold-bright .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gold-bright .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gold-bright .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gold-bright .boxstyle_.box1,
.brand-gold-bright .boxstyle-xs.box1,
.brand-gold-bright .boxstyle-sm.box1,
.brand-gold-bright .boxstyle-md.box1,
.brand-gold-bright .boxstyle-lg.box1 {
  border: 0;
  background: #fdf5e4;
  color: #202020;
}
@media print {
  .brand-gold-bright .boxstyle_.box1,
  .brand-gold-bright .boxstyle-xs.box1,
  .brand-gold-bright .boxstyle-sm.box1,
  .brand-gold-bright .boxstyle-md.box1,
  .brand-gold-bright .boxstyle-lg.box1 {
    border: 1px solid #fdf5e4;
  }
}
.brand-gold-bright .boxstyle_.box1 h1,
.brand-gold-bright .boxstyle-xs.box1 h1,
.brand-gold-bright .boxstyle-sm.box1 h1,
.brand-gold-bright .boxstyle-md.box1 h1,
.brand-gold-bright .boxstyle-lg.box1 h1,
.brand-gold-bright .boxstyle_.box1 h2,
.brand-gold-bright .boxstyle-xs.box1 h2,
.brand-gold-bright .boxstyle-sm.box1 h2,
.brand-gold-bright .boxstyle-md.box1 h2,
.brand-gold-bright .boxstyle-lg.box1 h2,
.brand-gold-bright .boxstyle_.box1 h3,
.brand-gold-bright .boxstyle-xs.box1 h3,
.brand-gold-bright .boxstyle-sm.box1 h3,
.brand-gold-bright .boxstyle-md.box1 h3,
.brand-gold-bright .boxstyle-lg.box1 h3,
.brand-gold-bright .boxstyle_.box1 h4,
.brand-gold-bright .boxstyle-xs.box1 h4,
.brand-gold-bright .boxstyle-sm.box1 h4,
.brand-gold-bright .boxstyle-md.box1 h4,
.brand-gold-bright .boxstyle-lg.box1 h4,
.brand-gold-bright .boxstyle_.box1 h5,
.brand-gold-bright .boxstyle-xs.box1 h5,
.brand-gold-bright .boxstyle-sm.box1 h5,
.brand-gold-bright .boxstyle-md.box1 h5,
.brand-gold-bright .boxstyle-lg.box1 h5,
.brand-gold-bright .boxstyle_.box1 h6,
.brand-gold-bright .boxstyle-xs.box1 h6,
.brand-gold-bright .boxstyle-sm.box1 h6,
.brand-gold-bright .boxstyle-md.box1 h6,
.brand-gold-bright .boxstyle-lg.box1 h6,
.brand-gold-bright .boxstyle_.box1 .h1,
.brand-gold-bright .boxstyle-xs.box1 .h1,
.brand-gold-bright .boxstyle-sm.box1 .h1,
.brand-gold-bright .boxstyle-md.box1 .h1,
.brand-gold-bright .boxstyle-lg.box1 .h1,
.brand-gold-bright .boxstyle_.box1 .h2,
.brand-gold-bright .boxstyle-xs.box1 .h2,
.brand-gold-bright .boxstyle-sm.box1 .h2,
.brand-gold-bright .boxstyle-md.box1 .h2,
.brand-gold-bright .boxstyle-lg.box1 .h2,
.brand-gold-bright .boxstyle_.box1 .h3,
.brand-gold-bright .boxstyle-xs.box1 .h3,
.brand-gold-bright .boxstyle-sm.box1 .h3,
.brand-gold-bright .boxstyle-md.box1 .h3,
.brand-gold-bright .boxstyle-lg.box1 .h3,
.brand-gold-bright .boxstyle_.box1 .h4,
.brand-gold-bright .boxstyle-xs.box1 .h4,
.brand-gold-bright .boxstyle-sm.box1 .h4,
.brand-gold-bright .boxstyle-md.box1 .h4,
.brand-gold-bright .boxstyle-lg.box1 .h4,
.brand-gold-bright .boxstyle_.box1 .h5,
.brand-gold-bright .boxstyle-xs.box1 .h5,
.brand-gold-bright .boxstyle-sm.box1 .h5,
.brand-gold-bright .boxstyle-md.box1 .h5,
.brand-gold-bright .boxstyle-lg.box1 .h5,
.brand-gold-bright .boxstyle_.box1 .h6,
.brand-gold-bright .boxstyle-xs.box1 .h6,
.brand-gold-bright .boxstyle-sm.box1 .h6,
.brand-gold-bright .boxstyle-md.box1 .h6,
.brand-gold-bright .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box1 blockquote.quotes,
.brand-gold-bright .boxstyle-xs.box1 blockquote.quotes,
.brand-gold-bright .boxstyle-sm.box1 blockquote.quotes,
.brand-gold-bright .boxstyle-md.box1 blockquote.quotes,
.brand-gold-bright .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-gold-bright .boxstyle_.box1 a,
.brand-gold-bright .boxstyle-xs.box1 a,
.brand-gold-bright .boxstyle-sm.box1 a,
.brand-gold-bright .boxstyle-md.box1 a,
.brand-gold-bright .boxstyle-lg.box1 a,
.brand-gold-bright .boxstyle_.box1 a:link,
.brand-gold-bright .boxstyle-xs.box1 a:link,
.brand-gold-bright .boxstyle-sm.box1 a:link,
.brand-gold-bright .boxstyle-md.box1 a:link,
.brand-gold-bright .boxstyle-lg.box1 a:link,
.brand-gold-bright .boxstyle_.box1 a:visited,
.brand-gold-bright .boxstyle-xs.box1 a:visited,
.brand-gold-bright .boxstyle-sm.box1 a:visited,
.brand-gold-bright .boxstyle-md.box1 a:visited,
.brand-gold-bright .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box1 a:hover,
.brand-gold-bright .boxstyle-xs.box1 a:hover,
.brand-gold-bright .boxstyle-sm.box1 a:hover,
.brand-gold-bright .boxstyle-md.box1 a:hover,
.brand-gold-bright .boxstyle-lg.box1 a:hover,
.brand-gold-bright .boxstyle_.box1 a:focus,
.brand-gold-bright .boxstyle-xs.box1 a:focus,
.brand-gold-bright .boxstyle-sm.box1 a:focus,
.brand-gold-bright .boxstyle-md.box1 a:focus,
.brand-gold-bright .boxstyle-lg.box1 a:focus,
.brand-gold-bright .boxstyle_.box1 a:active,
.brand-gold-bright .boxstyle-xs.box1 a:active,
.brand-gold-bright .boxstyle-sm.box1 a:active,
.brand-gold-bright .boxstyle-md.box1 a:active,
.brand-gold-bright .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box1 .popover button.close,
.brand-gold-bright .boxstyle-xs.box1 .popover button.close,
.brand-gold-bright .boxstyle-sm.box1 .popover button.close,
.brand-gold-bright .boxstyle-md.box1 .popover button.close,
.brand-gold-bright .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box1 .popover button.close:hover,
.brand-gold-bright .boxstyle-xs.box1 .popover button.close:hover,
.brand-gold-bright .boxstyle-sm.box1 .popover button.close:hover,
.brand-gold-bright .boxstyle-md.box1 .popover button.close:hover,
.brand-gold-bright .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box1 a:hover .new-window-link,
.brand-gold-bright .boxstyle-xs.box1 a:hover .new-window-link,
.brand-gold-bright .boxstyle-sm.box1 a:hover .new-window-link,
.brand-gold-bright .boxstyle-md.box1 a:hover .new-window-link,
.brand-gold-bright .boxstyle-lg.box1 a:hover .new-window-link,
.brand-gold-bright .boxstyle_.box1 a:hover .insecure-link,
.brand-gold-bright .boxstyle-xs.box1 a:hover .insecure-link,
.brand-gold-bright .boxstyle-sm.box1 a:hover .insecure-link,
.brand-gold-bright .boxstyle-md.box1 a:hover .insecure-link,
.brand-gold-bright .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box1 .btn-link,
.brand-gold-bright .boxstyle-xs.box1 .btn-link,
.brand-gold-bright .boxstyle-sm.box1 .btn-link,
.brand-gold-bright .boxstyle-md.box1 .btn-link,
.brand-gold-bright .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box1 .btn-link:hover,
.brand-gold-bright .boxstyle-xs.box1 .btn-link:hover,
.brand-gold-bright .boxstyle-sm.box1 .btn-link:hover,
.brand-gold-bright .boxstyle-md.box1 .btn-link:hover,
.brand-gold-bright .boxstyle-lg.box1 .btn-link:hover,
.brand-gold-bright .boxstyle_.box1 .btn-link:focus,
.brand-gold-bright .boxstyle-xs.box1 .btn-link:focus,
.brand-gold-bright .boxstyle-sm.box1 .btn-link:focus,
.brand-gold-bright .boxstyle-md.box1 .btn-link:focus,
.brand-gold-bright .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box1 a,
.brand-gold-bright .boxstyle-xs.box1 a,
.brand-gold-bright .boxstyle-sm.box1 a,
.brand-gold-bright .boxstyle-md.box1 a,
.brand-gold-bright .boxstyle-lg.box1 a,
.brand-gold-bright .boxstyle_.box1 a:link,
.brand-gold-bright .boxstyle-xs.box1 a:link,
.brand-gold-bright .boxstyle-sm.box1 a:link,
.brand-gold-bright .boxstyle-md.box1 a:link,
.brand-gold-bright .boxstyle-lg.box1 a:link,
.brand-gold-bright .boxstyle_.box1 a:visited,
.brand-gold-bright .boxstyle-xs.box1 a:visited,
.brand-gold-bright .boxstyle-sm.box1 a:visited,
.brand-gold-bright .boxstyle-md.box1 a:visited,
.brand-gold-bright .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold-bright .boxstyle_.box1 a:hover,
.brand-gold-bright .boxstyle-xs.box1 a:hover,
.brand-gold-bright .boxstyle-sm.box1 a:hover,
.brand-gold-bright .boxstyle-md.box1 a:hover,
.brand-gold-bright .boxstyle-lg.box1 a:hover,
.brand-gold-bright .boxstyle_.box1 a:focus,
.brand-gold-bright .boxstyle-xs.box1 a:focus,
.brand-gold-bright .boxstyle-sm.box1 a:focus,
.brand-gold-bright .boxstyle-md.box1 a:focus,
.brand-gold-bright .boxstyle-lg.box1 a:focus,
.brand-gold-bright .boxstyle_.box1 a:active,
.brand-gold-bright .boxstyle-xs.box1 a:active,
.brand-gold-bright .boxstyle-sm.box1 a:active,
.brand-gold-bright .boxstyle-md.box1 a:active,
.brand-gold-bright .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-gold-bright .boxstyle_.box2,
  .brand-gold-bright .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gold-bright .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gold-bright .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gold-bright .boxstyle_.box2,
.brand-gold-bright .boxstyle-xs.box2,
.brand-gold-bright .boxstyle-sm.box2,
.brand-gold-bright .boxstyle-md.box2,
.brand-gold-bright .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-gold-bright .boxstyle_.box2,
  .brand-gold-bright .boxstyle-xs.box2,
  .brand-gold-bright .boxstyle-sm.box2,
  .brand-gold-bright .boxstyle-md.box2,
  .brand-gold-bright .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-gold-bright .boxstyle_.box2 h1,
.brand-gold-bright .boxstyle-xs.box2 h1,
.brand-gold-bright .boxstyle-sm.box2 h1,
.brand-gold-bright .boxstyle-md.box2 h1,
.brand-gold-bright .boxstyle-lg.box2 h1,
.brand-gold-bright .boxstyle_.box2 h2,
.brand-gold-bright .boxstyle-xs.box2 h2,
.brand-gold-bright .boxstyle-sm.box2 h2,
.brand-gold-bright .boxstyle-md.box2 h2,
.brand-gold-bright .boxstyle-lg.box2 h2,
.brand-gold-bright .boxstyle_.box2 h3,
.brand-gold-bright .boxstyle-xs.box2 h3,
.brand-gold-bright .boxstyle-sm.box2 h3,
.brand-gold-bright .boxstyle-md.box2 h3,
.brand-gold-bright .boxstyle-lg.box2 h3,
.brand-gold-bright .boxstyle_.box2 h4,
.brand-gold-bright .boxstyle-xs.box2 h4,
.brand-gold-bright .boxstyle-sm.box2 h4,
.brand-gold-bright .boxstyle-md.box2 h4,
.brand-gold-bright .boxstyle-lg.box2 h4,
.brand-gold-bright .boxstyle_.box2 h5,
.brand-gold-bright .boxstyle-xs.box2 h5,
.brand-gold-bright .boxstyle-sm.box2 h5,
.brand-gold-bright .boxstyle-md.box2 h5,
.brand-gold-bright .boxstyle-lg.box2 h5,
.brand-gold-bright .boxstyle_.box2 h6,
.brand-gold-bright .boxstyle-xs.box2 h6,
.brand-gold-bright .boxstyle-sm.box2 h6,
.brand-gold-bright .boxstyle-md.box2 h6,
.brand-gold-bright .boxstyle-lg.box2 h6,
.brand-gold-bright .boxstyle_.box2 .h1,
.brand-gold-bright .boxstyle-xs.box2 .h1,
.brand-gold-bright .boxstyle-sm.box2 .h1,
.brand-gold-bright .boxstyle-md.box2 .h1,
.brand-gold-bright .boxstyle-lg.box2 .h1,
.brand-gold-bright .boxstyle_.box2 .h2,
.brand-gold-bright .boxstyle-xs.box2 .h2,
.brand-gold-bright .boxstyle-sm.box2 .h2,
.brand-gold-bright .boxstyle-md.box2 .h2,
.brand-gold-bright .boxstyle-lg.box2 .h2,
.brand-gold-bright .boxstyle_.box2 .h3,
.brand-gold-bright .boxstyle-xs.box2 .h3,
.brand-gold-bright .boxstyle-sm.box2 .h3,
.brand-gold-bright .boxstyle-md.box2 .h3,
.brand-gold-bright .boxstyle-lg.box2 .h3,
.brand-gold-bright .boxstyle_.box2 .h4,
.brand-gold-bright .boxstyle-xs.box2 .h4,
.brand-gold-bright .boxstyle-sm.box2 .h4,
.brand-gold-bright .boxstyle-md.box2 .h4,
.brand-gold-bright .boxstyle-lg.box2 .h4,
.brand-gold-bright .boxstyle_.box2 .h5,
.brand-gold-bright .boxstyle-xs.box2 .h5,
.brand-gold-bright .boxstyle-sm.box2 .h5,
.brand-gold-bright .boxstyle-md.box2 .h5,
.brand-gold-bright .boxstyle-lg.box2 .h5,
.brand-gold-bright .boxstyle_.box2 .h6,
.brand-gold-bright .boxstyle-xs.box2 .h6,
.brand-gold-bright .boxstyle-sm.box2 .h6,
.brand-gold-bright .boxstyle-md.box2 .h6,
.brand-gold-bright .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box2 blockquote.quotes,
.brand-gold-bright .boxstyle-xs.box2 blockquote.quotes,
.brand-gold-bright .boxstyle-sm.box2 blockquote.quotes,
.brand-gold-bright .boxstyle-md.box2 blockquote.quotes,
.brand-gold-bright .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-gold-bright .boxstyle_.box2 a,
.brand-gold-bright .boxstyle-xs.box2 a,
.brand-gold-bright .boxstyle-sm.box2 a,
.brand-gold-bright .boxstyle-md.box2 a,
.brand-gold-bright .boxstyle-lg.box2 a,
.brand-gold-bright .boxstyle_.box2 a:link,
.brand-gold-bright .boxstyle-xs.box2 a:link,
.brand-gold-bright .boxstyle-sm.box2 a:link,
.brand-gold-bright .boxstyle-md.box2 a:link,
.brand-gold-bright .boxstyle-lg.box2 a:link,
.brand-gold-bright .boxstyle_.box2 a:visited,
.brand-gold-bright .boxstyle-xs.box2 a:visited,
.brand-gold-bright .boxstyle-sm.box2 a:visited,
.brand-gold-bright .boxstyle-md.box2 a:visited,
.brand-gold-bright .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box2 a:hover,
.brand-gold-bright .boxstyle-xs.box2 a:hover,
.brand-gold-bright .boxstyle-sm.box2 a:hover,
.brand-gold-bright .boxstyle-md.box2 a:hover,
.brand-gold-bright .boxstyle-lg.box2 a:hover,
.brand-gold-bright .boxstyle_.box2 a:focus,
.brand-gold-bright .boxstyle-xs.box2 a:focus,
.brand-gold-bright .boxstyle-sm.box2 a:focus,
.brand-gold-bright .boxstyle-md.box2 a:focus,
.brand-gold-bright .boxstyle-lg.box2 a:focus,
.brand-gold-bright .boxstyle_.box2 a:active,
.brand-gold-bright .boxstyle-xs.box2 a:active,
.brand-gold-bright .boxstyle-sm.box2 a:active,
.brand-gold-bright .boxstyle-md.box2 a:active,
.brand-gold-bright .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box2 .popover button.close,
.brand-gold-bright .boxstyle-xs.box2 .popover button.close,
.brand-gold-bright .boxstyle-sm.box2 .popover button.close,
.brand-gold-bright .boxstyle-md.box2 .popover button.close,
.brand-gold-bright .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box2 .popover button.close:hover,
.brand-gold-bright .boxstyle-xs.box2 .popover button.close:hover,
.brand-gold-bright .boxstyle-sm.box2 .popover button.close:hover,
.brand-gold-bright .boxstyle-md.box2 .popover button.close:hover,
.brand-gold-bright .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box2 a:hover .new-window-link,
.brand-gold-bright .boxstyle-xs.box2 a:hover .new-window-link,
.brand-gold-bright .boxstyle-sm.box2 a:hover .new-window-link,
.brand-gold-bright .boxstyle-md.box2 a:hover .new-window-link,
.brand-gold-bright .boxstyle-lg.box2 a:hover .new-window-link,
.brand-gold-bright .boxstyle_.box2 a:hover .insecure-link,
.brand-gold-bright .boxstyle-xs.box2 a:hover .insecure-link,
.brand-gold-bright .boxstyle-sm.box2 a:hover .insecure-link,
.brand-gold-bright .boxstyle-md.box2 a:hover .insecure-link,
.brand-gold-bright .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box2 .btn-link,
.brand-gold-bright .boxstyle-xs.box2 .btn-link,
.brand-gold-bright .boxstyle-sm.box2 .btn-link,
.brand-gold-bright .boxstyle-md.box2 .btn-link,
.brand-gold-bright .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box2 .btn-link:hover,
.brand-gold-bright .boxstyle-xs.box2 .btn-link:hover,
.brand-gold-bright .boxstyle-sm.box2 .btn-link:hover,
.brand-gold-bright .boxstyle-md.box2 .btn-link:hover,
.brand-gold-bright .boxstyle-lg.box2 .btn-link:hover,
.brand-gold-bright .boxstyle_.box2 .btn-link:focus,
.brand-gold-bright .boxstyle-xs.box2 .btn-link:focus,
.brand-gold-bright .boxstyle-sm.box2 .btn-link:focus,
.brand-gold-bright .boxstyle-md.box2 .btn-link:focus,
.brand-gold-bright .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box2 a,
.brand-gold-bright .boxstyle-xs.box2 a,
.brand-gold-bright .boxstyle-sm.box2 a,
.brand-gold-bright .boxstyle-md.box2 a,
.brand-gold-bright .boxstyle-lg.box2 a,
.brand-gold-bright .boxstyle_.box2 a:link,
.brand-gold-bright .boxstyle-xs.box2 a:link,
.brand-gold-bright .boxstyle-sm.box2 a:link,
.brand-gold-bright .boxstyle-md.box2 a:link,
.brand-gold-bright .boxstyle-lg.box2 a:link,
.brand-gold-bright .boxstyle_.box2 a:visited,
.brand-gold-bright .boxstyle-xs.box2 a:visited,
.brand-gold-bright .boxstyle-sm.box2 a:visited,
.brand-gold-bright .boxstyle-md.box2 a:visited,
.brand-gold-bright .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold-bright .boxstyle_.box2 a:hover,
.brand-gold-bright .boxstyle-xs.box2 a:hover,
.brand-gold-bright .boxstyle-sm.box2 a:hover,
.brand-gold-bright .boxstyle-md.box2 a:hover,
.brand-gold-bright .boxstyle-lg.box2 a:hover,
.brand-gold-bright .boxstyle_.box2 a:focus,
.brand-gold-bright .boxstyle-xs.box2 a:focus,
.brand-gold-bright .boxstyle-sm.box2 a:focus,
.brand-gold-bright .boxstyle-md.box2 a:focus,
.brand-gold-bright .boxstyle-lg.box2 a:focus,
.brand-gold-bright .boxstyle_.box2 a:active,
.brand-gold-bright .boxstyle-xs.box2 a:active,
.brand-gold-bright .boxstyle-sm.box2 a:active,
.brand-gold-bright .boxstyle-md.box2 a:active,
.brand-gold-bright .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold-bright .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #F1BE48;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-gold-bright .boxstyle_.box3,
  .brand-gold-bright .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #F1BE48;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-gold-bright .boxstyle-md.box3 {
    background: none;
    border: 1px solid #F1BE48;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-gold-bright .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #F1BE48;
    color: inherit;
  }
}
.brand-gold-bright .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-gold-bright .boxstyle_.box4,
  .brand-gold-bright .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-gold-bright .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-gold-bright .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-gold-bright .boxstyle_.box5,
  .brand-gold-bright .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-gold-bright .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-gold-bright .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-gold-bright .boxstyle_.box5,
.brand-gold-bright .boxstyle-xs.box5,
.brand-gold-bright .boxstyle-sm.box5,
.brand-gold-bright .boxstyle-md.box5,
.brand-gold-bright .boxstyle-lg.box5 {
  border: 0;
  background: #F1BE48;
  color: #202020;
}
@media print {
  .brand-gold-bright .boxstyle_.box5,
  .brand-gold-bright .boxstyle-xs.box5,
  .brand-gold-bright .boxstyle-sm.box5,
  .brand-gold-bright .boxstyle-md.box5,
  .brand-gold-bright .boxstyle-lg.box5 {
    border: 1px solid #F1BE48;
  }
}
.brand-gold-bright .boxstyle_.box5 h1,
.brand-gold-bright .boxstyle-xs.box5 h1,
.brand-gold-bright .boxstyle-sm.box5 h1,
.brand-gold-bright .boxstyle-md.box5 h1,
.brand-gold-bright .boxstyle-lg.box5 h1,
.brand-gold-bright .boxstyle_.box5 h2,
.brand-gold-bright .boxstyle-xs.box5 h2,
.brand-gold-bright .boxstyle-sm.box5 h2,
.brand-gold-bright .boxstyle-md.box5 h2,
.brand-gold-bright .boxstyle-lg.box5 h2,
.brand-gold-bright .boxstyle_.box5 h3,
.brand-gold-bright .boxstyle-xs.box5 h3,
.brand-gold-bright .boxstyle-sm.box5 h3,
.brand-gold-bright .boxstyle-md.box5 h3,
.brand-gold-bright .boxstyle-lg.box5 h3,
.brand-gold-bright .boxstyle_.box5 h4,
.brand-gold-bright .boxstyle-xs.box5 h4,
.brand-gold-bright .boxstyle-sm.box5 h4,
.brand-gold-bright .boxstyle-md.box5 h4,
.brand-gold-bright .boxstyle-lg.box5 h4,
.brand-gold-bright .boxstyle_.box5 h5,
.brand-gold-bright .boxstyle-xs.box5 h5,
.brand-gold-bright .boxstyle-sm.box5 h5,
.brand-gold-bright .boxstyle-md.box5 h5,
.brand-gold-bright .boxstyle-lg.box5 h5,
.brand-gold-bright .boxstyle_.box5 h6,
.brand-gold-bright .boxstyle-xs.box5 h6,
.brand-gold-bright .boxstyle-sm.box5 h6,
.brand-gold-bright .boxstyle-md.box5 h6,
.brand-gold-bright .boxstyle-lg.box5 h6,
.brand-gold-bright .boxstyle_.box5 .h1,
.brand-gold-bright .boxstyle-xs.box5 .h1,
.brand-gold-bright .boxstyle-sm.box5 .h1,
.brand-gold-bright .boxstyle-md.box5 .h1,
.brand-gold-bright .boxstyle-lg.box5 .h1,
.brand-gold-bright .boxstyle_.box5 .h2,
.brand-gold-bright .boxstyle-xs.box5 .h2,
.brand-gold-bright .boxstyle-sm.box5 .h2,
.brand-gold-bright .boxstyle-md.box5 .h2,
.brand-gold-bright .boxstyle-lg.box5 .h2,
.brand-gold-bright .boxstyle_.box5 .h3,
.brand-gold-bright .boxstyle-xs.box5 .h3,
.brand-gold-bright .boxstyle-sm.box5 .h3,
.brand-gold-bright .boxstyle-md.box5 .h3,
.brand-gold-bright .boxstyle-lg.box5 .h3,
.brand-gold-bright .boxstyle_.box5 .h4,
.brand-gold-bright .boxstyle-xs.box5 .h4,
.brand-gold-bright .boxstyle-sm.box5 .h4,
.brand-gold-bright .boxstyle-md.box5 .h4,
.brand-gold-bright .boxstyle-lg.box5 .h4,
.brand-gold-bright .boxstyle_.box5 .h5,
.brand-gold-bright .boxstyle-xs.box5 .h5,
.brand-gold-bright .boxstyle-sm.box5 .h5,
.brand-gold-bright .boxstyle-md.box5 .h5,
.brand-gold-bright .boxstyle-lg.box5 .h5,
.brand-gold-bright .boxstyle_.box5 .h6,
.brand-gold-bright .boxstyle-xs.box5 .h6,
.brand-gold-bright .boxstyle-sm.box5 .h6,
.brand-gold-bright .boxstyle-md.box5 .h6,
.brand-gold-bright .boxstyle-lg.box5 .h6 {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box5 blockquote.quotes,
.brand-gold-bright .boxstyle-xs.box5 blockquote.quotes,
.brand-gold-bright .boxstyle-sm.box5 blockquote.quotes,
.brand-gold-bright .boxstyle-md.box5 blockquote.quotes,
.brand-gold-bright .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-gold-bright .boxstyle_.box5 a,
.brand-gold-bright .boxstyle-xs.box5 a,
.brand-gold-bright .boxstyle-sm.box5 a,
.brand-gold-bright .boxstyle-md.box5 a,
.brand-gold-bright .boxstyle-lg.box5 a,
.brand-gold-bright .boxstyle_.box5 a:link,
.brand-gold-bright .boxstyle-xs.box5 a:link,
.brand-gold-bright .boxstyle-sm.box5 a:link,
.brand-gold-bright .boxstyle-md.box5 a:link,
.brand-gold-bright .boxstyle-lg.box5 a:link,
.brand-gold-bright .boxstyle_.box5 a:visited,
.brand-gold-bright .boxstyle-xs.box5 a:visited,
.brand-gold-bright .boxstyle-sm.box5 a:visited,
.brand-gold-bright .boxstyle-md.box5 a:visited,
.brand-gold-bright .boxstyle-lg.box5 a:visited {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box5 a:hover,
.brand-gold-bright .boxstyle-xs.box5 a:hover,
.brand-gold-bright .boxstyle-sm.box5 a:hover,
.brand-gold-bright .boxstyle-md.box5 a:hover,
.brand-gold-bright .boxstyle-lg.box5 a:hover,
.brand-gold-bright .boxstyle_.box5 a:focus,
.brand-gold-bright .boxstyle-xs.box5 a:focus,
.brand-gold-bright .boxstyle-sm.box5 a:focus,
.brand-gold-bright .boxstyle-md.box5 a:focus,
.brand-gold-bright .boxstyle-lg.box5 a:focus,
.brand-gold-bright .boxstyle_.box5 a:active,
.brand-gold-bright .boxstyle-xs.box5 a:active,
.brand-gold-bright .boxstyle-sm.box5 a:active,
.brand-gold-bright .boxstyle-md.box5 a:active,
.brand-gold-bright .boxstyle-lg.box5 a:active {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box5 .popover button.close,
.brand-gold-bright .boxstyle-xs.box5 .popover button.close,
.brand-gold-bright .boxstyle-sm.box5 .popover button.close,
.brand-gold-bright .boxstyle-md.box5 .popover button.close,
.brand-gold-bright .boxstyle-lg.box5 .popover button.close {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box5 .popover button.close:hover,
.brand-gold-bright .boxstyle-xs.box5 .popover button.close:hover,
.brand-gold-bright .boxstyle-sm.box5 .popover button.close:hover,
.brand-gold-bright .boxstyle-md.box5 .popover button.close:hover,
.brand-gold-bright .boxstyle-lg.box5 .popover button.close:hover {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box5 a:hover .new-window-link,
.brand-gold-bright .boxstyle-xs.box5 a:hover .new-window-link,
.brand-gold-bright .boxstyle-sm.box5 a:hover .new-window-link,
.brand-gold-bright .boxstyle-md.box5 a:hover .new-window-link,
.brand-gold-bright .boxstyle-lg.box5 a:hover .new-window-link,
.brand-gold-bright .boxstyle_.box5 a:hover .insecure-link,
.brand-gold-bright .boxstyle-xs.box5 a:hover .insecure-link,
.brand-gold-bright .boxstyle-sm.box5 a:hover .insecure-link,
.brand-gold-bright .boxstyle-md.box5 a:hover .insecure-link,
.brand-gold-bright .boxstyle-lg.box5 a:hover .insecure-link {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box5 .btn-link,
.brand-gold-bright .boxstyle-xs.box5 .btn-link,
.brand-gold-bright .boxstyle-sm.box5 .btn-link,
.brand-gold-bright .boxstyle-md.box5 .btn-link,
.brand-gold-bright .boxstyle-lg.box5 .btn-link {
  color: #202020;
}
.brand-gold-bright .boxstyle_.box5 .btn-link:hover,
.brand-gold-bright .boxstyle-xs.box5 .btn-link:hover,
.brand-gold-bright .boxstyle-sm.box5 .btn-link:hover,
.brand-gold-bright .boxstyle-md.box5 .btn-link:hover,
.brand-gold-bright .boxstyle-lg.box5 .btn-link:hover,
.brand-gold-bright .boxstyle_.box5 .btn-link:focus,
.brand-gold-bright .boxstyle-xs.box5 .btn-link:focus,
.brand-gold-bright .boxstyle-sm.box5 .btn-link:focus,
.brand-gold-bright .boxstyle-md.box5 .btn-link:focus,
.brand-gold-bright .boxstyle-lg.box5 .btn-link:focus {
  color: #000000;
}
.brand-gold-bright .boxstyle_.box5 a,
.brand-gold-bright .boxstyle-xs.box5 a,
.brand-gold-bright .boxstyle-sm.box5 a,
.brand-gold-bright .boxstyle-md.box5 a,
.brand-gold-bright .boxstyle-lg.box5 a,
.brand-gold-bright .boxstyle_.box5 a:link,
.brand-gold-bright .boxstyle-xs.box5 a:link,
.brand-gold-bright .boxstyle-sm.box5 a:link,
.brand-gold-bright .boxstyle-md.box5 a:link,
.brand-gold-bright .boxstyle-lg.box5 a:link,
.brand-gold-bright .boxstyle_.box5 a:visited,
.brand-gold-bright .boxstyle-xs.box5 a:visited,
.brand-gold-bright .boxstyle-sm.box5 a:visited,
.brand-gold-bright .boxstyle-md.box5 a:visited,
.brand-gold-bright .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-gold-bright .boxstyle_.box5 a:hover,
.brand-gold-bright .boxstyle-xs.box5 a:hover,
.brand-gold-bright .boxstyle-sm.box5 a:hover,
.brand-gold-bright .boxstyle-md.box5 a:hover,
.brand-gold-bright .boxstyle-lg.box5 a:hover,
.brand-gold-bright .boxstyle_.box5 a:focus,
.brand-gold-bright .boxstyle-xs.box5 a:focus,
.brand-gold-bright .boxstyle-sm.box5 a:focus,
.brand-gold-bright .boxstyle-md.box5 a:focus,
.brand-gold-bright .boxstyle-lg.box5 a:focus,
.brand-gold-bright .boxstyle_.box5 a:active,
.brand-gold-bright .boxstyle-xs.box5 a:active,
.brand-gold-bright .boxstyle-sm.box5 a:active,
.brand-gold-bright .boxstyle-md.box5 a:active,
.brand-gold-bright .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-gold-bright .pagination > li a,
.brand-gold-bright .pagination > li span {
  color: #F1BE48;
}
.brand-gold-bright .pagination > li.active > a,
.brand-gold-bright .pagination > li.active > span:not(.sr-only),
.brand-gold-bright .pagination > li.active > a:hover,
.brand-gold-bright .pagination > li.active > span:not(.sr-only):hover,
.brand-gold-bright .pagination > li.active > a:focus,
.brand-gold-bright .pagination > li.active > span:not(.sr-only):focus {
  background-color: #F1BE48;
  border-color: #F1BE48;
  color: #202020;
}
.brand-gold-bright .nav-tabs {
  border-bottom-color: #f8dfa3;
}
.brand-gold-bright .nav-tabs > li > a {
  background-color: #fcf2da;
  border-color: #f8dfa3;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-gold-bright .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #f7d891;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-gold-bright .nav-tabs > li > a:hover,
.brand-gold-bright .nav-tabs > li > a:focus {
  background-color: #fef9ed;
  border-color: #f9e5b6 #f9e5b6 #f8dfa3;
}
.brand-gold-bright .nav-tabs > li > a:hover::before,
.brand-gold-bright .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-gold-bright .nav-tabs > li.open > a,
.brand-gold-bright .nav-tabs > li.open > a:hover,
.brand-gold-bright .nav-tabs > li.open > a:focus {
  background-color: #fef9ed;
  border-color: #f9e5b6 #f9e5b6 #f8dfa3;
}
.brand-gold-bright .nav-tabs > li.open > a::before,
.brand-gold-bright .nav-tabs > li.open > a:hover::before,
.brand-gold-bright .nav-tabs > li.open > a:focus::before {
  background: #F1BE48;
  width: 100%;
}
.brand-gold-bright .nav-tabs > li.active > a,
.brand-gold-bright .nav-tabs > li.active > a:hover,
.brand-gold-bright .nav-tabs > li.active > a:focus {
  border-color: #f8dfa3 #f8dfa3 transparent;
}
.brand-gold-bright .nav-tabs > li.active > a::before,
.brand-gold-bright .nav-tabs > li.active > a:hover::before,
.brand-gold-bright .nav-tabs > li.active > a:focus::before {
  background: #F1BE48;
  width: 100%;
}
.brand-gold-bright .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-gold-bright .nav-tabs > li.disabled > a:hover,
.brand-gold-bright .nav-tabs > li.disabled > a:focus {
  background-color: #fcf2da;
}
.brand-gold-bright .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-gold-bright .nav-pills > li.active > a,
.brand-gold-bright .nav-pills > li.active > a:hover,
.brand-gold-bright .nav-pills > li.active > a:focus {
  background-color: #F1BE48;
  color: #202020;
}
.brand-gold-bright .list-group-item.active,
.brand-gold-bright .list-group-item.active:hover,
.brand-gold-bright .list-group-item.active:focus {
  background-color: #F1BE48;
  border-color: #F1BE48;
  color: #202020;
}
.brand-gold-bright .id7-koan-spinner__neon--top,
.brand-gold-bright .id7-koan-spinner__neon--bottom {
  stroke: #F1BE48;
}
.brand-blue h1,
.brand-blue h2,
.brand-blue h3,
.brand-blue h4,
.brand-blue h5,
.brand-blue h6,
.brand-blue .h1,
.brand-blue .h2,
.brand-blue .h3,
.brand-blue .h4,
.brand-blue .h5,
.brand-blue .h6 {
  color: #41748D;
}
.brand-blue a,
.brand-blue a:link,
.brand-blue a:visited {
  color: #41748D;
}
.brand-blue a:hover,
.brand-blue a:focus,
.brand-blue a:active {
  color: #213b47;
}
.brand-blue .popover button.close {
  color: #41748D;
}
.brand-blue .popover button.close:hover {
  color: #213b47;
}
.brand-blue a:hover .new-window-link,
.brand-blue a:hover .insecure-link {
  color: #213b47;
}
.brand-blue .btn-link {
  color: #41748D;
}
.brand-blue .btn-link:hover,
.brand-blue .btn-link:focus {
  color: #213b47;
}
.brand-blue a,
.brand-blue a:link,
.brand-blue a:visited {
  -webkit-text-decoration-color: #7a9eaf;
  text-decoration-color: #7a9eaf;
}
.brand-blue a:hover,
.brand-blue a:focus,
.brand-blue a:active {
  -webkit-text-decoration-color: #213b47;
  text-decoration-color: #213b47;
}
.brand-blue blockquote.quotes {
  color: #213b47;
}
.brand-blue blockquote.quotes::before {
  color: #213b47;
}
.brand-blue .brand-bg {
  background: #41748D;
  color: white;
}
.brand-blue .brand-bg a,
.brand-blue .brand-bg a:link,
.brand-blue .brand-bg a:visited {
  color: white !important;
}
.brand-blue .brand-bg a:hover,
.brand-blue .brand-bg a:focus,
.brand-blue .brand-bg a:active {
  color: #cccccc !important;
}
.brand-blue .brand-bg .popover button.close {
  color: white !important;
}
.brand-blue .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-blue .brand-bg a:hover .new-window-link,
.brand-blue .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-blue .brand-bg .btn-link {
  color: white !important;
}
.brand-blue .brand-bg .btn-link:hover,
.brand-blue .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-blue .brand-bg a,
.brand-blue .brand-bg a:link,
.brand-blue .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-blue .brand-bg a:hover,
.brand-blue .brand-bg a:focus,
.brand-blue .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-blue .brand-text {
  color: #41748D;
}
.brand-blue .brand-text a,
.brand-blue .brand-text a:link,
.brand-blue .brand-text a:visited {
  color: #41748D !important;
}
.brand-blue .brand-text a:hover,
.brand-blue .brand-text a:focus,
.brand-blue .brand-text a:active {
  color: #213b47 !important;
}
.brand-blue .brand-text .popover button.close {
  color: #41748D !important;
}
.brand-blue .brand-text .popover button.close:hover {
  color: #213b47 !important;
}
.brand-blue .brand-text a:hover .new-window-link,
.brand-blue .brand-text a:hover .insecure-link {
  color: #213b47 !important;
}
.brand-blue .brand-text .btn-link {
  color: #41748D !important;
}
.brand-blue .brand-text .btn-link:hover,
.brand-blue .brand-text .btn-link:focus {
  color: #213b47 !important;
}
.brand-blue .brand-text a,
.brand-blue .brand-text a:link,
.brand-blue .brand-text a:visited {
  -webkit-text-decoration-color: #7a9eaf !important;
  text-decoration-color: #7a9eaf !important;
}
.brand-blue .brand-text a:hover,
.brand-blue .brand-text a:focus,
.brand-blue .brand-text a:active {
  -webkit-text-decoration-color: #213b47 !important;
  text-decoration-color: #213b47 !important;
}
.brand-blue .brand-border {
  border-color: #41748D;
}
.brand-blue .btn-brand {
  color: white;
  background-color: #41748D;
  border-color: #39667c;
  color: white !important;
}
.brand-blue .btn-brand:focus,
.brand-blue .btn-brand.focus {
  color: white;
  background-color: #31576a;
  border-color: #111e24;
}
.brand-blue .btn-brand:hover {
  color: white;
  background-color: #31576a;
  border-color: #264352;
}
.brand-blue .btn-brand:active,
.brand-blue .btn-brand.active,
.open > .dropdown-toggle.brand-blue .btn-brand {
  color: white;
  background-color: #31576a;
  background-image: none;
  border-color: #264352;
}
.brand-blue .btn-brand:active:hover,
.brand-blue .btn-brand.active:hover,
.open > .dropdown-toggle.brand-blue .btn-brand:hover,
.brand-blue .btn-brand:active:focus,
.brand-blue .btn-brand.active:focus,
.open > .dropdown-toggle.brand-blue .btn-brand:focus,
.brand-blue .btn-brand:active.focus,
.brand-blue .btn-brand.active.focus,
.open > .dropdown-toggle.brand-blue .btn-brand.focus {
  color: white;
  background-color: #264352;
  border-color: #111e24;
}
.brand-blue .btn-brand.disabled:hover,
.brand-blue .btn-brand[disabled]:hover,
fieldset[disabled] .brand-blue .btn-brand:hover,
.brand-blue .btn-brand.disabled:focus,
.brand-blue .btn-brand[disabled]:focus,
fieldset[disabled] .brand-blue .btn-brand:focus,
.brand-blue .btn-brand.disabled.focus,
.brand-blue .btn-brand[disabled].focus,
fieldset[disabled] .brand-blue .btn-brand.focus {
  background-color: #41748D;
  border-color: #39667c;
}
.brand-blue .btn-brand .badge {
  color: #41748D;
  background-color: white;
}
.brand-blue .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-blue .id7-utility-masthead {
  background-color: #7a9eaf;
}
.brand-blue .id7-utility-masthead::after {
  background-color: #41748D;
}
body.id7-point-1 .brand-blue .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-blue .id7-navigation .navbar-primary {
  background-color: #41748D;
}
.brand-blue .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-blue .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #afc5cf;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #345d71;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #afc5cf;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-blue .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-blue .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #345d71;
}
.brand-blue .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-blue .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-blue .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-blue .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #345d71;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #41748D;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #345d71;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #345d71;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #345d71;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #345d71;
  background-image: none;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #afc5cf;
}
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-blue .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #345d71;
}
.brand-blue .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .btn-link:hover,
.brand-blue .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-blue .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-blue .id7-navigation .navbar-primary .btn-link:hover,
.brand-blue .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-blue .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-blue .navbar-primary .navbar-brand {
  background-color: #41748D;
}
.brand-blue .navbar-secondary {
  background-color: #afc5cf;
}
.brand-blue .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-blue .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-blue .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-blue .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-blue .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-blue .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-blue .navbar-secondary .navbar-nav > li:hover,
.brand-blue .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.brand-blue .navbar-secondary .navbar-nav > li > a,
.brand-blue .navbar-secondary .navbar-nav > li > a:hover,
.brand-blue .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-blue .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-blue .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-blue .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-blue .navbar-secondary .navbar-nav > .disabled,
.brand-blue .navbar-secondary .navbar-nav > .disabled:hover,
.brand-blue .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.brand-blue .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-blue .navbar-secondary .navbar-toggle:hover,
.brand-blue .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-blue .navbar-secondary .navbar-nav > .open,
.brand-blue .navbar-secondary .navbar-nav > .open:hover,
.brand-blue .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #afc5cf;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #8c9da6;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #8c9da6;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c7d6dd;
  background-image: none;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-blue .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.brand-blue .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-blue .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-blue .navbar-secondary .btn-link {
  color: #202020;
}
.brand-blue .navbar-secondary .btn-link:hover,
.brand-blue .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-blue .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-blue .navbar-secondary .btn-link:hover,
.brand-blue .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-blue .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-blue .navbar-secondary .navbar-nav > li::after {
  color: #41748D;
}
.brand-blue .navbar-secondary .navbar-nav > li:first-child {
  background-color: #41748D;
  color: white;
}
.brand-blue .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #41748D;
}
.brand-blue .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #41748D;
}
.brand-blue .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-blue .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-blue .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-blue .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-blue .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-blue .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-blue .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-blue .navbar-tertiary .navbar-nav > li:hover,
.brand-blue .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue .navbar-tertiary .navbar-nav > li > a,
.brand-blue .navbar-tertiary .navbar-nav > li > a:hover,
.brand-blue .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-blue .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-blue .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-blue .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-blue .navbar-tertiary .navbar-nav > .disabled,
.brand-blue .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-blue .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-blue .navbar-tertiary .navbar-toggle:hover,
.brand-blue .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-blue .navbar-tertiary .navbar-nav > .open,
.brand-blue .navbar-tertiary .navbar-nav > .open:hover,
.brand-blue .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-blue .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-blue .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-blue .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-blue .navbar-tertiary .btn-link:hover,
.brand-blue .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-blue .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-blue .navbar-tertiary .btn-link:hover,
.brand-blue .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-blue .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-blue.bordered .id7-left-border,
.brand-blue.bordered .id7-right-border {
  border-color: rgba(65, 116, 141, 0.3);
}
.brand-blue .id7-page-footer .id7-app-footer {
  background: #41748D;
  color: white;
}
.brand-blue .id7-page-footer .id7-app-footer h1,
.brand-blue .id7-page-footer .id7-app-footer h2,
.brand-blue .id7-page-footer .id7-app-footer h3,
.brand-blue .id7-page-footer .id7-app-footer h4,
.brand-blue .id7-page-footer .id7-app-footer h5,
.brand-blue .id7-page-footer .id7-app-footer h6,
.brand-blue .id7-page-footer .id7-app-footer .h1,
.brand-blue .id7-page-footer .id7-app-footer .h2,
.brand-blue .id7-page-footer .id7-app-footer .h3,
.brand-blue .id7-page-footer .id7-app-footer .h4,
.brand-blue .id7-page-footer .id7-app-footer .h5,
.brand-blue .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-blue .id7-page-footer .id7-app-footer a,
.brand-blue .id7-page-footer .id7-app-footer a:link,
.brand-blue .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-blue .id7-page-footer .id7-app-footer a:hover,
.brand-blue .id7-page-footer .id7-app-footer a:focus,
.brand-blue .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-blue .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-blue .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-blue .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-blue .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-blue .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-blue .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-blue .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-blue .id7-page-footer .id7-app-footer a,
.brand-blue .id7-page-footer .id7-app-footer a:link,
.brand-blue .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-blue .id7-page-footer .id7-app-footer a:hover,
.brand-blue .id7-page-footer .id7-app-footer a:focus,
.brand-blue .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-blue .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #41748D;
}
.brand-blue .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-blue .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-blue .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-blue .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-blue .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-blue .id7-search .fa,
.brand-blue .id7-search .fas {
  color: #41748D;
  color: rgba(65, 116, 141, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-blue .id7-search .form-control:hover + .fa,
.brand-blue .id7-search .typeahead:hover + .fa,
.brand-blue .id7-search .fa:hover,
.brand-blue .id7-search .form-control:hover + .fas,
.brand-blue .id7-search .typeahead:hover + .fas,
.brand-blue .id7-search .fas:hover {
  color: #41748D;
}
.brand-blue .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #41748D;
  color: white;
}
.brand-blue .form-control:focus {
  border-color: #41748D;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(65, 116, 141, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(65, 116, 141, 0.6);
}
.brand-blue .carousel .carousel-indicators li::after {
  color: #41748D;
  color: rgba(65, 116, 141, 0.3);
}
.brand-blue .carousel .carousel-indicators li.active::after {
  color: #41748D;
}
@media (max-width: 767px) {
  .brand-blue .boxstyle_.box1,
  .brand-blue .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-blue .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-blue .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-blue .boxstyle_.box1,
.brand-blue .boxstyle-xs.box1,
.brand-blue .boxstyle-sm.box1,
.brand-blue .boxstyle-md.box1,
.brand-blue .boxstyle-lg.box1 {
  border: 0;
  background: #e3eaee;
  color: #202020;
}
@media print {
  .brand-blue .boxstyle_.box1,
  .brand-blue .boxstyle-xs.box1,
  .brand-blue .boxstyle-sm.box1,
  .brand-blue .boxstyle-md.box1,
  .brand-blue .boxstyle-lg.box1 {
    border: 1px solid #e3eaee;
  }
}
.brand-blue .boxstyle_.box1 h1,
.brand-blue .boxstyle-xs.box1 h1,
.brand-blue .boxstyle-sm.box1 h1,
.brand-blue .boxstyle-md.box1 h1,
.brand-blue .boxstyle-lg.box1 h1,
.brand-blue .boxstyle_.box1 h2,
.brand-blue .boxstyle-xs.box1 h2,
.brand-blue .boxstyle-sm.box1 h2,
.brand-blue .boxstyle-md.box1 h2,
.brand-blue .boxstyle-lg.box1 h2,
.brand-blue .boxstyle_.box1 h3,
.brand-blue .boxstyle-xs.box1 h3,
.brand-blue .boxstyle-sm.box1 h3,
.brand-blue .boxstyle-md.box1 h3,
.brand-blue .boxstyle-lg.box1 h3,
.brand-blue .boxstyle_.box1 h4,
.brand-blue .boxstyle-xs.box1 h4,
.brand-blue .boxstyle-sm.box1 h4,
.brand-blue .boxstyle-md.box1 h4,
.brand-blue .boxstyle-lg.box1 h4,
.brand-blue .boxstyle_.box1 h5,
.brand-blue .boxstyle-xs.box1 h5,
.brand-blue .boxstyle-sm.box1 h5,
.brand-blue .boxstyle-md.box1 h5,
.brand-blue .boxstyle-lg.box1 h5,
.brand-blue .boxstyle_.box1 h6,
.brand-blue .boxstyle-xs.box1 h6,
.brand-blue .boxstyle-sm.box1 h6,
.brand-blue .boxstyle-md.box1 h6,
.brand-blue .boxstyle-lg.box1 h6,
.brand-blue .boxstyle_.box1 .h1,
.brand-blue .boxstyle-xs.box1 .h1,
.brand-blue .boxstyle-sm.box1 .h1,
.brand-blue .boxstyle-md.box1 .h1,
.brand-blue .boxstyle-lg.box1 .h1,
.brand-blue .boxstyle_.box1 .h2,
.brand-blue .boxstyle-xs.box1 .h2,
.brand-blue .boxstyle-sm.box1 .h2,
.brand-blue .boxstyle-md.box1 .h2,
.brand-blue .boxstyle-lg.box1 .h2,
.brand-blue .boxstyle_.box1 .h3,
.brand-blue .boxstyle-xs.box1 .h3,
.brand-blue .boxstyle-sm.box1 .h3,
.brand-blue .boxstyle-md.box1 .h3,
.brand-blue .boxstyle-lg.box1 .h3,
.brand-blue .boxstyle_.box1 .h4,
.brand-blue .boxstyle-xs.box1 .h4,
.brand-blue .boxstyle-sm.box1 .h4,
.brand-blue .boxstyle-md.box1 .h4,
.brand-blue .boxstyle-lg.box1 .h4,
.brand-blue .boxstyle_.box1 .h5,
.brand-blue .boxstyle-xs.box1 .h5,
.brand-blue .boxstyle-sm.box1 .h5,
.brand-blue .boxstyle-md.box1 .h5,
.brand-blue .boxstyle-lg.box1 .h5,
.brand-blue .boxstyle_.box1 .h6,
.brand-blue .boxstyle-xs.box1 .h6,
.brand-blue .boxstyle-sm.box1 .h6,
.brand-blue .boxstyle-md.box1 .h6,
.brand-blue .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-blue .boxstyle_.box1 blockquote.quotes,
.brand-blue .boxstyle-xs.box1 blockquote.quotes,
.brand-blue .boxstyle-sm.box1 blockquote.quotes,
.brand-blue .boxstyle-md.box1 blockquote.quotes,
.brand-blue .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-blue .boxstyle_.box1 a,
.brand-blue .boxstyle-xs.box1 a,
.brand-blue .boxstyle-sm.box1 a,
.brand-blue .boxstyle-md.box1 a,
.brand-blue .boxstyle-lg.box1 a,
.brand-blue .boxstyle_.box1 a:link,
.brand-blue .boxstyle-xs.box1 a:link,
.brand-blue .boxstyle-sm.box1 a:link,
.brand-blue .boxstyle-md.box1 a:link,
.brand-blue .boxstyle-lg.box1 a:link,
.brand-blue .boxstyle_.box1 a:visited,
.brand-blue .boxstyle-xs.box1 a:visited,
.brand-blue .boxstyle-sm.box1 a:visited,
.brand-blue .boxstyle-md.box1 a:visited,
.brand-blue .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-blue .boxstyle_.box1 a:hover,
.brand-blue .boxstyle-xs.box1 a:hover,
.brand-blue .boxstyle-sm.box1 a:hover,
.brand-blue .boxstyle-md.box1 a:hover,
.brand-blue .boxstyle-lg.box1 a:hover,
.brand-blue .boxstyle_.box1 a:focus,
.brand-blue .boxstyle-xs.box1 a:focus,
.brand-blue .boxstyle-sm.box1 a:focus,
.brand-blue .boxstyle-md.box1 a:focus,
.brand-blue .boxstyle-lg.box1 a:focus,
.brand-blue .boxstyle_.box1 a:active,
.brand-blue .boxstyle-xs.box1 a:active,
.brand-blue .boxstyle-sm.box1 a:active,
.brand-blue .boxstyle-md.box1 a:active,
.brand-blue .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-blue .boxstyle_.box1 .popover button.close,
.brand-blue .boxstyle-xs.box1 .popover button.close,
.brand-blue .boxstyle-sm.box1 .popover button.close,
.brand-blue .boxstyle-md.box1 .popover button.close,
.brand-blue .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-blue .boxstyle_.box1 .popover button.close:hover,
.brand-blue .boxstyle-xs.box1 .popover button.close:hover,
.brand-blue .boxstyle-sm.box1 .popover button.close:hover,
.brand-blue .boxstyle-md.box1 .popover button.close:hover,
.brand-blue .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-blue .boxstyle_.box1 a:hover .new-window-link,
.brand-blue .boxstyle-xs.box1 a:hover .new-window-link,
.brand-blue .boxstyle-sm.box1 a:hover .new-window-link,
.brand-blue .boxstyle-md.box1 a:hover .new-window-link,
.brand-blue .boxstyle-lg.box1 a:hover .new-window-link,
.brand-blue .boxstyle_.box1 a:hover .insecure-link,
.brand-blue .boxstyle-xs.box1 a:hover .insecure-link,
.brand-blue .boxstyle-sm.box1 a:hover .insecure-link,
.brand-blue .boxstyle-md.box1 a:hover .insecure-link,
.brand-blue .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-blue .boxstyle_.box1 .btn-link,
.brand-blue .boxstyle-xs.box1 .btn-link,
.brand-blue .boxstyle-sm.box1 .btn-link,
.brand-blue .boxstyle-md.box1 .btn-link,
.brand-blue .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-blue .boxstyle_.box1 .btn-link:hover,
.brand-blue .boxstyle-xs.box1 .btn-link:hover,
.brand-blue .boxstyle-sm.box1 .btn-link:hover,
.brand-blue .boxstyle-md.box1 .btn-link:hover,
.brand-blue .boxstyle-lg.box1 .btn-link:hover,
.brand-blue .boxstyle_.box1 .btn-link:focus,
.brand-blue .boxstyle-xs.box1 .btn-link:focus,
.brand-blue .boxstyle-sm.box1 .btn-link:focus,
.brand-blue .boxstyle-md.box1 .btn-link:focus,
.brand-blue .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-blue .boxstyle_.box1 a,
.brand-blue .boxstyle-xs.box1 a,
.brand-blue .boxstyle-sm.box1 a,
.brand-blue .boxstyle-md.box1 a,
.brand-blue .boxstyle-lg.box1 a,
.brand-blue .boxstyle_.box1 a:link,
.brand-blue .boxstyle-xs.box1 a:link,
.brand-blue .boxstyle-sm.box1 a:link,
.brand-blue .boxstyle-md.box1 a:link,
.brand-blue .boxstyle-lg.box1 a:link,
.brand-blue .boxstyle_.box1 a:visited,
.brand-blue .boxstyle-xs.box1 a:visited,
.brand-blue .boxstyle-sm.box1 a:visited,
.brand-blue .boxstyle-md.box1 a:visited,
.brand-blue .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue .boxstyle_.box1 a:hover,
.brand-blue .boxstyle-xs.box1 a:hover,
.brand-blue .boxstyle-sm.box1 a:hover,
.brand-blue .boxstyle-md.box1 a:hover,
.brand-blue .boxstyle-lg.box1 a:hover,
.brand-blue .boxstyle_.box1 a:focus,
.brand-blue .boxstyle-xs.box1 a:focus,
.brand-blue .boxstyle-sm.box1 a:focus,
.brand-blue .boxstyle-md.box1 a:focus,
.brand-blue .boxstyle-lg.box1 a:focus,
.brand-blue .boxstyle_.box1 a:active,
.brand-blue .boxstyle-xs.box1 a:active,
.brand-blue .boxstyle-sm.box1 a:active,
.brand-blue .boxstyle-md.box1 a:active,
.brand-blue .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-blue .boxstyle_.box2,
  .brand-blue .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-blue .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-blue .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-blue .boxstyle_.box2,
.brand-blue .boxstyle-xs.box2,
.brand-blue .boxstyle-sm.box2,
.brand-blue .boxstyle-md.box2,
.brand-blue .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-blue .boxstyle_.box2,
  .brand-blue .boxstyle-xs.box2,
  .brand-blue .boxstyle-sm.box2,
  .brand-blue .boxstyle-md.box2,
  .brand-blue .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-blue .boxstyle_.box2 h1,
.brand-blue .boxstyle-xs.box2 h1,
.brand-blue .boxstyle-sm.box2 h1,
.brand-blue .boxstyle-md.box2 h1,
.brand-blue .boxstyle-lg.box2 h1,
.brand-blue .boxstyle_.box2 h2,
.brand-blue .boxstyle-xs.box2 h2,
.brand-blue .boxstyle-sm.box2 h2,
.brand-blue .boxstyle-md.box2 h2,
.brand-blue .boxstyle-lg.box2 h2,
.brand-blue .boxstyle_.box2 h3,
.brand-blue .boxstyle-xs.box2 h3,
.brand-blue .boxstyle-sm.box2 h3,
.brand-blue .boxstyle-md.box2 h3,
.brand-blue .boxstyle-lg.box2 h3,
.brand-blue .boxstyle_.box2 h4,
.brand-blue .boxstyle-xs.box2 h4,
.brand-blue .boxstyle-sm.box2 h4,
.brand-blue .boxstyle-md.box2 h4,
.brand-blue .boxstyle-lg.box2 h4,
.brand-blue .boxstyle_.box2 h5,
.brand-blue .boxstyle-xs.box2 h5,
.brand-blue .boxstyle-sm.box2 h5,
.brand-blue .boxstyle-md.box2 h5,
.brand-blue .boxstyle-lg.box2 h5,
.brand-blue .boxstyle_.box2 h6,
.brand-blue .boxstyle-xs.box2 h6,
.brand-blue .boxstyle-sm.box2 h6,
.brand-blue .boxstyle-md.box2 h6,
.brand-blue .boxstyle-lg.box2 h6,
.brand-blue .boxstyle_.box2 .h1,
.brand-blue .boxstyle-xs.box2 .h1,
.brand-blue .boxstyle-sm.box2 .h1,
.brand-blue .boxstyle-md.box2 .h1,
.brand-blue .boxstyle-lg.box2 .h1,
.brand-blue .boxstyle_.box2 .h2,
.brand-blue .boxstyle-xs.box2 .h2,
.brand-blue .boxstyle-sm.box2 .h2,
.brand-blue .boxstyle-md.box2 .h2,
.brand-blue .boxstyle-lg.box2 .h2,
.brand-blue .boxstyle_.box2 .h3,
.brand-blue .boxstyle-xs.box2 .h3,
.brand-blue .boxstyle-sm.box2 .h3,
.brand-blue .boxstyle-md.box2 .h3,
.brand-blue .boxstyle-lg.box2 .h3,
.brand-blue .boxstyle_.box2 .h4,
.brand-blue .boxstyle-xs.box2 .h4,
.brand-blue .boxstyle-sm.box2 .h4,
.brand-blue .boxstyle-md.box2 .h4,
.brand-blue .boxstyle-lg.box2 .h4,
.brand-blue .boxstyle_.box2 .h5,
.brand-blue .boxstyle-xs.box2 .h5,
.brand-blue .boxstyle-sm.box2 .h5,
.brand-blue .boxstyle-md.box2 .h5,
.brand-blue .boxstyle-lg.box2 .h5,
.brand-blue .boxstyle_.box2 .h6,
.brand-blue .boxstyle-xs.box2 .h6,
.brand-blue .boxstyle-sm.box2 .h6,
.brand-blue .boxstyle-md.box2 .h6,
.brand-blue .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-blue .boxstyle_.box2 blockquote.quotes,
.brand-blue .boxstyle-xs.box2 blockquote.quotes,
.brand-blue .boxstyle-sm.box2 blockquote.quotes,
.brand-blue .boxstyle-md.box2 blockquote.quotes,
.brand-blue .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-blue .boxstyle_.box2 a,
.brand-blue .boxstyle-xs.box2 a,
.brand-blue .boxstyle-sm.box2 a,
.brand-blue .boxstyle-md.box2 a,
.brand-blue .boxstyle-lg.box2 a,
.brand-blue .boxstyle_.box2 a:link,
.brand-blue .boxstyle-xs.box2 a:link,
.brand-blue .boxstyle-sm.box2 a:link,
.brand-blue .boxstyle-md.box2 a:link,
.brand-blue .boxstyle-lg.box2 a:link,
.brand-blue .boxstyle_.box2 a:visited,
.brand-blue .boxstyle-xs.box2 a:visited,
.brand-blue .boxstyle-sm.box2 a:visited,
.brand-blue .boxstyle-md.box2 a:visited,
.brand-blue .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-blue .boxstyle_.box2 a:hover,
.brand-blue .boxstyle-xs.box2 a:hover,
.brand-blue .boxstyle-sm.box2 a:hover,
.brand-blue .boxstyle-md.box2 a:hover,
.brand-blue .boxstyle-lg.box2 a:hover,
.brand-blue .boxstyle_.box2 a:focus,
.brand-blue .boxstyle-xs.box2 a:focus,
.brand-blue .boxstyle-sm.box2 a:focus,
.brand-blue .boxstyle-md.box2 a:focus,
.brand-blue .boxstyle-lg.box2 a:focus,
.brand-blue .boxstyle_.box2 a:active,
.brand-blue .boxstyle-xs.box2 a:active,
.brand-blue .boxstyle-sm.box2 a:active,
.brand-blue .boxstyle-md.box2 a:active,
.brand-blue .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-blue .boxstyle_.box2 .popover button.close,
.brand-blue .boxstyle-xs.box2 .popover button.close,
.brand-blue .boxstyle-sm.box2 .popover button.close,
.brand-blue .boxstyle-md.box2 .popover button.close,
.brand-blue .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-blue .boxstyle_.box2 .popover button.close:hover,
.brand-blue .boxstyle-xs.box2 .popover button.close:hover,
.brand-blue .boxstyle-sm.box2 .popover button.close:hover,
.brand-blue .boxstyle-md.box2 .popover button.close:hover,
.brand-blue .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-blue .boxstyle_.box2 a:hover .new-window-link,
.brand-blue .boxstyle-xs.box2 a:hover .new-window-link,
.brand-blue .boxstyle-sm.box2 a:hover .new-window-link,
.brand-blue .boxstyle-md.box2 a:hover .new-window-link,
.brand-blue .boxstyle-lg.box2 a:hover .new-window-link,
.brand-blue .boxstyle_.box2 a:hover .insecure-link,
.brand-blue .boxstyle-xs.box2 a:hover .insecure-link,
.brand-blue .boxstyle-sm.box2 a:hover .insecure-link,
.brand-blue .boxstyle-md.box2 a:hover .insecure-link,
.brand-blue .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-blue .boxstyle_.box2 .btn-link,
.brand-blue .boxstyle-xs.box2 .btn-link,
.brand-blue .boxstyle-sm.box2 .btn-link,
.brand-blue .boxstyle-md.box2 .btn-link,
.brand-blue .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-blue .boxstyle_.box2 .btn-link:hover,
.brand-blue .boxstyle-xs.box2 .btn-link:hover,
.brand-blue .boxstyle-sm.box2 .btn-link:hover,
.brand-blue .boxstyle-md.box2 .btn-link:hover,
.brand-blue .boxstyle-lg.box2 .btn-link:hover,
.brand-blue .boxstyle_.box2 .btn-link:focus,
.brand-blue .boxstyle-xs.box2 .btn-link:focus,
.brand-blue .boxstyle-sm.box2 .btn-link:focus,
.brand-blue .boxstyle-md.box2 .btn-link:focus,
.brand-blue .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-blue .boxstyle_.box2 a,
.brand-blue .boxstyle-xs.box2 a,
.brand-blue .boxstyle-sm.box2 a,
.brand-blue .boxstyle-md.box2 a,
.brand-blue .boxstyle-lg.box2 a,
.brand-blue .boxstyle_.box2 a:link,
.brand-blue .boxstyle-xs.box2 a:link,
.brand-blue .boxstyle-sm.box2 a:link,
.brand-blue .boxstyle-md.box2 a:link,
.brand-blue .boxstyle-lg.box2 a:link,
.brand-blue .boxstyle_.box2 a:visited,
.brand-blue .boxstyle-xs.box2 a:visited,
.brand-blue .boxstyle-sm.box2 a:visited,
.brand-blue .boxstyle-md.box2 a:visited,
.brand-blue .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue .boxstyle_.box2 a:hover,
.brand-blue .boxstyle-xs.box2 a:hover,
.brand-blue .boxstyle-sm.box2 a:hover,
.brand-blue .boxstyle-md.box2 a:hover,
.brand-blue .boxstyle-lg.box2 a:hover,
.brand-blue .boxstyle_.box2 a:focus,
.brand-blue .boxstyle-xs.box2 a:focus,
.brand-blue .boxstyle-sm.box2 a:focus,
.brand-blue .boxstyle-md.box2 a:focus,
.brand-blue .boxstyle-lg.box2 a:focus,
.brand-blue .boxstyle_.box2 a:active,
.brand-blue .boxstyle-xs.box2 a:active,
.brand-blue .boxstyle-sm.box2 a:active,
.brand-blue .boxstyle-md.box2 a:active,
.brand-blue .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-blue .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #41748D;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-blue .boxstyle_.box3,
  .brand-blue .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #41748D;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-blue .boxstyle-md.box3 {
    background: none;
    border: 1px solid #41748D;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-blue .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #41748D;
    color: inherit;
  }
}
.brand-blue .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-blue .boxstyle_.box4,
  .brand-blue .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-blue .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-blue .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-blue .boxstyle_.box5,
  .brand-blue .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-blue .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-blue .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-blue .boxstyle_.box5,
.brand-blue .boxstyle-xs.box5,
.brand-blue .boxstyle-sm.box5,
.brand-blue .boxstyle-md.box5,
.brand-blue .boxstyle-lg.box5 {
  border: 0;
  background: #41748D;
  color: white;
}
@media print {
  .brand-blue .boxstyle_.box5,
  .brand-blue .boxstyle-xs.box5,
  .brand-blue .boxstyle-sm.box5,
  .brand-blue .boxstyle-md.box5,
  .brand-blue .boxstyle-lg.box5 {
    border: 1px solid #41748D;
  }
}
.brand-blue .boxstyle_.box5 h1,
.brand-blue .boxstyle-xs.box5 h1,
.brand-blue .boxstyle-sm.box5 h1,
.brand-blue .boxstyle-md.box5 h1,
.brand-blue .boxstyle-lg.box5 h1,
.brand-blue .boxstyle_.box5 h2,
.brand-blue .boxstyle-xs.box5 h2,
.brand-blue .boxstyle-sm.box5 h2,
.brand-blue .boxstyle-md.box5 h2,
.brand-blue .boxstyle-lg.box5 h2,
.brand-blue .boxstyle_.box5 h3,
.brand-blue .boxstyle-xs.box5 h3,
.brand-blue .boxstyle-sm.box5 h3,
.brand-blue .boxstyle-md.box5 h3,
.brand-blue .boxstyle-lg.box5 h3,
.brand-blue .boxstyle_.box5 h4,
.brand-blue .boxstyle-xs.box5 h4,
.brand-blue .boxstyle-sm.box5 h4,
.brand-blue .boxstyle-md.box5 h4,
.brand-blue .boxstyle-lg.box5 h4,
.brand-blue .boxstyle_.box5 h5,
.brand-blue .boxstyle-xs.box5 h5,
.brand-blue .boxstyle-sm.box5 h5,
.brand-blue .boxstyle-md.box5 h5,
.brand-blue .boxstyle-lg.box5 h5,
.brand-blue .boxstyle_.box5 h6,
.brand-blue .boxstyle-xs.box5 h6,
.brand-blue .boxstyle-sm.box5 h6,
.brand-blue .boxstyle-md.box5 h6,
.brand-blue .boxstyle-lg.box5 h6,
.brand-blue .boxstyle_.box5 .h1,
.brand-blue .boxstyle-xs.box5 .h1,
.brand-blue .boxstyle-sm.box5 .h1,
.brand-blue .boxstyle-md.box5 .h1,
.brand-blue .boxstyle-lg.box5 .h1,
.brand-blue .boxstyle_.box5 .h2,
.brand-blue .boxstyle-xs.box5 .h2,
.brand-blue .boxstyle-sm.box5 .h2,
.brand-blue .boxstyle-md.box5 .h2,
.brand-blue .boxstyle-lg.box5 .h2,
.brand-blue .boxstyle_.box5 .h3,
.brand-blue .boxstyle-xs.box5 .h3,
.brand-blue .boxstyle-sm.box5 .h3,
.brand-blue .boxstyle-md.box5 .h3,
.brand-blue .boxstyle-lg.box5 .h3,
.brand-blue .boxstyle_.box5 .h4,
.brand-blue .boxstyle-xs.box5 .h4,
.brand-blue .boxstyle-sm.box5 .h4,
.brand-blue .boxstyle-md.box5 .h4,
.brand-blue .boxstyle-lg.box5 .h4,
.brand-blue .boxstyle_.box5 .h5,
.brand-blue .boxstyle-xs.box5 .h5,
.brand-blue .boxstyle-sm.box5 .h5,
.brand-blue .boxstyle-md.box5 .h5,
.brand-blue .boxstyle-lg.box5 .h5,
.brand-blue .boxstyle_.box5 .h6,
.brand-blue .boxstyle-xs.box5 .h6,
.brand-blue .boxstyle-sm.box5 .h6,
.brand-blue .boxstyle-md.box5 .h6,
.brand-blue .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-blue .boxstyle_.box5 blockquote.quotes,
.brand-blue .boxstyle-xs.box5 blockquote.quotes,
.brand-blue .boxstyle-sm.box5 blockquote.quotes,
.brand-blue .boxstyle-md.box5 blockquote.quotes,
.brand-blue .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-blue .boxstyle_.box5 a,
.brand-blue .boxstyle-xs.box5 a,
.brand-blue .boxstyle-sm.box5 a,
.brand-blue .boxstyle-md.box5 a,
.brand-blue .boxstyle-lg.box5 a,
.brand-blue .boxstyle_.box5 a:link,
.brand-blue .boxstyle-xs.box5 a:link,
.brand-blue .boxstyle-sm.box5 a:link,
.brand-blue .boxstyle-md.box5 a:link,
.brand-blue .boxstyle-lg.box5 a:link,
.brand-blue .boxstyle_.box5 a:visited,
.brand-blue .boxstyle-xs.box5 a:visited,
.brand-blue .boxstyle-sm.box5 a:visited,
.brand-blue .boxstyle-md.box5 a:visited,
.brand-blue .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-blue .boxstyle_.box5 a:hover,
.brand-blue .boxstyle-xs.box5 a:hover,
.brand-blue .boxstyle-sm.box5 a:hover,
.brand-blue .boxstyle-md.box5 a:hover,
.brand-blue .boxstyle-lg.box5 a:hover,
.brand-blue .boxstyle_.box5 a:focus,
.brand-blue .boxstyle-xs.box5 a:focus,
.brand-blue .boxstyle-sm.box5 a:focus,
.brand-blue .boxstyle-md.box5 a:focus,
.brand-blue .boxstyle-lg.box5 a:focus,
.brand-blue .boxstyle_.box5 a:active,
.brand-blue .boxstyle-xs.box5 a:active,
.brand-blue .boxstyle-sm.box5 a:active,
.brand-blue .boxstyle-md.box5 a:active,
.brand-blue .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-blue .boxstyle_.box5 .popover button.close,
.brand-blue .boxstyle-xs.box5 .popover button.close,
.brand-blue .boxstyle-sm.box5 .popover button.close,
.brand-blue .boxstyle-md.box5 .popover button.close,
.brand-blue .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-blue .boxstyle_.box5 .popover button.close:hover,
.brand-blue .boxstyle-xs.box5 .popover button.close:hover,
.brand-blue .boxstyle-sm.box5 .popover button.close:hover,
.brand-blue .boxstyle-md.box5 .popover button.close:hover,
.brand-blue .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-blue .boxstyle_.box5 a:hover .new-window-link,
.brand-blue .boxstyle-xs.box5 a:hover .new-window-link,
.brand-blue .boxstyle-sm.box5 a:hover .new-window-link,
.brand-blue .boxstyle-md.box5 a:hover .new-window-link,
.brand-blue .boxstyle-lg.box5 a:hover .new-window-link,
.brand-blue .boxstyle_.box5 a:hover .insecure-link,
.brand-blue .boxstyle-xs.box5 a:hover .insecure-link,
.brand-blue .boxstyle-sm.box5 a:hover .insecure-link,
.brand-blue .boxstyle-md.box5 a:hover .insecure-link,
.brand-blue .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-blue .boxstyle_.box5 .btn-link,
.brand-blue .boxstyle-xs.box5 .btn-link,
.brand-blue .boxstyle-sm.box5 .btn-link,
.brand-blue .boxstyle-md.box5 .btn-link,
.brand-blue .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-blue .boxstyle_.box5 .btn-link:hover,
.brand-blue .boxstyle-xs.box5 .btn-link:hover,
.brand-blue .boxstyle-sm.box5 .btn-link:hover,
.brand-blue .boxstyle-md.box5 .btn-link:hover,
.brand-blue .boxstyle-lg.box5 .btn-link:hover,
.brand-blue .boxstyle_.box5 .btn-link:focus,
.brand-blue .boxstyle-xs.box5 .btn-link:focus,
.brand-blue .boxstyle-sm.box5 .btn-link:focus,
.brand-blue .boxstyle-md.box5 .btn-link:focus,
.brand-blue .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-blue .boxstyle_.box5 a,
.brand-blue .boxstyle-xs.box5 a,
.brand-blue .boxstyle-sm.box5 a,
.brand-blue .boxstyle-md.box5 a,
.brand-blue .boxstyle-lg.box5 a,
.brand-blue .boxstyle_.box5 a:link,
.brand-blue .boxstyle-xs.box5 a:link,
.brand-blue .boxstyle-sm.box5 a:link,
.brand-blue .boxstyle-md.box5 a:link,
.brand-blue .boxstyle-lg.box5 a:link,
.brand-blue .boxstyle_.box5 a:visited,
.brand-blue .boxstyle-xs.box5 a:visited,
.brand-blue .boxstyle-sm.box5 a:visited,
.brand-blue .boxstyle-md.box5 a:visited,
.brand-blue .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-blue .boxstyle_.box5 a:hover,
.brand-blue .boxstyle-xs.box5 a:hover,
.brand-blue .boxstyle-sm.box5 a:hover,
.brand-blue .boxstyle-md.box5 a:hover,
.brand-blue .boxstyle-lg.box5 a:hover,
.brand-blue .boxstyle_.box5 a:focus,
.brand-blue .boxstyle-xs.box5 a:focus,
.brand-blue .boxstyle-sm.box5 a:focus,
.brand-blue .boxstyle-md.box5 a:focus,
.brand-blue .boxstyle-lg.box5 a:focus,
.brand-blue .boxstyle_.box5 a:active,
.brand-blue .boxstyle-xs.box5 a:active,
.brand-blue .boxstyle-sm.box5 a:active,
.brand-blue .boxstyle-md.box5 a:active,
.brand-blue .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-blue .pagination > li a,
.brand-blue .pagination > li span {
  color: #41748D;
}
.brand-blue .pagination > li.active > a,
.brand-blue .pagination > li.active > span:not(.sr-only),
.brand-blue .pagination > li.active > a:hover,
.brand-blue .pagination > li.active > span:not(.sr-only):hover,
.brand-blue .pagination > li.active > a:focus,
.brand-blue .pagination > li.active > span:not(.sr-only):focus {
  background-color: #41748D;
  border-color: #41748D;
  color: white;
}
.brand-blue .nav-tabs {
  border-bottom-color: #a0bac6;
}
.brand-blue .nav-tabs > li > a {
  background-color: #d9e3e8;
  border-color: #a0bac6;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-blue .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #8dacbb;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-blue .nav-tabs > li > a:hover,
.brand-blue .nav-tabs > li > a:focus {
  background-color: #ecf1f4;
  border-color: #b3c7d1 #b3c7d1 #a0bac6;
}
.brand-blue .nav-tabs > li > a:hover::before,
.brand-blue .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-blue .nav-tabs > li.open > a,
.brand-blue .nav-tabs > li.open > a:hover,
.brand-blue .nav-tabs > li.open > a:focus {
  background-color: #ecf1f4;
  border-color: #b3c7d1 #b3c7d1 #a0bac6;
}
.brand-blue .nav-tabs > li.open > a::before,
.brand-blue .nav-tabs > li.open > a:hover::before,
.brand-blue .nav-tabs > li.open > a:focus::before {
  background: #41748D;
  width: 100%;
}
.brand-blue .nav-tabs > li.active > a,
.brand-blue .nav-tabs > li.active > a:hover,
.brand-blue .nav-tabs > li.active > a:focus {
  border-color: #a0bac6 #a0bac6 transparent;
}
.brand-blue .nav-tabs > li.active > a::before,
.brand-blue .nav-tabs > li.active > a:hover::before,
.brand-blue .nav-tabs > li.active > a:focus::before {
  background: #41748D;
  width: 100%;
}
.brand-blue .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-blue .nav-tabs > li.disabled > a:hover,
.brand-blue .nav-tabs > li.disabled > a:focus {
  background-color: #d9e3e8;
}
.brand-blue .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-blue .nav-pills > li.active > a,
.brand-blue .nav-pills > li.active > a:hover,
.brand-blue .nav-pills > li.active > a:focus {
  background-color: #41748D;
  color: white;
}
.brand-blue .list-group-item.active,
.brand-blue .list-group-item.active:hover,
.brand-blue .list-group-item.active:focus {
  background-color: #41748D;
  border-color: #41748D;
  color: white;
}
.brand-blue .id7-koan-spinner__neon--top,
.brand-blue .id7-koan-spinner__neon--bottom {
  stroke: #41748D;
}
.brand-blue-bright h1,
.brand-blue-bright h2,
.brand-blue-bright h3,
.brand-blue-bright h4,
.brand-blue-bright h5,
.brand-blue-bright h6,
.brand-blue-bright .h1,
.brand-blue-bright .h2,
.brand-blue-bright .h3,
.brand-blue-bright .h4,
.brand-blue-bright .h5,
.brand-blue-bright .h6 {
  color: #202020;
}
.brand-blue-bright a,
.brand-blue-bright a:link,
.brand-blue-bright a:visited {
  color: #202020;
}
.brand-blue-bright a:hover,
.brand-blue-bright a:focus,
.brand-blue-bright a:active {
  color: #000000;
}
.brand-blue-bright .popover button.close {
  color: #202020;
}
.brand-blue-bright .popover button.close:hover {
  color: #000000;
}
.brand-blue-bright a:hover .new-window-link,
.brand-blue-bright a:hover .insecure-link {
  color: #000000;
}
.brand-blue-bright .btn-link {
  color: #202020;
}
.brand-blue-bright .btn-link:hover,
.brand-blue-bright .btn-link:focus {
  color: #000000;
}
.brand-blue-bright a,
.brand-blue-bright a:link,
.brand-blue-bright a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue-bright a:hover,
.brand-blue-bright a:focus,
.brand-blue-bright a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-blue-bright blockquote.quotes {
  color: #005568;
}
.brand-blue-bright blockquote.quotes::before {
  color: #005568;
}
.brand-blue-bright .brand-bg {
  background: #00A9CE;
  color: #202020;
}
.brand-blue-bright .brand-bg a,
.brand-blue-bright .brand-bg a:link,
.brand-blue-bright .brand-bg a:visited {
  color: #202020 !important;
}
.brand-blue-bright .brand-bg a:hover,
.brand-blue-bright .brand-bg a:focus,
.brand-blue-bright .brand-bg a:active {
  color: #000000 !important;
}
.brand-blue-bright .brand-bg .popover button.close {
  color: #202020 !important;
}
.brand-blue-bright .brand-bg .popover button.close:hover {
  color: #000000 !important;
}
.brand-blue-bright .brand-bg a:hover .new-window-link,
.brand-blue-bright .brand-bg a:hover .insecure-link {
  color: #000000 !important;
}
.brand-blue-bright .brand-bg .btn-link {
  color: #202020 !important;
}
.brand-blue-bright .brand-bg .btn-link:hover,
.brand-blue-bright .brand-bg .btn-link:focus {
  color: #000000 !important;
}
.brand-blue-bright .brand-bg a,
.brand-blue-bright .brand-bg a:link,
.brand-blue-bright .brand-bg a:visited {
  -webkit-text-decoration-color: #636363 !important;
  text-decoration-color: #636363 !important;
}
.brand-blue-bright .brand-bg a:hover,
.brand-blue-bright .brand-bg a:focus,
.brand-blue-bright .brand-bg a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-blue-bright .brand-text {
  color: #00A9CE;
}
.brand-blue-bright .brand-text a,
.brand-blue-bright .brand-text a:link,
.brand-blue-bright .brand-text a:visited {
  color: #00A9CE !important;
}
.brand-blue-bright .brand-text a:hover,
.brand-blue-bright .brand-text a:focus,
.brand-blue-bright .brand-text a:active {
  color: #005568 !important;
}
.brand-blue-bright .brand-text .popover button.close {
  color: #00A9CE !important;
}
.brand-blue-bright .brand-text .popover button.close:hover {
  color: #005568 !important;
}
.brand-blue-bright .brand-text a:hover .new-window-link,
.brand-blue-bright .brand-text a:hover .insecure-link {
  color: #005568 !important;
}
.brand-blue-bright .brand-text .btn-link {
  color: #00A9CE !important;
}
.brand-blue-bright .brand-text .btn-link:hover,
.brand-blue-bright .brand-text .btn-link:focus {
  color: #005568 !important;
}
.brand-blue-bright .brand-text a,
.brand-blue-bright .brand-text a:link,
.brand-blue-bright .brand-text a:visited {
  -webkit-text-decoration-color: #4dc3dd !important;
  text-decoration-color: #4dc3dd !important;
}
.brand-blue-bright .brand-text a:hover,
.brand-blue-bright .brand-text a:focus,
.brand-blue-bright .brand-text a:active {
  -webkit-text-decoration-color: #005568 !important;
  text-decoration-color: #005568 !important;
}
.brand-blue-bright .brand-border {
  border-color: #00A9CE;
}
.brand-blue-bright .btn-brand {
  color: #202020;
  background-color: #00A9CE;
  border-color: #0094b5;
  color: #202020 !important;
}
.brand-blue-bright .btn-brand:focus,
.brand-blue-bright .btn-brand.focus {
  color: #202020;
  background-color: #007f9b;
  border-color: #002b35;
}
.brand-blue-bright .btn-brand:hover {
  color: #202020;
  background-color: #007f9b;
  border-color: #006277;
}
.brand-blue-bright .btn-brand:active,
.brand-blue-bright .btn-brand.active,
.open > .dropdown-toggle.brand-blue-bright .btn-brand {
  color: #202020;
  background-color: #007f9b;
  background-image: none;
  border-color: #006277;
}
.brand-blue-bright .btn-brand:active:hover,
.brand-blue-bright .btn-brand.active:hover,
.open > .dropdown-toggle.brand-blue-bright .btn-brand:hover,
.brand-blue-bright .btn-brand:active:focus,
.brand-blue-bright .btn-brand.active:focus,
.open > .dropdown-toggle.brand-blue-bright .btn-brand:focus,
.brand-blue-bright .btn-brand:active.focus,
.brand-blue-bright .btn-brand.active.focus,
.open > .dropdown-toggle.brand-blue-bright .btn-brand.focus {
  color: #202020;
  background-color: #006277;
  border-color: #002b35;
}
.brand-blue-bright .btn-brand.disabled:hover,
.brand-blue-bright .btn-brand[disabled]:hover,
fieldset[disabled] .brand-blue-bright .btn-brand:hover,
.brand-blue-bright .btn-brand.disabled:focus,
.brand-blue-bright .btn-brand[disabled]:focus,
fieldset[disabled] .brand-blue-bright .btn-brand:focus,
.brand-blue-bright .btn-brand.disabled.focus,
.brand-blue-bright .btn-brand[disabled].focus,
fieldset[disabled] .brand-blue-bright .btn-brand.focus {
  background-color: #00A9CE;
  border-color: #0094b5;
}
.brand-blue-bright .btn-brand .badge {
  color: #00A9CE;
  background-color: #202020;
}
.brand-blue-bright .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-blue-bright .id7-utility-masthead {
  background-color: #4dc3dd;
}
.brand-blue-bright .id7-utility-masthead::after {
  background-color: #00A9CE;
}
body.id7-point-1 .brand-blue-bright .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-blue-bright .id7-navigation .navbar-primary {
  background-color: #00A9CE;
}
.brand-blue-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-blue-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-text {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #94dbea;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: #202020;
  background-color: #0087a5;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #94dbea;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #0087a5;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #0087a5;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #00A9CE;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #0087a5;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #0087a5;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #0087a5;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #0087a5;
  background-image: none;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #94dbea;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-blue-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #0087a5;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-link {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .navbar-link:hover {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .btn-link {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-blue-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-blue-bright .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-blue-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-blue-bright .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-blue-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-blue-bright .navbar-primary .navbar-brand {
  background-color: #00A9CE;
}
.brand-blue-bright .navbar-secondary {
  background-color: #94dbea;
}
.brand-blue-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-blue-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-blue-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li:hover,
.brand-blue-bright .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #b4e6f1;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li > a,
.brand-blue-bright .navbar-secondary .navbar-nav > li > a:hover,
.brand-blue-bright .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-blue-bright .navbar-secondary .navbar-nav > .disabled,
.brand-blue-bright .navbar-secondary .navbar-nav > .disabled:hover,
.brand-blue-bright .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #b4e6f1;
}
.brand-blue-bright .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-blue-bright .navbar-secondary .navbar-toggle:hover,
.brand-blue-bright .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-blue-bright .navbar-secondary .navbar-nav > .open,
.brand-blue-bright .navbar-secondary .navbar-nav > .open:hover,
.brand-blue-bright .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #b4e6f1;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #94dbea;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #76afbc;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #76afbc;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #b4e6f1;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #b4e6f1;
  background-image: none;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-blue-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #b4e6f1;
}
.brand-blue-bright .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .btn-link {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .btn-link:hover,
.brand-blue-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-blue-bright .navbar-secondary .btn-link:hover,
.brand-blue-bright .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-blue-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li::after {
  color: #00A9CE;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li:first-child {
  background-color: #00A9CE;
  color: white;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #00A9CE;
}
.brand-blue-bright .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #00A9CE;
}
.brand-blue-bright .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-blue-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-blue-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > li:hover,
.brand-blue-bright .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > li > a,
.brand-blue-bright .navbar-tertiary .navbar-nav > li > a:hover,
.brand-blue-bright .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > .disabled,
.brand-blue-bright .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-blue-bright .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue-bright .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .navbar-toggle:hover,
.brand-blue-bright .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-blue-bright .navbar-tertiary .navbar-nav > .open,
.brand-blue-bright .navbar-tertiary .navbar-nav > .open:hover,
.brand-blue-bright .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-blue-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-blue-bright .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .btn-link:hover,
.brand-blue-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-blue-bright .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-blue-bright .navbar-tertiary .btn-link:hover,
.brand-blue-bright .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-blue-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-blue-bright.bordered .id7-left-border,
.brand-blue-bright.bordered .id7-right-border {
  border-color: rgba(0, 169, 206, 0.3);
}
.brand-blue-bright .id7-page-footer .id7-app-footer {
  background: #00A9CE;
  color: white;
}
.brand-blue-bright .id7-page-footer .id7-app-footer h1,
.brand-blue-bright .id7-page-footer .id7-app-footer h2,
.brand-blue-bright .id7-page-footer .id7-app-footer h3,
.brand-blue-bright .id7-page-footer .id7-app-footer h4,
.brand-blue-bright .id7-page-footer .id7-app-footer h5,
.brand-blue-bright .id7-page-footer .id7-app-footer h6,
.brand-blue-bright .id7-page-footer .id7-app-footer .h1,
.brand-blue-bright .id7-page-footer .id7-app-footer .h2,
.brand-blue-bright .id7-page-footer .id7-app-footer .h3,
.brand-blue-bright .id7-page-footer .id7-app-footer .h4,
.brand-blue-bright .id7-page-footer .id7-app-footer .h5,
.brand-blue-bright .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-blue-bright .id7-page-footer .id7-app-footer a,
.brand-blue-bright .id7-page-footer .id7-app-footer a:link,
.brand-blue-bright .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-blue-bright .id7-page-footer .id7-app-footer a:hover,
.brand-blue-bright .id7-page-footer .id7-app-footer a:focus,
.brand-blue-bright .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-blue-bright .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-blue-bright .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-blue-bright .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-blue-bright .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-blue-bright .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-blue-bright .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-blue-bright .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-blue-bright .id7-page-footer .id7-app-footer a,
.brand-blue-bright .id7-page-footer .id7-app-footer a:link,
.brand-blue-bright .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-blue-bright .id7-page-footer .id7-app-footer a:hover,
.brand-blue-bright .id7-page-footer .id7-app-footer a:focus,
.brand-blue-bright .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-blue-bright .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #00A9CE;
}
.brand-blue-bright .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-blue-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-blue-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-blue-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-blue-bright .id7-search .fa,
.brand-blue-bright .id7-search .fas {
  color: #00A9CE;
  color: rgba(0, 169, 206, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-blue-bright .id7-search .form-control:hover + .fa,
.brand-blue-bright .id7-search .typeahead:hover + .fa,
.brand-blue-bright .id7-search .fa:hover,
.brand-blue-bright .id7-search .form-control:hover + .fas,
.brand-blue-bright .id7-search .typeahead:hover + .fas,
.brand-blue-bright .id7-search .fas:hover {
  color: #00A9CE;
}
.brand-blue-bright .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #00A9CE;
  color: white;
}
.brand-blue-bright .form-control:focus {
  border-color: #00A9CE;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 169, 206, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 169, 206, 0.6);
}
.brand-blue-bright .carousel .carousel-indicators li::after {
  color: #00A9CE;
  color: rgba(0, 169, 206, 0.3);
}
.brand-blue-bright .carousel .carousel-indicators li.active::after {
  color: #00A9CE;
}
@media (max-width: 767px) {
  .brand-blue-bright .boxstyle_.box1,
  .brand-blue-bright .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-blue-bright .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-blue-bright .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-blue-bright .boxstyle_.box1,
.brand-blue-bright .boxstyle-xs.box1,
.brand-blue-bright .boxstyle-sm.box1,
.brand-blue-bright .boxstyle-md.box1,
.brand-blue-bright .boxstyle-lg.box1 {
  border: 0;
  background: #d9f2f8;
  color: #202020;
}
@media print {
  .brand-blue-bright .boxstyle_.box1,
  .brand-blue-bright .boxstyle-xs.box1,
  .brand-blue-bright .boxstyle-sm.box1,
  .brand-blue-bright .boxstyle-md.box1,
  .brand-blue-bright .boxstyle-lg.box1 {
    border: 1px solid #d9f2f8;
  }
}
.brand-blue-bright .boxstyle_.box1 h1,
.brand-blue-bright .boxstyle-xs.box1 h1,
.brand-blue-bright .boxstyle-sm.box1 h1,
.brand-blue-bright .boxstyle-md.box1 h1,
.brand-blue-bright .boxstyle-lg.box1 h1,
.brand-blue-bright .boxstyle_.box1 h2,
.brand-blue-bright .boxstyle-xs.box1 h2,
.brand-blue-bright .boxstyle-sm.box1 h2,
.brand-blue-bright .boxstyle-md.box1 h2,
.brand-blue-bright .boxstyle-lg.box1 h2,
.brand-blue-bright .boxstyle_.box1 h3,
.brand-blue-bright .boxstyle-xs.box1 h3,
.brand-blue-bright .boxstyle-sm.box1 h3,
.brand-blue-bright .boxstyle-md.box1 h3,
.brand-blue-bright .boxstyle-lg.box1 h3,
.brand-blue-bright .boxstyle_.box1 h4,
.brand-blue-bright .boxstyle-xs.box1 h4,
.brand-blue-bright .boxstyle-sm.box1 h4,
.brand-blue-bright .boxstyle-md.box1 h4,
.brand-blue-bright .boxstyle-lg.box1 h4,
.brand-blue-bright .boxstyle_.box1 h5,
.brand-blue-bright .boxstyle-xs.box1 h5,
.brand-blue-bright .boxstyle-sm.box1 h5,
.brand-blue-bright .boxstyle-md.box1 h5,
.brand-blue-bright .boxstyle-lg.box1 h5,
.brand-blue-bright .boxstyle_.box1 h6,
.brand-blue-bright .boxstyle-xs.box1 h6,
.brand-blue-bright .boxstyle-sm.box1 h6,
.brand-blue-bright .boxstyle-md.box1 h6,
.brand-blue-bright .boxstyle-lg.box1 h6,
.brand-blue-bright .boxstyle_.box1 .h1,
.brand-blue-bright .boxstyle-xs.box1 .h1,
.brand-blue-bright .boxstyle-sm.box1 .h1,
.brand-blue-bright .boxstyle-md.box1 .h1,
.brand-blue-bright .boxstyle-lg.box1 .h1,
.brand-blue-bright .boxstyle_.box1 .h2,
.brand-blue-bright .boxstyle-xs.box1 .h2,
.brand-blue-bright .boxstyle-sm.box1 .h2,
.brand-blue-bright .boxstyle-md.box1 .h2,
.brand-blue-bright .boxstyle-lg.box1 .h2,
.brand-blue-bright .boxstyle_.box1 .h3,
.brand-blue-bright .boxstyle-xs.box1 .h3,
.brand-blue-bright .boxstyle-sm.box1 .h3,
.brand-blue-bright .boxstyle-md.box1 .h3,
.brand-blue-bright .boxstyle-lg.box1 .h3,
.brand-blue-bright .boxstyle_.box1 .h4,
.brand-blue-bright .boxstyle-xs.box1 .h4,
.brand-blue-bright .boxstyle-sm.box1 .h4,
.brand-blue-bright .boxstyle-md.box1 .h4,
.brand-blue-bright .boxstyle-lg.box1 .h4,
.brand-blue-bright .boxstyle_.box1 .h5,
.brand-blue-bright .boxstyle-xs.box1 .h5,
.brand-blue-bright .boxstyle-sm.box1 .h5,
.brand-blue-bright .boxstyle-md.box1 .h5,
.brand-blue-bright .boxstyle-lg.box1 .h5,
.brand-blue-bright .boxstyle_.box1 .h6,
.brand-blue-bright .boxstyle-xs.box1 .h6,
.brand-blue-bright .boxstyle-sm.box1 .h6,
.brand-blue-bright .boxstyle-md.box1 .h6,
.brand-blue-bright .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box1 blockquote.quotes,
.brand-blue-bright .boxstyle-xs.box1 blockquote.quotes,
.brand-blue-bright .boxstyle-sm.box1 blockquote.quotes,
.brand-blue-bright .boxstyle-md.box1 blockquote.quotes,
.brand-blue-bright .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-blue-bright .boxstyle_.box1 a,
.brand-blue-bright .boxstyle-xs.box1 a,
.brand-blue-bright .boxstyle-sm.box1 a,
.brand-blue-bright .boxstyle-md.box1 a,
.brand-blue-bright .boxstyle-lg.box1 a,
.brand-blue-bright .boxstyle_.box1 a:link,
.brand-blue-bright .boxstyle-xs.box1 a:link,
.brand-blue-bright .boxstyle-sm.box1 a:link,
.brand-blue-bright .boxstyle-md.box1 a:link,
.brand-blue-bright .boxstyle-lg.box1 a:link,
.brand-blue-bright .boxstyle_.box1 a:visited,
.brand-blue-bright .boxstyle-xs.box1 a:visited,
.brand-blue-bright .boxstyle-sm.box1 a:visited,
.brand-blue-bright .boxstyle-md.box1 a:visited,
.brand-blue-bright .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box1 a:hover,
.brand-blue-bright .boxstyle-xs.box1 a:hover,
.brand-blue-bright .boxstyle-sm.box1 a:hover,
.brand-blue-bright .boxstyle-md.box1 a:hover,
.brand-blue-bright .boxstyle-lg.box1 a:hover,
.brand-blue-bright .boxstyle_.box1 a:focus,
.brand-blue-bright .boxstyle-xs.box1 a:focus,
.brand-blue-bright .boxstyle-sm.box1 a:focus,
.brand-blue-bright .boxstyle-md.box1 a:focus,
.brand-blue-bright .boxstyle-lg.box1 a:focus,
.brand-blue-bright .boxstyle_.box1 a:active,
.brand-blue-bright .boxstyle-xs.box1 a:active,
.brand-blue-bright .boxstyle-sm.box1 a:active,
.brand-blue-bright .boxstyle-md.box1 a:active,
.brand-blue-bright .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box1 .popover button.close,
.brand-blue-bright .boxstyle-xs.box1 .popover button.close,
.brand-blue-bright .boxstyle-sm.box1 .popover button.close,
.brand-blue-bright .boxstyle-md.box1 .popover button.close,
.brand-blue-bright .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box1 .popover button.close:hover,
.brand-blue-bright .boxstyle-xs.box1 .popover button.close:hover,
.brand-blue-bright .boxstyle-sm.box1 .popover button.close:hover,
.brand-blue-bright .boxstyle-md.box1 .popover button.close:hover,
.brand-blue-bright .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box1 a:hover .new-window-link,
.brand-blue-bright .boxstyle-xs.box1 a:hover .new-window-link,
.brand-blue-bright .boxstyle-sm.box1 a:hover .new-window-link,
.brand-blue-bright .boxstyle-md.box1 a:hover .new-window-link,
.brand-blue-bright .boxstyle-lg.box1 a:hover .new-window-link,
.brand-blue-bright .boxstyle_.box1 a:hover .insecure-link,
.brand-blue-bright .boxstyle-xs.box1 a:hover .insecure-link,
.brand-blue-bright .boxstyle-sm.box1 a:hover .insecure-link,
.brand-blue-bright .boxstyle-md.box1 a:hover .insecure-link,
.brand-blue-bright .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box1 .btn-link,
.brand-blue-bright .boxstyle-xs.box1 .btn-link,
.brand-blue-bright .boxstyle-sm.box1 .btn-link,
.brand-blue-bright .boxstyle-md.box1 .btn-link,
.brand-blue-bright .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box1 .btn-link:hover,
.brand-blue-bright .boxstyle-xs.box1 .btn-link:hover,
.brand-blue-bright .boxstyle-sm.box1 .btn-link:hover,
.brand-blue-bright .boxstyle-md.box1 .btn-link:hover,
.brand-blue-bright .boxstyle-lg.box1 .btn-link:hover,
.brand-blue-bright .boxstyle_.box1 .btn-link:focus,
.brand-blue-bright .boxstyle-xs.box1 .btn-link:focus,
.brand-blue-bright .boxstyle-sm.box1 .btn-link:focus,
.brand-blue-bright .boxstyle-md.box1 .btn-link:focus,
.brand-blue-bright .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box1 a,
.brand-blue-bright .boxstyle-xs.box1 a,
.brand-blue-bright .boxstyle-sm.box1 a,
.brand-blue-bright .boxstyle-md.box1 a,
.brand-blue-bright .boxstyle-lg.box1 a,
.brand-blue-bright .boxstyle_.box1 a:link,
.brand-blue-bright .boxstyle-xs.box1 a:link,
.brand-blue-bright .boxstyle-sm.box1 a:link,
.brand-blue-bright .boxstyle-md.box1 a:link,
.brand-blue-bright .boxstyle-lg.box1 a:link,
.brand-blue-bright .boxstyle_.box1 a:visited,
.brand-blue-bright .boxstyle-xs.box1 a:visited,
.brand-blue-bright .boxstyle-sm.box1 a:visited,
.brand-blue-bright .boxstyle-md.box1 a:visited,
.brand-blue-bright .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue-bright .boxstyle_.box1 a:hover,
.brand-blue-bright .boxstyle-xs.box1 a:hover,
.brand-blue-bright .boxstyle-sm.box1 a:hover,
.brand-blue-bright .boxstyle-md.box1 a:hover,
.brand-blue-bright .boxstyle-lg.box1 a:hover,
.brand-blue-bright .boxstyle_.box1 a:focus,
.brand-blue-bright .boxstyle-xs.box1 a:focus,
.brand-blue-bright .boxstyle-sm.box1 a:focus,
.brand-blue-bright .boxstyle-md.box1 a:focus,
.brand-blue-bright .boxstyle-lg.box1 a:focus,
.brand-blue-bright .boxstyle_.box1 a:active,
.brand-blue-bright .boxstyle-xs.box1 a:active,
.brand-blue-bright .boxstyle-sm.box1 a:active,
.brand-blue-bright .boxstyle-md.box1 a:active,
.brand-blue-bright .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-blue-bright .boxstyle_.box2,
  .brand-blue-bright .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-blue-bright .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-blue-bright .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-blue-bright .boxstyle_.box2,
.brand-blue-bright .boxstyle-xs.box2,
.brand-blue-bright .boxstyle-sm.box2,
.brand-blue-bright .boxstyle-md.box2,
.brand-blue-bright .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-blue-bright .boxstyle_.box2,
  .brand-blue-bright .boxstyle-xs.box2,
  .brand-blue-bright .boxstyle-sm.box2,
  .brand-blue-bright .boxstyle-md.box2,
  .brand-blue-bright .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-blue-bright .boxstyle_.box2 h1,
.brand-blue-bright .boxstyle-xs.box2 h1,
.brand-blue-bright .boxstyle-sm.box2 h1,
.brand-blue-bright .boxstyle-md.box2 h1,
.brand-blue-bright .boxstyle-lg.box2 h1,
.brand-blue-bright .boxstyle_.box2 h2,
.brand-blue-bright .boxstyle-xs.box2 h2,
.brand-blue-bright .boxstyle-sm.box2 h2,
.brand-blue-bright .boxstyle-md.box2 h2,
.brand-blue-bright .boxstyle-lg.box2 h2,
.brand-blue-bright .boxstyle_.box2 h3,
.brand-blue-bright .boxstyle-xs.box2 h3,
.brand-blue-bright .boxstyle-sm.box2 h3,
.brand-blue-bright .boxstyle-md.box2 h3,
.brand-blue-bright .boxstyle-lg.box2 h3,
.brand-blue-bright .boxstyle_.box2 h4,
.brand-blue-bright .boxstyle-xs.box2 h4,
.brand-blue-bright .boxstyle-sm.box2 h4,
.brand-blue-bright .boxstyle-md.box2 h4,
.brand-blue-bright .boxstyle-lg.box2 h4,
.brand-blue-bright .boxstyle_.box2 h5,
.brand-blue-bright .boxstyle-xs.box2 h5,
.brand-blue-bright .boxstyle-sm.box2 h5,
.brand-blue-bright .boxstyle-md.box2 h5,
.brand-blue-bright .boxstyle-lg.box2 h5,
.brand-blue-bright .boxstyle_.box2 h6,
.brand-blue-bright .boxstyle-xs.box2 h6,
.brand-blue-bright .boxstyle-sm.box2 h6,
.brand-blue-bright .boxstyle-md.box2 h6,
.brand-blue-bright .boxstyle-lg.box2 h6,
.brand-blue-bright .boxstyle_.box2 .h1,
.brand-blue-bright .boxstyle-xs.box2 .h1,
.brand-blue-bright .boxstyle-sm.box2 .h1,
.brand-blue-bright .boxstyle-md.box2 .h1,
.brand-blue-bright .boxstyle-lg.box2 .h1,
.brand-blue-bright .boxstyle_.box2 .h2,
.brand-blue-bright .boxstyle-xs.box2 .h2,
.brand-blue-bright .boxstyle-sm.box2 .h2,
.brand-blue-bright .boxstyle-md.box2 .h2,
.brand-blue-bright .boxstyle-lg.box2 .h2,
.brand-blue-bright .boxstyle_.box2 .h3,
.brand-blue-bright .boxstyle-xs.box2 .h3,
.brand-blue-bright .boxstyle-sm.box2 .h3,
.brand-blue-bright .boxstyle-md.box2 .h3,
.brand-blue-bright .boxstyle-lg.box2 .h3,
.brand-blue-bright .boxstyle_.box2 .h4,
.brand-blue-bright .boxstyle-xs.box2 .h4,
.brand-blue-bright .boxstyle-sm.box2 .h4,
.brand-blue-bright .boxstyle-md.box2 .h4,
.brand-blue-bright .boxstyle-lg.box2 .h4,
.brand-blue-bright .boxstyle_.box2 .h5,
.brand-blue-bright .boxstyle-xs.box2 .h5,
.brand-blue-bright .boxstyle-sm.box2 .h5,
.brand-blue-bright .boxstyle-md.box2 .h5,
.brand-blue-bright .boxstyle-lg.box2 .h5,
.brand-blue-bright .boxstyle_.box2 .h6,
.brand-blue-bright .boxstyle-xs.box2 .h6,
.brand-blue-bright .boxstyle-sm.box2 .h6,
.brand-blue-bright .boxstyle-md.box2 .h6,
.brand-blue-bright .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box2 blockquote.quotes,
.brand-blue-bright .boxstyle-xs.box2 blockquote.quotes,
.brand-blue-bright .boxstyle-sm.box2 blockquote.quotes,
.brand-blue-bright .boxstyle-md.box2 blockquote.quotes,
.brand-blue-bright .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-blue-bright .boxstyle_.box2 a,
.brand-blue-bright .boxstyle-xs.box2 a,
.brand-blue-bright .boxstyle-sm.box2 a,
.brand-blue-bright .boxstyle-md.box2 a,
.brand-blue-bright .boxstyle-lg.box2 a,
.brand-blue-bright .boxstyle_.box2 a:link,
.brand-blue-bright .boxstyle-xs.box2 a:link,
.brand-blue-bright .boxstyle-sm.box2 a:link,
.brand-blue-bright .boxstyle-md.box2 a:link,
.brand-blue-bright .boxstyle-lg.box2 a:link,
.brand-blue-bright .boxstyle_.box2 a:visited,
.brand-blue-bright .boxstyle-xs.box2 a:visited,
.brand-blue-bright .boxstyle-sm.box2 a:visited,
.brand-blue-bright .boxstyle-md.box2 a:visited,
.brand-blue-bright .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box2 a:hover,
.brand-blue-bright .boxstyle-xs.box2 a:hover,
.brand-blue-bright .boxstyle-sm.box2 a:hover,
.brand-blue-bright .boxstyle-md.box2 a:hover,
.brand-blue-bright .boxstyle-lg.box2 a:hover,
.brand-blue-bright .boxstyle_.box2 a:focus,
.brand-blue-bright .boxstyle-xs.box2 a:focus,
.brand-blue-bright .boxstyle-sm.box2 a:focus,
.brand-blue-bright .boxstyle-md.box2 a:focus,
.brand-blue-bright .boxstyle-lg.box2 a:focus,
.brand-blue-bright .boxstyle_.box2 a:active,
.brand-blue-bright .boxstyle-xs.box2 a:active,
.brand-blue-bright .boxstyle-sm.box2 a:active,
.brand-blue-bright .boxstyle-md.box2 a:active,
.brand-blue-bright .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box2 .popover button.close,
.brand-blue-bright .boxstyle-xs.box2 .popover button.close,
.brand-blue-bright .boxstyle-sm.box2 .popover button.close,
.brand-blue-bright .boxstyle-md.box2 .popover button.close,
.brand-blue-bright .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box2 .popover button.close:hover,
.brand-blue-bright .boxstyle-xs.box2 .popover button.close:hover,
.brand-blue-bright .boxstyle-sm.box2 .popover button.close:hover,
.brand-blue-bright .boxstyle-md.box2 .popover button.close:hover,
.brand-blue-bright .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box2 a:hover .new-window-link,
.brand-blue-bright .boxstyle-xs.box2 a:hover .new-window-link,
.brand-blue-bright .boxstyle-sm.box2 a:hover .new-window-link,
.brand-blue-bright .boxstyle-md.box2 a:hover .new-window-link,
.brand-blue-bright .boxstyle-lg.box2 a:hover .new-window-link,
.brand-blue-bright .boxstyle_.box2 a:hover .insecure-link,
.brand-blue-bright .boxstyle-xs.box2 a:hover .insecure-link,
.brand-blue-bright .boxstyle-sm.box2 a:hover .insecure-link,
.brand-blue-bright .boxstyle-md.box2 a:hover .insecure-link,
.brand-blue-bright .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box2 .btn-link,
.brand-blue-bright .boxstyle-xs.box2 .btn-link,
.brand-blue-bright .boxstyle-sm.box2 .btn-link,
.brand-blue-bright .boxstyle-md.box2 .btn-link,
.brand-blue-bright .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box2 .btn-link:hover,
.brand-blue-bright .boxstyle-xs.box2 .btn-link:hover,
.brand-blue-bright .boxstyle-sm.box2 .btn-link:hover,
.brand-blue-bright .boxstyle-md.box2 .btn-link:hover,
.brand-blue-bright .boxstyle-lg.box2 .btn-link:hover,
.brand-blue-bright .boxstyle_.box2 .btn-link:focus,
.brand-blue-bright .boxstyle-xs.box2 .btn-link:focus,
.brand-blue-bright .boxstyle-sm.box2 .btn-link:focus,
.brand-blue-bright .boxstyle-md.box2 .btn-link:focus,
.brand-blue-bright .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box2 a,
.brand-blue-bright .boxstyle-xs.box2 a,
.brand-blue-bright .boxstyle-sm.box2 a,
.brand-blue-bright .boxstyle-md.box2 a,
.brand-blue-bright .boxstyle-lg.box2 a,
.brand-blue-bright .boxstyle_.box2 a:link,
.brand-blue-bright .boxstyle-xs.box2 a:link,
.brand-blue-bright .boxstyle-sm.box2 a:link,
.brand-blue-bright .boxstyle-md.box2 a:link,
.brand-blue-bright .boxstyle-lg.box2 a:link,
.brand-blue-bright .boxstyle_.box2 a:visited,
.brand-blue-bright .boxstyle-xs.box2 a:visited,
.brand-blue-bright .boxstyle-sm.box2 a:visited,
.brand-blue-bright .boxstyle-md.box2 a:visited,
.brand-blue-bright .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue-bright .boxstyle_.box2 a:hover,
.brand-blue-bright .boxstyle-xs.box2 a:hover,
.brand-blue-bright .boxstyle-sm.box2 a:hover,
.brand-blue-bright .boxstyle-md.box2 a:hover,
.brand-blue-bright .boxstyle-lg.box2 a:hover,
.brand-blue-bright .boxstyle_.box2 a:focus,
.brand-blue-bright .boxstyle-xs.box2 a:focus,
.brand-blue-bright .boxstyle-sm.box2 a:focus,
.brand-blue-bright .boxstyle-md.box2 a:focus,
.brand-blue-bright .boxstyle-lg.box2 a:focus,
.brand-blue-bright .boxstyle_.box2 a:active,
.brand-blue-bright .boxstyle-xs.box2 a:active,
.brand-blue-bright .boxstyle-sm.box2 a:active,
.brand-blue-bright .boxstyle-md.box2 a:active,
.brand-blue-bright .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-blue-bright .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #00A9CE;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-blue-bright .boxstyle_.box3,
  .brand-blue-bright .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #00A9CE;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-blue-bright .boxstyle-md.box3 {
    background: none;
    border: 1px solid #00A9CE;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-blue-bright .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #00A9CE;
    color: inherit;
  }
}
.brand-blue-bright .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-blue-bright .boxstyle_.box4,
  .brand-blue-bright .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-blue-bright .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-blue-bright .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-blue-bright .boxstyle_.box5,
  .brand-blue-bright .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-blue-bright .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-blue-bright .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-blue-bright .boxstyle_.box5,
.brand-blue-bright .boxstyle-xs.box5,
.brand-blue-bright .boxstyle-sm.box5,
.brand-blue-bright .boxstyle-md.box5,
.brand-blue-bright .boxstyle-lg.box5 {
  border: 0;
  background: #00A9CE;
  color: #202020;
}
@media print {
  .brand-blue-bright .boxstyle_.box5,
  .brand-blue-bright .boxstyle-xs.box5,
  .brand-blue-bright .boxstyle-sm.box5,
  .brand-blue-bright .boxstyle-md.box5,
  .brand-blue-bright .boxstyle-lg.box5 {
    border: 1px solid #00A9CE;
  }
}
.brand-blue-bright .boxstyle_.box5 h1,
.brand-blue-bright .boxstyle-xs.box5 h1,
.brand-blue-bright .boxstyle-sm.box5 h1,
.brand-blue-bright .boxstyle-md.box5 h1,
.brand-blue-bright .boxstyle-lg.box5 h1,
.brand-blue-bright .boxstyle_.box5 h2,
.brand-blue-bright .boxstyle-xs.box5 h2,
.brand-blue-bright .boxstyle-sm.box5 h2,
.brand-blue-bright .boxstyle-md.box5 h2,
.brand-blue-bright .boxstyle-lg.box5 h2,
.brand-blue-bright .boxstyle_.box5 h3,
.brand-blue-bright .boxstyle-xs.box5 h3,
.brand-blue-bright .boxstyle-sm.box5 h3,
.brand-blue-bright .boxstyle-md.box5 h3,
.brand-blue-bright .boxstyle-lg.box5 h3,
.brand-blue-bright .boxstyle_.box5 h4,
.brand-blue-bright .boxstyle-xs.box5 h4,
.brand-blue-bright .boxstyle-sm.box5 h4,
.brand-blue-bright .boxstyle-md.box5 h4,
.brand-blue-bright .boxstyle-lg.box5 h4,
.brand-blue-bright .boxstyle_.box5 h5,
.brand-blue-bright .boxstyle-xs.box5 h5,
.brand-blue-bright .boxstyle-sm.box5 h5,
.brand-blue-bright .boxstyle-md.box5 h5,
.brand-blue-bright .boxstyle-lg.box5 h5,
.brand-blue-bright .boxstyle_.box5 h6,
.brand-blue-bright .boxstyle-xs.box5 h6,
.brand-blue-bright .boxstyle-sm.box5 h6,
.brand-blue-bright .boxstyle-md.box5 h6,
.brand-blue-bright .boxstyle-lg.box5 h6,
.brand-blue-bright .boxstyle_.box5 .h1,
.brand-blue-bright .boxstyle-xs.box5 .h1,
.brand-blue-bright .boxstyle-sm.box5 .h1,
.brand-blue-bright .boxstyle-md.box5 .h1,
.brand-blue-bright .boxstyle-lg.box5 .h1,
.brand-blue-bright .boxstyle_.box5 .h2,
.brand-blue-bright .boxstyle-xs.box5 .h2,
.brand-blue-bright .boxstyle-sm.box5 .h2,
.brand-blue-bright .boxstyle-md.box5 .h2,
.brand-blue-bright .boxstyle-lg.box5 .h2,
.brand-blue-bright .boxstyle_.box5 .h3,
.brand-blue-bright .boxstyle-xs.box5 .h3,
.brand-blue-bright .boxstyle-sm.box5 .h3,
.brand-blue-bright .boxstyle-md.box5 .h3,
.brand-blue-bright .boxstyle-lg.box5 .h3,
.brand-blue-bright .boxstyle_.box5 .h4,
.brand-blue-bright .boxstyle-xs.box5 .h4,
.brand-blue-bright .boxstyle-sm.box5 .h4,
.brand-blue-bright .boxstyle-md.box5 .h4,
.brand-blue-bright .boxstyle-lg.box5 .h4,
.brand-blue-bright .boxstyle_.box5 .h5,
.brand-blue-bright .boxstyle-xs.box5 .h5,
.brand-blue-bright .boxstyle-sm.box5 .h5,
.brand-blue-bright .boxstyle-md.box5 .h5,
.brand-blue-bright .boxstyle-lg.box5 .h5,
.brand-blue-bright .boxstyle_.box5 .h6,
.brand-blue-bright .boxstyle-xs.box5 .h6,
.brand-blue-bright .boxstyle-sm.box5 .h6,
.brand-blue-bright .boxstyle-md.box5 .h6,
.brand-blue-bright .boxstyle-lg.box5 .h6 {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box5 blockquote.quotes,
.brand-blue-bright .boxstyle-xs.box5 blockquote.quotes,
.brand-blue-bright .boxstyle-sm.box5 blockquote.quotes,
.brand-blue-bright .boxstyle-md.box5 blockquote.quotes,
.brand-blue-bright .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-blue-bright .boxstyle_.box5 a,
.brand-blue-bright .boxstyle-xs.box5 a,
.brand-blue-bright .boxstyle-sm.box5 a,
.brand-blue-bright .boxstyle-md.box5 a,
.brand-blue-bright .boxstyle-lg.box5 a,
.brand-blue-bright .boxstyle_.box5 a:link,
.brand-blue-bright .boxstyle-xs.box5 a:link,
.brand-blue-bright .boxstyle-sm.box5 a:link,
.brand-blue-bright .boxstyle-md.box5 a:link,
.brand-blue-bright .boxstyle-lg.box5 a:link,
.brand-blue-bright .boxstyle_.box5 a:visited,
.brand-blue-bright .boxstyle-xs.box5 a:visited,
.brand-blue-bright .boxstyle-sm.box5 a:visited,
.brand-blue-bright .boxstyle-md.box5 a:visited,
.brand-blue-bright .boxstyle-lg.box5 a:visited {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box5 a:hover,
.brand-blue-bright .boxstyle-xs.box5 a:hover,
.brand-blue-bright .boxstyle-sm.box5 a:hover,
.brand-blue-bright .boxstyle-md.box5 a:hover,
.brand-blue-bright .boxstyle-lg.box5 a:hover,
.brand-blue-bright .boxstyle_.box5 a:focus,
.brand-blue-bright .boxstyle-xs.box5 a:focus,
.brand-blue-bright .boxstyle-sm.box5 a:focus,
.brand-blue-bright .boxstyle-md.box5 a:focus,
.brand-blue-bright .boxstyle-lg.box5 a:focus,
.brand-blue-bright .boxstyle_.box5 a:active,
.brand-blue-bright .boxstyle-xs.box5 a:active,
.brand-blue-bright .boxstyle-sm.box5 a:active,
.brand-blue-bright .boxstyle-md.box5 a:active,
.brand-blue-bright .boxstyle-lg.box5 a:active {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box5 .popover button.close,
.brand-blue-bright .boxstyle-xs.box5 .popover button.close,
.brand-blue-bright .boxstyle-sm.box5 .popover button.close,
.brand-blue-bright .boxstyle-md.box5 .popover button.close,
.brand-blue-bright .boxstyle-lg.box5 .popover button.close {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box5 .popover button.close:hover,
.brand-blue-bright .boxstyle-xs.box5 .popover button.close:hover,
.brand-blue-bright .boxstyle-sm.box5 .popover button.close:hover,
.brand-blue-bright .boxstyle-md.box5 .popover button.close:hover,
.brand-blue-bright .boxstyle-lg.box5 .popover button.close:hover {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box5 a:hover .new-window-link,
.brand-blue-bright .boxstyle-xs.box5 a:hover .new-window-link,
.brand-blue-bright .boxstyle-sm.box5 a:hover .new-window-link,
.brand-blue-bright .boxstyle-md.box5 a:hover .new-window-link,
.brand-blue-bright .boxstyle-lg.box5 a:hover .new-window-link,
.brand-blue-bright .boxstyle_.box5 a:hover .insecure-link,
.brand-blue-bright .boxstyle-xs.box5 a:hover .insecure-link,
.brand-blue-bright .boxstyle-sm.box5 a:hover .insecure-link,
.brand-blue-bright .boxstyle-md.box5 a:hover .insecure-link,
.brand-blue-bright .boxstyle-lg.box5 a:hover .insecure-link {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box5 .btn-link,
.brand-blue-bright .boxstyle-xs.box5 .btn-link,
.brand-blue-bright .boxstyle-sm.box5 .btn-link,
.brand-blue-bright .boxstyle-md.box5 .btn-link,
.brand-blue-bright .boxstyle-lg.box5 .btn-link {
  color: #202020;
}
.brand-blue-bright .boxstyle_.box5 .btn-link:hover,
.brand-blue-bright .boxstyle-xs.box5 .btn-link:hover,
.brand-blue-bright .boxstyle-sm.box5 .btn-link:hover,
.brand-blue-bright .boxstyle-md.box5 .btn-link:hover,
.brand-blue-bright .boxstyle-lg.box5 .btn-link:hover,
.brand-blue-bright .boxstyle_.box5 .btn-link:focus,
.brand-blue-bright .boxstyle-xs.box5 .btn-link:focus,
.brand-blue-bright .boxstyle-sm.box5 .btn-link:focus,
.brand-blue-bright .boxstyle-md.box5 .btn-link:focus,
.brand-blue-bright .boxstyle-lg.box5 .btn-link:focus {
  color: #000000;
}
.brand-blue-bright .boxstyle_.box5 a,
.brand-blue-bright .boxstyle-xs.box5 a,
.brand-blue-bright .boxstyle-sm.box5 a,
.brand-blue-bright .boxstyle-md.box5 a,
.brand-blue-bright .boxstyle-lg.box5 a,
.brand-blue-bright .boxstyle_.box5 a:link,
.brand-blue-bright .boxstyle-xs.box5 a:link,
.brand-blue-bright .boxstyle-sm.box5 a:link,
.brand-blue-bright .boxstyle-md.box5 a:link,
.brand-blue-bright .boxstyle-lg.box5 a:link,
.brand-blue-bright .boxstyle_.box5 a:visited,
.brand-blue-bright .boxstyle-xs.box5 a:visited,
.brand-blue-bright .boxstyle-sm.box5 a:visited,
.brand-blue-bright .boxstyle-md.box5 a:visited,
.brand-blue-bright .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-blue-bright .boxstyle_.box5 a:hover,
.brand-blue-bright .boxstyle-xs.box5 a:hover,
.brand-blue-bright .boxstyle-sm.box5 a:hover,
.brand-blue-bright .boxstyle-md.box5 a:hover,
.brand-blue-bright .boxstyle-lg.box5 a:hover,
.brand-blue-bright .boxstyle_.box5 a:focus,
.brand-blue-bright .boxstyle-xs.box5 a:focus,
.brand-blue-bright .boxstyle-sm.box5 a:focus,
.brand-blue-bright .boxstyle-md.box5 a:focus,
.brand-blue-bright .boxstyle-lg.box5 a:focus,
.brand-blue-bright .boxstyle_.box5 a:active,
.brand-blue-bright .boxstyle-xs.box5 a:active,
.brand-blue-bright .boxstyle-sm.box5 a:active,
.brand-blue-bright .boxstyle-md.box5 a:active,
.brand-blue-bright .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-blue-bright .pagination > li a,
.brand-blue-bright .pagination > li span {
  color: #00A9CE;
}
.brand-blue-bright .pagination > li.active > a,
.brand-blue-bright .pagination > li.active > span:not(.sr-only),
.brand-blue-bright .pagination > li.active > a:hover,
.brand-blue-bright .pagination > li.active > span:not(.sr-only):hover,
.brand-blue-bright .pagination > li.active > a:focus,
.brand-blue-bright .pagination > li.active > span:not(.sr-only):focus {
  background-color: #00A9CE;
  border-color: #00A9CE;
  color: white;
}
.brand-blue-bright .nav-tabs {
  border-bottom-color: #80d4e7;
}
.brand-blue-bright .nav-tabs > li > a {
  background-color: #cceef5;
  border-color: #80d4e7;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-blue-bright .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #66cbe2;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-blue-bright .nav-tabs > li > a:hover,
.brand-blue-bright .nav-tabs > li > a:focus {
  background-color: #e6f6fa;
  border-color: #99ddeb #99ddeb #80d4e7;
}
.brand-blue-bright .nav-tabs > li > a:hover::before,
.brand-blue-bright .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-blue-bright .nav-tabs > li.open > a,
.brand-blue-bright .nav-tabs > li.open > a:hover,
.brand-blue-bright .nav-tabs > li.open > a:focus {
  background-color: #e6f6fa;
  border-color: #99ddeb #99ddeb #80d4e7;
}
.brand-blue-bright .nav-tabs > li.open > a::before,
.brand-blue-bright .nav-tabs > li.open > a:hover::before,
.brand-blue-bright .nav-tabs > li.open > a:focus::before {
  background: #00A9CE;
  width: 100%;
}
.brand-blue-bright .nav-tabs > li.active > a,
.brand-blue-bright .nav-tabs > li.active > a:hover,
.brand-blue-bright .nav-tabs > li.active > a:focus {
  border-color: #80d4e7 #80d4e7 transparent;
}
.brand-blue-bright .nav-tabs > li.active > a::before,
.brand-blue-bright .nav-tabs > li.active > a:hover::before,
.brand-blue-bright .nav-tabs > li.active > a:focus::before {
  background: #00A9CE;
  width: 100%;
}
.brand-blue-bright .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-blue-bright .nav-tabs > li.disabled > a:hover,
.brand-blue-bright .nav-tabs > li.disabled > a:focus {
  background-color: #cceef5;
}
.brand-blue-bright .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-blue-bright .nav-pills > li.active > a,
.brand-blue-bright .nav-pills > li.active > a:hover,
.brand-blue-bright .nav-pills > li.active > a:focus {
  background-color: #00A9CE;
  color: white;
}
.brand-blue-bright .list-group-item.active,
.brand-blue-bright .list-group-item.active:hover,
.brand-blue-bright .list-group-item.active:focus {
  background-color: #00A9CE;
  border-color: #00A9CE;
  color: white;
}
.brand-blue-bright .id7-koan-spinner__neon--top,
.brand-blue-bright .id7-koan-spinner__neon--bottom {
  stroke: #00A9CE;
}
.brand-orange h1,
.brand-orange h2,
.brand-orange h3,
.brand-orange h4,
.brand-orange h5,
.brand-orange h6,
.brand-orange .h1,
.brand-orange .h2,
.brand-orange .h3,
.brand-orange .h4,
.brand-orange .h5,
.brand-orange .h6 {
  color: #BE531C;
}
.brand-orange a,
.brand-orange a:link,
.brand-orange a:visited {
  color: #BE531C;
}
.brand-orange a:hover,
.brand-orange a:focus,
.brand-orange a:active {
  color: #652c0f;
}
.brand-orange .popover button.close {
  color: #BE531C;
}
.brand-orange .popover button.close:hover {
  color: #652c0f;
}
.brand-orange a:hover .new-window-link,
.brand-orange a:hover .insecure-link {
  color: #652c0f;
}
.brand-orange .btn-link {
  color: #BE531C;
}
.brand-orange .btn-link:hover,
.brand-orange .btn-link:focus {
  color: #652c0f;
}
.brand-orange a,
.brand-orange a:link,
.brand-orange a:visited {
  -webkit-text-decoration-color: #d28760;
  text-decoration-color: #d28760;
}
.brand-orange a:hover,
.brand-orange a:focus,
.brand-orange a:active {
  -webkit-text-decoration-color: #652c0f;
  text-decoration-color: #652c0f;
}
.brand-orange blockquote.quotes {
  color: #652c0f;
}
.brand-orange blockquote.quotes::before {
  color: #652c0f;
}
.brand-orange .brand-bg {
  background: #BE531C;
  color: white;
}
.brand-orange .brand-bg a,
.brand-orange .brand-bg a:link,
.brand-orange .brand-bg a:visited {
  color: white !important;
}
.brand-orange .brand-bg a:hover,
.brand-orange .brand-bg a:focus,
.brand-orange .brand-bg a:active {
  color: #cccccc !important;
}
.brand-orange .brand-bg .popover button.close {
  color: white !important;
}
.brand-orange .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-orange .brand-bg a:hover .new-window-link,
.brand-orange .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-orange .brand-bg .btn-link {
  color: white !important;
}
.brand-orange .brand-bg .btn-link:hover,
.brand-orange .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-orange .brand-bg a,
.brand-orange .brand-bg a:link,
.brand-orange .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-orange .brand-bg a:hover,
.brand-orange .brand-bg a:focus,
.brand-orange .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-orange .brand-text {
  color: #BE531C;
}
.brand-orange .brand-text a,
.brand-orange .brand-text a:link,
.brand-orange .brand-text a:visited {
  color: #BE531C !important;
}
.brand-orange .brand-text a:hover,
.brand-orange .brand-text a:focus,
.brand-orange .brand-text a:active {
  color: #652c0f !important;
}
.brand-orange .brand-text .popover button.close {
  color: #BE531C !important;
}
.brand-orange .brand-text .popover button.close:hover {
  color: #652c0f !important;
}
.brand-orange .brand-text a:hover .new-window-link,
.brand-orange .brand-text a:hover .insecure-link {
  color: #652c0f !important;
}
.brand-orange .brand-text .btn-link {
  color: #BE531C !important;
}
.brand-orange .brand-text .btn-link:hover,
.brand-orange .brand-text .btn-link:focus {
  color: #652c0f !important;
}
.brand-orange .brand-text a,
.brand-orange .brand-text a:link,
.brand-orange .brand-text a:visited {
  -webkit-text-decoration-color: #d28760 !important;
  text-decoration-color: #d28760 !important;
}
.brand-orange .brand-text a:hover,
.brand-orange .brand-text a:focus,
.brand-orange .brand-text a:active {
  -webkit-text-decoration-color: #652c0f !important;
  text-decoration-color: #652c0f !important;
}
.brand-orange .brand-border {
  border-color: #BE531C;
}
.brand-orange .btn-brand {
  color: white;
  background-color: #BE531C;
  border-color: #a84919;
  color: white !important;
}
.brand-orange .btn-brand:focus,
.brand-orange .btn-brand.focus {
  color: white;
  background-color: #924015;
  border-color: #391908;
}
.brand-orange .btn-brand:hover {
  color: white;
  background-color: #924015;
  border-color: #723211;
}
.brand-orange .btn-brand:active,
.brand-orange .btn-brand.active,
.open > .dropdown-toggle.brand-orange .btn-brand {
  color: white;
  background-color: #924015;
  background-image: none;
  border-color: #723211;
}
.brand-orange .btn-brand:active:hover,
.brand-orange .btn-brand.active:hover,
.open > .dropdown-toggle.brand-orange .btn-brand:hover,
.brand-orange .btn-brand:active:focus,
.brand-orange .btn-brand.active:focus,
.open > .dropdown-toggle.brand-orange .btn-brand:focus,
.brand-orange .btn-brand:active.focus,
.brand-orange .btn-brand.active.focus,
.open > .dropdown-toggle.brand-orange .btn-brand.focus {
  color: white;
  background-color: #723211;
  border-color: #391908;
}
.brand-orange .btn-brand.disabled:hover,
.brand-orange .btn-brand[disabled]:hover,
fieldset[disabled] .brand-orange .btn-brand:hover,
.brand-orange .btn-brand.disabled:focus,
.brand-orange .btn-brand[disabled]:focus,
fieldset[disabled] .brand-orange .btn-brand:focus,
.brand-orange .btn-brand.disabled.focus,
.brand-orange .btn-brand[disabled].focus,
fieldset[disabled] .brand-orange .btn-brand.focus {
  background-color: #BE531C;
  border-color: #a84919;
}
.brand-orange .btn-brand .badge {
  color: #BE531C;
  background-color: white;
}
.brand-orange .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-orange .id7-utility-masthead {
  background-color: #d28760;
}
.brand-orange .id7-utility-masthead::after {
  background-color: #BE531C;
}
body.id7-point-1 .brand-orange .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-orange .id7-navigation .navbar-primary {
  background-color: #BE531C;
}
.brand-orange .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-orange .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #e4b7a0;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #984216;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #e4b7a0;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-orange .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-orange .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #984216;
}
.brand-orange .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-orange .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-orange .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-orange .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #984216;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #BE531C;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #984216;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #984216;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #984216;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #984216;
  background-image: none;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #e4b7a0;
}
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-orange .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #984216;
}
.brand-orange .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .btn-link:hover,
.brand-orange .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-orange .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-orange .id7-navigation .navbar-primary .btn-link:hover,
.brand-orange .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-orange .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-orange .navbar-primary .navbar-brand {
  background-color: #BE531C;
}
.brand-orange .navbar-secondary {
  background-color: #e4b7a0;
}
.brand-orange .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-orange .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-orange .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-orange .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-orange .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-orange .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-orange .navbar-secondary .navbar-nav > li:hover,
.brand-orange .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #ecccbc;
}
.brand-orange .navbar-secondary .navbar-nav > li > a,
.brand-orange .navbar-secondary .navbar-nav > li > a:hover,
.brand-orange .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-orange .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-orange .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-orange .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-orange .navbar-secondary .navbar-nav > .disabled,
.brand-orange .navbar-secondary .navbar-nav > .disabled:hover,
.brand-orange .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #ecccbc;
}
.brand-orange .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-orange .navbar-secondary .navbar-toggle:hover,
.brand-orange .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-orange .navbar-secondary .navbar-nav > .open,
.brand-orange .navbar-secondary .navbar-nav > .open:hover,
.brand-orange .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #ecccbc;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #e4b7a0;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #b69280;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #b69280;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #ecccbc;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #ecccbc;
  background-image: none;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-orange .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #ecccbc;
}
.brand-orange .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-orange .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-orange .navbar-secondary .btn-link {
  color: #202020;
}
.brand-orange .navbar-secondary .btn-link:hover,
.brand-orange .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-orange .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-orange .navbar-secondary .btn-link:hover,
.brand-orange .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-orange .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-orange .navbar-secondary .navbar-nav > li::after {
  color: #BE531C;
}
.brand-orange .navbar-secondary .navbar-nav > li:first-child {
  background-color: #BE531C;
  color: white;
}
.brand-orange .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #BE531C;
}
.brand-orange .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #BE531C;
}
.brand-orange .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-orange .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-orange .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-orange .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-orange .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-orange .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-orange .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-orange .navbar-tertiary .navbar-nav > li:hover,
.brand-orange .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange .navbar-tertiary .navbar-nav > li > a,
.brand-orange .navbar-tertiary .navbar-nav > li > a:hover,
.brand-orange .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-orange .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-orange .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-orange .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-orange .navbar-tertiary .navbar-nav > .disabled,
.brand-orange .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-orange .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-orange .navbar-tertiary .navbar-toggle:hover,
.brand-orange .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-orange .navbar-tertiary .navbar-nav > .open,
.brand-orange .navbar-tertiary .navbar-nav > .open:hover,
.brand-orange .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-orange .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-orange .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-orange .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-orange .navbar-tertiary .btn-link:hover,
.brand-orange .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-orange .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-orange .navbar-tertiary .btn-link:hover,
.brand-orange .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-orange .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-orange.bordered .id7-left-border,
.brand-orange.bordered .id7-right-border {
  border-color: rgba(190, 83, 28, 0.3);
}
.brand-orange .id7-page-footer .id7-app-footer {
  background: #BE531C;
  color: white;
}
.brand-orange .id7-page-footer .id7-app-footer h1,
.brand-orange .id7-page-footer .id7-app-footer h2,
.brand-orange .id7-page-footer .id7-app-footer h3,
.brand-orange .id7-page-footer .id7-app-footer h4,
.brand-orange .id7-page-footer .id7-app-footer h5,
.brand-orange .id7-page-footer .id7-app-footer h6,
.brand-orange .id7-page-footer .id7-app-footer .h1,
.brand-orange .id7-page-footer .id7-app-footer .h2,
.brand-orange .id7-page-footer .id7-app-footer .h3,
.brand-orange .id7-page-footer .id7-app-footer .h4,
.brand-orange .id7-page-footer .id7-app-footer .h5,
.brand-orange .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-orange .id7-page-footer .id7-app-footer a,
.brand-orange .id7-page-footer .id7-app-footer a:link,
.brand-orange .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-orange .id7-page-footer .id7-app-footer a:hover,
.brand-orange .id7-page-footer .id7-app-footer a:focus,
.brand-orange .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-orange .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-orange .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-orange .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-orange .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-orange .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-orange .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-orange .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-orange .id7-page-footer .id7-app-footer a,
.brand-orange .id7-page-footer .id7-app-footer a:link,
.brand-orange .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-orange .id7-page-footer .id7-app-footer a:hover,
.brand-orange .id7-page-footer .id7-app-footer a:focus,
.brand-orange .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-orange .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #BE531C;
}
.brand-orange .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-orange .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-orange .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-orange .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-orange .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-orange .id7-search .fa,
.brand-orange .id7-search .fas {
  color: #BE531C;
  color: rgba(190, 83, 28, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-orange .id7-search .form-control:hover + .fa,
.brand-orange .id7-search .typeahead:hover + .fa,
.brand-orange .id7-search .fa:hover,
.brand-orange .id7-search .form-control:hover + .fas,
.brand-orange .id7-search .typeahead:hover + .fas,
.brand-orange .id7-search .fas:hover {
  color: #BE531C;
}
.brand-orange .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #BE531C;
  color: white;
}
.brand-orange .form-control:focus {
  border-color: #BE531C;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(190, 83, 28, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(190, 83, 28, 0.6);
}
.brand-orange .carousel .carousel-indicators li::after {
  color: #BE531C;
  color: rgba(190, 83, 28, 0.3);
}
.brand-orange .carousel .carousel-indicators li.active::after {
  color: #BE531C;
}
@media (max-width: 767px) {
  .brand-orange .boxstyle_.box1,
  .brand-orange .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-orange .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-orange .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-orange .boxstyle_.box1,
.brand-orange .boxstyle-xs.box1,
.brand-orange .boxstyle-sm.box1,
.brand-orange .boxstyle-md.box1,
.brand-orange .boxstyle-lg.box1 {
  border: 0;
  background: #f5e5dd;
  color: #202020;
}
@media print {
  .brand-orange .boxstyle_.box1,
  .brand-orange .boxstyle-xs.box1,
  .brand-orange .boxstyle-sm.box1,
  .brand-orange .boxstyle-md.box1,
  .brand-orange .boxstyle-lg.box1 {
    border: 1px solid #f5e5dd;
  }
}
.brand-orange .boxstyle_.box1 h1,
.brand-orange .boxstyle-xs.box1 h1,
.brand-orange .boxstyle-sm.box1 h1,
.brand-orange .boxstyle-md.box1 h1,
.brand-orange .boxstyle-lg.box1 h1,
.brand-orange .boxstyle_.box1 h2,
.brand-orange .boxstyle-xs.box1 h2,
.brand-orange .boxstyle-sm.box1 h2,
.brand-orange .boxstyle-md.box1 h2,
.brand-orange .boxstyle-lg.box1 h2,
.brand-orange .boxstyle_.box1 h3,
.brand-orange .boxstyle-xs.box1 h3,
.brand-orange .boxstyle-sm.box1 h3,
.brand-orange .boxstyle-md.box1 h3,
.brand-orange .boxstyle-lg.box1 h3,
.brand-orange .boxstyle_.box1 h4,
.brand-orange .boxstyle-xs.box1 h4,
.brand-orange .boxstyle-sm.box1 h4,
.brand-orange .boxstyle-md.box1 h4,
.brand-orange .boxstyle-lg.box1 h4,
.brand-orange .boxstyle_.box1 h5,
.brand-orange .boxstyle-xs.box1 h5,
.brand-orange .boxstyle-sm.box1 h5,
.brand-orange .boxstyle-md.box1 h5,
.brand-orange .boxstyle-lg.box1 h5,
.brand-orange .boxstyle_.box1 h6,
.brand-orange .boxstyle-xs.box1 h6,
.brand-orange .boxstyle-sm.box1 h6,
.brand-orange .boxstyle-md.box1 h6,
.brand-orange .boxstyle-lg.box1 h6,
.brand-orange .boxstyle_.box1 .h1,
.brand-orange .boxstyle-xs.box1 .h1,
.brand-orange .boxstyle-sm.box1 .h1,
.brand-orange .boxstyle-md.box1 .h1,
.brand-orange .boxstyle-lg.box1 .h1,
.brand-orange .boxstyle_.box1 .h2,
.brand-orange .boxstyle-xs.box1 .h2,
.brand-orange .boxstyle-sm.box1 .h2,
.brand-orange .boxstyle-md.box1 .h2,
.brand-orange .boxstyle-lg.box1 .h2,
.brand-orange .boxstyle_.box1 .h3,
.brand-orange .boxstyle-xs.box1 .h3,
.brand-orange .boxstyle-sm.box1 .h3,
.brand-orange .boxstyle-md.box1 .h3,
.brand-orange .boxstyle-lg.box1 .h3,
.brand-orange .boxstyle_.box1 .h4,
.brand-orange .boxstyle-xs.box1 .h4,
.brand-orange .boxstyle-sm.box1 .h4,
.brand-orange .boxstyle-md.box1 .h4,
.brand-orange .boxstyle-lg.box1 .h4,
.brand-orange .boxstyle_.box1 .h5,
.brand-orange .boxstyle-xs.box1 .h5,
.brand-orange .boxstyle-sm.box1 .h5,
.brand-orange .boxstyle-md.box1 .h5,
.brand-orange .boxstyle-lg.box1 .h5,
.brand-orange .boxstyle_.box1 .h6,
.brand-orange .boxstyle-xs.box1 .h6,
.brand-orange .boxstyle-sm.box1 .h6,
.brand-orange .boxstyle-md.box1 .h6,
.brand-orange .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-orange .boxstyle_.box1 blockquote.quotes,
.brand-orange .boxstyle-xs.box1 blockquote.quotes,
.brand-orange .boxstyle-sm.box1 blockquote.quotes,
.brand-orange .boxstyle-md.box1 blockquote.quotes,
.brand-orange .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-orange .boxstyle_.box1 a,
.brand-orange .boxstyle-xs.box1 a,
.brand-orange .boxstyle-sm.box1 a,
.brand-orange .boxstyle-md.box1 a,
.brand-orange .boxstyle-lg.box1 a,
.brand-orange .boxstyle_.box1 a:link,
.brand-orange .boxstyle-xs.box1 a:link,
.brand-orange .boxstyle-sm.box1 a:link,
.brand-orange .boxstyle-md.box1 a:link,
.brand-orange .boxstyle-lg.box1 a:link,
.brand-orange .boxstyle_.box1 a:visited,
.brand-orange .boxstyle-xs.box1 a:visited,
.brand-orange .boxstyle-sm.box1 a:visited,
.brand-orange .boxstyle-md.box1 a:visited,
.brand-orange .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-orange .boxstyle_.box1 a:hover,
.brand-orange .boxstyle-xs.box1 a:hover,
.brand-orange .boxstyle-sm.box1 a:hover,
.brand-orange .boxstyle-md.box1 a:hover,
.brand-orange .boxstyle-lg.box1 a:hover,
.brand-orange .boxstyle_.box1 a:focus,
.brand-orange .boxstyle-xs.box1 a:focus,
.brand-orange .boxstyle-sm.box1 a:focus,
.brand-orange .boxstyle-md.box1 a:focus,
.brand-orange .boxstyle-lg.box1 a:focus,
.brand-orange .boxstyle_.box1 a:active,
.brand-orange .boxstyle-xs.box1 a:active,
.brand-orange .boxstyle-sm.box1 a:active,
.brand-orange .boxstyle-md.box1 a:active,
.brand-orange .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-orange .boxstyle_.box1 .popover button.close,
.brand-orange .boxstyle-xs.box1 .popover button.close,
.brand-orange .boxstyle-sm.box1 .popover button.close,
.brand-orange .boxstyle-md.box1 .popover button.close,
.brand-orange .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-orange .boxstyle_.box1 .popover button.close:hover,
.brand-orange .boxstyle-xs.box1 .popover button.close:hover,
.brand-orange .boxstyle-sm.box1 .popover button.close:hover,
.brand-orange .boxstyle-md.box1 .popover button.close:hover,
.brand-orange .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-orange .boxstyle_.box1 a:hover .new-window-link,
.brand-orange .boxstyle-xs.box1 a:hover .new-window-link,
.brand-orange .boxstyle-sm.box1 a:hover .new-window-link,
.brand-orange .boxstyle-md.box1 a:hover .new-window-link,
.brand-orange .boxstyle-lg.box1 a:hover .new-window-link,
.brand-orange .boxstyle_.box1 a:hover .insecure-link,
.brand-orange .boxstyle-xs.box1 a:hover .insecure-link,
.brand-orange .boxstyle-sm.box1 a:hover .insecure-link,
.brand-orange .boxstyle-md.box1 a:hover .insecure-link,
.brand-orange .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-orange .boxstyle_.box1 .btn-link,
.brand-orange .boxstyle-xs.box1 .btn-link,
.brand-orange .boxstyle-sm.box1 .btn-link,
.brand-orange .boxstyle-md.box1 .btn-link,
.brand-orange .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-orange .boxstyle_.box1 .btn-link:hover,
.brand-orange .boxstyle-xs.box1 .btn-link:hover,
.brand-orange .boxstyle-sm.box1 .btn-link:hover,
.brand-orange .boxstyle-md.box1 .btn-link:hover,
.brand-orange .boxstyle-lg.box1 .btn-link:hover,
.brand-orange .boxstyle_.box1 .btn-link:focus,
.brand-orange .boxstyle-xs.box1 .btn-link:focus,
.brand-orange .boxstyle-sm.box1 .btn-link:focus,
.brand-orange .boxstyle-md.box1 .btn-link:focus,
.brand-orange .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-orange .boxstyle_.box1 a,
.brand-orange .boxstyle-xs.box1 a,
.brand-orange .boxstyle-sm.box1 a,
.brand-orange .boxstyle-md.box1 a,
.brand-orange .boxstyle-lg.box1 a,
.brand-orange .boxstyle_.box1 a:link,
.brand-orange .boxstyle-xs.box1 a:link,
.brand-orange .boxstyle-sm.box1 a:link,
.brand-orange .boxstyle-md.box1 a:link,
.brand-orange .boxstyle-lg.box1 a:link,
.brand-orange .boxstyle_.box1 a:visited,
.brand-orange .boxstyle-xs.box1 a:visited,
.brand-orange .boxstyle-sm.box1 a:visited,
.brand-orange .boxstyle-md.box1 a:visited,
.brand-orange .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange .boxstyle_.box1 a:hover,
.brand-orange .boxstyle-xs.box1 a:hover,
.brand-orange .boxstyle-sm.box1 a:hover,
.brand-orange .boxstyle-md.box1 a:hover,
.brand-orange .boxstyle-lg.box1 a:hover,
.brand-orange .boxstyle_.box1 a:focus,
.brand-orange .boxstyle-xs.box1 a:focus,
.brand-orange .boxstyle-sm.box1 a:focus,
.brand-orange .boxstyle-md.box1 a:focus,
.brand-orange .boxstyle-lg.box1 a:focus,
.brand-orange .boxstyle_.box1 a:active,
.brand-orange .boxstyle-xs.box1 a:active,
.brand-orange .boxstyle-sm.box1 a:active,
.brand-orange .boxstyle-md.box1 a:active,
.brand-orange .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-orange .boxstyle_.box2,
  .brand-orange .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-orange .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-orange .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-orange .boxstyle_.box2,
.brand-orange .boxstyle-xs.box2,
.brand-orange .boxstyle-sm.box2,
.brand-orange .boxstyle-md.box2,
.brand-orange .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-orange .boxstyle_.box2,
  .brand-orange .boxstyle-xs.box2,
  .brand-orange .boxstyle-sm.box2,
  .brand-orange .boxstyle-md.box2,
  .brand-orange .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-orange .boxstyle_.box2 h1,
.brand-orange .boxstyle-xs.box2 h1,
.brand-orange .boxstyle-sm.box2 h1,
.brand-orange .boxstyle-md.box2 h1,
.brand-orange .boxstyle-lg.box2 h1,
.brand-orange .boxstyle_.box2 h2,
.brand-orange .boxstyle-xs.box2 h2,
.brand-orange .boxstyle-sm.box2 h2,
.brand-orange .boxstyle-md.box2 h2,
.brand-orange .boxstyle-lg.box2 h2,
.brand-orange .boxstyle_.box2 h3,
.brand-orange .boxstyle-xs.box2 h3,
.brand-orange .boxstyle-sm.box2 h3,
.brand-orange .boxstyle-md.box2 h3,
.brand-orange .boxstyle-lg.box2 h3,
.brand-orange .boxstyle_.box2 h4,
.brand-orange .boxstyle-xs.box2 h4,
.brand-orange .boxstyle-sm.box2 h4,
.brand-orange .boxstyle-md.box2 h4,
.brand-orange .boxstyle-lg.box2 h4,
.brand-orange .boxstyle_.box2 h5,
.brand-orange .boxstyle-xs.box2 h5,
.brand-orange .boxstyle-sm.box2 h5,
.brand-orange .boxstyle-md.box2 h5,
.brand-orange .boxstyle-lg.box2 h5,
.brand-orange .boxstyle_.box2 h6,
.brand-orange .boxstyle-xs.box2 h6,
.brand-orange .boxstyle-sm.box2 h6,
.brand-orange .boxstyle-md.box2 h6,
.brand-orange .boxstyle-lg.box2 h6,
.brand-orange .boxstyle_.box2 .h1,
.brand-orange .boxstyle-xs.box2 .h1,
.brand-orange .boxstyle-sm.box2 .h1,
.brand-orange .boxstyle-md.box2 .h1,
.brand-orange .boxstyle-lg.box2 .h1,
.brand-orange .boxstyle_.box2 .h2,
.brand-orange .boxstyle-xs.box2 .h2,
.brand-orange .boxstyle-sm.box2 .h2,
.brand-orange .boxstyle-md.box2 .h2,
.brand-orange .boxstyle-lg.box2 .h2,
.brand-orange .boxstyle_.box2 .h3,
.brand-orange .boxstyle-xs.box2 .h3,
.brand-orange .boxstyle-sm.box2 .h3,
.brand-orange .boxstyle-md.box2 .h3,
.brand-orange .boxstyle-lg.box2 .h3,
.brand-orange .boxstyle_.box2 .h4,
.brand-orange .boxstyle-xs.box2 .h4,
.brand-orange .boxstyle-sm.box2 .h4,
.brand-orange .boxstyle-md.box2 .h4,
.brand-orange .boxstyle-lg.box2 .h4,
.brand-orange .boxstyle_.box2 .h5,
.brand-orange .boxstyle-xs.box2 .h5,
.brand-orange .boxstyle-sm.box2 .h5,
.brand-orange .boxstyle-md.box2 .h5,
.brand-orange .boxstyle-lg.box2 .h5,
.brand-orange .boxstyle_.box2 .h6,
.brand-orange .boxstyle-xs.box2 .h6,
.brand-orange .boxstyle-sm.box2 .h6,
.brand-orange .boxstyle-md.box2 .h6,
.brand-orange .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-orange .boxstyle_.box2 blockquote.quotes,
.brand-orange .boxstyle-xs.box2 blockquote.quotes,
.brand-orange .boxstyle-sm.box2 blockquote.quotes,
.brand-orange .boxstyle-md.box2 blockquote.quotes,
.brand-orange .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-orange .boxstyle_.box2 a,
.brand-orange .boxstyle-xs.box2 a,
.brand-orange .boxstyle-sm.box2 a,
.brand-orange .boxstyle-md.box2 a,
.brand-orange .boxstyle-lg.box2 a,
.brand-orange .boxstyle_.box2 a:link,
.brand-orange .boxstyle-xs.box2 a:link,
.brand-orange .boxstyle-sm.box2 a:link,
.brand-orange .boxstyle-md.box2 a:link,
.brand-orange .boxstyle-lg.box2 a:link,
.brand-orange .boxstyle_.box2 a:visited,
.brand-orange .boxstyle-xs.box2 a:visited,
.brand-orange .boxstyle-sm.box2 a:visited,
.brand-orange .boxstyle-md.box2 a:visited,
.brand-orange .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-orange .boxstyle_.box2 a:hover,
.brand-orange .boxstyle-xs.box2 a:hover,
.brand-orange .boxstyle-sm.box2 a:hover,
.brand-orange .boxstyle-md.box2 a:hover,
.brand-orange .boxstyle-lg.box2 a:hover,
.brand-orange .boxstyle_.box2 a:focus,
.brand-orange .boxstyle-xs.box2 a:focus,
.brand-orange .boxstyle-sm.box2 a:focus,
.brand-orange .boxstyle-md.box2 a:focus,
.brand-orange .boxstyle-lg.box2 a:focus,
.brand-orange .boxstyle_.box2 a:active,
.brand-orange .boxstyle-xs.box2 a:active,
.brand-orange .boxstyle-sm.box2 a:active,
.brand-orange .boxstyle-md.box2 a:active,
.brand-orange .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-orange .boxstyle_.box2 .popover button.close,
.brand-orange .boxstyle-xs.box2 .popover button.close,
.brand-orange .boxstyle-sm.box2 .popover button.close,
.brand-orange .boxstyle-md.box2 .popover button.close,
.brand-orange .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-orange .boxstyle_.box2 .popover button.close:hover,
.brand-orange .boxstyle-xs.box2 .popover button.close:hover,
.brand-orange .boxstyle-sm.box2 .popover button.close:hover,
.brand-orange .boxstyle-md.box2 .popover button.close:hover,
.brand-orange .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-orange .boxstyle_.box2 a:hover .new-window-link,
.brand-orange .boxstyle-xs.box2 a:hover .new-window-link,
.brand-orange .boxstyle-sm.box2 a:hover .new-window-link,
.brand-orange .boxstyle-md.box2 a:hover .new-window-link,
.brand-orange .boxstyle-lg.box2 a:hover .new-window-link,
.brand-orange .boxstyle_.box2 a:hover .insecure-link,
.brand-orange .boxstyle-xs.box2 a:hover .insecure-link,
.brand-orange .boxstyle-sm.box2 a:hover .insecure-link,
.brand-orange .boxstyle-md.box2 a:hover .insecure-link,
.brand-orange .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-orange .boxstyle_.box2 .btn-link,
.brand-orange .boxstyle-xs.box2 .btn-link,
.brand-orange .boxstyle-sm.box2 .btn-link,
.brand-orange .boxstyle-md.box2 .btn-link,
.brand-orange .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-orange .boxstyle_.box2 .btn-link:hover,
.brand-orange .boxstyle-xs.box2 .btn-link:hover,
.brand-orange .boxstyle-sm.box2 .btn-link:hover,
.brand-orange .boxstyle-md.box2 .btn-link:hover,
.brand-orange .boxstyle-lg.box2 .btn-link:hover,
.brand-orange .boxstyle_.box2 .btn-link:focus,
.brand-orange .boxstyle-xs.box2 .btn-link:focus,
.brand-orange .boxstyle-sm.box2 .btn-link:focus,
.brand-orange .boxstyle-md.box2 .btn-link:focus,
.brand-orange .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-orange .boxstyle_.box2 a,
.brand-orange .boxstyle-xs.box2 a,
.brand-orange .boxstyle-sm.box2 a,
.brand-orange .boxstyle-md.box2 a,
.brand-orange .boxstyle-lg.box2 a,
.brand-orange .boxstyle_.box2 a:link,
.brand-orange .boxstyle-xs.box2 a:link,
.brand-orange .boxstyle-sm.box2 a:link,
.brand-orange .boxstyle-md.box2 a:link,
.brand-orange .boxstyle-lg.box2 a:link,
.brand-orange .boxstyle_.box2 a:visited,
.brand-orange .boxstyle-xs.box2 a:visited,
.brand-orange .boxstyle-sm.box2 a:visited,
.brand-orange .boxstyle-md.box2 a:visited,
.brand-orange .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange .boxstyle_.box2 a:hover,
.brand-orange .boxstyle-xs.box2 a:hover,
.brand-orange .boxstyle-sm.box2 a:hover,
.brand-orange .boxstyle-md.box2 a:hover,
.brand-orange .boxstyle-lg.box2 a:hover,
.brand-orange .boxstyle_.box2 a:focus,
.brand-orange .boxstyle-xs.box2 a:focus,
.brand-orange .boxstyle-sm.box2 a:focus,
.brand-orange .boxstyle-md.box2 a:focus,
.brand-orange .boxstyle-lg.box2 a:focus,
.brand-orange .boxstyle_.box2 a:active,
.brand-orange .boxstyle-xs.box2 a:active,
.brand-orange .boxstyle-sm.box2 a:active,
.brand-orange .boxstyle-md.box2 a:active,
.brand-orange .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-orange .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #BE531C;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-orange .boxstyle_.box3,
  .brand-orange .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #BE531C;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-orange .boxstyle-md.box3 {
    background: none;
    border: 1px solid #BE531C;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-orange .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #BE531C;
    color: inherit;
  }
}
.brand-orange .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-orange .boxstyle_.box4,
  .brand-orange .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-orange .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-orange .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-orange .boxstyle_.box5,
  .brand-orange .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-orange .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-orange .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-orange .boxstyle_.box5,
.brand-orange .boxstyle-xs.box5,
.brand-orange .boxstyle-sm.box5,
.brand-orange .boxstyle-md.box5,
.brand-orange .boxstyle-lg.box5 {
  border: 0;
  background: #BE531C;
  color: white;
}
@media print {
  .brand-orange .boxstyle_.box5,
  .brand-orange .boxstyle-xs.box5,
  .brand-orange .boxstyle-sm.box5,
  .brand-orange .boxstyle-md.box5,
  .brand-orange .boxstyle-lg.box5 {
    border: 1px solid #BE531C;
  }
}
.brand-orange .boxstyle_.box5 h1,
.brand-orange .boxstyle-xs.box5 h1,
.brand-orange .boxstyle-sm.box5 h1,
.brand-orange .boxstyle-md.box5 h1,
.brand-orange .boxstyle-lg.box5 h1,
.brand-orange .boxstyle_.box5 h2,
.brand-orange .boxstyle-xs.box5 h2,
.brand-orange .boxstyle-sm.box5 h2,
.brand-orange .boxstyle-md.box5 h2,
.brand-orange .boxstyle-lg.box5 h2,
.brand-orange .boxstyle_.box5 h3,
.brand-orange .boxstyle-xs.box5 h3,
.brand-orange .boxstyle-sm.box5 h3,
.brand-orange .boxstyle-md.box5 h3,
.brand-orange .boxstyle-lg.box5 h3,
.brand-orange .boxstyle_.box5 h4,
.brand-orange .boxstyle-xs.box5 h4,
.brand-orange .boxstyle-sm.box5 h4,
.brand-orange .boxstyle-md.box5 h4,
.brand-orange .boxstyle-lg.box5 h4,
.brand-orange .boxstyle_.box5 h5,
.brand-orange .boxstyle-xs.box5 h5,
.brand-orange .boxstyle-sm.box5 h5,
.brand-orange .boxstyle-md.box5 h5,
.brand-orange .boxstyle-lg.box5 h5,
.brand-orange .boxstyle_.box5 h6,
.brand-orange .boxstyle-xs.box5 h6,
.brand-orange .boxstyle-sm.box5 h6,
.brand-orange .boxstyle-md.box5 h6,
.brand-orange .boxstyle-lg.box5 h6,
.brand-orange .boxstyle_.box5 .h1,
.brand-orange .boxstyle-xs.box5 .h1,
.brand-orange .boxstyle-sm.box5 .h1,
.brand-orange .boxstyle-md.box5 .h1,
.brand-orange .boxstyle-lg.box5 .h1,
.brand-orange .boxstyle_.box5 .h2,
.brand-orange .boxstyle-xs.box5 .h2,
.brand-orange .boxstyle-sm.box5 .h2,
.brand-orange .boxstyle-md.box5 .h2,
.brand-orange .boxstyle-lg.box5 .h2,
.brand-orange .boxstyle_.box5 .h3,
.brand-orange .boxstyle-xs.box5 .h3,
.brand-orange .boxstyle-sm.box5 .h3,
.brand-orange .boxstyle-md.box5 .h3,
.brand-orange .boxstyle-lg.box5 .h3,
.brand-orange .boxstyle_.box5 .h4,
.brand-orange .boxstyle-xs.box5 .h4,
.brand-orange .boxstyle-sm.box5 .h4,
.brand-orange .boxstyle-md.box5 .h4,
.brand-orange .boxstyle-lg.box5 .h4,
.brand-orange .boxstyle_.box5 .h5,
.brand-orange .boxstyle-xs.box5 .h5,
.brand-orange .boxstyle-sm.box5 .h5,
.brand-orange .boxstyle-md.box5 .h5,
.brand-orange .boxstyle-lg.box5 .h5,
.brand-orange .boxstyle_.box5 .h6,
.brand-orange .boxstyle-xs.box5 .h6,
.brand-orange .boxstyle-sm.box5 .h6,
.brand-orange .boxstyle-md.box5 .h6,
.brand-orange .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-orange .boxstyle_.box5 blockquote.quotes,
.brand-orange .boxstyle-xs.box5 blockquote.quotes,
.brand-orange .boxstyle-sm.box5 blockquote.quotes,
.brand-orange .boxstyle-md.box5 blockquote.quotes,
.brand-orange .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-orange .boxstyle_.box5 a,
.brand-orange .boxstyle-xs.box5 a,
.brand-orange .boxstyle-sm.box5 a,
.brand-orange .boxstyle-md.box5 a,
.brand-orange .boxstyle-lg.box5 a,
.brand-orange .boxstyle_.box5 a:link,
.brand-orange .boxstyle-xs.box5 a:link,
.brand-orange .boxstyle-sm.box5 a:link,
.brand-orange .boxstyle-md.box5 a:link,
.brand-orange .boxstyle-lg.box5 a:link,
.brand-orange .boxstyle_.box5 a:visited,
.brand-orange .boxstyle-xs.box5 a:visited,
.brand-orange .boxstyle-sm.box5 a:visited,
.brand-orange .boxstyle-md.box5 a:visited,
.brand-orange .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-orange .boxstyle_.box5 a:hover,
.brand-orange .boxstyle-xs.box5 a:hover,
.brand-orange .boxstyle-sm.box5 a:hover,
.brand-orange .boxstyle-md.box5 a:hover,
.brand-orange .boxstyle-lg.box5 a:hover,
.brand-orange .boxstyle_.box5 a:focus,
.brand-orange .boxstyle-xs.box5 a:focus,
.brand-orange .boxstyle-sm.box5 a:focus,
.brand-orange .boxstyle-md.box5 a:focus,
.brand-orange .boxstyle-lg.box5 a:focus,
.brand-orange .boxstyle_.box5 a:active,
.brand-orange .boxstyle-xs.box5 a:active,
.brand-orange .boxstyle-sm.box5 a:active,
.brand-orange .boxstyle-md.box5 a:active,
.brand-orange .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-orange .boxstyle_.box5 .popover button.close,
.brand-orange .boxstyle-xs.box5 .popover button.close,
.brand-orange .boxstyle-sm.box5 .popover button.close,
.brand-orange .boxstyle-md.box5 .popover button.close,
.brand-orange .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-orange .boxstyle_.box5 .popover button.close:hover,
.brand-orange .boxstyle-xs.box5 .popover button.close:hover,
.brand-orange .boxstyle-sm.box5 .popover button.close:hover,
.brand-orange .boxstyle-md.box5 .popover button.close:hover,
.brand-orange .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-orange .boxstyle_.box5 a:hover .new-window-link,
.brand-orange .boxstyle-xs.box5 a:hover .new-window-link,
.brand-orange .boxstyle-sm.box5 a:hover .new-window-link,
.brand-orange .boxstyle-md.box5 a:hover .new-window-link,
.brand-orange .boxstyle-lg.box5 a:hover .new-window-link,
.brand-orange .boxstyle_.box5 a:hover .insecure-link,
.brand-orange .boxstyle-xs.box5 a:hover .insecure-link,
.brand-orange .boxstyle-sm.box5 a:hover .insecure-link,
.brand-orange .boxstyle-md.box5 a:hover .insecure-link,
.brand-orange .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-orange .boxstyle_.box5 .btn-link,
.brand-orange .boxstyle-xs.box5 .btn-link,
.brand-orange .boxstyle-sm.box5 .btn-link,
.brand-orange .boxstyle-md.box5 .btn-link,
.brand-orange .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-orange .boxstyle_.box5 .btn-link:hover,
.brand-orange .boxstyle-xs.box5 .btn-link:hover,
.brand-orange .boxstyle-sm.box5 .btn-link:hover,
.brand-orange .boxstyle-md.box5 .btn-link:hover,
.brand-orange .boxstyle-lg.box5 .btn-link:hover,
.brand-orange .boxstyle_.box5 .btn-link:focus,
.brand-orange .boxstyle-xs.box5 .btn-link:focus,
.brand-orange .boxstyle-sm.box5 .btn-link:focus,
.brand-orange .boxstyle-md.box5 .btn-link:focus,
.brand-orange .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-orange .boxstyle_.box5 a,
.brand-orange .boxstyle-xs.box5 a,
.brand-orange .boxstyle-sm.box5 a,
.brand-orange .boxstyle-md.box5 a,
.brand-orange .boxstyle-lg.box5 a,
.brand-orange .boxstyle_.box5 a:link,
.brand-orange .boxstyle-xs.box5 a:link,
.brand-orange .boxstyle-sm.box5 a:link,
.brand-orange .boxstyle-md.box5 a:link,
.brand-orange .boxstyle-lg.box5 a:link,
.brand-orange .boxstyle_.box5 a:visited,
.brand-orange .boxstyle-xs.box5 a:visited,
.brand-orange .boxstyle-sm.box5 a:visited,
.brand-orange .boxstyle-md.box5 a:visited,
.brand-orange .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-orange .boxstyle_.box5 a:hover,
.brand-orange .boxstyle-xs.box5 a:hover,
.brand-orange .boxstyle-sm.box5 a:hover,
.brand-orange .boxstyle-md.box5 a:hover,
.brand-orange .boxstyle-lg.box5 a:hover,
.brand-orange .boxstyle_.box5 a:focus,
.brand-orange .boxstyle-xs.box5 a:focus,
.brand-orange .boxstyle-sm.box5 a:focus,
.brand-orange .boxstyle-md.box5 a:focus,
.brand-orange .boxstyle-lg.box5 a:focus,
.brand-orange .boxstyle_.box5 a:active,
.brand-orange .boxstyle-xs.box5 a:active,
.brand-orange .boxstyle-sm.box5 a:active,
.brand-orange .boxstyle-md.box5 a:active,
.brand-orange .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-orange .pagination > li a,
.brand-orange .pagination > li span {
  color: #BE531C;
}
.brand-orange .pagination > li.active > a,
.brand-orange .pagination > li.active > span:not(.sr-only),
.brand-orange .pagination > li.active > a:hover,
.brand-orange .pagination > li.active > span:not(.sr-only):hover,
.brand-orange .pagination > li.active > a:focus,
.brand-orange .pagination > li.active > span:not(.sr-only):focus {
  background-color: #BE531C;
  border-color: #BE531C;
  color: white;
}
.brand-orange .nav-tabs {
  border-bottom-color: #dfa98e;
}
.brand-orange .nav-tabs > li > a {
  background-color: #f2ddd2;
  border-color: #dfa98e;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-orange .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #d89877;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-orange .nav-tabs > li > a:hover,
.brand-orange .nav-tabs > li > a:focus {
  background-color: #f9eee8;
  border-color: #e5baa4 #e5baa4 #dfa98e;
}
.brand-orange .nav-tabs > li > a:hover::before,
.brand-orange .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-orange .nav-tabs > li.open > a,
.brand-orange .nav-tabs > li.open > a:hover,
.brand-orange .nav-tabs > li.open > a:focus {
  background-color: #f9eee8;
  border-color: #e5baa4 #e5baa4 #dfa98e;
}
.brand-orange .nav-tabs > li.open > a::before,
.brand-orange .nav-tabs > li.open > a:hover::before,
.brand-orange .nav-tabs > li.open > a:focus::before {
  background: #BE531C;
  width: 100%;
}
.brand-orange .nav-tabs > li.active > a,
.brand-orange .nav-tabs > li.active > a:hover,
.brand-orange .nav-tabs > li.active > a:focus {
  border-color: #dfa98e #dfa98e transparent;
}
.brand-orange .nav-tabs > li.active > a::before,
.brand-orange .nav-tabs > li.active > a:hover::before,
.brand-orange .nav-tabs > li.active > a:focus::before {
  background: #BE531C;
  width: 100%;
}
.brand-orange .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-orange .nav-tabs > li.disabled > a:hover,
.brand-orange .nav-tabs > li.disabled > a:focus {
  background-color: #f2ddd2;
}
.brand-orange .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-orange .nav-pills > li.active > a,
.brand-orange .nav-pills > li.active > a:hover,
.brand-orange .nav-pills > li.active > a:focus {
  background-color: #BE531C;
  color: white;
}
.brand-orange .list-group-item.active,
.brand-orange .list-group-item.active:hover,
.brand-orange .list-group-item.active:focus {
  background-color: #BE531C;
  border-color: #BE531C;
  color: white;
}
.brand-orange .id7-koan-spinner__neon--top,
.brand-orange .id7-koan-spinner__neon--bottom {
  stroke: #BE531C;
}
.brand-orange-bright h1,
.brand-orange-bright h2,
.brand-orange-bright h3,
.brand-orange-bright h4,
.brand-orange-bright h5,
.brand-orange-bright h6,
.brand-orange-bright .h1,
.brand-orange-bright .h2,
.brand-orange-bright .h3,
.brand-orange-bright .h4,
.brand-orange-bright .h5,
.brand-orange-bright .h6 {
  color: #202020;
}
.brand-orange-bright a,
.brand-orange-bright a:link,
.brand-orange-bright a:visited {
  color: #202020;
}
.brand-orange-bright a:hover,
.brand-orange-bright a:focus,
.brand-orange-bright a:active {
  color: #000000;
}
.brand-orange-bright .popover button.close {
  color: #202020;
}
.brand-orange-bright .popover button.close:hover {
  color: #000000;
}
.brand-orange-bright a:hover .new-window-link,
.brand-orange-bright a:hover .insecure-link {
  color: #000000;
}
.brand-orange-bright .btn-link {
  color: #202020;
}
.brand-orange-bright .btn-link:hover,
.brand-orange-bright .btn-link:focus {
  color: #000000;
}
.brand-orange-bright a,
.brand-orange-bright a:link,
.brand-orange-bright a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange-bright a:hover,
.brand-orange-bright a:focus,
.brand-orange-bright a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-orange-bright blockquote.quotes {
  color: #95490f;
}
.brand-orange-bright blockquote.quotes::before {
  color: #95490f;
}
.brand-orange-bright .brand-bg {
  background: #E87722;
  color: #202020;
}
.brand-orange-bright .brand-bg a,
.brand-orange-bright .brand-bg a:link,
.brand-orange-bright .brand-bg a:visited {
  color: #202020 !important;
}
.brand-orange-bright .brand-bg a:hover,
.brand-orange-bright .brand-bg a:focus,
.brand-orange-bright .brand-bg a:active {
  color: #000000 !important;
}
.brand-orange-bright .brand-bg .popover button.close {
  color: #202020 !important;
}
.brand-orange-bright .brand-bg .popover button.close:hover {
  color: #000000 !important;
}
.brand-orange-bright .brand-bg a:hover .new-window-link,
.brand-orange-bright .brand-bg a:hover .insecure-link {
  color: #000000 !important;
}
.brand-orange-bright .brand-bg .btn-link {
  color: #202020 !important;
}
.brand-orange-bright .brand-bg .btn-link:hover,
.brand-orange-bright .brand-bg .btn-link:focus {
  color: #000000 !important;
}
.brand-orange-bright .brand-bg a,
.brand-orange-bright .brand-bg a:link,
.brand-orange-bright .brand-bg a:visited {
  -webkit-text-decoration-color: #636363 !important;
  text-decoration-color: #636363 !important;
}
.brand-orange-bright .brand-bg a:hover,
.brand-orange-bright .brand-bg a:focus,
.brand-orange-bright .brand-bg a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-orange-bright .brand-text {
  color: #E87722;
}
.brand-orange-bright .brand-text a,
.brand-orange-bright .brand-text a:link,
.brand-orange-bright .brand-text a:visited {
  color: #E87722 !important;
}
.brand-orange-bright .brand-text a:hover,
.brand-orange-bright .brand-text a:focus,
.brand-orange-bright .brand-text a:active {
  color: #95490f !important;
}
.brand-orange-bright .brand-text .popover button.close {
  color: #E87722 !important;
}
.brand-orange-bright .brand-text .popover button.close:hover {
  color: #95490f !important;
}
.brand-orange-bright .brand-text a:hover .new-window-link,
.brand-orange-bright .brand-text a:hover .insecure-link {
  color: #95490f !important;
}
.brand-orange-bright .brand-text .btn-link {
  color: #E87722 !important;
}
.brand-orange-bright .brand-text .btn-link:hover,
.brand-orange-bright .brand-text .btn-link:focus {
  color: #95490f !important;
}
.brand-orange-bright .brand-text a,
.brand-orange-bright .brand-text a:link,
.brand-orange-bright .brand-text a:visited {
  -webkit-text-decoration-color: #efa064 !important;
  text-decoration-color: #efa064 !important;
}
.brand-orange-bright .brand-text a:hover,
.brand-orange-bright .brand-text a:focus,
.brand-orange-bright .brand-text a:active {
  -webkit-text-decoration-color: #95490f !important;
  text-decoration-color: #95490f !important;
}
.brand-orange-bright .brand-border {
  border-color: #E87722;
}
.brand-orange-bright .btn-brand {
  color: #202020;
  background-color: #E87722;
  border-color: #da6a17;
  color: #202020 !important;
}
.brand-orange-bright .btn-brand:focus,
.brand-orange-bright .btn-brand.focus {
  color: #202020;
  background-color: #c35f14;
  border-color: #66320b;
}
.brand-orange-bright .btn-brand:hover {
  color: #202020;
  background-color: #c35f14;
  border-color: #a24f11;
}
.brand-orange-bright .btn-brand:active,
.brand-orange-bright .btn-brand.active,
.open > .dropdown-toggle.brand-orange-bright .btn-brand {
  color: #202020;
  background-color: #c35f14;
  background-image: none;
  border-color: #a24f11;
}
.brand-orange-bright .btn-brand:active:hover,
.brand-orange-bright .btn-brand.active:hover,
.open > .dropdown-toggle.brand-orange-bright .btn-brand:hover,
.brand-orange-bright .btn-brand:active:focus,
.brand-orange-bright .btn-brand.active:focus,
.open > .dropdown-toggle.brand-orange-bright .btn-brand:focus,
.brand-orange-bright .btn-brand:active.focus,
.brand-orange-bright .btn-brand.active.focus,
.open > .dropdown-toggle.brand-orange-bright .btn-brand.focus {
  color: #202020;
  background-color: #a24f11;
  border-color: #66320b;
}
.brand-orange-bright .btn-brand.disabled:hover,
.brand-orange-bright .btn-brand[disabled]:hover,
fieldset[disabled] .brand-orange-bright .btn-brand:hover,
.brand-orange-bright .btn-brand.disabled:focus,
.brand-orange-bright .btn-brand[disabled]:focus,
fieldset[disabled] .brand-orange-bright .btn-brand:focus,
.brand-orange-bright .btn-brand.disabled.focus,
.brand-orange-bright .btn-brand[disabled].focus,
fieldset[disabled] .brand-orange-bright .btn-brand.focus {
  background-color: #E87722;
  border-color: #da6a17;
}
.brand-orange-bright .btn-brand .badge {
  color: #E87722;
  background-color: #202020;
}
.brand-orange-bright .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-orange-bright .id7-utility-masthead {
  background-color: #efa064;
}
.brand-orange-bright .id7-utility-masthead::after {
  background-color: #E87722;
}
body.id7-point-1 .brand-orange-bright .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-orange-bright .id7-navigation .navbar-primary {
  background-color: #E87722;
}
.brand-orange-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-orange-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-text {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #f5c6a2;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: #202020;
  background-color: #ba5f1b;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #f5c6a2;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #ba5f1b;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #ba5f1b;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #E87722;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #ba5f1b;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #ba5f1b;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #ba5f1b;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #ba5f1b;
  background-image: none;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #f5c6a2;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-orange-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #ba5f1b;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-link {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .navbar-link:hover {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .btn-link {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-orange-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-orange-bright .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-orange-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-orange-bright .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-orange-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-orange-bright .navbar-primary .navbar-brand {
  background-color: #E87722;
}
.brand-orange-bright .navbar-secondary {
  background-color: #f5c6a2;
}
.brand-orange-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-orange-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-orange-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li:hover,
.brand-orange-bright .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #f8d7be;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li > a,
.brand-orange-bright .navbar-secondary .navbar-nav > li > a:hover,
.brand-orange-bright .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-orange-bright .navbar-secondary .navbar-nav > .disabled,
.brand-orange-bright .navbar-secondary .navbar-nav > .disabled:hover,
.brand-orange-bright .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #f8d7be;
}
.brand-orange-bright .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-orange-bright .navbar-secondary .navbar-toggle:hover,
.brand-orange-bright .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-orange-bright .navbar-secondary .navbar-nav > .open,
.brand-orange-bright .navbar-secondary .navbar-nav > .open:hover,
.brand-orange-bright .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #f8d7be;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #f5c6a2;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c49e82;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #c49e82;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #f8d7be;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #f8d7be;
  background-image: none;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-orange-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #f8d7be;
}
.brand-orange-bright .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .btn-link {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .btn-link:hover,
.brand-orange-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-orange-bright .navbar-secondary .btn-link:hover,
.brand-orange-bright .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-orange-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li::after {
  color: #E87722;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li:first-child {
  background-color: #E87722;
  color: white;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #E87722;
}
.brand-orange-bright .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #E87722;
}
.brand-orange-bright .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-orange-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-orange-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > li:hover,
.brand-orange-bright .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > li > a,
.brand-orange-bright .navbar-tertiary .navbar-nav > li > a:hover,
.brand-orange-bright .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > .disabled,
.brand-orange-bright .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-orange-bright .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange-bright .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .navbar-toggle:hover,
.brand-orange-bright .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-orange-bright .navbar-tertiary .navbar-nav > .open,
.brand-orange-bright .navbar-tertiary .navbar-nav > .open:hover,
.brand-orange-bright .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-orange-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-orange-bright .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .btn-link:hover,
.brand-orange-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-orange-bright .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-orange-bright .navbar-tertiary .btn-link:hover,
.brand-orange-bright .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-orange-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-orange-bright.bordered .id7-left-border,
.brand-orange-bright.bordered .id7-right-border {
  border-color: rgba(232, 119, 34, 0.3);
}
.brand-orange-bright .id7-page-footer .id7-app-footer {
  background: #E87722;
  color: white;
}
.brand-orange-bright .id7-page-footer .id7-app-footer h1,
.brand-orange-bright .id7-page-footer .id7-app-footer h2,
.brand-orange-bright .id7-page-footer .id7-app-footer h3,
.brand-orange-bright .id7-page-footer .id7-app-footer h4,
.brand-orange-bright .id7-page-footer .id7-app-footer h5,
.brand-orange-bright .id7-page-footer .id7-app-footer h6,
.brand-orange-bright .id7-page-footer .id7-app-footer .h1,
.brand-orange-bright .id7-page-footer .id7-app-footer .h2,
.brand-orange-bright .id7-page-footer .id7-app-footer .h3,
.brand-orange-bright .id7-page-footer .id7-app-footer .h4,
.brand-orange-bright .id7-page-footer .id7-app-footer .h5,
.brand-orange-bright .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-orange-bright .id7-page-footer .id7-app-footer a,
.brand-orange-bright .id7-page-footer .id7-app-footer a:link,
.brand-orange-bright .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-orange-bright .id7-page-footer .id7-app-footer a:hover,
.brand-orange-bright .id7-page-footer .id7-app-footer a:focus,
.brand-orange-bright .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-orange-bright .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-orange-bright .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-orange-bright .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-orange-bright .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-orange-bright .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-orange-bright .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-orange-bright .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-orange-bright .id7-page-footer .id7-app-footer a,
.brand-orange-bright .id7-page-footer .id7-app-footer a:link,
.brand-orange-bright .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-orange-bright .id7-page-footer .id7-app-footer a:hover,
.brand-orange-bright .id7-page-footer .id7-app-footer a:focus,
.brand-orange-bright .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-orange-bright .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #E87722;
}
.brand-orange-bright .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-orange-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-orange-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-orange-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-orange-bright .id7-search .fa,
.brand-orange-bright .id7-search .fas {
  color: #E87722;
  color: rgba(232, 119, 34, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-orange-bright .id7-search .form-control:hover + .fa,
.brand-orange-bright .id7-search .typeahead:hover + .fa,
.brand-orange-bright .id7-search .fa:hover,
.brand-orange-bright .id7-search .form-control:hover + .fas,
.brand-orange-bright .id7-search .typeahead:hover + .fas,
.brand-orange-bright .id7-search .fas:hover {
  color: #E87722;
}
.brand-orange-bright .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #E87722;
  color: white;
}
.brand-orange-bright .form-control:focus {
  border-color: #E87722;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(232, 119, 34, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(232, 119, 34, 0.6);
}
.brand-orange-bright .carousel .carousel-indicators li::after {
  color: #E87722;
  color: rgba(232, 119, 34, 0.3);
}
.brand-orange-bright .carousel .carousel-indicators li.active::after {
  color: #E87722;
}
@media (max-width: 767px) {
  .brand-orange-bright .boxstyle_.box1,
  .brand-orange-bright .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-orange-bright .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-orange-bright .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-orange-bright .boxstyle_.box1,
.brand-orange-bright .boxstyle-xs.box1,
.brand-orange-bright .boxstyle-sm.box1,
.brand-orange-bright .boxstyle-md.box1,
.brand-orange-bright .boxstyle-lg.box1 {
  border: 0;
  background: #fcebde;
  color: #202020;
}
@media print {
  .brand-orange-bright .boxstyle_.box1,
  .brand-orange-bright .boxstyle-xs.box1,
  .brand-orange-bright .boxstyle-sm.box1,
  .brand-orange-bright .boxstyle-md.box1,
  .brand-orange-bright .boxstyle-lg.box1 {
    border: 1px solid #fcebde;
  }
}
.brand-orange-bright .boxstyle_.box1 h1,
.brand-orange-bright .boxstyle-xs.box1 h1,
.brand-orange-bright .boxstyle-sm.box1 h1,
.brand-orange-bright .boxstyle-md.box1 h1,
.brand-orange-bright .boxstyle-lg.box1 h1,
.brand-orange-bright .boxstyle_.box1 h2,
.brand-orange-bright .boxstyle-xs.box1 h2,
.brand-orange-bright .boxstyle-sm.box1 h2,
.brand-orange-bright .boxstyle-md.box1 h2,
.brand-orange-bright .boxstyle-lg.box1 h2,
.brand-orange-bright .boxstyle_.box1 h3,
.brand-orange-bright .boxstyle-xs.box1 h3,
.brand-orange-bright .boxstyle-sm.box1 h3,
.brand-orange-bright .boxstyle-md.box1 h3,
.brand-orange-bright .boxstyle-lg.box1 h3,
.brand-orange-bright .boxstyle_.box1 h4,
.brand-orange-bright .boxstyle-xs.box1 h4,
.brand-orange-bright .boxstyle-sm.box1 h4,
.brand-orange-bright .boxstyle-md.box1 h4,
.brand-orange-bright .boxstyle-lg.box1 h4,
.brand-orange-bright .boxstyle_.box1 h5,
.brand-orange-bright .boxstyle-xs.box1 h5,
.brand-orange-bright .boxstyle-sm.box1 h5,
.brand-orange-bright .boxstyle-md.box1 h5,
.brand-orange-bright .boxstyle-lg.box1 h5,
.brand-orange-bright .boxstyle_.box1 h6,
.brand-orange-bright .boxstyle-xs.box1 h6,
.brand-orange-bright .boxstyle-sm.box1 h6,
.brand-orange-bright .boxstyle-md.box1 h6,
.brand-orange-bright .boxstyle-lg.box1 h6,
.brand-orange-bright .boxstyle_.box1 .h1,
.brand-orange-bright .boxstyle-xs.box1 .h1,
.brand-orange-bright .boxstyle-sm.box1 .h1,
.brand-orange-bright .boxstyle-md.box1 .h1,
.brand-orange-bright .boxstyle-lg.box1 .h1,
.brand-orange-bright .boxstyle_.box1 .h2,
.brand-orange-bright .boxstyle-xs.box1 .h2,
.brand-orange-bright .boxstyle-sm.box1 .h2,
.brand-orange-bright .boxstyle-md.box1 .h2,
.brand-orange-bright .boxstyle-lg.box1 .h2,
.brand-orange-bright .boxstyle_.box1 .h3,
.brand-orange-bright .boxstyle-xs.box1 .h3,
.brand-orange-bright .boxstyle-sm.box1 .h3,
.brand-orange-bright .boxstyle-md.box1 .h3,
.brand-orange-bright .boxstyle-lg.box1 .h3,
.brand-orange-bright .boxstyle_.box1 .h4,
.brand-orange-bright .boxstyle-xs.box1 .h4,
.brand-orange-bright .boxstyle-sm.box1 .h4,
.brand-orange-bright .boxstyle-md.box1 .h4,
.brand-orange-bright .boxstyle-lg.box1 .h4,
.brand-orange-bright .boxstyle_.box1 .h5,
.brand-orange-bright .boxstyle-xs.box1 .h5,
.brand-orange-bright .boxstyle-sm.box1 .h5,
.brand-orange-bright .boxstyle-md.box1 .h5,
.brand-orange-bright .boxstyle-lg.box1 .h5,
.brand-orange-bright .boxstyle_.box1 .h6,
.brand-orange-bright .boxstyle-xs.box1 .h6,
.brand-orange-bright .boxstyle-sm.box1 .h6,
.brand-orange-bright .boxstyle-md.box1 .h6,
.brand-orange-bright .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box1 blockquote.quotes,
.brand-orange-bright .boxstyle-xs.box1 blockquote.quotes,
.brand-orange-bright .boxstyle-sm.box1 blockquote.quotes,
.brand-orange-bright .boxstyle-md.box1 blockquote.quotes,
.brand-orange-bright .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-orange-bright .boxstyle_.box1 a,
.brand-orange-bright .boxstyle-xs.box1 a,
.brand-orange-bright .boxstyle-sm.box1 a,
.brand-orange-bright .boxstyle-md.box1 a,
.brand-orange-bright .boxstyle-lg.box1 a,
.brand-orange-bright .boxstyle_.box1 a:link,
.brand-orange-bright .boxstyle-xs.box1 a:link,
.brand-orange-bright .boxstyle-sm.box1 a:link,
.brand-orange-bright .boxstyle-md.box1 a:link,
.brand-orange-bright .boxstyle-lg.box1 a:link,
.brand-orange-bright .boxstyle_.box1 a:visited,
.brand-orange-bright .boxstyle-xs.box1 a:visited,
.brand-orange-bright .boxstyle-sm.box1 a:visited,
.brand-orange-bright .boxstyle-md.box1 a:visited,
.brand-orange-bright .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box1 a:hover,
.brand-orange-bright .boxstyle-xs.box1 a:hover,
.brand-orange-bright .boxstyle-sm.box1 a:hover,
.brand-orange-bright .boxstyle-md.box1 a:hover,
.brand-orange-bright .boxstyle-lg.box1 a:hover,
.brand-orange-bright .boxstyle_.box1 a:focus,
.brand-orange-bright .boxstyle-xs.box1 a:focus,
.brand-orange-bright .boxstyle-sm.box1 a:focus,
.brand-orange-bright .boxstyle-md.box1 a:focus,
.brand-orange-bright .boxstyle-lg.box1 a:focus,
.brand-orange-bright .boxstyle_.box1 a:active,
.brand-orange-bright .boxstyle-xs.box1 a:active,
.brand-orange-bright .boxstyle-sm.box1 a:active,
.brand-orange-bright .boxstyle-md.box1 a:active,
.brand-orange-bright .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box1 .popover button.close,
.brand-orange-bright .boxstyle-xs.box1 .popover button.close,
.brand-orange-bright .boxstyle-sm.box1 .popover button.close,
.brand-orange-bright .boxstyle-md.box1 .popover button.close,
.brand-orange-bright .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box1 .popover button.close:hover,
.brand-orange-bright .boxstyle-xs.box1 .popover button.close:hover,
.brand-orange-bright .boxstyle-sm.box1 .popover button.close:hover,
.brand-orange-bright .boxstyle-md.box1 .popover button.close:hover,
.brand-orange-bright .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box1 a:hover .new-window-link,
.brand-orange-bright .boxstyle-xs.box1 a:hover .new-window-link,
.brand-orange-bright .boxstyle-sm.box1 a:hover .new-window-link,
.brand-orange-bright .boxstyle-md.box1 a:hover .new-window-link,
.brand-orange-bright .boxstyle-lg.box1 a:hover .new-window-link,
.brand-orange-bright .boxstyle_.box1 a:hover .insecure-link,
.brand-orange-bright .boxstyle-xs.box1 a:hover .insecure-link,
.brand-orange-bright .boxstyle-sm.box1 a:hover .insecure-link,
.brand-orange-bright .boxstyle-md.box1 a:hover .insecure-link,
.brand-orange-bright .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box1 .btn-link,
.brand-orange-bright .boxstyle-xs.box1 .btn-link,
.brand-orange-bright .boxstyle-sm.box1 .btn-link,
.brand-orange-bright .boxstyle-md.box1 .btn-link,
.brand-orange-bright .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box1 .btn-link:hover,
.brand-orange-bright .boxstyle-xs.box1 .btn-link:hover,
.brand-orange-bright .boxstyle-sm.box1 .btn-link:hover,
.brand-orange-bright .boxstyle-md.box1 .btn-link:hover,
.brand-orange-bright .boxstyle-lg.box1 .btn-link:hover,
.brand-orange-bright .boxstyle_.box1 .btn-link:focus,
.brand-orange-bright .boxstyle-xs.box1 .btn-link:focus,
.brand-orange-bright .boxstyle-sm.box1 .btn-link:focus,
.brand-orange-bright .boxstyle-md.box1 .btn-link:focus,
.brand-orange-bright .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box1 a,
.brand-orange-bright .boxstyle-xs.box1 a,
.brand-orange-bright .boxstyle-sm.box1 a,
.brand-orange-bright .boxstyle-md.box1 a,
.brand-orange-bright .boxstyle-lg.box1 a,
.brand-orange-bright .boxstyle_.box1 a:link,
.brand-orange-bright .boxstyle-xs.box1 a:link,
.brand-orange-bright .boxstyle-sm.box1 a:link,
.brand-orange-bright .boxstyle-md.box1 a:link,
.brand-orange-bright .boxstyle-lg.box1 a:link,
.brand-orange-bright .boxstyle_.box1 a:visited,
.brand-orange-bright .boxstyle-xs.box1 a:visited,
.brand-orange-bright .boxstyle-sm.box1 a:visited,
.brand-orange-bright .boxstyle-md.box1 a:visited,
.brand-orange-bright .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange-bright .boxstyle_.box1 a:hover,
.brand-orange-bright .boxstyle-xs.box1 a:hover,
.brand-orange-bright .boxstyle-sm.box1 a:hover,
.brand-orange-bright .boxstyle-md.box1 a:hover,
.brand-orange-bright .boxstyle-lg.box1 a:hover,
.brand-orange-bright .boxstyle_.box1 a:focus,
.brand-orange-bright .boxstyle-xs.box1 a:focus,
.brand-orange-bright .boxstyle-sm.box1 a:focus,
.brand-orange-bright .boxstyle-md.box1 a:focus,
.brand-orange-bright .boxstyle-lg.box1 a:focus,
.brand-orange-bright .boxstyle_.box1 a:active,
.brand-orange-bright .boxstyle-xs.box1 a:active,
.brand-orange-bright .boxstyle-sm.box1 a:active,
.brand-orange-bright .boxstyle-md.box1 a:active,
.brand-orange-bright .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-orange-bright .boxstyle_.box2,
  .brand-orange-bright .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-orange-bright .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-orange-bright .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-orange-bright .boxstyle_.box2,
.brand-orange-bright .boxstyle-xs.box2,
.brand-orange-bright .boxstyle-sm.box2,
.brand-orange-bright .boxstyle-md.box2,
.brand-orange-bright .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-orange-bright .boxstyle_.box2,
  .brand-orange-bright .boxstyle-xs.box2,
  .brand-orange-bright .boxstyle-sm.box2,
  .brand-orange-bright .boxstyle-md.box2,
  .brand-orange-bright .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-orange-bright .boxstyle_.box2 h1,
.brand-orange-bright .boxstyle-xs.box2 h1,
.brand-orange-bright .boxstyle-sm.box2 h1,
.brand-orange-bright .boxstyle-md.box2 h1,
.brand-orange-bright .boxstyle-lg.box2 h1,
.brand-orange-bright .boxstyle_.box2 h2,
.brand-orange-bright .boxstyle-xs.box2 h2,
.brand-orange-bright .boxstyle-sm.box2 h2,
.brand-orange-bright .boxstyle-md.box2 h2,
.brand-orange-bright .boxstyle-lg.box2 h2,
.brand-orange-bright .boxstyle_.box2 h3,
.brand-orange-bright .boxstyle-xs.box2 h3,
.brand-orange-bright .boxstyle-sm.box2 h3,
.brand-orange-bright .boxstyle-md.box2 h3,
.brand-orange-bright .boxstyle-lg.box2 h3,
.brand-orange-bright .boxstyle_.box2 h4,
.brand-orange-bright .boxstyle-xs.box2 h4,
.brand-orange-bright .boxstyle-sm.box2 h4,
.brand-orange-bright .boxstyle-md.box2 h4,
.brand-orange-bright .boxstyle-lg.box2 h4,
.brand-orange-bright .boxstyle_.box2 h5,
.brand-orange-bright .boxstyle-xs.box2 h5,
.brand-orange-bright .boxstyle-sm.box2 h5,
.brand-orange-bright .boxstyle-md.box2 h5,
.brand-orange-bright .boxstyle-lg.box2 h5,
.brand-orange-bright .boxstyle_.box2 h6,
.brand-orange-bright .boxstyle-xs.box2 h6,
.brand-orange-bright .boxstyle-sm.box2 h6,
.brand-orange-bright .boxstyle-md.box2 h6,
.brand-orange-bright .boxstyle-lg.box2 h6,
.brand-orange-bright .boxstyle_.box2 .h1,
.brand-orange-bright .boxstyle-xs.box2 .h1,
.brand-orange-bright .boxstyle-sm.box2 .h1,
.brand-orange-bright .boxstyle-md.box2 .h1,
.brand-orange-bright .boxstyle-lg.box2 .h1,
.brand-orange-bright .boxstyle_.box2 .h2,
.brand-orange-bright .boxstyle-xs.box2 .h2,
.brand-orange-bright .boxstyle-sm.box2 .h2,
.brand-orange-bright .boxstyle-md.box2 .h2,
.brand-orange-bright .boxstyle-lg.box2 .h2,
.brand-orange-bright .boxstyle_.box2 .h3,
.brand-orange-bright .boxstyle-xs.box2 .h3,
.brand-orange-bright .boxstyle-sm.box2 .h3,
.brand-orange-bright .boxstyle-md.box2 .h3,
.brand-orange-bright .boxstyle-lg.box2 .h3,
.brand-orange-bright .boxstyle_.box2 .h4,
.brand-orange-bright .boxstyle-xs.box2 .h4,
.brand-orange-bright .boxstyle-sm.box2 .h4,
.brand-orange-bright .boxstyle-md.box2 .h4,
.brand-orange-bright .boxstyle-lg.box2 .h4,
.brand-orange-bright .boxstyle_.box2 .h5,
.brand-orange-bright .boxstyle-xs.box2 .h5,
.brand-orange-bright .boxstyle-sm.box2 .h5,
.brand-orange-bright .boxstyle-md.box2 .h5,
.brand-orange-bright .boxstyle-lg.box2 .h5,
.brand-orange-bright .boxstyle_.box2 .h6,
.brand-orange-bright .boxstyle-xs.box2 .h6,
.brand-orange-bright .boxstyle-sm.box2 .h6,
.brand-orange-bright .boxstyle-md.box2 .h6,
.brand-orange-bright .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box2 blockquote.quotes,
.brand-orange-bright .boxstyle-xs.box2 blockquote.quotes,
.brand-orange-bright .boxstyle-sm.box2 blockquote.quotes,
.brand-orange-bright .boxstyle-md.box2 blockquote.quotes,
.brand-orange-bright .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-orange-bright .boxstyle_.box2 a,
.brand-orange-bright .boxstyle-xs.box2 a,
.brand-orange-bright .boxstyle-sm.box2 a,
.brand-orange-bright .boxstyle-md.box2 a,
.brand-orange-bright .boxstyle-lg.box2 a,
.brand-orange-bright .boxstyle_.box2 a:link,
.brand-orange-bright .boxstyle-xs.box2 a:link,
.brand-orange-bright .boxstyle-sm.box2 a:link,
.brand-orange-bright .boxstyle-md.box2 a:link,
.brand-orange-bright .boxstyle-lg.box2 a:link,
.brand-orange-bright .boxstyle_.box2 a:visited,
.brand-orange-bright .boxstyle-xs.box2 a:visited,
.brand-orange-bright .boxstyle-sm.box2 a:visited,
.brand-orange-bright .boxstyle-md.box2 a:visited,
.brand-orange-bright .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box2 a:hover,
.brand-orange-bright .boxstyle-xs.box2 a:hover,
.brand-orange-bright .boxstyle-sm.box2 a:hover,
.brand-orange-bright .boxstyle-md.box2 a:hover,
.brand-orange-bright .boxstyle-lg.box2 a:hover,
.brand-orange-bright .boxstyle_.box2 a:focus,
.brand-orange-bright .boxstyle-xs.box2 a:focus,
.brand-orange-bright .boxstyle-sm.box2 a:focus,
.brand-orange-bright .boxstyle-md.box2 a:focus,
.brand-orange-bright .boxstyle-lg.box2 a:focus,
.brand-orange-bright .boxstyle_.box2 a:active,
.brand-orange-bright .boxstyle-xs.box2 a:active,
.brand-orange-bright .boxstyle-sm.box2 a:active,
.brand-orange-bright .boxstyle-md.box2 a:active,
.brand-orange-bright .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box2 .popover button.close,
.brand-orange-bright .boxstyle-xs.box2 .popover button.close,
.brand-orange-bright .boxstyle-sm.box2 .popover button.close,
.brand-orange-bright .boxstyle-md.box2 .popover button.close,
.brand-orange-bright .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box2 .popover button.close:hover,
.brand-orange-bright .boxstyle-xs.box2 .popover button.close:hover,
.brand-orange-bright .boxstyle-sm.box2 .popover button.close:hover,
.brand-orange-bright .boxstyle-md.box2 .popover button.close:hover,
.brand-orange-bright .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box2 a:hover .new-window-link,
.brand-orange-bright .boxstyle-xs.box2 a:hover .new-window-link,
.brand-orange-bright .boxstyle-sm.box2 a:hover .new-window-link,
.brand-orange-bright .boxstyle-md.box2 a:hover .new-window-link,
.brand-orange-bright .boxstyle-lg.box2 a:hover .new-window-link,
.brand-orange-bright .boxstyle_.box2 a:hover .insecure-link,
.brand-orange-bright .boxstyle-xs.box2 a:hover .insecure-link,
.brand-orange-bright .boxstyle-sm.box2 a:hover .insecure-link,
.brand-orange-bright .boxstyle-md.box2 a:hover .insecure-link,
.brand-orange-bright .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box2 .btn-link,
.brand-orange-bright .boxstyle-xs.box2 .btn-link,
.brand-orange-bright .boxstyle-sm.box2 .btn-link,
.brand-orange-bright .boxstyle-md.box2 .btn-link,
.brand-orange-bright .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box2 .btn-link:hover,
.brand-orange-bright .boxstyle-xs.box2 .btn-link:hover,
.brand-orange-bright .boxstyle-sm.box2 .btn-link:hover,
.brand-orange-bright .boxstyle-md.box2 .btn-link:hover,
.brand-orange-bright .boxstyle-lg.box2 .btn-link:hover,
.brand-orange-bright .boxstyle_.box2 .btn-link:focus,
.brand-orange-bright .boxstyle-xs.box2 .btn-link:focus,
.brand-orange-bright .boxstyle-sm.box2 .btn-link:focus,
.brand-orange-bright .boxstyle-md.box2 .btn-link:focus,
.brand-orange-bright .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box2 a,
.brand-orange-bright .boxstyle-xs.box2 a,
.brand-orange-bright .boxstyle-sm.box2 a,
.brand-orange-bright .boxstyle-md.box2 a,
.brand-orange-bright .boxstyle-lg.box2 a,
.brand-orange-bright .boxstyle_.box2 a:link,
.brand-orange-bright .boxstyle-xs.box2 a:link,
.brand-orange-bright .boxstyle-sm.box2 a:link,
.brand-orange-bright .boxstyle-md.box2 a:link,
.brand-orange-bright .boxstyle-lg.box2 a:link,
.brand-orange-bright .boxstyle_.box2 a:visited,
.brand-orange-bright .boxstyle-xs.box2 a:visited,
.brand-orange-bright .boxstyle-sm.box2 a:visited,
.brand-orange-bright .boxstyle-md.box2 a:visited,
.brand-orange-bright .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange-bright .boxstyle_.box2 a:hover,
.brand-orange-bright .boxstyle-xs.box2 a:hover,
.brand-orange-bright .boxstyle-sm.box2 a:hover,
.brand-orange-bright .boxstyle-md.box2 a:hover,
.brand-orange-bright .boxstyle-lg.box2 a:hover,
.brand-orange-bright .boxstyle_.box2 a:focus,
.brand-orange-bright .boxstyle-xs.box2 a:focus,
.brand-orange-bright .boxstyle-sm.box2 a:focus,
.brand-orange-bright .boxstyle-md.box2 a:focus,
.brand-orange-bright .boxstyle-lg.box2 a:focus,
.brand-orange-bright .boxstyle_.box2 a:active,
.brand-orange-bright .boxstyle-xs.box2 a:active,
.brand-orange-bright .boxstyle-sm.box2 a:active,
.brand-orange-bright .boxstyle-md.box2 a:active,
.brand-orange-bright .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-orange-bright .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #E87722;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-orange-bright .boxstyle_.box3,
  .brand-orange-bright .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #E87722;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-orange-bright .boxstyle-md.box3 {
    background: none;
    border: 1px solid #E87722;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-orange-bright .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #E87722;
    color: inherit;
  }
}
.brand-orange-bright .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-orange-bright .boxstyle_.box4,
  .brand-orange-bright .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-orange-bright .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-orange-bright .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-orange-bright .boxstyle_.box5,
  .brand-orange-bright .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-orange-bright .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-orange-bright .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-orange-bright .boxstyle_.box5,
.brand-orange-bright .boxstyle-xs.box5,
.brand-orange-bright .boxstyle-sm.box5,
.brand-orange-bright .boxstyle-md.box5,
.brand-orange-bright .boxstyle-lg.box5 {
  border: 0;
  background: #E87722;
  color: #202020;
}
@media print {
  .brand-orange-bright .boxstyle_.box5,
  .brand-orange-bright .boxstyle-xs.box5,
  .brand-orange-bright .boxstyle-sm.box5,
  .brand-orange-bright .boxstyle-md.box5,
  .brand-orange-bright .boxstyle-lg.box5 {
    border: 1px solid #E87722;
  }
}
.brand-orange-bright .boxstyle_.box5 h1,
.brand-orange-bright .boxstyle-xs.box5 h1,
.brand-orange-bright .boxstyle-sm.box5 h1,
.brand-orange-bright .boxstyle-md.box5 h1,
.brand-orange-bright .boxstyle-lg.box5 h1,
.brand-orange-bright .boxstyle_.box5 h2,
.brand-orange-bright .boxstyle-xs.box5 h2,
.brand-orange-bright .boxstyle-sm.box5 h2,
.brand-orange-bright .boxstyle-md.box5 h2,
.brand-orange-bright .boxstyle-lg.box5 h2,
.brand-orange-bright .boxstyle_.box5 h3,
.brand-orange-bright .boxstyle-xs.box5 h3,
.brand-orange-bright .boxstyle-sm.box5 h3,
.brand-orange-bright .boxstyle-md.box5 h3,
.brand-orange-bright .boxstyle-lg.box5 h3,
.brand-orange-bright .boxstyle_.box5 h4,
.brand-orange-bright .boxstyle-xs.box5 h4,
.brand-orange-bright .boxstyle-sm.box5 h4,
.brand-orange-bright .boxstyle-md.box5 h4,
.brand-orange-bright .boxstyle-lg.box5 h4,
.brand-orange-bright .boxstyle_.box5 h5,
.brand-orange-bright .boxstyle-xs.box5 h5,
.brand-orange-bright .boxstyle-sm.box5 h5,
.brand-orange-bright .boxstyle-md.box5 h5,
.brand-orange-bright .boxstyle-lg.box5 h5,
.brand-orange-bright .boxstyle_.box5 h6,
.brand-orange-bright .boxstyle-xs.box5 h6,
.brand-orange-bright .boxstyle-sm.box5 h6,
.brand-orange-bright .boxstyle-md.box5 h6,
.brand-orange-bright .boxstyle-lg.box5 h6,
.brand-orange-bright .boxstyle_.box5 .h1,
.brand-orange-bright .boxstyle-xs.box5 .h1,
.brand-orange-bright .boxstyle-sm.box5 .h1,
.brand-orange-bright .boxstyle-md.box5 .h1,
.brand-orange-bright .boxstyle-lg.box5 .h1,
.brand-orange-bright .boxstyle_.box5 .h2,
.brand-orange-bright .boxstyle-xs.box5 .h2,
.brand-orange-bright .boxstyle-sm.box5 .h2,
.brand-orange-bright .boxstyle-md.box5 .h2,
.brand-orange-bright .boxstyle-lg.box5 .h2,
.brand-orange-bright .boxstyle_.box5 .h3,
.brand-orange-bright .boxstyle-xs.box5 .h3,
.brand-orange-bright .boxstyle-sm.box5 .h3,
.brand-orange-bright .boxstyle-md.box5 .h3,
.brand-orange-bright .boxstyle-lg.box5 .h3,
.brand-orange-bright .boxstyle_.box5 .h4,
.brand-orange-bright .boxstyle-xs.box5 .h4,
.brand-orange-bright .boxstyle-sm.box5 .h4,
.brand-orange-bright .boxstyle-md.box5 .h4,
.brand-orange-bright .boxstyle-lg.box5 .h4,
.brand-orange-bright .boxstyle_.box5 .h5,
.brand-orange-bright .boxstyle-xs.box5 .h5,
.brand-orange-bright .boxstyle-sm.box5 .h5,
.brand-orange-bright .boxstyle-md.box5 .h5,
.brand-orange-bright .boxstyle-lg.box5 .h5,
.brand-orange-bright .boxstyle_.box5 .h6,
.brand-orange-bright .boxstyle-xs.box5 .h6,
.brand-orange-bright .boxstyle-sm.box5 .h6,
.brand-orange-bright .boxstyle-md.box5 .h6,
.brand-orange-bright .boxstyle-lg.box5 .h6 {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box5 blockquote.quotes,
.brand-orange-bright .boxstyle-xs.box5 blockquote.quotes,
.brand-orange-bright .boxstyle-sm.box5 blockquote.quotes,
.brand-orange-bright .boxstyle-md.box5 blockquote.quotes,
.brand-orange-bright .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-orange-bright .boxstyle_.box5 a,
.brand-orange-bright .boxstyle-xs.box5 a,
.brand-orange-bright .boxstyle-sm.box5 a,
.brand-orange-bright .boxstyle-md.box5 a,
.brand-orange-bright .boxstyle-lg.box5 a,
.brand-orange-bright .boxstyle_.box5 a:link,
.brand-orange-bright .boxstyle-xs.box5 a:link,
.brand-orange-bright .boxstyle-sm.box5 a:link,
.brand-orange-bright .boxstyle-md.box5 a:link,
.brand-orange-bright .boxstyle-lg.box5 a:link,
.brand-orange-bright .boxstyle_.box5 a:visited,
.brand-orange-bright .boxstyle-xs.box5 a:visited,
.brand-orange-bright .boxstyle-sm.box5 a:visited,
.brand-orange-bright .boxstyle-md.box5 a:visited,
.brand-orange-bright .boxstyle-lg.box5 a:visited {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box5 a:hover,
.brand-orange-bright .boxstyle-xs.box5 a:hover,
.brand-orange-bright .boxstyle-sm.box5 a:hover,
.brand-orange-bright .boxstyle-md.box5 a:hover,
.brand-orange-bright .boxstyle-lg.box5 a:hover,
.brand-orange-bright .boxstyle_.box5 a:focus,
.brand-orange-bright .boxstyle-xs.box5 a:focus,
.brand-orange-bright .boxstyle-sm.box5 a:focus,
.brand-orange-bright .boxstyle-md.box5 a:focus,
.brand-orange-bright .boxstyle-lg.box5 a:focus,
.brand-orange-bright .boxstyle_.box5 a:active,
.brand-orange-bright .boxstyle-xs.box5 a:active,
.brand-orange-bright .boxstyle-sm.box5 a:active,
.brand-orange-bright .boxstyle-md.box5 a:active,
.brand-orange-bright .boxstyle-lg.box5 a:active {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box5 .popover button.close,
.brand-orange-bright .boxstyle-xs.box5 .popover button.close,
.brand-orange-bright .boxstyle-sm.box5 .popover button.close,
.brand-orange-bright .boxstyle-md.box5 .popover button.close,
.brand-orange-bright .boxstyle-lg.box5 .popover button.close {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box5 .popover button.close:hover,
.brand-orange-bright .boxstyle-xs.box5 .popover button.close:hover,
.brand-orange-bright .boxstyle-sm.box5 .popover button.close:hover,
.brand-orange-bright .boxstyle-md.box5 .popover button.close:hover,
.brand-orange-bright .boxstyle-lg.box5 .popover button.close:hover {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box5 a:hover .new-window-link,
.brand-orange-bright .boxstyle-xs.box5 a:hover .new-window-link,
.brand-orange-bright .boxstyle-sm.box5 a:hover .new-window-link,
.brand-orange-bright .boxstyle-md.box5 a:hover .new-window-link,
.brand-orange-bright .boxstyle-lg.box5 a:hover .new-window-link,
.brand-orange-bright .boxstyle_.box5 a:hover .insecure-link,
.brand-orange-bright .boxstyle-xs.box5 a:hover .insecure-link,
.brand-orange-bright .boxstyle-sm.box5 a:hover .insecure-link,
.brand-orange-bright .boxstyle-md.box5 a:hover .insecure-link,
.brand-orange-bright .boxstyle-lg.box5 a:hover .insecure-link {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box5 .btn-link,
.brand-orange-bright .boxstyle-xs.box5 .btn-link,
.brand-orange-bright .boxstyle-sm.box5 .btn-link,
.brand-orange-bright .boxstyle-md.box5 .btn-link,
.brand-orange-bright .boxstyle-lg.box5 .btn-link {
  color: #202020;
}
.brand-orange-bright .boxstyle_.box5 .btn-link:hover,
.brand-orange-bright .boxstyle-xs.box5 .btn-link:hover,
.brand-orange-bright .boxstyle-sm.box5 .btn-link:hover,
.brand-orange-bright .boxstyle-md.box5 .btn-link:hover,
.brand-orange-bright .boxstyle-lg.box5 .btn-link:hover,
.brand-orange-bright .boxstyle_.box5 .btn-link:focus,
.brand-orange-bright .boxstyle-xs.box5 .btn-link:focus,
.brand-orange-bright .boxstyle-sm.box5 .btn-link:focus,
.brand-orange-bright .boxstyle-md.box5 .btn-link:focus,
.brand-orange-bright .boxstyle-lg.box5 .btn-link:focus {
  color: #000000;
}
.brand-orange-bright .boxstyle_.box5 a,
.brand-orange-bright .boxstyle-xs.box5 a,
.brand-orange-bright .boxstyle-sm.box5 a,
.brand-orange-bright .boxstyle-md.box5 a,
.brand-orange-bright .boxstyle-lg.box5 a,
.brand-orange-bright .boxstyle_.box5 a:link,
.brand-orange-bright .boxstyle-xs.box5 a:link,
.brand-orange-bright .boxstyle-sm.box5 a:link,
.brand-orange-bright .boxstyle-md.box5 a:link,
.brand-orange-bright .boxstyle-lg.box5 a:link,
.brand-orange-bright .boxstyle_.box5 a:visited,
.brand-orange-bright .boxstyle-xs.box5 a:visited,
.brand-orange-bright .boxstyle-sm.box5 a:visited,
.brand-orange-bright .boxstyle-md.box5 a:visited,
.brand-orange-bright .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-orange-bright .boxstyle_.box5 a:hover,
.brand-orange-bright .boxstyle-xs.box5 a:hover,
.brand-orange-bright .boxstyle-sm.box5 a:hover,
.brand-orange-bright .boxstyle-md.box5 a:hover,
.brand-orange-bright .boxstyle-lg.box5 a:hover,
.brand-orange-bright .boxstyle_.box5 a:focus,
.brand-orange-bright .boxstyle-xs.box5 a:focus,
.brand-orange-bright .boxstyle-sm.box5 a:focus,
.brand-orange-bright .boxstyle-md.box5 a:focus,
.brand-orange-bright .boxstyle-lg.box5 a:focus,
.brand-orange-bright .boxstyle_.box5 a:active,
.brand-orange-bright .boxstyle-xs.box5 a:active,
.brand-orange-bright .boxstyle-sm.box5 a:active,
.brand-orange-bright .boxstyle-md.box5 a:active,
.brand-orange-bright .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-orange-bright .pagination > li a,
.brand-orange-bright .pagination > li span {
  color: #E87722;
}
.brand-orange-bright .pagination > li.active > a,
.brand-orange-bright .pagination > li.active > span:not(.sr-only),
.brand-orange-bright .pagination > li.active > a:hover,
.brand-orange-bright .pagination > li.active > span:not(.sr-only):hover,
.brand-orange-bright .pagination > li.active > a:focus,
.brand-orange-bright .pagination > li.active > span:not(.sr-only):focus {
  background-color: #E87722;
  border-color: #E87722;
  color: white;
}
.brand-orange-bright .nav-tabs {
  border-bottom-color: #f4bb91;
}
.brand-orange-bright .nav-tabs > li > a {
  background-color: #fae4d3;
  border-color: #f4bb91;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-orange-bright .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #f1ad7a;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-orange-bright .nav-tabs > li > a:hover,
.brand-orange-bright .nav-tabs > li > a:focus {
  background-color: #fdf1e9;
  border-color: #f6c9a7 #f6c9a7 #f4bb91;
}
.brand-orange-bright .nav-tabs > li > a:hover::before,
.brand-orange-bright .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-orange-bright .nav-tabs > li.open > a,
.brand-orange-bright .nav-tabs > li.open > a:hover,
.brand-orange-bright .nav-tabs > li.open > a:focus {
  background-color: #fdf1e9;
  border-color: #f6c9a7 #f6c9a7 #f4bb91;
}
.brand-orange-bright .nav-tabs > li.open > a::before,
.brand-orange-bright .nav-tabs > li.open > a:hover::before,
.brand-orange-bright .nav-tabs > li.open > a:focus::before {
  background: #E87722;
  width: 100%;
}
.brand-orange-bright .nav-tabs > li.active > a,
.brand-orange-bright .nav-tabs > li.active > a:hover,
.brand-orange-bright .nav-tabs > li.active > a:focus {
  border-color: #f4bb91 #f4bb91 transparent;
}
.brand-orange-bright .nav-tabs > li.active > a::before,
.brand-orange-bright .nav-tabs > li.active > a:hover::before,
.brand-orange-bright .nav-tabs > li.active > a:focus::before {
  background: #E87722;
  width: 100%;
}
.brand-orange-bright .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-orange-bright .nav-tabs > li.disabled > a:hover,
.brand-orange-bright .nav-tabs > li.disabled > a:focus {
  background-color: #fae4d3;
}
.brand-orange-bright .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-orange-bright .nav-pills > li.active > a,
.brand-orange-bright .nav-pills > li.active > a:hover,
.brand-orange-bright .nav-pills > li.active > a:focus {
  background-color: #E87722;
  color: white;
}
.brand-orange-bright .list-group-item.active,
.brand-orange-bright .list-group-item.active:hover,
.brand-orange-bright .list-group-item.active:focus {
  background-color: #E87722;
  border-color: #E87722;
  color: white;
}
.brand-orange-bright .id7-koan-spinner__neon--top,
.brand-orange-bright .id7-koan-spinner__neon--bottom {
  stroke: #E87722;
}
.brand-green h1,
.brand-green h2,
.brand-green h3,
.brand-green h4,
.brand-green h5,
.brand-green h6,
.brand-green .h1,
.brand-green .h2,
.brand-green .h3,
.brand-green .h4,
.brand-green .h5,
.brand-green .h6 {
  color: #0F4001;
}
.brand-green a,
.brand-green a:link,
.brand-green a:visited {
  color: #0F4001;
}
.brand-green a:hover,
.brand-green a:focus,
.brand-green a:active {
  color: #000000;
}
.brand-green .popover button.close {
  color: #0F4001;
}
.brand-green .popover button.close:hover {
  color: #000000;
}
.brand-green a:hover .new-window-link,
.brand-green a:hover .insecure-link {
  color: #000000;
}
.brand-green .btn-link {
  color: #0F4001;
}
.brand-green .btn-link:hover,
.brand-green .btn-link:focus {
  color: #000000;
}
.brand-green a,
.brand-green a:link,
.brand-green a:visited {
  -webkit-text-decoration-color: #57794d;
  text-decoration-color: #57794d;
}
.brand-green a:hover,
.brand-green a:focus,
.brand-green a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-green blockquote.quotes {
  color: #000000;
}
.brand-green blockquote.quotes::before {
  color: #000000;
}
.brand-green .brand-bg {
  background: #0F4001;
  color: white;
}
.brand-green .brand-bg a,
.brand-green .brand-bg a:link,
.brand-green .brand-bg a:visited {
  color: white !important;
}
.brand-green .brand-bg a:hover,
.brand-green .brand-bg a:focus,
.brand-green .brand-bg a:active {
  color: #cccccc !important;
}
.brand-green .brand-bg .popover button.close {
  color: white !important;
}
.brand-green .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-green .brand-bg a:hover .new-window-link,
.brand-green .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-green .brand-bg .btn-link {
  color: white !important;
}
.brand-green .brand-bg .btn-link:hover,
.brand-green .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-green .brand-bg a,
.brand-green .brand-bg a:link,
.brand-green .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-green .brand-bg a:hover,
.brand-green .brand-bg a:focus,
.brand-green .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-green .brand-text {
  color: #0F4001;
}
.brand-green .brand-text a,
.brand-green .brand-text a:link,
.brand-green .brand-text a:visited {
  color: #0F4001 !important;
}
.brand-green .brand-text a:hover,
.brand-green .brand-text a:focus,
.brand-green .brand-text a:active {
  color: #000000 !important;
}
.brand-green .brand-text .popover button.close {
  color: #0F4001 !important;
}
.brand-green .brand-text .popover button.close:hover {
  color: #000000 !important;
}
.brand-green .brand-text a:hover .new-window-link,
.brand-green .brand-text a:hover .insecure-link {
  color: #000000 !important;
}
.brand-green .brand-text .btn-link {
  color: #0F4001 !important;
}
.brand-green .brand-text .btn-link:hover,
.brand-green .brand-text .btn-link:focus {
  color: #000000 !important;
}
.brand-green .brand-text a,
.brand-green .brand-text a:link,
.brand-green .brand-text a:visited {
  -webkit-text-decoration-color: #57794d !important;
  text-decoration-color: #57794d !important;
}
.brand-green .brand-text a:hover,
.brand-green .brand-text a:focus,
.brand-green .brand-text a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-green .brand-border {
  border-color: #0F4001;
}
.brand-green .btn-brand {
  color: white;
  background-color: #0F4001;
  border-color: #092701;
  color: white !important;
}
.brand-green .btn-brand:focus,
.brand-green .btn-brand.focus {
  color: white;
  background-color: #030e00;
  border-color: #000000;
}
.brand-green .btn-brand:hover {
  color: white;
  background-color: #030e00;
  border-color: #000000;
}
.brand-green .btn-brand:active,
.brand-green .btn-brand.active,
.open > .dropdown-toggle.brand-green .btn-brand {
  color: white;
  background-color: #030e00;
  background-image: none;
  border-color: #000000;
}
.brand-green .btn-brand:active:hover,
.brand-green .btn-brand.active:hover,
.open > .dropdown-toggle.brand-green .btn-brand:hover,
.brand-green .btn-brand:active:focus,
.brand-green .btn-brand.active:focus,
.open > .dropdown-toggle.brand-green .btn-brand:focus,
.brand-green .btn-brand:active.focus,
.brand-green .btn-brand.active.focus,
.open > .dropdown-toggle.brand-green .btn-brand.focus {
  color: white;
  background-color: #000000;
  border-color: #000000;
}
.brand-green .btn-brand.disabled:hover,
.brand-green .btn-brand[disabled]:hover,
fieldset[disabled] .brand-green .btn-brand:hover,
.brand-green .btn-brand.disabled:focus,
.brand-green .btn-brand[disabled]:focus,
fieldset[disabled] .brand-green .btn-brand:focus,
.brand-green .btn-brand.disabled.focus,
.brand-green .btn-brand[disabled].focus,
fieldset[disabled] .brand-green .btn-brand.focus {
  background-color: #0F4001;
  border-color: #092701;
}
.brand-green .btn-brand .badge {
  color: #0F4001;
  background-color: white;
}
.brand-green .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-green .id7-utility-masthead {
  background-color: #57794d;
}
.brand-green .id7-utility-masthead::after {
  background-color: #0F4001;
}
body.id7-point-1 .brand-green .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-green .id7-navigation .navbar-primary {
  background-color: #0F4001;
}
.brand-green .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-green .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-green .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #57794d;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-green .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #0c3301;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-green .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-green .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #57794d;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-green .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-green .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #0c3301;
}
.brand-green .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-green .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-green .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-green .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-green .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #0c3301;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #0F4001;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #0c3301;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #0c3301;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #0c3301;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #0c3301;
  background-image: none;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #57794d;
}
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-green .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #0c3301;
}
.brand-green .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .btn-link:hover,
.brand-green .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-green .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-green .id7-navigation .navbar-primary .btn-link:hover,
.brand-green .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-green .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-green .navbar-primary .navbar-brand {
  background-color: #0F4001;
}
.brand-green .navbar-secondary {
  background-color: #57794d;
}
.brand-green .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-green .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-green .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-green .navbar-secondary .navbar-text {
  color: white;
}
.brand-green .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-green .navbar-secondary .navbar-nav > li {
  color: white;
}
.brand-green .navbar-secondary .navbar-nav > li:hover,
.brand-green .navbar-secondary .navbar-nav > li:focus {
  color: white;
  background-color: #46613e;
}
.brand-green .navbar-secondary .navbar-nav > li > a,
.brand-green .navbar-secondary .navbar-nav > li > a:hover,
.brand-green .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-green .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-green .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-green .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-green .navbar-secondary .navbar-nav > .disabled,
.brand-green .navbar-secondary .navbar-nav > .disabled:hover,
.brand-green .navbar-secondary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #46613e;
}
.brand-green .navbar-secondary .navbar-toggle {
  border: none;
  color: white;
}
.brand-green .navbar-secondary .navbar-toggle:hover,
.brand-green .navbar-secondary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-green .navbar-secondary .navbar-nav > .open,
.brand-green .navbar-secondary .navbar-nav > .open:hover,
.brand-green .navbar-secondary .navbar-nav > .open:focus {
  color: white;
  background-color: #46613e;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #57794d;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #46613e;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #46613e;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #46613e;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #46613e;
  background-image: none;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-green .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #46613e;
}
.brand-green .navbar-secondary .navbar-link {
  color: white;
}
.brand-green .navbar-secondary .navbar-link:hover {
  color: white;
}
.brand-green .navbar-secondary .btn-link {
  color: white;
}
.brand-green .navbar-secondary .btn-link:hover,
.brand-green .navbar-secondary .btn-link:focus {
  color: white;
}
.brand-green .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-green .navbar-secondary .btn-link:hover,
.brand-green .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-green .navbar-secondary .btn-link:focus {
  color: white;
}
.brand-green .navbar-secondary .navbar-nav > li::after {
  color: #0F4001;
}
.brand-green .navbar-secondary .navbar-nav > li:first-child {
  background-color: #0F4001;
  color: white;
}
.brand-green .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #0F4001;
}
.brand-green .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #0F4001;
}
.brand-green .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-green .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-green .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-green .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-green .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-green .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-green .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-green .navbar-tertiary .navbar-nav > li:hover,
.brand-green .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-green .navbar-tertiary .navbar-nav > li > a,
.brand-green .navbar-tertiary .navbar-nav > li > a:hover,
.brand-green .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-green .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-green .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-green .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-green .navbar-tertiary .navbar-nav > .disabled,
.brand-green .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-green .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-green .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-green .navbar-tertiary .navbar-toggle:hover,
.brand-green .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-green .navbar-tertiary .navbar-nav > .open,
.brand-green .navbar-tertiary .navbar-nav > .open:hover,
.brand-green .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-green .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-green .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-green .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-green .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-green .navbar-tertiary .btn-link:hover,
.brand-green .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-green .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-green .navbar-tertiary .btn-link:hover,
.brand-green .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-green .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-green.bordered .id7-left-border,
.brand-green.bordered .id7-right-border {
  border-color: rgba(15, 64, 1, 0.3);
}
.brand-green .id7-page-footer .id7-app-footer {
  background: #0F4001;
  color: white;
}
.brand-green .id7-page-footer .id7-app-footer h1,
.brand-green .id7-page-footer .id7-app-footer h2,
.brand-green .id7-page-footer .id7-app-footer h3,
.brand-green .id7-page-footer .id7-app-footer h4,
.brand-green .id7-page-footer .id7-app-footer h5,
.brand-green .id7-page-footer .id7-app-footer h6,
.brand-green .id7-page-footer .id7-app-footer .h1,
.brand-green .id7-page-footer .id7-app-footer .h2,
.brand-green .id7-page-footer .id7-app-footer .h3,
.brand-green .id7-page-footer .id7-app-footer .h4,
.brand-green .id7-page-footer .id7-app-footer .h5,
.brand-green .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-green .id7-page-footer .id7-app-footer a,
.brand-green .id7-page-footer .id7-app-footer a:link,
.brand-green .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-green .id7-page-footer .id7-app-footer a:hover,
.brand-green .id7-page-footer .id7-app-footer a:focus,
.brand-green .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-green .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-green .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-green .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-green .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-green .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-green .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-green .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-green .id7-page-footer .id7-app-footer a,
.brand-green .id7-page-footer .id7-app-footer a:link,
.brand-green .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-green .id7-page-footer .id7-app-footer a:hover,
.brand-green .id7-page-footer .id7-app-footer a:focus,
.brand-green .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-green .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #0F4001;
}
.brand-green .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-green .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-green .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-green .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-green .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-green .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-green .id7-search .fa,
.brand-green .id7-search .fas {
  color: #0F4001;
  color: rgba(15, 64, 1, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-green .id7-search .form-control:hover + .fa,
.brand-green .id7-search .typeahead:hover + .fa,
.brand-green .id7-search .fa:hover,
.brand-green .id7-search .form-control:hover + .fas,
.brand-green .id7-search .typeahead:hover + .fas,
.brand-green .id7-search .fas:hover {
  color: #0F4001;
}
.brand-green .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #0F4001;
  color: white;
}
.brand-green .form-control:focus {
  border-color: #0F4001;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(15, 64, 1, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(15, 64, 1, 0.6);
}
.brand-green .carousel .carousel-indicators li::after {
  color: #0F4001;
  color: rgba(15, 64, 1, 0.3);
}
.brand-green .carousel .carousel-indicators li.active::after {
  color: #0F4001;
}
@media (max-width: 767px) {
  .brand-green .boxstyle_.box1,
  .brand-green .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-green .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-green .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-green .boxstyle_.box1,
.brand-green .boxstyle-xs.box1,
.brand-green .boxstyle-sm.box1,
.brand-green .boxstyle-md.box1,
.brand-green .boxstyle-lg.box1 {
  border: 0;
  background: #dbe2d9;
  color: #202020;
}
@media print {
  .brand-green .boxstyle_.box1,
  .brand-green .boxstyle-xs.box1,
  .brand-green .boxstyle-sm.box1,
  .brand-green .boxstyle-md.box1,
  .brand-green .boxstyle-lg.box1 {
    border: 1px solid #dbe2d9;
  }
}
.brand-green .boxstyle_.box1 h1,
.brand-green .boxstyle-xs.box1 h1,
.brand-green .boxstyle-sm.box1 h1,
.brand-green .boxstyle-md.box1 h1,
.brand-green .boxstyle-lg.box1 h1,
.brand-green .boxstyle_.box1 h2,
.brand-green .boxstyle-xs.box1 h2,
.brand-green .boxstyle-sm.box1 h2,
.brand-green .boxstyle-md.box1 h2,
.brand-green .boxstyle-lg.box1 h2,
.brand-green .boxstyle_.box1 h3,
.brand-green .boxstyle-xs.box1 h3,
.brand-green .boxstyle-sm.box1 h3,
.brand-green .boxstyle-md.box1 h3,
.brand-green .boxstyle-lg.box1 h3,
.brand-green .boxstyle_.box1 h4,
.brand-green .boxstyle-xs.box1 h4,
.brand-green .boxstyle-sm.box1 h4,
.brand-green .boxstyle-md.box1 h4,
.brand-green .boxstyle-lg.box1 h4,
.brand-green .boxstyle_.box1 h5,
.brand-green .boxstyle-xs.box1 h5,
.brand-green .boxstyle-sm.box1 h5,
.brand-green .boxstyle-md.box1 h5,
.brand-green .boxstyle-lg.box1 h5,
.brand-green .boxstyle_.box1 h6,
.brand-green .boxstyle-xs.box1 h6,
.brand-green .boxstyle-sm.box1 h6,
.brand-green .boxstyle-md.box1 h6,
.brand-green .boxstyle-lg.box1 h6,
.brand-green .boxstyle_.box1 .h1,
.brand-green .boxstyle-xs.box1 .h1,
.brand-green .boxstyle-sm.box1 .h1,
.brand-green .boxstyle-md.box1 .h1,
.brand-green .boxstyle-lg.box1 .h1,
.brand-green .boxstyle_.box1 .h2,
.brand-green .boxstyle-xs.box1 .h2,
.brand-green .boxstyle-sm.box1 .h2,
.brand-green .boxstyle-md.box1 .h2,
.brand-green .boxstyle-lg.box1 .h2,
.brand-green .boxstyle_.box1 .h3,
.brand-green .boxstyle-xs.box1 .h3,
.brand-green .boxstyle-sm.box1 .h3,
.brand-green .boxstyle-md.box1 .h3,
.brand-green .boxstyle-lg.box1 .h3,
.brand-green .boxstyle_.box1 .h4,
.brand-green .boxstyle-xs.box1 .h4,
.brand-green .boxstyle-sm.box1 .h4,
.brand-green .boxstyle-md.box1 .h4,
.brand-green .boxstyle-lg.box1 .h4,
.brand-green .boxstyle_.box1 .h5,
.brand-green .boxstyle-xs.box1 .h5,
.brand-green .boxstyle-sm.box1 .h5,
.brand-green .boxstyle-md.box1 .h5,
.brand-green .boxstyle-lg.box1 .h5,
.brand-green .boxstyle_.box1 .h6,
.brand-green .boxstyle-xs.box1 .h6,
.brand-green .boxstyle-sm.box1 .h6,
.brand-green .boxstyle-md.box1 .h6,
.brand-green .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-green .boxstyle_.box1 blockquote.quotes,
.brand-green .boxstyle-xs.box1 blockquote.quotes,
.brand-green .boxstyle-sm.box1 blockquote.quotes,
.brand-green .boxstyle-md.box1 blockquote.quotes,
.brand-green .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-green .boxstyle_.box1 a,
.brand-green .boxstyle-xs.box1 a,
.brand-green .boxstyle-sm.box1 a,
.brand-green .boxstyle-md.box1 a,
.brand-green .boxstyle-lg.box1 a,
.brand-green .boxstyle_.box1 a:link,
.brand-green .boxstyle-xs.box1 a:link,
.brand-green .boxstyle-sm.box1 a:link,
.brand-green .boxstyle-md.box1 a:link,
.brand-green .boxstyle-lg.box1 a:link,
.brand-green .boxstyle_.box1 a:visited,
.brand-green .boxstyle-xs.box1 a:visited,
.brand-green .boxstyle-sm.box1 a:visited,
.brand-green .boxstyle-md.box1 a:visited,
.brand-green .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-green .boxstyle_.box1 a:hover,
.brand-green .boxstyle-xs.box1 a:hover,
.brand-green .boxstyle-sm.box1 a:hover,
.brand-green .boxstyle-md.box1 a:hover,
.brand-green .boxstyle-lg.box1 a:hover,
.brand-green .boxstyle_.box1 a:focus,
.brand-green .boxstyle-xs.box1 a:focus,
.brand-green .boxstyle-sm.box1 a:focus,
.brand-green .boxstyle-md.box1 a:focus,
.brand-green .boxstyle-lg.box1 a:focus,
.brand-green .boxstyle_.box1 a:active,
.brand-green .boxstyle-xs.box1 a:active,
.brand-green .boxstyle-sm.box1 a:active,
.brand-green .boxstyle-md.box1 a:active,
.brand-green .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-green .boxstyle_.box1 .popover button.close,
.brand-green .boxstyle-xs.box1 .popover button.close,
.brand-green .boxstyle-sm.box1 .popover button.close,
.brand-green .boxstyle-md.box1 .popover button.close,
.brand-green .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-green .boxstyle_.box1 .popover button.close:hover,
.brand-green .boxstyle-xs.box1 .popover button.close:hover,
.brand-green .boxstyle-sm.box1 .popover button.close:hover,
.brand-green .boxstyle-md.box1 .popover button.close:hover,
.brand-green .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-green .boxstyle_.box1 a:hover .new-window-link,
.brand-green .boxstyle-xs.box1 a:hover .new-window-link,
.brand-green .boxstyle-sm.box1 a:hover .new-window-link,
.brand-green .boxstyle-md.box1 a:hover .new-window-link,
.brand-green .boxstyle-lg.box1 a:hover .new-window-link,
.brand-green .boxstyle_.box1 a:hover .insecure-link,
.brand-green .boxstyle-xs.box1 a:hover .insecure-link,
.brand-green .boxstyle-sm.box1 a:hover .insecure-link,
.brand-green .boxstyle-md.box1 a:hover .insecure-link,
.brand-green .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-green .boxstyle_.box1 .btn-link,
.brand-green .boxstyle-xs.box1 .btn-link,
.brand-green .boxstyle-sm.box1 .btn-link,
.brand-green .boxstyle-md.box1 .btn-link,
.brand-green .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-green .boxstyle_.box1 .btn-link:hover,
.brand-green .boxstyle-xs.box1 .btn-link:hover,
.brand-green .boxstyle-sm.box1 .btn-link:hover,
.brand-green .boxstyle-md.box1 .btn-link:hover,
.brand-green .boxstyle-lg.box1 .btn-link:hover,
.brand-green .boxstyle_.box1 .btn-link:focus,
.brand-green .boxstyle-xs.box1 .btn-link:focus,
.brand-green .boxstyle-sm.box1 .btn-link:focus,
.brand-green .boxstyle-md.box1 .btn-link:focus,
.brand-green .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-green .boxstyle_.box1 a,
.brand-green .boxstyle-xs.box1 a,
.brand-green .boxstyle-sm.box1 a,
.brand-green .boxstyle-md.box1 a,
.brand-green .boxstyle-lg.box1 a,
.brand-green .boxstyle_.box1 a:link,
.brand-green .boxstyle-xs.box1 a:link,
.brand-green .boxstyle-sm.box1 a:link,
.brand-green .boxstyle-md.box1 a:link,
.brand-green .boxstyle-lg.box1 a:link,
.brand-green .boxstyle_.box1 a:visited,
.brand-green .boxstyle-xs.box1 a:visited,
.brand-green .boxstyle-sm.box1 a:visited,
.brand-green .boxstyle-md.box1 a:visited,
.brand-green .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-green .boxstyle_.box1 a:hover,
.brand-green .boxstyle-xs.box1 a:hover,
.brand-green .boxstyle-sm.box1 a:hover,
.brand-green .boxstyle-md.box1 a:hover,
.brand-green .boxstyle-lg.box1 a:hover,
.brand-green .boxstyle_.box1 a:focus,
.brand-green .boxstyle-xs.box1 a:focus,
.brand-green .boxstyle-sm.box1 a:focus,
.brand-green .boxstyle-md.box1 a:focus,
.brand-green .boxstyle-lg.box1 a:focus,
.brand-green .boxstyle_.box1 a:active,
.brand-green .boxstyle-xs.box1 a:active,
.brand-green .boxstyle-sm.box1 a:active,
.brand-green .boxstyle-md.box1 a:active,
.brand-green .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-green .boxstyle_.box2,
  .brand-green .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-green .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-green .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-green .boxstyle_.box2,
.brand-green .boxstyle-xs.box2,
.brand-green .boxstyle-sm.box2,
.brand-green .boxstyle-md.box2,
.brand-green .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-green .boxstyle_.box2,
  .brand-green .boxstyle-xs.box2,
  .brand-green .boxstyle-sm.box2,
  .brand-green .boxstyle-md.box2,
  .brand-green .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-green .boxstyle_.box2 h1,
.brand-green .boxstyle-xs.box2 h1,
.brand-green .boxstyle-sm.box2 h1,
.brand-green .boxstyle-md.box2 h1,
.brand-green .boxstyle-lg.box2 h1,
.brand-green .boxstyle_.box2 h2,
.brand-green .boxstyle-xs.box2 h2,
.brand-green .boxstyle-sm.box2 h2,
.brand-green .boxstyle-md.box2 h2,
.brand-green .boxstyle-lg.box2 h2,
.brand-green .boxstyle_.box2 h3,
.brand-green .boxstyle-xs.box2 h3,
.brand-green .boxstyle-sm.box2 h3,
.brand-green .boxstyle-md.box2 h3,
.brand-green .boxstyle-lg.box2 h3,
.brand-green .boxstyle_.box2 h4,
.brand-green .boxstyle-xs.box2 h4,
.brand-green .boxstyle-sm.box2 h4,
.brand-green .boxstyle-md.box2 h4,
.brand-green .boxstyle-lg.box2 h4,
.brand-green .boxstyle_.box2 h5,
.brand-green .boxstyle-xs.box2 h5,
.brand-green .boxstyle-sm.box2 h5,
.brand-green .boxstyle-md.box2 h5,
.brand-green .boxstyle-lg.box2 h5,
.brand-green .boxstyle_.box2 h6,
.brand-green .boxstyle-xs.box2 h6,
.brand-green .boxstyle-sm.box2 h6,
.brand-green .boxstyle-md.box2 h6,
.brand-green .boxstyle-lg.box2 h6,
.brand-green .boxstyle_.box2 .h1,
.brand-green .boxstyle-xs.box2 .h1,
.brand-green .boxstyle-sm.box2 .h1,
.brand-green .boxstyle-md.box2 .h1,
.brand-green .boxstyle-lg.box2 .h1,
.brand-green .boxstyle_.box2 .h2,
.brand-green .boxstyle-xs.box2 .h2,
.brand-green .boxstyle-sm.box2 .h2,
.brand-green .boxstyle-md.box2 .h2,
.brand-green .boxstyle-lg.box2 .h2,
.brand-green .boxstyle_.box2 .h3,
.brand-green .boxstyle-xs.box2 .h3,
.brand-green .boxstyle-sm.box2 .h3,
.brand-green .boxstyle-md.box2 .h3,
.brand-green .boxstyle-lg.box2 .h3,
.brand-green .boxstyle_.box2 .h4,
.brand-green .boxstyle-xs.box2 .h4,
.brand-green .boxstyle-sm.box2 .h4,
.brand-green .boxstyle-md.box2 .h4,
.brand-green .boxstyle-lg.box2 .h4,
.brand-green .boxstyle_.box2 .h5,
.brand-green .boxstyle-xs.box2 .h5,
.brand-green .boxstyle-sm.box2 .h5,
.brand-green .boxstyle-md.box2 .h5,
.brand-green .boxstyle-lg.box2 .h5,
.brand-green .boxstyle_.box2 .h6,
.brand-green .boxstyle-xs.box2 .h6,
.brand-green .boxstyle-sm.box2 .h6,
.brand-green .boxstyle-md.box2 .h6,
.brand-green .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-green .boxstyle_.box2 blockquote.quotes,
.brand-green .boxstyle-xs.box2 blockquote.quotes,
.brand-green .boxstyle-sm.box2 blockquote.quotes,
.brand-green .boxstyle-md.box2 blockquote.quotes,
.brand-green .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-green .boxstyle_.box2 a,
.brand-green .boxstyle-xs.box2 a,
.brand-green .boxstyle-sm.box2 a,
.brand-green .boxstyle-md.box2 a,
.brand-green .boxstyle-lg.box2 a,
.brand-green .boxstyle_.box2 a:link,
.brand-green .boxstyle-xs.box2 a:link,
.brand-green .boxstyle-sm.box2 a:link,
.brand-green .boxstyle-md.box2 a:link,
.brand-green .boxstyle-lg.box2 a:link,
.brand-green .boxstyle_.box2 a:visited,
.brand-green .boxstyle-xs.box2 a:visited,
.brand-green .boxstyle-sm.box2 a:visited,
.brand-green .boxstyle-md.box2 a:visited,
.brand-green .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-green .boxstyle_.box2 a:hover,
.brand-green .boxstyle-xs.box2 a:hover,
.brand-green .boxstyle-sm.box2 a:hover,
.brand-green .boxstyle-md.box2 a:hover,
.brand-green .boxstyle-lg.box2 a:hover,
.brand-green .boxstyle_.box2 a:focus,
.brand-green .boxstyle-xs.box2 a:focus,
.brand-green .boxstyle-sm.box2 a:focus,
.brand-green .boxstyle-md.box2 a:focus,
.brand-green .boxstyle-lg.box2 a:focus,
.brand-green .boxstyle_.box2 a:active,
.brand-green .boxstyle-xs.box2 a:active,
.brand-green .boxstyle-sm.box2 a:active,
.brand-green .boxstyle-md.box2 a:active,
.brand-green .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-green .boxstyle_.box2 .popover button.close,
.brand-green .boxstyle-xs.box2 .popover button.close,
.brand-green .boxstyle-sm.box2 .popover button.close,
.brand-green .boxstyle-md.box2 .popover button.close,
.brand-green .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-green .boxstyle_.box2 .popover button.close:hover,
.brand-green .boxstyle-xs.box2 .popover button.close:hover,
.brand-green .boxstyle-sm.box2 .popover button.close:hover,
.brand-green .boxstyle-md.box2 .popover button.close:hover,
.brand-green .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-green .boxstyle_.box2 a:hover .new-window-link,
.brand-green .boxstyle-xs.box2 a:hover .new-window-link,
.brand-green .boxstyle-sm.box2 a:hover .new-window-link,
.brand-green .boxstyle-md.box2 a:hover .new-window-link,
.brand-green .boxstyle-lg.box2 a:hover .new-window-link,
.brand-green .boxstyle_.box2 a:hover .insecure-link,
.brand-green .boxstyle-xs.box2 a:hover .insecure-link,
.brand-green .boxstyle-sm.box2 a:hover .insecure-link,
.brand-green .boxstyle-md.box2 a:hover .insecure-link,
.brand-green .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-green .boxstyle_.box2 .btn-link,
.brand-green .boxstyle-xs.box2 .btn-link,
.brand-green .boxstyle-sm.box2 .btn-link,
.brand-green .boxstyle-md.box2 .btn-link,
.brand-green .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-green .boxstyle_.box2 .btn-link:hover,
.brand-green .boxstyle-xs.box2 .btn-link:hover,
.brand-green .boxstyle-sm.box2 .btn-link:hover,
.brand-green .boxstyle-md.box2 .btn-link:hover,
.brand-green .boxstyle-lg.box2 .btn-link:hover,
.brand-green .boxstyle_.box2 .btn-link:focus,
.brand-green .boxstyle-xs.box2 .btn-link:focus,
.brand-green .boxstyle-sm.box2 .btn-link:focus,
.brand-green .boxstyle-md.box2 .btn-link:focus,
.brand-green .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-green .boxstyle_.box2 a,
.brand-green .boxstyle-xs.box2 a,
.brand-green .boxstyle-sm.box2 a,
.brand-green .boxstyle-md.box2 a,
.brand-green .boxstyle-lg.box2 a,
.brand-green .boxstyle_.box2 a:link,
.brand-green .boxstyle-xs.box2 a:link,
.brand-green .boxstyle-sm.box2 a:link,
.brand-green .boxstyle-md.box2 a:link,
.brand-green .boxstyle-lg.box2 a:link,
.brand-green .boxstyle_.box2 a:visited,
.brand-green .boxstyle-xs.box2 a:visited,
.brand-green .boxstyle-sm.box2 a:visited,
.brand-green .boxstyle-md.box2 a:visited,
.brand-green .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-green .boxstyle_.box2 a:hover,
.brand-green .boxstyle-xs.box2 a:hover,
.brand-green .boxstyle-sm.box2 a:hover,
.brand-green .boxstyle-md.box2 a:hover,
.brand-green .boxstyle-lg.box2 a:hover,
.brand-green .boxstyle_.box2 a:focus,
.brand-green .boxstyle-xs.box2 a:focus,
.brand-green .boxstyle-sm.box2 a:focus,
.brand-green .boxstyle-md.box2 a:focus,
.brand-green .boxstyle-lg.box2 a:focus,
.brand-green .boxstyle_.box2 a:active,
.brand-green .boxstyle-xs.box2 a:active,
.brand-green .boxstyle-sm.box2 a:active,
.brand-green .boxstyle-md.box2 a:active,
.brand-green .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-green .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #0F4001;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-green .boxstyle_.box3,
  .brand-green .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #0F4001;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-green .boxstyle-md.box3 {
    background: none;
    border: 1px solid #0F4001;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-green .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #0F4001;
    color: inherit;
  }
}
.brand-green .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-green .boxstyle_.box4,
  .brand-green .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-green .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-green .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-green .boxstyle_.box5,
  .brand-green .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-green .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-green .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-green .boxstyle_.box5,
.brand-green .boxstyle-xs.box5,
.brand-green .boxstyle-sm.box5,
.brand-green .boxstyle-md.box5,
.brand-green .boxstyle-lg.box5 {
  border: 0;
  background: #0F4001;
  color: white;
}
@media print {
  .brand-green .boxstyle_.box5,
  .brand-green .boxstyle-xs.box5,
  .brand-green .boxstyle-sm.box5,
  .brand-green .boxstyle-md.box5,
  .brand-green .boxstyle-lg.box5 {
    border: 1px solid #0F4001;
  }
}
.brand-green .boxstyle_.box5 h1,
.brand-green .boxstyle-xs.box5 h1,
.brand-green .boxstyle-sm.box5 h1,
.brand-green .boxstyle-md.box5 h1,
.brand-green .boxstyle-lg.box5 h1,
.brand-green .boxstyle_.box5 h2,
.brand-green .boxstyle-xs.box5 h2,
.brand-green .boxstyle-sm.box5 h2,
.brand-green .boxstyle-md.box5 h2,
.brand-green .boxstyle-lg.box5 h2,
.brand-green .boxstyle_.box5 h3,
.brand-green .boxstyle-xs.box5 h3,
.brand-green .boxstyle-sm.box5 h3,
.brand-green .boxstyle-md.box5 h3,
.brand-green .boxstyle-lg.box5 h3,
.brand-green .boxstyle_.box5 h4,
.brand-green .boxstyle-xs.box5 h4,
.brand-green .boxstyle-sm.box5 h4,
.brand-green .boxstyle-md.box5 h4,
.brand-green .boxstyle-lg.box5 h4,
.brand-green .boxstyle_.box5 h5,
.brand-green .boxstyle-xs.box5 h5,
.brand-green .boxstyle-sm.box5 h5,
.brand-green .boxstyle-md.box5 h5,
.brand-green .boxstyle-lg.box5 h5,
.brand-green .boxstyle_.box5 h6,
.brand-green .boxstyle-xs.box5 h6,
.brand-green .boxstyle-sm.box5 h6,
.brand-green .boxstyle-md.box5 h6,
.brand-green .boxstyle-lg.box5 h6,
.brand-green .boxstyle_.box5 .h1,
.brand-green .boxstyle-xs.box5 .h1,
.brand-green .boxstyle-sm.box5 .h1,
.brand-green .boxstyle-md.box5 .h1,
.brand-green .boxstyle-lg.box5 .h1,
.brand-green .boxstyle_.box5 .h2,
.brand-green .boxstyle-xs.box5 .h2,
.brand-green .boxstyle-sm.box5 .h2,
.brand-green .boxstyle-md.box5 .h2,
.brand-green .boxstyle-lg.box5 .h2,
.brand-green .boxstyle_.box5 .h3,
.brand-green .boxstyle-xs.box5 .h3,
.brand-green .boxstyle-sm.box5 .h3,
.brand-green .boxstyle-md.box5 .h3,
.brand-green .boxstyle-lg.box5 .h3,
.brand-green .boxstyle_.box5 .h4,
.brand-green .boxstyle-xs.box5 .h4,
.brand-green .boxstyle-sm.box5 .h4,
.brand-green .boxstyle-md.box5 .h4,
.brand-green .boxstyle-lg.box5 .h4,
.brand-green .boxstyle_.box5 .h5,
.brand-green .boxstyle-xs.box5 .h5,
.brand-green .boxstyle-sm.box5 .h5,
.brand-green .boxstyle-md.box5 .h5,
.brand-green .boxstyle-lg.box5 .h5,
.brand-green .boxstyle_.box5 .h6,
.brand-green .boxstyle-xs.box5 .h6,
.brand-green .boxstyle-sm.box5 .h6,
.brand-green .boxstyle-md.box5 .h6,
.brand-green .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-green .boxstyle_.box5 blockquote.quotes,
.brand-green .boxstyle-xs.box5 blockquote.quotes,
.brand-green .boxstyle-sm.box5 blockquote.quotes,
.brand-green .boxstyle-md.box5 blockquote.quotes,
.brand-green .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-green .boxstyle_.box5 a,
.brand-green .boxstyle-xs.box5 a,
.brand-green .boxstyle-sm.box5 a,
.brand-green .boxstyle-md.box5 a,
.brand-green .boxstyle-lg.box5 a,
.brand-green .boxstyle_.box5 a:link,
.brand-green .boxstyle-xs.box5 a:link,
.brand-green .boxstyle-sm.box5 a:link,
.brand-green .boxstyle-md.box5 a:link,
.brand-green .boxstyle-lg.box5 a:link,
.brand-green .boxstyle_.box5 a:visited,
.brand-green .boxstyle-xs.box5 a:visited,
.brand-green .boxstyle-sm.box5 a:visited,
.brand-green .boxstyle-md.box5 a:visited,
.brand-green .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-green .boxstyle_.box5 a:hover,
.brand-green .boxstyle-xs.box5 a:hover,
.brand-green .boxstyle-sm.box5 a:hover,
.brand-green .boxstyle-md.box5 a:hover,
.brand-green .boxstyle-lg.box5 a:hover,
.brand-green .boxstyle_.box5 a:focus,
.brand-green .boxstyle-xs.box5 a:focus,
.brand-green .boxstyle-sm.box5 a:focus,
.brand-green .boxstyle-md.box5 a:focus,
.brand-green .boxstyle-lg.box5 a:focus,
.brand-green .boxstyle_.box5 a:active,
.brand-green .boxstyle-xs.box5 a:active,
.brand-green .boxstyle-sm.box5 a:active,
.brand-green .boxstyle-md.box5 a:active,
.brand-green .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-green .boxstyle_.box5 .popover button.close,
.brand-green .boxstyle-xs.box5 .popover button.close,
.brand-green .boxstyle-sm.box5 .popover button.close,
.brand-green .boxstyle-md.box5 .popover button.close,
.brand-green .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-green .boxstyle_.box5 .popover button.close:hover,
.brand-green .boxstyle-xs.box5 .popover button.close:hover,
.brand-green .boxstyle-sm.box5 .popover button.close:hover,
.brand-green .boxstyle-md.box5 .popover button.close:hover,
.brand-green .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-green .boxstyle_.box5 a:hover .new-window-link,
.brand-green .boxstyle-xs.box5 a:hover .new-window-link,
.brand-green .boxstyle-sm.box5 a:hover .new-window-link,
.brand-green .boxstyle-md.box5 a:hover .new-window-link,
.brand-green .boxstyle-lg.box5 a:hover .new-window-link,
.brand-green .boxstyle_.box5 a:hover .insecure-link,
.brand-green .boxstyle-xs.box5 a:hover .insecure-link,
.brand-green .boxstyle-sm.box5 a:hover .insecure-link,
.brand-green .boxstyle-md.box5 a:hover .insecure-link,
.brand-green .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-green .boxstyle_.box5 .btn-link,
.brand-green .boxstyle-xs.box5 .btn-link,
.brand-green .boxstyle-sm.box5 .btn-link,
.brand-green .boxstyle-md.box5 .btn-link,
.brand-green .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-green .boxstyle_.box5 .btn-link:hover,
.brand-green .boxstyle-xs.box5 .btn-link:hover,
.brand-green .boxstyle-sm.box5 .btn-link:hover,
.brand-green .boxstyle-md.box5 .btn-link:hover,
.brand-green .boxstyle-lg.box5 .btn-link:hover,
.brand-green .boxstyle_.box5 .btn-link:focus,
.brand-green .boxstyle-xs.box5 .btn-link:focus,
.brand-green .boxstyle-sm.box5 .btn-link:focus,
.brand-green .boxstyle-md.box5 .btn-link:focus,
.brand-green .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-green .boxstyle_.box5 a,
.brand-green .boxstyle-xs.box5 a,
.brand-green .boxstyle-sm.box5 a,
.brand-green .boxstyle-md.box5 a,
.brand-green .boxstyle-lg.box5 a,
.brand-green .boxstyle_.box5 a:link,
.brand-green .boxstyle-xs.box5 a:link,
.brand-green .boxstyle-sm.box5 a:link,
.brand-green .boxstyle-md.box5 a:link,
.brand-green .boxstyle-lg.box5 a:link,
.brand-green .boxstyle_.box5 a:visited,
.brand-green .boxstyle-xs.box5 a:visited,
.brand-green .boxstyle-sm.box5 a:visited,
.brand-green .boxstyle-md.box5 a:visited,
.brand-green .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-green .boxstyle_.box5 a:hover,
.brand-green .boxstyle-xs.box5 a:hover,
.brand-green .boxstyle-sm.box5 a:hover,
.brand-green .boxstyle-md.box5 a:hover,
.brand-green .boxstyle-lg.box5 a:hover,
.brand-green .boxstyle_.box5 a:focus,
.brand-green .boxstyle-xs.box5 a:focus,
.brand-green .boxstyle-sm.box5 a:focus,
.brand-green .boxstyle-md.box5 a:focus,
.brand-green .boxstyle-lg.box5 a:focus,
.brand-green .boxstyle_.box5 a:active,
.brand-green .boxstyle-xs.box5 a:active,
.brand-green .boxstyle-sm.box5 a:active,
.brand-green .boxstyle-md.box5 a:active,
.brand-green .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-green .pagination > li a,
.brand-green .pagination > li span {
  color: #0F4001;
}
.brand-green .pagination > li.active > a,
.brand-green .pagination > li.active > span:not(.sr-only),
.brand-green .pagination > li.active > a:hover,
.brand-green .pagination > li.active > span:not(.sr-only):hover,
.brand-green .pagination > li.active > a:focus,
.brand-green .pagination > li.active > span:not(.sr-only):focus {
  background-color: #0F4001;
  border-color: #0F4001;
  color: white;
}
.brand-green .nav-tabs {
  border-bottom-color: #87a080;
}
.brand-green .nav-tabs > li > a {
  background-color: #cfd9cc;
  border-color: #87a080;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-green .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #6f8c67;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-green .nav-tabs > li > a:hover,
.brand-green .nav-tabs > li > a:focus {
  background-color: #e7ece6;
  border-color: #9fb399 #9fb399 #87a080;
}
.brand-green .nav-tabs > li > a:hover::before,
.brand-green .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-green .nav-tabs > li.open > a,
.brand-green .nav-tabs > li.open > a:hover,
.brand-green .nav-tabs > li.open > a:focus {
  background-color: #e7ece6;
  border-color: #9fb399 #9fb399 #87a080;
}
.brand-green .nav-tabs > li.open > a::before,
.brand-green .nav-tabs > li.open > a:hover::before,
.brand-green .nav-tabs > li.open > a:focus::before {
  background: #0F4001;
  width: 100%;
}
.brand-green .nav-tabs > li.active > a,
.brand-green .nav-tabs > li.active > a:hover,
.brand-green .nav-tabs > li.active > a:focus {
  border-color: #87a080 #87a080 transparent;
}
.brand-green .nav-tabs > li.active > a::before,
.brand-green .nav-tabs > li.active > a:hover::before,
.brand-green .nav-tabs > li.active > a:focus::before {
  background: #0F4001;
  width: 100%;
}
.brand-green .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-green .nav-tabs > li.disabled > a:hover,
.brand-green .nav-tabs > li.disabled > a:focus {
  background-color: #cfd9cc;
}
.brand-green .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-green .nav-pills > li.active > a,
.brand-green .nav-pills > li.active > a:hover,
.brand-green .nav-pills > li.active > a:focus {
  background-color: #0F4001;
  color: white;
}
.brand-green .list-group-item.active,
.brand-green .list-group-item.active:hover,
.brand-green .list-group-item.active:focus {
  background-color: #0F4001;
  border-color: #0F4001;
  color: white;
}
.brand-green .id7-koan-spinner__neon--top,
.brand-green .id7-koan-spinner__neon--bottom {
  stroke: #0F4001;
}
.brand-teal h1,
.brand-teal h2,
.brand-teal h3,
.brand-teal h4,
.brand-teal h5,
.brand-teal h6,
.brand-teal .h1,
.brand-teal .h2,
.brand-teal .h3,
.brand-teal .h4,
.brand-teal .h5,
.brand-teal .h6 {
  color: #507F70;
}
.brand-teal a,
.brand-teal a:link,
.brand-teal a:visited {
  color: #507F70;
}
.brand-teal a:hover,
.brand-teal a:focus,
.brand-teal a:active {
  color: #294039;
}
.brand-teal .popover button.close {
  color: #507F70;
}
.brand-teal .popover button.close:hover {
  color: #294039;
}
.brand-teal a:hover .new-window-link,
.brand-teal a:hover .insecure-link {
  color: #294039;
}
.brand-teal .btn-link {
  color: #507F70;
}
.brand-teal .btn-link:hover,
.brand-teal .btn-link:focus {
  color: #294039;
}
.brand-teal a,
.brand-teal a:link,
.brand-teal a:visited {
  -webkit-text-decoration-color: #84a59b;
  text-decoration-color: #84a59b;
}
.brand-teal a:hover,
.brand-teal a:focus,
.brand-teal a:active {
  -webkit-text-decoration-color: #294039;
  text-decoration-color: #294039;
}
.brand-teal blockquote.quotes {
  color: #294039;
}
.brand-teal blockquote.quotes::before {
  color: #294039;
}
.brand-teal .brand-bg {
  background: #507F70;
  color: white;
}
.brand-teal .brand-bg a,
.brand-teal .brand-bg a:link,
.brand-teal .brand-bg a:visited {
  color: white !important;
}
.brand-teal .brand-bg a:hover,
.brand-teal .brand-bg a:focus,
.brand-teal .brand-bg a:active {
  color: #cccccc !important;
}
.brand-teal .brand-bg .popover button.close {
  color: white !important;
}
.brand-teal .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-teal .brand-bg a:hover .new-window-link,
.brand-teal .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-teal .brand-bg .btn-link {
  color: white !important;
}
.brand-teal .brand-bg .btn-link:hover,
.brand-teal .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-teal .brand-bg a,
.brand-teal .brand-bg a:link,
.brand-teal .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-teal .brand-bg a:hover,
.brand-teal .brand-bg a:focus,
.brand-teal .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-teal .brand-text {
  color: #507F70;
}
.brand-teal .brand-text a,
.brand-teal .brand-text a:link,
.brand-teal .brand-text a:visited {
  color: #507F70 !important;
}
.brand-teal .brand-text a:hover,
.brand-teal .brand-text a:focus,
.brand-teal .brand-text a:active {
  color: #294039 !important;
}
.brand-teal .brand-text .popover button.close {
  color: #507F70 !important;
}
.brand-teal .brand-text .popover button.close:hover {
  color: #294039 !important;
}
.brand-teal .brand-text a:hover .new-window-link,
.brand-teal .brand-text a:hover .insecure-link {
  color: #294039 !important;
}
.brand-teal .brand-text .btn-link {
  color: #507F70 !important;
}
.brand-teal .brand-text .btn-link:hover,
.brand-teal .brand-text .btn-link:focus {
  color: #294039 !important;
}
.brand-teal .brand-text a,
.brand-teal .brand-text a:link,
.brand-teal .brand-text a:visited {
  -webkit-text-decoration-color: #84a59b !important;
  text-decoration-color: #84a59b !important;
}
.brand-teal .brand-text a:hover,
.brand-teal .brand-text a:focus,
.brand-teal .brand-text a:active {
  -webkit-text-decoration-color: #294039 !important;
  text-decoration-color: #294039 !important;
}
.brand-teal .brand-border {
  border-color: #507F70;
}
.brand-teal .btn-brand {
  color: white;
  background-color: #507F70;
  border-color: #466f62;
  color: white !important;
}
.brand-teal .btn-brand:focus,
.brand-teal .btn-brand.focus {
  color: white;
  background-color: #3c6054;
  border-color: #15211d;
}
.brand-teal .btn-brand:hover {
  color: white;
  background-color: #3c6054;
  border-color: #2e4a41;
}
.brand-teal .btn-brand:active,
.brand-teal .btn-brand.active,
.open > .dropdown-toggle.brand-teal .btn-brand {
  color: white;
  background-color: #3c6054;
  background-image: none;
  border-color: #2e4a41;
}
.brand-teal .btn-brand:active:hover,
.brand-teal .btn-brand.active:hover,
.open > .dropdown-toggle.brand-teal .btn-brand:hover,
.brand-teal .btn-brand:active:focus,
.brand-teal .btn-brand.active:focus,
.open > .dropdown-toggle.brand-teal .btn-brand:focus,
.brand-teal .btn-brand:active.focus,
.brand-teal .btn-brand.active.focus,
.open > .dropdown-toggle.brand-teal .btn-brand.focus {
  color: white;
  background-color: #2e4a41;
  border-color: #15211d;
}
.brand-teal .btn-brand.disabled:hover,
.brand-teal .btn-brand[disabled]:hover,
fieldset[disabled] .brand-teal .btn-brand:hover,
.brand-teal .btn-brand.disabled:focus,
.brand-teal .btn-brand[disabled]:focus,
fieldset[disabled] .brand-teal .btn-brand:focus,
.brand-teal .btn-brand.disabled.focus,
.brand-teal .btn-brand[disabled].focus,
fieldset[disabled] .brand-teal .btn-brand.focus {
  background-color: #507F70;
  border-color: #466f62;
}
.brand-teal .btn-brand .badge {
  color: #507F70;
  background-color: white;
}
.brand-teal .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-teal .id7-utility-masthead {
  background-color: #84a59b;
}
.brand-teal .id7-utility-masthead::after {
  background-color: #507F70;
}
body.id7-point-1 .brand-teal .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-teal .id7-navigation .navbar-primary {
  background-color: #507F70;
}
.brand-teal .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-teal .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #b6c9c3;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #40665a;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #b6c9c3;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-teal .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-teal .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #40665a;
}
.brand-teal .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-teal .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-teal .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-teal .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #40665a;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #507F70;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #40665a;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #40665a;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #40665a;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #40665a;
  background-image: none;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #b6c9c3;
}
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-teal .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #40665a;
}
.brand-teal .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .btn-link:hover,
.brand-teal .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-teal .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-teal .id7-navigation .navbar-primary .btn-link:hover,
.brand-teal .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-teal .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-teal .navbar-primary .navbar-brand {
  background-color: #507F70;
}
.brand-teal .navbar-secondary {
  background-color: #b6c9c3;
}
.brand-teal .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-teal .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-teal .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-teal .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-teal .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-teal .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-teal .navbar-secondary .navbar-nav > li:hover,
.brand-teal .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #ccd9d5;
}
.brand-teal .navbar-secondary .navbar-nav > li > a,
.brand-teal .navbar-secondary .navbar-nav > li > a:hover,
.brand-teal .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-teal .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-teal .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-teal .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-teal .navbar-secondary .navbar-nav > .disabled,
.brand-teal .navbar-secondary .navbar-nav > .disabled:hover,
.brand-teal .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #ccd9d5;
}
.brand-teal .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-teal .navbar-secondary .navbar-toggle:hover,
.brand-teal .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-teal .navbar-secondary .navbar-nav > .open,
.brand-teal .navbar-secondary .navbar-nav > .open:hover,
.brand-teal .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #ccd9d5;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #b6c9c3;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #91a19c;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #91a19c;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #ccd9d5;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #ccd9d5;
  background-image: none;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-teal .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #ccd9d5;
}
.brand-teal .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-teal .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-teal .navbar-secondary .btn-link {
  color: #202020;
}
.brand-teal .navbar-secondary .btn-link:hover,
.brand-teal .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-teal .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-teal .navbar-secondary .btn-link:hover,
.brand-teal .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-teal .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-teal .navbar-secondary .navbar-nav > li::after {
  color: #507F70;
}
.brand-teal .navbar-secondary .navbar-nav > li:first-child {
  background-color: #507F70;
  color: white;
}
.brand-teal .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #507F70;
}
.brand-teal .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #507F70;
}
.brand-teal .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-teal .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-teal .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-teal .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-teal .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-teal .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-teal .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-teal .navbar-tertiary .navbar-nav > li:hover,
.brand-teal .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal .navbar-tertiary .navbar-nav > li > a,
.brand-teal .navbar-tertiary .navbar-nav > li > a:hover,
.brand-teal .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-teal .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-teal .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-teal .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-teal .navbar-tertiary .navbar-nav > .disabled,
.brand-teal .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-teal .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-teal .navbar-tertiary .navbar-toggle:hover,
.brand-teal .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-teal .navbar-tertiary .navbar-nav > .open,
.brand-teal .navbar-tertiary .navbar-nav > .open:hover,
.brand-teal .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-teal .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-teal .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-teal .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-teal .navbar-tertiary .btn-link:hover,
.brand-teal .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-teal .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-teal .navbar-tertiary .btn-link:hover,
.brand-teal .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-teal .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-teal.bordered .id7-left-border,
.brand-teal.bordered .id7-right-border {
  border-color: rgba(80, 127, 112, 0.3);
}
.brand-teal .id7-page-footer .id7-app-footer {
  background: #507F70;
  color: white;
}
.brand-teal .id7-page-footer .id7-app-footer h1,
.brand-teal .id7-page-footer .id7-app-footer h2,
.brand-teal .id7-page-footer .id7-app-footer h3,
.brand-teal .id7-page-footer .id7-app-footer h4,
.brand-teal .id7-page-footer .id7-app-footer h5,
.brand-teal .id7-page-footer .id7-app-footer h6,
.brand-teal .id7-page-footer .id7-app-footer .h1,
.brand-teal .id7-page-footer .id7-app-footer .h2,
.brand-teal .id7-page-footer .id7-app-footer .h3,
.brand-teal .id7-page-footer .id7-app-footer .h4,
.brand-teal .id7-page-footer .id7-app-footer .h5,
.brand-teal .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-teal .id7-page-footer .id7-app-footer a,
.brand-teal .id7-page-footer .id7-app-footer a:link,
.brand-teal .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-teal .id7-page-footer .id7-app-footer a:hover,
.brand-teal .id7-page-footer .id7-app-footer a:focus,
.brand-teal .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-teal .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-teal .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-teal .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-teal .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-teal .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-teal .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-teal .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-teal .id7-page-footer .id7-app-footer a,
.brand-teal .id7-page-footer .id7-app-footer a:link,
.brand-teal .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-teal .id7-page-footer .id7-app-footer a:hover,
.brand-teal .id7-page-footer .id7-app-footer a:focus,
.brand-teal .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-teal .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #507F70;
}
.brand-teal .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-teal .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-teal .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-teal .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-teal .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-teal .id7-search .fa,
.brand-teal .id7-search .fas {
  color: #507F70;
  color: rgba(80, 127, 112, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-teal .id7-search .form-control:hover + .fa,
.brand-teal .id7-search .typeahead:hover + .fa,
.brand-teal .id7-search .fa:hover,
.brand-teal .id7-search .form-control:hover + .fas,
.brand-teal .id7-search .typeahead:hover + .fas,
.brand-teal .id7-search .fas:hover {
  color: #507F70;
}
.brand-teal .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #507F70;
  color: white;
}
.brand-teal .form-control:focus {
  border-color: #507F70;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(80, 127, 112, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(80, 127, 112, 0.6);
}
.brand-teal .carousel .carousel-indicators li::after {
  color: #507F70;
  color: rgba(80, 127, 112, 0.3);
}
.brand-teal .carousel .carousel-indicators li.active::after {
  color: #507F70;
}
@media (max-width: 767px) {
  .brand-teal .boxstyle_.box1,
  .brand-teal .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-teal .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-teal .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-teal .boxstyle_.box1,
.brand-teal .boxstyle-xs.box1,
.brand-teal .boxstyle-sm.box1,
.brand-teal .boxstyle-md.box1,
.brand-teal .boxstyle-lg.box1 {
  border: 0;
  background: #e5ecea;
  color: #202020;
}
@media print {
  .brand-teal .boxstyle_.box1,
  .brand-teal .boxstyle-xs.box1,
  .brand-teal .boxstyle-sm.box1,
  .brand-teal .boxstyle-md.box1,
  .brand-teal .boxstyle-lg.box1 {
    border: 1px solid #e5ecea;
  }
}
.brand-teal .boxstyle_.box1 h1,
.brand-teal .boxstyle-xs.box1 h1,
.brand-teal .boxstyle-sm.box1 h1,
.brand-teal .boxstyle-md.box1 h1,
.brand-teal .boxstyle-lg.box1 h1,
.brand-teal .boxstyle_.box1 h2,
.brand-teal .boxstyle-xs.box1 h2,
.brand-teal .boxstyle-sm.box1 h2,
.brand-teal .boxstyle-md.box1 h2,
.brand-teal .boxstyle-lg.box1 h2,
.brand-teal .boxstyle_.box1 h3,
.brand-teal .boxstyle-xs.box1 h3,
.brand-teal .boxstyle-sm.box1 h3,
.brand-teal .boxstyle-md.box1 h3,
.brand-teal .boxstyle-lg.box1 h3,
.brand-teal .boxstyle_.box1 h4,
.brand-teal .boxstyle-xs.box1 h4,
.brand-teal .boxstyle-sm.box1 h4,
.brand-teal .boxstyle-md.box1 h4,
.brand-teal .boxstyle-lg.box1 h4,
.brand-teal .boxstyle_.box1 h5,
.brand-teal .boxstyle-xs.box1 h5,
.brand-teal .boxstyle-sm.box1 h5,
.brand-teal .boxstyle-md.box1 h5,
.brand-teal .boxstyle-lg.box1 h5,
.brand-teal .boxstyle_.box1 h6,
.brand-teal .boxstyle-xs.box1 h6,
.brand-teal .boxstyle-sm.box1 h6,
.brand-teal .boxstyle-md.box1 h6,
.brand-teal .boxstyle-lg.box1 h6,
.brand-teal .boxstyle_.box1 .h1,
.brand-teal .boxstyle-xs.box1 .h1,
.brand-teal .boxstyle-sm.box1 .h1,
.brand-teal .boxstyle-md.box1 .h1,
.brand-teal .boxstyle-lg.box1 .h1,
.brand-teal .boxstyle_.box1 .h2,
.brand-teal .boxstyle-xs.box1 .h2,
.brand-teal .boxstyle-sm.box1 .h2,
.brand-teal .boxstyle-md.box1 .h2,
.brand-teal .boxstyle-lg.box1 .h2,
.brand-teal .boxstyle_.box1 .h3,
.brand-teal .boxstyle-xs.box1 .h3,
.brand-teal .boxstyle-sm.box1 .h3,
.brand-teal .boxstyle-md.box1 .h3,
.brand-teal .boxstyle-lg.box1 .h3,
.brand-teal .boxstyle_.box1 .h4,
.brand-teal .boxstyle-xs.box1 .h4,
.brand-teal .boxstyle-sm.box1 .h4,
.brand-teal .boxstyle-md.box1 .h4,
.brand-teal .boxstyle-lg.box1 .h4,
.brand-teal .boxstyle_.box1 .h5,
.brand-teal .boxstyle-xs.box1 .h5,
.brand-teal .boxstyle-sm.box1 .h5,
.brand-teal .boxstyle-md.box1 .h5,
.brand-teal .boxstyle-lg.box1 .h5,
.brand-teal .boxstyle_.box1 .h6,
.brand-teal .boxstyle-xs.box1 .h6,
.brand-teal .boxstyle-sm.box1 .h6,
.brand-teal .boxstyle-md.box1 .h6,
.brand-teal .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-teal .boxstyle_.box1 blockquote.quotes,
.brand-teal .boxstyle-xs.box1 blockquote.quotes,
.brand-teal .boxstyle-sm.box1 blockquote.quotes,
.brand-teal .boxstyle-md.box1 blockquote.quotes,
.brand-teal .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-teal .boxstyle_.box1 a,
.brand-teal .boxstyle-xs.box1 a,
.brand-teal .boxstyle-sm.box1 a,
.brand-teal .boxstyle-md.box1 a,
.brand-teal .boxstyle-lg.box1 a,
.brand-teal .boxstyle_.box1 a:link,
.brand-teal .boxstyle-xs.box1 a:link,
.brand-teal .boxstyle-sm.box1 a:link,
.brand-teal .boxstyle-md.box1 a:link,
.brand-teal .boxstyle-lg.box1 a:link,
.brand-teal .boxstyle_.box1 a:visited,
.brand-teal .boxstyle-xs.box1 a:visited,
.brand-teal .boxstyle-sm.box1 a:visited,
.brand-teal .boxstyle-md.box1 a:visited,
.brand-teal .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-teal .boxstyle_.box1 a:hover,
.brand-teal .boxstyle-xs.box1 a:hover,
.brand-teal .boxstyle-sm.box1 a:hover,
.brand-teal .boxstyle-md.box1 a:hover,
.brand-teal .boxstyle-lg.box1 a:hover,
.brand-teal .boxstyle_.box1 a:focus,
.brand-teal .boxstyle-xs.box1 a:focus,
.brand-teal .boxstyle-sm.box1 a:focus,
.brand-teal .boxstyle-md.box1 a:focus,
.brand-teal .boxstyle-lg.box1 a:focus,
.brand-teal .boxstyle_.box1 a:active,
.brand-teal .boxstyle-xs.box1 a:active,
.brand-teal .boxstyle-sm.box1 a:active,
.brand-teal .boxstyle-md.box1 a:active,
.brand-teal .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-teal .boxstyle_.box1 .popover button.close,
.brand-teal .boxstyle-xs.box1 .popover button.close,
.brand-teal .boxstyle-sm.box1 .popover button.close,
.brand-teal .boxstyle-md.box1 .popover button.close,
.brand-teal .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-teal .boxstyle_.box1 .popover button.close:hover,
.brand-teal .boxstyle-xs.box1 .popover button.close:hover,
.brand-teal .boxstyle-sm.box1 .popover button.close:hover,
.brand-teal .boxstyle-md.box1 .popover button.close:hover,
.brand-teal .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-teal .boxstyle_.box1 a:hover .new-window-link,
.brand-teal .boxstyle-xs.box1 a:hover .new-window-link,
.brand-teal .boxstyle-sm.box1 a:hover .new-window-link,
.brand-teal .boxstyle-md.box1 a:hover .new-window-link,
.brand-teal .boxstyle-lg.box1 a:hover .new-window-link,
.brand-teal .boxstyle_.box1 a:hover .insecure-link,
.brand-teal .boxstyle-xs.box1 a:hover .insecure-link,
.brand-teal .boxstyle-sm.box1 a:hover .insecure-link,
.brand-teal .boxstyle-md.box1 a:hover .insecure-link,
.brand-teal .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-teal .boxstyle_.box1 .btn-link,
.brand-teal .boxstyle-xs.box1 .btn-link,
.brand-teal .boxstyle-sm.box1 .btn-link,
.brand-teal .boxstyle-md.box1 .btn-link,
.brand-teal .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-teal .boxstyle_.box1 .btn-link:hover,
.brand-teal .boxstyle-xs.box1 .btn-link:hover,
.brand-teal .boxstyle-sm.box1 .btn-link:hover,
.brand-teal .boxstyle-md.box1 .btn-link:hover,
.brand-teal .boxstyle-lg.box1 .btn-link:hover,
.brand-teal .boxstyle_.box1 .btn-link:focus,
.brand-teal .boxstyle-xs.box1 .btn-link:focus,
.brand-teal .boxstyle-sm.box1 .btn-link:focus,
.brand-teal .boxstyle-md.box1 .btn-link:focus,
.brand-teal .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-teal .boxstyle_.box1 a,
.brand-teal .boxstyle-xs.box1 a,
.brand-teal .boxstyle-sm.box1 a,
.brand-teal .boxstyle-md.box1 a,
.brand-teal .boxstyle-lg.box1 a,
.brand-teal .boxstyle_.box1 a:link,
.brand-teal .boxstyle-xs.box1 a:link,
.brand-teal .boxstyle-sm.box1 a:link,
.brand-teal .boxstyle-md.box1 a:link,
.brand-teal .boxstyle-lg.box1 a:link,
.brand-teal .boxstyle_.box1 a:visited,
.brand-teal .boxstyle-xs.box1 a:visited,
.brand-teal .boxstyle-sm.box1 a:visited,
.brand-teal .boxstyle-md.box1 a:visited,
.brand-teal .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal .boxstyle_.box1 a:hover,
.brand-teal .boxstyle-xs.box1 a:hover,
.brand-teal .boxstyle-sm.box1 a:hover,
.brand-teal .boxstyle-md.box1 a:hover,
.brand-teal .boxstyle-lg.box1 a:hover,
.brand-teal .boxstyle_.box1 a:focus,
.brand-teal .boxstyle-xs.box1 a:focus,
.brand-teal .boxstyle-sm.box1 a:focus,
.brand-teal .boxstyle-md.box1 a:focus,
.brand-teal .boxstyle-lg.box1 a:focus,
.brand-teal .boxstyle_.box1 a:active,
.brand-teal .boxstyle-xs.box1 a:active,
.brand-teal .boxstyle-sm.box1 a:active,
.brand-teal .boxstyle-md.box1 a:active,
.brand-teal .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-teal .boxstyle_.box2,
  .brand-teal .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-teal .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-teal .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-teal .boxstyle_.box2,
.brand-teal .boxstyle-xs.box2,
.brand-teal .boxstyle-sm.box2,
.brand-teal .boxstyle-md.box2,
.brand-teal .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-teal .boxstyle_.box2,
  .brand-teal .boxstyle-xs.box2,
  .brand-teal .boxstyle-sm.box2,
  .brand-teal .boxstyle-md.box2,
  .brand-teal .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-teal .boxstyle_.box2 h1,
.brand-teal .boxstyle-xs.box2 h1,
.brand-teal .boxstyle-sm.box2 h1,
.brand-teal .boxstyle-md.box2 h1,
.brand-teal .boxstyle-lg.box2 h1,
.brand-teal .boxstyle_.box2 h2,
.brand-teal .boxstyle-xs.box2 h2,
.brand-teal .boxstyle-sm.box2 h2,
.brand-teal .boxstyle-md.box2 h2,
.brand-teal .boxstyle-lg.box2 h2,
.brand-teal .boxstyle_.box2 h3,
.brand-teal .boxstyle-xs.box2 h3,
.brand-teal .boxstyle-sm.box2 h3,
.brand-teal .boxstyle-md.box2 h3,
.brand-teal .boxstyle-lg.box2 h3,
.brand-teal .boxstyle_.box2 h4,
.brand-teal .boxstyle-xs.box2 h4,
.brand-teal .boxstyle-sm.box2 h4,
.brand-teal .boxstyle-md.box2 h4,
.brand-teal .boxstyle-lg.box2 h4,
.brand-teal .boxstyle_.box2 h5,
.brand-teal .boxstyle-xs.box2 h5,
.brand-teal .boxstyle-sm.box2 h5,
.brand-teal .boxstyle-md.box2 h5,
.brand-teal .boxstyle-lg.box2 h5,
.brand-teal .boxstyle_.box2 h6,
.brand-teal .boxstyle-xs.box2 h6,
.brand-teal .boxstyle-sm.box2 h6,
.brand-teal .boxstyle-md.box2 h6,
.brand-teal .boxstyle-lg.box2 h6,
.brand-teal .boxstyle_.box2 .h1,
.brand-teal .boxstyle-xs.box2 .h1,
.brand-teal .boxstyle-sm.box2 .h1,
.brand-teal .boxstyle-md.box2 .h1,
.brand-teal .boxstyle-lg.box2 .h1,
.brand-teal .boxstyle_.box2 .h2,
.brand-teal .boxstyle-xs.box2 .h2,
.brand-teal .boxstyle-sm.box2 .h2,
.brand-teal .boxstyle-md.box2 .h2,
.brand-teal .boxstyle-lg.box2 .h2,
.brand-teal .boxstyle_.box2 .h3,
.brand-teal .boxstyle-xs.box2 .h3,
.brand-teal .boxstyle-sm.box2 .h3,
.brand-teal .boxstyle-md.box2 .h3,
.brand-teal .boxstyle-lg.box2 .h3,
.brand-teal .boxstyle_.box2 .h4,
.brand-teal .boxstyle-xs.box2 .h4,
.brand-teal .boxstyle-sm.box2 .h4,
.brand-teal .boxstyle-md.box2 .h4,
.brand-teal .boxstyle-lg.box2 .h4,
.brand-teal .boxstyle_.box2 .h5,
.brand-teal .boxstyle-xs.box2 .h5,
.brand-teal .boxstyle-sm.box2 .h5,
.brand-teal .boxstyle-md.box2 .h5,
.brand-teal .boxstyle-lg.box2 .h5,
.brand-teal .boxstyle_.box2 .h6,
.brand-teal .boxstyle-xs.box2 .h6,
.brand-teal .boxstyle-sm.box2 .h6,
.brand-teal .boxstyle-md.box2 .h6,
.brand-teal .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-teal .boxstyle_.box2 blockquote.quotes,
.brand-teal .boxstyle-xs.box2 blockquote.quotes,
.brand-teal .boxstyle-sm.box2 blockquote.quotes,
.brand-teal .boxstyle-md.box2 blockquote.quotes,
.brand-teal .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-teal .boxstyle_.box2 a,
.brand-teal .boxstyle-xs.box2 a,
.brand-teal .boxstyle-sm.box2 a,
.brand-teal .boxstyle-md.box2 a,
.brand-teal .boxstyle-lg.box2 a,
.brand-teal .boxstyle_.box2 a:link,
.brand-teal .boxstyle-xs.box2 a:link,
.brand-teal .boxstyle-sm.box2 a:link,
.brand-teal .boxstyle-md.box2 a:link,
.brand-teal .boxstyle-lg.box2 a:link,
.brand-teal .boxstyle_.box2 a:visited,
.brand-teal .boxstyle-xs.box2 a:visited,
.brand-teal .boxstyle-sm.box2 a:visited,
.brand-teal .boxstyle-md.box2 a:visited,
.brand-teal .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-teal .boxstyle_.box2 a:hover,
.brand-teal .boxstyle-xs.box2 a:hover,
.brand-teal .boxstyle-sm.box2 a:hover,
.brand-teal .boxstyle-md.box2 a:hover,
.brand-teal .boxstyle-lg.box2 a:hover,
.brand-teal .boxstyle_.box2 a:focus,
.brand-teal .boxstyle-xs.box2 a:focus,
.brand-teal .boxstyle-sm.box2 a:focus,
.brand-teal .boxstyle-md.box2 a:focus,
.brand-teal .boxstyle-lg.box2 a:focus,
.brand-teal .boxstyle_.box2 a:active,
.brand-teal .boxstyle-xs.box2 a:active,
.brand-teal .boxstyle-sm.box2 a:active,
.brand-teal .boxstyle-md.box2 a:active,
.brand-teal .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-teal .boxstyle_.box2 .popover button.close,
.brand-teal .boxstyle-xs.box2 .popover button.close,
.brand-teal .boxstyle-sm.box2 .popover button.close,
.brand-teal .boxstyle-md.box2 .popover button.close,
.brand-teal .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-teal .boxstyle_.box2 .popover button.close:hover,
.brand-teal .boxstyle-xs.box2 .popover button.close:hover,
.brand-teal .boxstyle-sm.box2 .popover button.close:hover,
.brand-teal .boxstyle-md.box2 .popover button.close:hover,
.brand-teal .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-teal .boxstyle_.box2 a:hover .new-window-link,
.brand-teal .boxstyle-xs.box2 a:hover .new-window-link,
.brand-teal .boxstyle-sm.box2 a:hover .new-window-link,
.brand-teal .boxstyle-md.box2 a:hover .new-window-link,
.brand-teal .boxstyle-lg.box2 a:hover .new-window-link,
.brand-teal .boxstyle_.box2 a:hover .insecure-link,
.brand-teal .boxstyle-xs.box2 a:hover .insecure-link,
.brand-teal .boxstyle-sm.box2 a:hover .insecure-link,
.brand-teal .boxstyle-md.box2 a:hover .insecure-link,
.brand-teal .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-teal .boxstyle_.box2 .btn-link,
.brand-teal .boxstyle-xs.box2 .btn-link,
.brand-teal .boxstyle-sm.box2 .btn-link,
.brand-teal .boxstyle-md.box2 .btn-link,
.brand-teal .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-teal .boxstyle_.box2 .btn-link:hover,
.brand-teal .boxstyle-xs.box2 .btn-link:hover,
.brand-teal .boxstyle-sm.box2 .btn-link:hover,
.brand-teal .boxstyle-md.box2 .btn-link:hover,
.brand-teal .boxstyle-lg.box2 .btn-link:hover,
.brand-teal .boxstyle_.box2 .btn-link:focus,
.brand-teal .boxstyle-xs.box2 .btn-link:focus,
.brand-teal .boxstyle-sm.box2 .btn-link:focus,
.brand-teal .boxstyle-md.box2 .btn-link:focus,
.brand-teal .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-teal .boxstyle_.box2 a,
.brand-teal .boxstyle-xs.box2 a,
.brand-teal .boxstyle-sm.box2 a,
.brand-teal .boxstyle-md.box2 a,
.brand-teal .boxstyle-lg.box2 a,
.brand-teal .boxstyle_.box2 a:link,
.brand-teal .boxstyle-xs.box2 a:link,
.brand-teal .boxstyle-sm.box2 a:link,
.brand-teal .boxstyle-md.box2 a:link,
.brand-teal .boxstyle-lg.box2 a:link,
.brand-teal .boxstyle_.box2 a:visited,
.brand-teal .boxstyle-xs.box2 a:visited,
.brand-teal .boxstyle-sm.box2 a:visited,
.brand-teal .boxstyle-md.box2 a:visited,
.brand-teal .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal .boxstyle_.box2 a:hover,
.brand-teal .boxstyle-xs.box2 a:hover,
.brand-teal .boxstyle-sm.box2 a:hover,
.brand-teal .boxstyle-md.box2 a:hover,
.brand-teal .boxstyle-lg.box2 a:hover,
.brand-teal .boxstyle_.box2 a:focus,
.brand-teal .boxstyle-xs.box2 a:focus,
.brand-teal .boxstyle-sm.box2 a:focus,
.brand-teal .boxstyle-md.box2 a:focus,
.brand-teal .boxstyle-lg.box2 a:focus,
.brand-teal .boxstyle_.box2 a:active,
.brand-teal .boxstyle-xs.box2 a:active,
.brand-teal .boxstyle-sm.box2 a:active,
.brand-teal .boxstyle-md.box2 a:active,
.brand-teal .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-teal .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #507F70;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-teal .boxstyle_.box3,
  .brand-teal .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #507F70;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-teal .boxstyle-md.box3 {
    background: none;
    border: 1px solid #507F70;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-teal .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #507F70;
    color: inherit;
  }
}
.brand-teal .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-teal .boxstyle_.box4,
  .brand-teal .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-teal .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-teal .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-teal .boxstyle_.box5,
  .brand-teal .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-teal .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-teal .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-teal .boxstyle_.box5,
.brand-teal .boxstyle-xs.box5,
.brand-teal .boxstyle-sm.box5,
.brand-teal .boxstyle-md.box5,
.brand-teal .boxstyle-lg.box5 {
  border: 0;
  background: #507F70;
  color: white;
}
@media print {
  .brand-teal .boxstyle_.box5,
  .brand-teal .boxstyle-xs.box5,
  .brand-teal .boxstyle-sm.box5,
  .brand-teal .boxstyle-md.box5,
  .brand-teal .boxstyle-lg.box5 {
    border: 1px solid #507F70;
  }
}
.brand-teal .boxstyle_.box5 h1,
.brand-teal .boxstyle-xs.box5 h1,
.brand-teal .boxstyle-sm.box5 h1,
.brand-teal .boxstyle-md.box5 h1,
.brand-teal .boxstyle-lg.box5 h1,
.brand-teal .boxstyle_.box5 h2,
.brand-teal .boxstyle-xs.box5 h2,
.brand-teal .boxstyle-sm.box5 h2,
.brand-teal .boxstyle-md.box5 h2,
.brand-teal .boxstyle-lg.box5 h2,
.brand-teal .boxstyle_.box5 h3,
.brand-teal .boxstyle-xs.box5 h3,
.brand-teal .boxstyle-sm.box5 h3,
.brand-teal .boxstyle-md.box5 h3,
.brand-teal .boxstyle-lg.box5 h3,
.brand-teal .boxstyle_.box5 h4,
.brand-teal .boxstyle-xs.box5 h4,
.brand-teal .boxstyle-sm.box5 h4,
.brand-teal .boxstyle-md.box5 h4,
.brand-teal .boxstyle-lg.box5 h4,
.brand-teal .boxstyle_.box5 h5,
.brand-teal .boxstyle-xs.box5 h5,
.brand-teal .boxstyle-sm.box5 h5,
.brand-teal .boxstyle-md.box5 h5,
.brand-teal .boxstyle-lg.box5 h5,
.brand-teal .boxstyle_.box5 h6,
.brand-teal .boxstyle-xs.box5 h6,
.brand-teal .boxstyle-sm.box5 h6,
.brand-teal .boxstyle-md.box5 h6,
.brand-teal .boxstyle-lg.box5 h6,
.brand-teal .boxstyle_.box5 .h1,
.brand-teal .boxstyle-xs.box5 .h1,
.brand-teal .boxstyle-sm.box5 .h1,
.brand-teal .boxstyle-md.box5 .h1,
.brand-teal .boxstyle-lg.box5 .h1,
.brand-teal .boxstyle_.box5 .h2,
.brand-teal .boxstyle-xs.box5 .h2,
.brand-teal .boxstyle-sm.box5 .h2,
.brand-teal .boxstyle-md.box5 .h2,
.brand-teal .boxstyle-lg.box5 .h2,
.brand-teal .boxstyle_.box5 .h3,
.brand-teal .boxstyle-xs.box5 .h3,
.brand-teal .boxstyle-sm.box5 .h3,
.brand-teal .boxstyle-md.box5 .h3,
.brand-teal .boxstyle-lg.box5 .h3,
.brand-teal .boxstyle_.box5 .h4,
.brand-teal .boxstyle-xs.box5 .h4,
.brand-teal .boxstyle-sm.box5 .h4,
.brand-teal .boxstyle-md.box5 .h4,
.brand-teal .boxstyle-lg.box5 .h4,
.brand-teal .boxstyle_.box5 .h5,
.brand-teal .boxstyle-xs.box5 .h5,
.brand-teal .boxstyle-sm.box5 .h5,
.brand-teal .boxstyle-md.box5 .h5,
.brand-teal .boxstyle-lg.box5 .h5,
.brand-teal .boxstyle_.box5 .h6,
.brand-teal .boxstyle-xs.box5 .h6,
.brand-teal .boxstyle-sm.box5 .h6,
.brand-teal .boxstyle-md.box5 .h6,
.brand-teal .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-teal .boxstyle_.box5 blockquote.quotes,
.brand-teal .boxstyle-xs.box5 blockquote.quotes,
.brand-teal .boxstyle-sm.box5 blockquote.quotes,
.brand-teal .boxstyle-md.box5 blockquote.quotes,
.brand-teal .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-teal .boxstyle_.box5 a,
.brand-teal .boxstyle-xs.box5 a,
.brand-teal .boxstyle-sm.box5 a,
.brand-teal .boxstyle-md.box5 a,
.brand-teal .boxstyle-lg.box5 a,
.brand-teal .boxstyle_.box5 a:link,
.brand-teal .boxstyle-xs.box5 a:link,
.brand-teal .boxstyle-sm.box5 a:link,
.brand-teal .boxstyle-md.box5 a:link,
.brand-teal .boxstyle-lg.box5 a:link,
.brand-teal .boxstyle_.box5 a:visited,
.brand-teal .boxstyle-xs.box5 a:visited,
.brand-teal .boxstyle-sm.box5 a:visited,
.brand-teal .boxstyle-md.box5 a:visited,
.brand-teal .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-teal .boxstyle_.box5 a:hover,
.brand-teal .boxstyle-xs.box5 a:hover,
.brand-teal .boxstyle-sm.box5 a:hover,
.brand-teal .boxstyle-md.box5 a:hover,
.brand-teal .boxstyle-lg.box5 a:hover,
.brand-teal .boxstyle_.box5 a:focus,
.brand-teal .boxstyle-xs.box5 a:focus,
.brand-teal .boxstyle-sm.box5 a:focus,
.brand-teal .boxstyle-md.box5 a:focus,
.brand-teal .boxstyle-lg.box5 a:focus,
.brand-teal .boxstyle_.box5 a:active,
.brand-teal .boxstyle-xs.box5 a:active,
.brand-teal .boxstyle-sm.box5 a:active,
.brand-teal .boxstyle-md.box5 a:active,
.brand-teal .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-teal .boxstyle_.box5 .popover button.close,
.brand-teal .boxstyle-xs.box5 .popover button.close,
.brand-teal .boxstyle-sm.box5 .popover button.close,
.brand-teal .boxstyle-md.box5 .popover button.close,
.brand-teal .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-teal .boxstyle_.box5 .popover button.close:hover,
.brand-teal .boxstyle-xs.box5 .popover button.close:hover,
.brand-teal .boxstyle-sm.box5 .popover button.close:hover,
.brand-teal .boxstyle-md.box5 .popover button.close:hover,
.brand-teal .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-teal .boxstyle_.box5 a:hover .new-window-link,
.brand-teal .boxstyle-xs.box5 a:hover .new-window-link,
.brand-teal .boxstyle-sm.box5 a:hover .new-window-link,
.brand-teal .boxstyle-md.box5 a:hover .new-window-link,
.brand-teal .boxstyle-lg.box5 a:hover .new-window-link,
.brand-teal .boxstyle_.box5 a:hover .insecure-link,
.brand-teal .boxstyle-xs.box5 a:hover .insecure-link,
.brand-teal .boxstyle-sm.box5 a:hover .insecure-link,
.brand-teal .boxstyle-md.box5 a:hover .insecure-link,
.brand-teal .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-teal .boxstyle_.box5 .btn-link,
.brand-teal .boxstyle-xs.box5 .btn-link,
.brand-teal .boxstyle-sm.box5 .btn-link,
.brand-teal .boxstyle-md.box5 .btn-link,
.brand-teal .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-teal .boxstyle_.box5 .btn-link:hover,
.brand-teal .boxstyle-xs.box5 .btn-link:hover,
.brand-teal .boxstyle-sm.box5 .btn-link:hover,
.brand-teal .boxstyle-md.box5 .btn-link:hover,
.brand-teal .boxstyle-lg.box5 .btn-link:hover,
.brand-teal .boxstyle_.box5 .btn-link:focus,
.brand-teal .boxstyle-xs.box5 .btn-link:focus,
.brand-teal .boxstyle-sm.box5 .btn-link:focus,
.brand-teal .boxstyle-md.box5 .btn-link:focus,
.brand-teal .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-teal .boxstyle_.box5 a,
.brand-teal .boxstyle-xs.box5 a,
.brand-teal .boxstyle-sm.box5 a,
.brand-teal .boxstyle-md.box5 a,
.brand-teal .boxstyle-lg.box5 a,
.brand-teal .boxstyle_.box5 a:link,
.brand-teal .boxstyle-xs.box5 a:link,
.brand-teal .boxstyle-sm.box5 a:link,
.brand-teal .boxstyle-md.box5 a:link,
.brand-teal .boxstyle-lg.box5 a:link,
.brand-teal .boxstyle_.box5 a:visited,
.brand-teal .boxstyle-xs.box5 a:visited,
.brand-teal .boxstyle-sm.box5 a:visited,
.brand-teal .boxstyle-md.box5 a:visited,
.brand-teal .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-teal .boxstyle_.box5 a:hover,
.brand-teal .boxstyle-xs.box5 a:hover,
.brand-teal .boxstyle-sm.box5 a:hover,
.brand-teal .boxstyle-md.box5 a:hover,
.brand-teal .boxstyle-lg.box5 a:hover,
.brand-teal .boxstyle_.box5 a:focus,
.brand-teal .boxstyle-xs.box5 a:focus,
.brand-teal .boxstyle-sm.box5 a:focus,
.brand-teal .boxstyle-md.box5 a:focus,
.brand-teal .boxstyle-lg.box5 a:focus,
.brand-teal .boxstyle_.box5 a:active,
.brand-teal .boxstyle-xs.box5 a:active,
.brand-teal .boxstyle-sm.box5 a:active,
.brand-teal .boxstyle-md.box5 a:active,
.brand-teal .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-teal .pagination > li a,
.brand-teal .pagination > li span {
  color: #507F70;
}
.brand-teal .pagination > li.active > a,
.brand-teal .pagination > li.active > span:not(.sr-only),
.brand-teal .pagination > li.active > a:hover,
.brand-teal .pagination > li.active > span:not(.sr-only):hover,
.brand-teal .pagination > li.active > a:focus,
.brand-teal .pagination > li.active > span:not(.sr-only):focus {
  background-color: #507F70;
  border-color: #507F70;
  color: white;
}
.brand-teal .nav-tabs {
  border-bottom-color: #a8bfb8;
}
.brand-teal .nav-tabs > li > a {
  background-color: #dce5e2;
  border-color: #a8bfb8;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-teal .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #96b2a9;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-teal .nav-tabs > li > a:hover,
.brand-teal .nav-tabs > li > a:focus {
  background-color: #eef2f1;
  border-color: #b9ccc6 #b9ccc6 #a8bfb8;
}
.brand-teal .nav-tabs > li > a:hover::before,
.brand-teal .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-teal .nav-tabs > li.open > a,
.brand-teal .nav-tabs > li.open > a:hover,
.brand-teal .nav-tabs > li.open > a:focus {
  background-color: #eef2f1;
  border-color: #b9ccc6 #b9ccc6 #a8bfb8;
}
.brand-teal .nav-tabs > li.open > a::before,
.brand-teal .nav-tabs > li.open > a:hover::before,
.brand-teal .nav-tabs > li.open > a:focus::before {
  background: #507F70;
  width: 100%;
}
.brand-teal .nav-tabs > li.active > a,
.brand-teal .nav-tabs > li.active > a:hover,
.brand-teal .nav-tabs > li.active > a:focus {
  border-color: #a8bfb8 #a8bfb8 transparent;
}
.brand-teal .nav-tabs > li.active > a::before,
.brand-teal .nav-tabs > li.active > a:hover::before,
.brand-teal .nav-tabs > li.active > a:focus::before {
  background: #507F70;
  width: 100%;
}
.brand-teal .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-teal .nav-tabs > li.disabled > a:hover,
.brand-teal .nav-tabs > li.disabled > a:focus {
  background-color: #dce5e2;
}
.brand-teal .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-teal .nav-pills > li.active > a,
.brand-teal .nav-pills > li.active > a:hover,
.brand-teal .nav-pills > li.active > a:focus {
  background-color: #507F70;
  color: white;
}
.brand-teal .list-group-item.active,
.brand-teal .list-group-item.active:hover,
.brand-teal .list-group-item.active:focus {
  background-color: #507F70;
  border-color: #507F70;
  color: white;
}
.brand-teal .id7-koan-spinner__neon--top,
.brand-teal .id7-koan-spinner__neon--bottom {
  stroke: #507F70;
}
.brand-teal-bright h1,
.brand-teal-bright h2,
.brand-teal-bright h3,
.brand-teal-bright h4,
.brand-teal-bright h5,
.brand-teal-bright h6,
.brand-teal-bright .h1,
.brand-teal-bright .h2,
.brand-teal-bright .h3,
.brand-teal-bright .h4,
.brand-teal-bright .h5,
.brand-teal-bright .h6 {
  color: #202020;
}
.brand-teal-bright a,
.brand-teal-bright a:link,
.brand-teal-bright a:visited {
  color: #202020;
}
.brand-teal-bright a:hover,
.brand-teal-bright a:focus,
.brand-teal-bright a:active {
  color: #000000;
}
.brand-teal-bright .popover button.close {
  color: #202020;
}
.brand-teal-bright .popover button.close:hover {
  color: #000000;
}
.brand-teal-bright a:hover .new-window-link,
.brand-teal-bright a:hover .insecure-link {
  color: #000000;
}
.brand-teal-bright .btn-link {
  color: #202020;
}
.brand-teal-bright .btn-link:hover,
.brand-teal-bright .btn-link:focus {
  color: #000000;
}
.brand-teal-bright a,
.brand-teal-bright a:link,
.brand-teal-bright a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal-bright a:hover,
.brand-teal-bright a:focus,
.brand-teal-bright a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-teal-bright blockquote.quotes {
  color: #202020;
}
.brand-teal-bright blockquote.quotes::before {
  color: #202020;
}
.brand-teal-bright .brand-bg {
  background: #6DCDB8;
  color: #202020;
}
.brand-teal-bright .brand-bg a,
.brand-teal-bright .brand-bg a:link,
.brand-teal-bright .brand-bg a:visited {
  color: #202020 !important;
}
.brand-teal-bright .brand-bg a:hover,
.brand-teal-bright .brand-bg a:focus,
.brand-teal-bright .brand-bg a:active {
  color: #000000 !important;
}
.brand-teal-bright .brand-bg .popover button.close {
  color: #202020 !important;
}
.brand-teal-bright .brand-bg .popover button.close:hover {
  color: #000000 !important;
}
.brand-teal-bright .brand-bg a:hover .new-window-link,
.brand-teal-bright .brand-bg a:hover .insecure-link {
  color: #000000 !important;
}
.brand-teal-bright .brand-bg .btn-link {
  color: #202020 !important;
}
.brand-teal-bright .brand-bg .btn-link:hover,
.brand-teal-bright .brand-bg .btn-link:focus {
  color: #000000 !important;
}
.brand-teal-bright .brand-bg a,
.brand-teal-bright .brand-bg a:link,
.brand-teal-bright .brand-bg a:visited {
  -webkit-text-decoration-color: #636363 !important;
  text-decoration-color: #636363 !important;
}
.brand-teal-bright .brand-bg a:hover,
.brand-teal-bright .brand-bg a:focus,
.brand-teal-bright .brand-bg a:active {
  -webkit-text-decoration-color: #000000 !important;
  text-decoration-color: #000000 !important;
}
.brand-teal-bright .brand-text {
  color: #6DCDB8;
}
.brand-teal-bright .brand-text a,
.brand-teal-bright .brand-text a:link,
.brand-teal-bright .brand-text a:visited {
  color: #6DCDB8 !important;
}
.brand-teal-bright .brand-text a:hover,
.brand-teal-bright .brand-text a:focus,
.brand-teal-bright .brand-text a:active {
  color: #369e87 !important;
}
.brand-teal-bright .brand-text .popover button.close {
  color: #6DCDB8 !important;
}
.brand-teal-bright .brand-text .popover button.close:hover {
  color: #369e87 !important;
}
.brand-teal-bright .brand-text a:hover .new-window-link,
.brand-teal-bright .brand-text a:hover .insecure-link {
  color: #369e87 !important;
}
.brand-teal-bright .brand-text .btn-link {
  color: #6DCDB8 !important;
}
.brand-teal-bright .brand-text .btn-link:hover,
.brand-teal-bright .brand-text .btn-link:focus {
  color: #369e87 !important;
}
.brand-teal-bright .brand-text a,
.brand-teal-bright .brand-text a:link,
.brand-teal-bright .brand-text a:visited {
  -webkit-text-decoration-color: #99dccd !important;
  text-decoration-color: #99dccd !important;
}
.brand-teal-bright .brand-text a:hover,
.brand-teal-bright .brand-text a:focus,
.brand-teal-bright .brand-text a:active {
  -webkit-text-decoration-color: #369e87 !important;
  text-decoration-color: #369e87 !important;
}
.brand-teal-bright .brand-border {
  border-color: #6DCDB8;
}
.brand-teal-bright .btn-brand {
  color: #202020;
  background-color: #6DCDB8;
  border-color: #5ac6af;
  color: #202020 !important;
}
.brand-teal-bright .btn-brand:focus,
.brand-teal-bright .btn-brand.focus {
  color: #202020;
  background-color: #47c0a6;
  border-color: #297867;
}
.brand-teal-bright .btn-brand:hover {
  color: #202020;
  background-color: #47c0a6;
  border-color: #3aa991;
}
.brand-teal-bright .btn-brand:active,
.brand-teal-bright .btn-brand.active,
.open > .dropdown-toggle.brand-teal-bright .btn-brand {
  color: #202020;
  background-color: #47c0a6;
  background-image: none;
  border-color: #3aa991;
}
.brand-teal-bright .btn-brand:active:hover,
.brand-teal-bright .btn-brand.active:hover,
.open > .dropdown-toggle.brand-teal-bright .btn-brand:hover,
.brand-teal-bright .btn-brand:active:focus,
.brand-teal-bright .btn-brand.active:focus,
.open > .dropdown-toggle.brand-teal-bright .btn-brand:focus,
.brand-teal-bright .btn-brand:active.focus,
.brand-teal-bright .btn-brand.active.focus,
.open > .dropdown-toggle.brand-teal-bright .btn-brand.focus {
  color: #202020;
  background-color: #3aa991;
  border-color: #297867;
}
.brand-teal-bright .btn-brand.disabled:hover,
.brand-teal-bright .btn-brand[disabled]:hover,
fieldset[disabled] .brand-teal-bright .btn-brand:hover,
.brand-teal-bright .btn-brand.disabled:focus,
.brand-teal-bright .btn-brand[disabled]:focus,
fieldset[disabled] .brand-teal-bright .btn-brand:focus,
.brand-teal-bright .btn-brand.disabled.focus,
.brand-teal-bright .btn-brand[disabled].focus,
fieldset[disabled] .brand-teal-bright .btn-brand.focus {
  background-color: #6DCDB8;
  border-color: #5ac6af;
}
.brand-teal-bright .btn-brand .badge {
  color: #6DCDB8;
  background-color: #202020;
}
.brand-teal-bright .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-teal-bright .id7-utility-masthead {
  background-color: #99dccd;
}
.brand-teal-bright .id7-utility-masthead::after {
  background-color: #6DCDB8;
}
body.id7-point-1 .brand-teal-bright .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-teal-bright .id7-navigation .navbar-primary {
  background-color: #6DCDB8;
}
.brand-teal-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-teal-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-text {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #c2eae1;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: #202020;
  background-color: #57a493;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #c2eae1;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #57a493;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #57a493;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #6DCDB8;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #57a493;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #57a493;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #57a493;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #57a493;
  background-image: none;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #c2eae1;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-teal-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #57a493;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-link {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .navbar-link:hover {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .btn-link {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-teal-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-teal-bright .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-teal-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-teal-bright .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-teal-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: #202020;
}
.brand-teal-bright .navbar-primary .navbar-brand {
  background-color: #6DCDB8;
}
.brand-teal-bright .navbar-secondary {
  background-color: #c2eae1;
}
.brand-teal-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-teal-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-teal-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li:hover,
.brand-teal-bright .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #d4f0ea;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li > a,
.brand-teal-bright .navbar-secondary .navbar-nav > li > a:hover,
.brand-teal-bright .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-teal-bright .navbar-secondary .navbar-nav > .disabled,
.brand-teal-bright .navbar-secondary .navbar-nav > .disabled:hover,
.brand-teal-bright .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #d4f0ea;
}
.brand-teal-bright .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-teal-bright .navbar-secondary .navbar-toggle:hover,
.brand-teal-bright .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-teal-bright .navbar-secondary .navbar-nav > .open,
.brand-teal-bright .navbar-secondary .navbar-nav > .open:hover,
.brand-teal-bright .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #d4f0ea;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #c2eae1;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #9bbbb4;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #9bbbb4;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #d4f0ea;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #d4f0ea;
  background-image: none;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-teal-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #d4f0ea;
}
.brand-teal-bright .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .btn-link {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .btn-link:hover,
.brand-teal-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-teal-bright .navbar-secondary .btn-link:hover,
.brand-teal-bright .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-teal-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li::after {
  color: #6DCDB8;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li:first-child {
  background-color: #6DCDB8;
  color: white;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #6DCDB8;
}
.brand-teal-bright .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #6DCDB8;
}
.brand-teal-bright .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-teal-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-teal-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > li:hover,
.brand-teal-bright .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > li > a,
.brand-teal-bright .navbar-tertiary .navbar-nav > li > a:hover,
.brand-teal-bright .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > .disabled,
.brand-teal-bright .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-teal-bright .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal-bright .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .navbar-toggle:hover,
.brand-teal-bright .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-teal-bright .navbar-tertiary .navbar-nav > .open,
.brand-teal-bright .navbar-tertiary .navbar-nav > .open:hover,
.brand-teal-bright .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-teal-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-teal-bright .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .btn-link:hover,
.brand-teal-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-teal-bright .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-teal-bright .navbar-tertiary .btn-link:hover,
.brand-teal-bright .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-teal-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-teal-bright.bordered .id7-left-border,
.brand-teal-bright.bordered .id7-right-border {
  border-color: rgba(109, 205, 184, 0.3);
}
.brand-teal-bright .id7-page-footer .id7-app-footer {
  background: #6DCDB8;
  color: #202020;
}
.brand-teal-bright .id7-page-footer .id7-app-footer h1,
.brand-teal-bright .id7-page-footer .id7-app-footer h2,
.brand-teal-bright .id7-page-footer .id7-app-footer h3,
.brand-teal-bright .id7-page-footer .id7-app-footer h4,
.brand-teal-bright .id7-page-footer .id7-app-footer h5,
.brand-teal-bright .id7-page-footer .id7-app-footer h6,
.brand-teal-bright .id7-page-footer .id7-app-footer .h1,
.brand-teal-bright .id7-page-footer .id7-app-footer .h2,
.brand-teal-bright .id7-page-footer .id7-app-footer .h3,
.brand-teal-bright .id7-page-footer .id7-app-footer .h4,
.brand-teal-bright .id7-page-footer .id7-app-footer .h5,
.brand-teal-bright .id7-page-footer .id7-app-footer .h6 {
  color: #202020;
}
.brand-teal-bright .id7-page-footer .id7-app-footer a,
.brand-teal-bright .id7-page-footer .id7-app-footer a:link,
.brand-teal-bright .id7-page-footer .id7-app-footer a:visited {
  color: #202020;
}
.brand-teal-bright .id7-page-footer .id7-app-footer a:hover,
.brand-teal-bright .id7-page-footer .id7-app-footer a:focus,
.brand-teal-bright .id7-page-footer .id7-app-footer a:active {
  color: #000000;
}
.brand-teal-bright .id7-page-footer .id7-app-footer .popover button.close {
  color: #202020;
}
.brand-teal-bright .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #000000;
}
.brand-teal-bright .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-teal-bright .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #000000;
}
.brand-teal-bright .id7-page-footer .id7-app-footer .btn-link {
  color: #202020;
}
.brand-teal-bright .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-teal-bright .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #000000;
}
.brand-teal-bright .id7-page-footer .id7-app-footer a,
.brand-teal-bright .id7-page-footer .id7-app-footer a:link,
.brand-teal-bright .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal-bright .id7-page-footer .id7-app-footer a:hover,
.brand-teal-bright .id7-page-footer .id7-app-footer a:focus,
.brand-teal-bright .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-teal-bright .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #6DCDB8;
}
.brand-teal-bright .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-teal-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-teal-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-teal-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-teal-bright .id7-search .fa,
.brand-teal-bright .id7-search .fas {
  color: #6DCDB8;
  color: rgba(109, 205, 184, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-teal-bright .id7-search .form-control:hover + .fa,
.brand-teal-bright .id7-search .typeahead:hover + .fa,
.brand-teal-bright .id7-search .fa:hover,
.brand-teal-bright .id7-search .form-control:hover + .fas,
.brand-teal-bright .id7-search .typeahead:hover + .fas,
.brand-teal-bright .id7-search .fas:hover {
  color: #6DCDB8;
}
.brand-teal-bright .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #6DCDB8;
  color: #202020;
}
.brand-teal-bright .form-control:focus {
  border-color: #6DCDB8;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(109, 205, 184, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(109, 205, 184, 0.6);
}
.brand-teal-bright .carousel .carousel-indicators li::after {
  color: #6DCDB8;
  color: rgba(109, 205, 184, 0.3);
}
.brand-teal-bright .carousel .carousel-indicators li.active::after {
  color: #6DCDB8;
}
@media (max-width: 767px) {
  .brand-teal-bright .boxstyle_.box1,
  .brand-teal-bright .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-teal-bright .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-teal-bright .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-teal-bright .boxstyle_.box1,
.brand-teal-bright .boxstyle-xs.box1,
.brand-teal-bright .boxstyle-sm.box1,
.brand-teal-bright .boxstyle-md.box1,
.brand-teal-bright .boxstyle-lg.box1 {
  border: 0;
  background: #e9f8f4;
  color: #202020;
}
@media print {
  .brand-teal-bright .boxstyle_.box1,
  .brand-teal-bright .boxstyle-xs.box1,
  .brand-teal-bright .boxstyle-sm.box1,
  .brand-teal-bright .boxstyle-md.box1,
  .brand-teal-bright .boxstyle-lg.box1 {
    border: 1px solid #e9f8f4;
  }
}
.brand-teal-bright .boxstyle_.box1 h1,
.brand-teal-bright .boxstyle-xs.box1 h1,
.brand-teal-bright .boxstyle-sm.box1 h1,
.brand-teal-bright .boxstyle-md.box1 h1,
.brand-teal-bright .boxstyle-lg.box1 h1,
.brand-teal-bright .boxstyle_.box1 h2,
.brand-teal-bright .boxstyle-xs.box1 h2,
.brand-teal-bright .boxstyle-sm.box1 h2,
.brand-teal-bright .boxstyle-md.box1 h2,
.brand-teal-bright .boxstyle-lg.box1 h2,
.brand-teal-bright .boxstyle_.box1 h3,
.brand-teal-bright .boxstyle-xs.box1 h3,
.brand-teal-bright .boxstyle-sm.box1 h3,
.brand-teal-bright .boxstyle-md.box1 h3,
.brand-teal-bright .boxstyle-lg.box1 h3,
.brand-teal-bright .boxstyle_.box1 h4,
.brand-teal-bright .boxstyle-xs.box1 h4,
.brand-teal-bright .boxstyle-sm.box1 h4,
.brand-teal-bright .boxstyle-md.box1 h4,
.brand-teal-bright .boxstyle-lg.box1 h4,
.brand-teal-bright .boxstyle_.box1 h5,
.brand-teal-bright .boxstyle-xs.box1 h5,
.brand-teal-bright .boxstyle-sm.box1 h5,
.brand-teal-bright .boxstyle-md.box1 h5,
.brand-teal-bright .boxstyle-lg.box1 h5,
.brand-teal-bright .boxstyle_.box1 h6,
.brand-teal-bright .boxstyle-xs.box1 h6,
.brand-teal-bright .boxstyle-sm.box1 h6,
.brand-teal-bright .boxstyle-md.box1 h6,
.brand-teal-bright .boxstyle-lg.box1 h6,
.brand-teal-bright .boxstyle_.box1 .h1,
.brand-teal-bright .boxstyle-xs.box1 .h1,
.brand-teal-bright .boxstyle-sm.box1 .h1,
.brand-teal-bright .boxstyle-md.box1 .h1,
.brand-teal-bright .boxstyle-lg.box1 .h1,
.brand-teal-bright .boxstyle_.box1 .h2,
.brand-teal-bright .boxstyle-xs.box1 .h2,
.brand-teal-bright .boxstyle-sm.box1 .h2,
.brand-teal-bright .boxstyle-md.box1 .h2,
.brand-teal-bright .boxstyle-lg.box1 .h2,
.brand-teal-bright .boxstyle_.box1 .h3,
.brand-teal-bright .boxstyle-xs.box1 .h3,
.brand-teal-bright .boxstyle-sm.box1 .h3,
.brand-teal-bright .boxstyle-md.box1 .h3,
.brand-teal-bright .boxstyle-lg.box1 .h3,
.brand-teal-bright .boxstyle_.box1 .h4,
.brand-teal-bright .boxstyle-xs.box1 .h4,
.brand-teal-bright .boxstyle-sm.box1 .h4,
.brand-teal-bright .boxstyle-md.box1 .h4,
.brand-teal-bright .boxstyle-lg.box1 .h4,
.brand-teal-bright .boxstyle_.box1 .h5,
.brand-teal-bright .boxstyle-xs.box1 .h5,
.brand-teal-bright .boxstyle-sm.box1 .h5,
.brand-teal-bright .boxstyle-md.box1 .h5,
.brand-teal-bright .boxstyle-lg.box1 .h5,
.brand-teal-bright .boxstyle_.box1 .h6,
.brand-teal-bright .boxstyle-xs.box1 .h6,
.brand-teal-bright .boxstyle-sm.box1 .h6,
.brand-teal-bright .boxstyle-md.box1 .h6,
.brand-teal-bright .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box1 blockquote.quotes,
.brand-teal-bright .boxstyle-xs.box1 blockquote.quotes,
.brand-teal-bright .boxstyle-sm.box1 blockquote.quotes,
.brand-teal-bright .boxstyle-md.box1 blockquote.quotes,
.brand-teal-bright .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-teal-bright .boxstyle_.box1 a,
.brand-teal-bright .boxstyle-xs.box1 a,
.brand-teal-bright .boxstyle-sm.box1 a,
.brand-teal-bright .boxstyle-md.box1 a,
.brand-teal-bright .boxstyle-lg.box1 a,
.brand-teal-bright .boxstyle_.box1 a:link,
.brand-teal-bright .boxstyle-xs.box1 a:link,
.brand-teal-bright .boxstyle-sm.box1 a:link,
.brand-teal-bright .boxstyle-md.box1 a:link,
.brand-teal-bright .boxstyle-lg.box1 a:link,
.brand-teal-bright .boxstyle_.box1 a:visited,
.brand-teal-bright .boxstyle-xs.box1 a:visited,
.brand-teal-bright .boxstyle-sm.box1 a:visited,
.brand-teal-bright .boxstyle-md.box1 a:visited,
.brand-teal-bright .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box1 a:hover,
.brand-teal-bright .boxstyle-xs.box1 a:hover,
.brand-teal-bright .boxstyle-sm.box1 a:hover,
.brand-teal-bright .boxstyle-md.box1 a:hover,
.brand-teal-bright .boxstyle-lg.box1 a:hover,
.brand-teal-bright .boxstyle_.box1 a:focus,
.brand-teal-bright .boxstyle-xs.box1 a:focus,
.brand-teal-bright .boxstyle-sm.box1 a:focus,
.brand-teal-bright .boxstyle-md.box1 a:focus,
.brand-teal-bright .boxstyle-lg.box1 a:focus,
.brand-teal-bright .boxstyle_.box1 a:active,
.brand-teal-bright .boxstyle-xs.box1 a:active,
.brand-teal-bright .boxstyle-sm.box1 a:active,
.brand-teal-bright .boxstyle-md.box1 a:active,
.brand-teal-bright .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box1 .popover button.close,
.brand-teal-bright .boxstyle-xs.box1 .popover button.close,
.brand-teal-bright .boxstyle-sm.box1 .popover button.close,
.brand-teal-bright .boxstyle-md.box1 .popover button.close,
.brand-teal-bright .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box1 .popover button.close:hover,
.brand-teal-bright .boxstyle-xs.box1 .popover button.close:hover,
.brand-teal-bright .boxstyle-sm.box1 .popover button.close:hover,
.brand-teal-bright .boxstyle-md.box1 .popover button.close:hover,
.brand-teal-bright .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box1 a:hover .new-window-link,
.brand-teal-bright .boxstyle-xs.box1 a:hover .new-window-link,
.brand-teal-bright .boxstyle-sm.box1 a:hover .new-window-link,
.brand-teal-bright .boxstyle-md.box1 a:hover .new-window-link,
.brand-teal-bright .boxstyle-lg.box1 a:hover .new-window-link,
.brand-teal-bright .boxstyle_.box1 a:hover .insecure-link,
.brand-teal-bright .boxstyle-xs.box1 a:hover .insecure-link,
.brand-teal-bright .boxstyle-sm.box1 a:hover .insecure-link,
.brand-teal-bright .boxstyle-md.box1 a:hover .insecure-link,
.brand-teal-bright .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box1 .btn-link,
.brand-teal-bright .boxstyle-xs.box1 .btn-link,
.brand-teal-bright .boxstyle-sm.box1 .btn-link,
.brand-teal-bright .boxstyle-md.box1 .btn-link,
.brand-teal-bright .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box1 .btn-link:hover,
.brand-teal-bright .boxstyle-xs.box1 .btn-link:hover,
.brand-teal-bright .boxstyle-sm.box1 .btn-link:hover,
.brand-teal-bright .boxstyle-md.box1 .btn-link:hover,
.brand-teal-bright .boxstyle-lg.box1 .btn-link:hover,
.brand-teal-bright .boxstyle_.box1 .btn-link:focus,
.brand-teal-bright .boxstyle-xs.box1 .btn-link:focus,
.brand-teal-bright .boxstyle-sm.box1 .btn-link:focus,
.brand-teal-bright .boxstyle-md.box1 .btn-link:focus,
.brand-teal-bright .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box1 a,
.brand-teal-bright .boxstyle-xs.box1 a,
.brand-teal-bright .boxstyle-sm.box1 a,
.brand-teal-bright .boxstyle-md.box1 a,
.brand-teal-bright .boxstyle-lg.box1 a,
.brand-teal-bright .boxstyle_.box1 a:link,
.brand-teal-bright .boxstyle-xs.box1 a:link,
.brand-teal-bright .boxstyle-sm.box1 a:link,
.brand-teal-bright .boxstyle-md.box1 a:link,
.brand-teal-bright .boxstyle-lg.box1 a:link,
.brand-teal-bright .boxstyle_.box1 a:visited,
.brand-teal-bright .boxstyle-xs.box1 a:visited,
.brand-teal-bright .boxstyle-sm.box1 a:visited,
.brand-teal-bright .boxstyle-md.box1 a:visited,
.brand-teal-bright .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal-bright .boxstyle_.box1 a:hover,
.brand-teal-bright .boxstyle-xs.box1 a:hover,
.brand-teal-bright .boxstyle-sm.box1 a:hover,
.brand-teal-bright .boxstyle-md.box1 a:hover,
.brand-teal-bright .boxstyle-lg.box1 a:hover,
.brand-teal-bright .boxstyle_.box1 a:focus,
.brand-teal-bright .boxstyle-xs.box1 a:focus,
.brand-teal-bright .boxstyle-sm.box1 a:focus,
.brand-teal-bright .boxstyle-md.box1 a:focus,
.brand-teal-bright .boxstyle-lg.box1 a:focus,
.brand-teal-bright .boxstyle_.box1 a:active,
.brand-teal-bright .boxstyle-xs.box1 a:active,
.brand-teal-bright .boxstyle-sm.box1 a:active,
.brand-teal-bright .boxstyle-md.box1 a:active,
.brand-teal-bright .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-teal-bright .boxstyle_.box2,
  .brand-teal-bright .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-teal-bright .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-teal-bright .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-teal-bright .boxstyle_.box2,
.brand-teal-bright .boxstyle-xs.box2,
.brand-teal-bright .boxstyle-sm.box2,
.brand-teal-bright .boxstyle-md.box2,
.brand-teal-bright .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-teal-bright .boxstyle_.box2,
  .brand-teal-bright .boxstyle-xs.box2,
  .brand-teal-bright .boxstyle-sm.box2,
  .brand-teal-bright .boxstyle-md.box2,
  .brand-teal-bright .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-teal-bright .boxstyle_.box2 h1,
.brand-teal-bright .boxstyle-xs.box2 h1,
.brand-teal-bright .boxstyle-sm.box2 h1,
.brand-teal-bright .boxstyle-md.box2 h1,
.brand-teal-bright .boxstyle-lg.box2 h1,
.brand-teal-bright .boxstyle_.box2 h2,
.brand-teal-bright .boxstyle-xs.box2 h2,
.brand-teal-bright .boxstyle-sm.box2 h2,
.brand-teal-bright .boxstyle-md.box2 h2,
.brand-teal-bright .boxstyle-lg.box2 h2,
.brand-teal-bright .boxstyle_.box2 h3,
.brand-teal-bright .boxstyle-xs.box2 h3,
.brand-teal-bright .boxstyle-sm.box2 h3,
.brand-teal-bright .boxstyle-md.box2 h3,
.brand-teal-bright .boxstyle-lg.box2 h3,
.brand-teal-bright .boxstyle_.box2 h4,
.brand-teal-bright .boxstyle-xs.box2 h4,
.brand-teal-bright .boxstyle-sm.box2 h4,
.brand-teal-bright .boxstyle-md.box2 h4,
.brand-teal-bright .boxstyle-lg.box2 h4,
.brand-teal-bright .boxstyle_.box2 h5,
.brand-teal-bright .boxstyle-xs.box2 h5,
.brand-teal-bright .boxstyle-sm.box2 h5,
.brand-teal-bright .boxstyle-md.box2 h5,
.brand-teal-bright .boxstyle-lg.box2 h5,
.brand-teal-bright .boxstyle_.box2 h6,
.brand-teal-bright .boxstyle-xs.box2 h6,
.brand-teal-bright .boxstyle-sm.box2 h6,
.brand-teal-bright .boxstyle-md.box2 h6,
.brand-teal-bright .boxstyle-lg.box2 h6,
.brand-teal-bright .boxstyle_.box2 .h1,
.brand-teal-bright .boxstyle-xs.box2 .h1,
.brand-teal-bright .boxstyle-sm.box2 .h1,
.brand-teal-bright .boxstyle-md.box2 .h1,
.brand-teal-bright .boxstyle-lg.box2 .h1,
.brand-teal-bright .boxstyle_.box2 .h2,
.brand-teal-bright .boxstyle-xs.box2 .h2,
.brand-teal-bright .boxstyle-sm.box2 .h2,
.brand-teal-bright .boxstyle-md.box2 .h2,
.brand-teal-bright .boxstyle-lg.box2 .h2,
.brand-teal-bright .boxstyle_.box2 .h3,
.brand-teal-bright .boxstyle-xs.box2 .h3,
.brand-teal-bright .boxstyle-sm.box2 .h3,
.brand-teal-bright .boxstyle-md.box2 .h3,
.brand-teal-bright .boxstyle-lg.box2 .h3,
.brand-teal-bright .boxstyle_.box2 .h4,
.brand-teal-bright .boxstyle-xs.box2 .h4,
.brand-teal-bright .boxstyle-sm.box2 .h4,
.brand-teal-bright .boxstyle-md.box2 .h4,
.brand-teal-bright .boxstyle-lg.box2 .h4,
.brand-teal-bright .boxstyle_.box2 .h5,
.brand-teal-bright .boxstyle-xs.box2 .h5,
.brand-teal-bright .boxstyle-sm.box2 .h5,
.brand-teal-bright .boxstyle-md.box2 .h5,
.brand-teal-bright .boxstyle-lg.box2 .h5,
.brand-teal-bright .boxstyle_.box2 .h6,
.brand-teal-bright .boxstyle-xs.box2 .h6,
.brand-teal-bright .boxstyle-sm.box2 .h6,
.brand-teal-bright .boxstyle-md.box2 .h6,
.brand-teal-bright .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box2 blockquote.quotes,
.brand-teal-bright .boxstyle-xs.box2 blockquote.quotes,
.brand-teal-bright .boxstyle-sm.box2 blockquote.quotes,
.brand-teal-bright .boxstyle-md.box2 blockquote.quotes,
.brand-teal-bright .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-teal-bright .boxstyle_.box2 a,
.brand-teal-bright .boxstyle-xs.box2 a,
.brand-teal-bright .boxstyle-sm.box2 a,
.brand-teal-bright .boxstyle-md.box2 a,
.brand-teal-bright .boxstyle-lg.box2 a,
.brand-teal-bright .boxstyle_.box2 a:link,
.brand-teal-bright .boxstyle-xs.box2 a:link,
.brand-teal-bright .boxstyle-sm.box2 a:link,
.brand-teal-bright .boxstyle-md.box2 a:link,
.brand-teal-bright .boxstyle-lg.box2 a:link,
.brand-teal-bright .boxstyle_.box2 a:visited,
.brand-teal-bright .boxstyle-xs.box2 a:visited,
.brand-teal-bright .boxstyle-sm.box2 a:visited,
.brand-teal-bright .boxstyle-md.box2 a:visited,
.brand-teal-bright .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box2 a:hover,
.brand-teal-bright .boxstyle-xs.box2 a:hover,
.brand-teal-bright .boxstyle-sm.box2 a:hover,
.brand-teal-bright .boxstyle-md.box2 a:hover,
.brand-teal-bright .boxstyle-lg.box2 a:hover,
.brand-teal-bright .boxstyle_.box2 a:focus,
.brand-teal-bright .boxstyle-xs.box2 a:focus,
.brand-teal-bright .boxstyle-sm.box2 a:focus,
.brand-teal-bright .boxstyle-md.box2 a:focus,
.brand-teal-bright .boxstyle-lg.box2 a:focus,
.brand-teal-bright .boxstyle_.box2 a:active,
.brand-teal-bright .boxstyle-xs.box2 a:active,
.brand-teal-bright .boxstyle-sm.box2 a:active,
.brand-teal-bright .boxstyle-md.box2 a:active,
.brand-teal-bright .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box2 .popover button.close,
.brand-teal-bright .boxstyle-xs.box2 .popover button.close,
.brand-teal-bright .boxstyle-sm.box2 .popover button.close,
.brand-teal-bright .boxstyle-md.box2 .popover button.close,
.brand-teal-bright .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box2 .popover button.close:hover,
.brand-teal-bright .boxstyle-xs.box2 .popover button.close:hover,
.brand-teal-bright .boxstyle-sm.box2 .popover button.close:hover,
.brand-teal-bright .boxstyle-md.box2 .popover button.close:hover,
.brand-teal-bright .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box2 a:hover .new-window-link,
.brand-teal-bright .boxstyle-xs.box2 a:hover .new-window-link,
.brand-teal-bright .boxstyle-sm.box2 a:hover .new-window-link,
.brand-teal-bright .boxstyle-md.box2 a:hover .new-window-link,
.brand-teal-bright .boxstyle-lg.box2 a:hover .new-window-link,
.brand-teal-bright .boxstyle_.box2 a:hover .insecure-link,
.brand-teal-bright .boxstyle-xs.box2 a:hover .insecure-link,
.brand-teal-bright .boxstyle-sm.box2 a:hover .insecure-link,
.brand-teal-bright .boxstyle-md.box2 a:hover .insecure-link,
.brand-teal-bright .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box2 .btn-link,
.brand-teal-bright .boxstyle-xs.box2 .btn-link,
.brand-teal-bright .boxstyle-sm.box2 .btn-link,
.brand-teal-bright .boxstyle-md.box2 .btn-link,
.brand-teal-bright .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box2 .btn-link:hover,
.brand-teal-bright .boxstyle-xs.box2 .btn-link:hover,
.brand-teal-bright .boxstyle-sm.box2 .btn-link:hover,
.brand-teal-bright .boxstyle-md.box2 .btn-link:hover,
.brand-teal-bright .boxstyle-lg.box2 .btn-link:hover,
.brand-teal-bright .boxstyle_.box2 .btn-link:focus,
.brand-teal-bright .boxstyle-xs.box2 .btn-link:focus,
.brand-teal-bright .boxstyle-sm.box2 .btn-link:focus,
.brand-teal-bright .boxstyle-md.box2 .btn-link:focus,
.brand-teal-bright .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box2 a,
.brand-teal-bright .boxstyle-xs.box2 a,
.brand-teal-bright .boxstyle-sm.box2 a,
.brand-teal-bright .boxstyle-md.box2 a,
.brand-teal-bright .boxstyle-lg.box2 a,
.brand-teal-bright .boxstyle_.box2 a:link,
.brand-teal-bright .boxstyle-xs.box2 a:link,
.brand-teal-bright .boxstyle-sm.box2 a:link,
.brand-teal-bright .boxstyle-md.box2 a:link,
.brand-teal-bright .boxstyle-lg.box2 a:link,
.brand-teal-bright .boxstyle_.box2 a:visited,
.brand-teal-bright .boxstyle-xs.box2 a:visited,
.brand-teal-bright .boxstyle-sm.box2 a:visited,
.brand-teal-bright .boxstyle-md.box2 a:visited,
.brand-teal-bright .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal-bright .boxstyle_.box2 a:hover,
.brand-teal-bright .boxstyle-xs.box2 a:hover,
.brand-teal-bright .boxstyle-sm.box2 a:hover,
.brand-teal-bright .boxstyle-md.box2 a:hover,
.brand-teal-bright .boxstyle-lg.box2 a:hover,
.brand-teal-bright .boxstyle_.box2 a:focus,
.brand-teal-bright .boxstyle-xs.box2 a:focus,
.brand-teal-bright .boxstyle-sm.box2 a:focus,
.brand-teal-bright .boxstyle-md.box2 a:focus,
.brand-teal-bright .boxstyle-lg.box2 a:focus,
.brand-teal-bright .boxstyle_.box2 a:active,
.brand-teal-bright .boxstyle-xs.box2 a:active,
.brand-teal-bright .boxstyle-sm.box2 a:active,
.brand-teal-bright .boxstyle-md.box2 a:active,
.brand-teal-bright .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-teal-bright .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #6DCDB8;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-teal-bright .boxstyle_.box3,
  .brand-teal-bright .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #6DCDB8;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-teal-bright .boxstyle-md.box3 {
    background: none;
    border: 1px solid #6DCDB8;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-teal-bright .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #6DCDB8;
    color: inherit;
  }
}
.brand-teal-bright .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-teal-bright .boxstyle_.box4,
  .brand-teal-bright .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-teal-bright .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-teal-bright .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-teal-bright .boxstyle_.box5,
  .brand-teal-bright .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-teal-bright .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-teal-bright .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-teal-bright .boxstyle_.box5,
.brand-teal-bright .boxstyle-xs.box5,
.brand-teal-bright .boxstyle-sm.box5,
.brand-teal-bright .boxstyle-md.box5,
.brand-teal-bright .boxstyle-lg.box5 {
  border: 0;
  background: #6DCDB8;
  color: #202020;
}
@media print {
  .brand-teal-bright .boxstyle_.box5,
  .brand-teal-bright .boxstyle-xs.box5,
  .brand-teal-bright .boxstyle-sm.box5,
  .brand-teal-bright .boxstyle-md.box5,
  .brand-teal-bright .boxstyle-lg.box5 {
    border: 1px solid #6DCDB8;
  }
}
.brand-teal-bright .boxstyle_.box5 h1,
.brand-teal-bright .boxstyle-xs.box5 h1,
.brand-teal-bright .boxstyle-sm.box5 h1,
.brand-teal-bright .boxstyle-md.box5 h1,
.brand-teal-bright .boxstyle-lg.box5 h1,
.brand-teal-bright .boxstyle_.box5 h2,
.brand-teal-bright .boxstyle-xs.box5 h2,
.brand-teal-bright .boxstyle-sm.box5 h2,
.brand-teal-bright .boxstyle-md.box5 h2,
.brand-teal-bright .boxstyle-lg.box5 h2,
.brand-teal-bright .boxstyle_.box5 h3,
.brand-teal-bright .boxstyle-xs.box5 h3,
.brand-teal-bright .boxstyle-sm.box5 h3,
.brand-teal-bright .boxstyle-md.box5 h3,
.brand-teal-bright .boxstyle-lg.box5 h3,
.brand-teal-bright .boxstyle_.box5 h4,
.brand-teal-bright .boxstyle-xs.box5 h4,
.brand-teal-bright .boxstyle-sm.box5 h4,
.brand-teal-bright .boxstyle-md.box5 h4,
.brand-teal-bright .boxstyle-lg.box5 h4,
.brand-teal-bright .boxstyle_.box5 h5,
.brand-teal-bright .boxstyle-xs.box5 h5,
.brand-teal-bright .boxstyle-sm.box5 h5,
.brand-teal-bright .boxstyle-md.box5 h5,
.brand-teal-bright .boxstyle-lg.box5 h5,
.brand-teal-bright .boxstyle_.box5 h6,
.brand-teal-bright .boxstyle-xs.box5 h6,
.brand-teal-bright .boxstyle-sm.box5 h6,
.brand-teal-bright .boxstyle-md.box5 h6,
.brand-teal-bright .boxstyle-lg.box5 h6,
.brand-teal-bright .boxstyle_.box5 .h1,
.brand-teal-bright .boxstyle-xs.box5 .h1,
.brand-teal-bright .boxstyle-sm.box5 .h1,
.brand-teal-bright .boxstyle-md.box5 .h1,
.brand-teal-bright .boxstyle-lg.box5 .h1,
.brand-teal-bright .boxstyle_.box5 .h2,
.brand-teal-bright .boxstyle-xs.box5 .h2,
.brand-teal-bright .boxstyle-sm.box5 .h2,
.brand-teal-bright .boxstyle-md.box5 .h2,
.brand-teal-bright .boxstyle-lg.box5 .h2,
.brand-teal-bright .boxstyle_.box5 .h3,
.brand-teal-bright .boxstyle-xs.box5 .h3,
.brand-teal-bright .boxstyle-sm.box5 .h3,
.brand-teal-bright .boxstyle-md.box5 .h3,
.brand-teal-bright .boxstyle-lg.box5 .h3,
.brand-teal-bright .boxstyle_.box5 .h4,
.brand-teal-bright .boxstyle-xs.box5 .h4,
.brand-teal-bright .boxstyle-sm.box5 .h4,
.brand-teal-bright .boxstyle-md.box5 .h4,
.brand-teal-bright .boxstyle-lg.box5 .h4,
.brand-teal-bright .boxstyle_.box5 .h5,
.brand-teal-bright .boxstyle-xs.box5 .h5,
.brand-teal-bright .boxstyle-sm.box5 .h5,
.brand-teal-bright .boxstyle-md.box5 .h5,
.brand-teal-bright .boxstyle-lg.box5 .h5,
.brand-teal-bright .boxstyle_.box5 .h6,
.brand-teal-bright .boxstyle-xs.box5 .h6,
.brand-teal-bright .boxstyle-sm.box5 .h6,
.brand-teal-bright .boxstyle-md.box5 .h6,
.brand-teal-bright .boxstyle-lg.box5 .h6 {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box5 blockquote.quotes,
.brand-teal-bright .boxstyle-xs.box5 blockquote.quotes,
.brand-teal-bright .boxstyle-sm.box5 blockquote.quotes,
.brand-teal-bright .boxstyle-md.box5 blockquote.quotes,
.brand-teal-bright .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-teal-bright .boxstyle_.box5 a,
.brand-teal-bright .boxstyle-xs.box5 a,
.brand-teal-bright .boxstyle-sm.box5 a,
.brand-teal-bright .boxstyle-md.box5 a,
.brand-teal-bright .boxstyle-lg.box5 a,
.brand-teal-bright .boxstyle_.box5 a:link,
.brand-teal-bright .boxstyle-xs.box5 a:link,
.brand-teal-bright .boxstyle-sm.box5 a:link,
.brand-teal-bright .boxstyle-md.box5 a:link,
.brand-teal-bright .boxstyle-lg.box5 a:link,
.brand-teal-bright .boxstyle_.box5 a:visited,
.brand-teal-bright .boxstyle-xs.box5 a:visited,
.brand-teal-bright .boxstyle-sm.box5 a:visited,
.brand-teal-bright .boxstyle-md.box5 a:visited,
.brand-teal-bright .boxstyle-lg.box5 a:visited {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box5 a:hover,
.brand-teal-bright .boxstyle-xs.box5 a:hover,
.brand-teal-bright .boxstyle-sm.box5 a:hover,
.brand-teal-bright .boxstyle-md.box5 a:hover,
.brand-teal-bright .boxstyle-lg.box5 a:hover,
.brand-teal-bright .boxstyle_.box5 a:focus,
.brand-teal-bright .boxstyle-xs.box5 a:focus,
.brand-teal-bright .boxstyle-sm.box5 a:focus,
.brand-teal-bright .boxstyle-md.box5 a:focus,
.brand-teal-bright .boxstyle-lg.box5 a:focus,
.brand-teal-bright .boxstyle_.box5 a:active,
.brand-teal-bright .boxstyle-xs.box5 a:active,
.brand-teal-bright .boxstyle-sm.box5 a:active,
.brand-teal-bright .boxstyle-md.box5 a:active,
.brand-teal-bright .boxstyle-lg.box5 a:active {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box5 .popover button.close,
.brand-teal-bright .boxstyle-xs.box5 .popover button.close,
.brand-teal-bright .boxstyle-sm.box5 .popover button.close,
.brand-teal-bright .boxstyle-md.box5 .popover button.close,
.brand-teal-bright .boxstyle-lg.box5 .popover button.close {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box5 .popover button.close:hover,
.brand-teal-bright .boxstyle-xs.box5 .popover button.close:hover,
.brand-teal-bright .boxstyle-sm.box5 .popover button.close:hover,
.brand-teal-bright .boxstyle-md.box5 .popover button.close:hover,
.brand-teal-bright .boxstyle-lg.box5 .popover button.close:hover {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box5 a:hover .new-window-link,
.brand-teal-bright .boxstyle-xs.box5 a:hover .new-window-link,
.brand-teal-bright .boxstyle-sm.box5 a:hover .new-window-link,
.brand-teal-bright .boxstyle-md.box5 a:hover .new-window-link,
.brand-teal-bright .boxstyle-lg.box5 a:hover .new-window-link,
.brand-teal-bright .boxstyle_.box5 a:hover .insecure-link,
.brand-teal-bright .boxstyle-xs.box5 a:hover .insecure-link,
.brand-teal-bright .boxstyle-sm.box5 a:hover .insecure-link,
.brand-teal-bright .boxstyle-md.box5 a:hover .insecure-link,
.brand-teal-bright .boxstyle-lg.box5 a:hover .insecure-link {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box5 .btn-link,
.brand-teal-bright .boxstyle-xs.box5 .btn-link,
.brand-teal-bright .boxstyle-sm.box5 .btn-link,
.brand-teal-bright .boxstyle-md.box5 .btn-link,
.brand-teal-bright .boxstyle-lg.box5 .btn-link {
  color: #202020;
}
.brand-teal-bright .boxstyle_.box5 .btn-link:hover,
.brand-teal-bright .boxstyle-xs.box5 .btn-link:hover,
.brand-teal-bright .boxstyle-sm.box5 .btn-link:hover,
.brand-teal-bright .boxstyle-md.box5 .btn-link:hover,
.brand-teal-bright .boxstyle-lg.box5 .btn-link:hover,
.brand-teal-bright .boxstyle_.box5 .btn-link:focus,
.brand-teal-bright .boxstyle-xs.box5 .btn-link:focus,
.brand-teal-bright .boxstyle-sm.box5 .btn-link:focus,
.brand-teal-bright .boxstyle-md.box5 .btn-link:focus,
.brand-teal-bright .boxstyle-lg.box5 .btn-link:focus {
  color: #000000;
}
.brand-teal-bright .boxstyle_.box5 a,
.brand-teal-bright .boxstyle-xs.box5 a,
.brand-teal-bright .boxstyle-sm.box5 a,
.brand-teal-bright .boxstyle-md.box5 a,
.brand-teal-bright .boxstyle-lg.box5 a,
.brand-teal-bright .boxstyle_.box5 a:link,
.brand-teal-bright .boxstyle-xs.box5 a:link,
.brand-teal-bright .boxstyle-sm.box5 a:link,
.brand-teal-bright .boxstyle-md.box5 a:link,
.brand-teal-bright .boxstyle-lg.box5 a:link,
.brand-teal-bright .boxstyle_.box5 a:visited,
.brand-teal-bright .boxstyle-xs.box5 a:visited,
.brand-teal-bright .boxstyle-sm.box5 a:visited,
.brand-teal-bright .boxstyle-md.box5 a:visited,
.brand-teal-bright .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-teal-bright .boxstyle_.box5 a:hover,
.brand-teal-bright .boxstyle-xs.box5 a:hover,
.brand-teal-bright .boxstyle-sm.box5 a:hover,
.brand-teal-bright .boxstyle-md.box5 a:hover,
.brand-teal-bright .boxstyle-lg.box5 a:hover,
.brand-teal-bright .boxstyle_.box5 a:focus,
.brand-teal-bright .boxstyle-xs.box5 a:focus,
.brand-teal-bright .boxstyle-sm.box5 a:focus,
.brand-teal-bright .boxstyle-md.box5 a:focus,
.brand-teal-bright .boxstyle-lg.box5 a:focus,
.brand-teal-bright .boxstyle_.box5 a:active,
.brand-teal-bright .boxstyle-xs.box5 a:active,
.brand-teal-bright .boxstyle-sm.box5 a:active,
.brand-teal-bright .boxstyle-md.box5 a:active,
.brand-teal-bright .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-teal-bright .pagination > li a,
.brand-teal-bright .pagination > li span {
  color: #6DCDB8;
}
.brand-teal-bright .pagination > li.active > a,
.brand-teal-bright .pagination > li.active > span:not(.sr-only),
.brand-teal-bright .pagination > li.active > a:hover,
.brand-teal-bright .pagination > li.active > span:not(.sr-only):hover,
.brand-teal-bright .pagination > li.active > a:focus,
.brand-teal-bright .pagination > li.active > span:not(.sr-only):focus {
  background-color: #6DCDB8;
  border-color: #6DCDB8;
  color: #202020;
}
.brand-teal-bright .nav-tabs {
  border-bottom-color: #b6e6db;
}
.brand-teal-bright .nav-tabs > li > a {
  background-color: #e2f5f1;
  border-color: #b6e6db;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-teal-bright .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #a7e1d4;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-teal-bright .nav-tabs > li > a:hover,
.brand-teal-bright .nav-tabs > li > a:focus {
  background-color: #f0faf8;
  border-color: #c5ebe3 #c5ebe3 #b6e6db;
}
.brand-teal-bright .nav-tabs > li > a:hover::before,
.brand-teal-bright .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-teal-bright .nav-tabs > li.open > a,
.brand-teal-bright .nav-tabs > li.open > a:hover,
.brand-teal-bright .nav-tabs > li.open > a:focus {
  background-color: #f0faf8;
  border-color: #c5ebe3 #c5ebe3 #b6e6db;
}
.brand-teal-bright .nav-tabs > li.open > a::before,
.brand-teal-bright .nav-tabs > li.open > a:hover::before,
.brand-teal-bright .nav-tabs > li.open > a:focus::before {
  background: #6DCDB8;
  width: 100%;
}
.brand-teal-bright .nav-tabs > li.active > a,
.brand-teal-bright .nav-tabs > li.active > a:hover,
.brand-teal-bright .nav-tabs > li.active > a:focus {
  border-color: #b6e6db #b6e6db transparent;
}
.brand-teal-bright .nav-tabs > li.active > a::before,
.brand-teal-bright .nav-tabs > li.active > a:hover::before,
.brand-teal-bright .nav-tabs > li.active > a:focus::before {
  background: #6DCDB8;
  width: 100%;
}
.brand-teal-bright .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-teal-bright .nav-tabs > li.disabled > a:hover,
.brand-teal-bright .nav-tabs > li.disabled > a:focus {
  background-color: #e2f5f1;
}
.brand-teal-bright .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-teal-bright .nav-pills > li.active > a,
.brand-teal-bright .nav-pills > li.active > a:hover,
.brand-teal-bright .nav-pills > li.active > a:focus {
  background-color: #6DCDB8;
  color: #202020;
}
.brand-teal-bright .list-group-item.active,
.brand-teal-bright .list-group-item.active:hover,
.brand-teal-bright .list-group-item.active:focus {
  background-color: #6DCDB8;
  border-color: #6DCDB8;
  color: #202020;
}
.brand-teal-bright .id7-koan-spinner__neon--top,
.brand-teal-bright .id7-koan-spinner__neon--bottom {
  stroke: #6DCDB8;
}
.brand-red h1,
.brand-red h2,
.brand-red h3,
.brand-red h4,
.brand-red h5,
.brand-red h6,
.brand-red .h1,
.brand-red .h2,
.brand-red .h3,
.brand-red .h4,
.brand-red .h5,
.brand-red .h6 {
  color: #9D2235;
}
.brand-red a,
.brand-red a:link,
.brand-red a:visited {
  color: #9D2235;
}
.brand-red a:hover,
.brand-red a:focus,
.brand-red a:active {
  color: #491019;
}
.brand-red .popover button.close {
  color: #9D2235;
}
.brand-red .popover button.close:hover {
  color: #491019;
}
.brand-red a:hover .new-window-link,
.brand-red a:hover .insecure-link {
  color: #491019;
}
.brand-red .btn-link {
  color: #9D2235;
}
.brand-red .btn-link:hover,
.brand-red .btn-link:focus {
  color: #491019;
}
.brand-red a,
.brand-red a:link,
.brand-red a:visited {
  -webkit-text-decoration-color: #ba6472;
  text-decoration-color: #ba6472;
}
.brand-red a:hover,
.brand-red a:focus,
.brand-red a:active {
  -webkit-text-decoration-color: #491019;
  text-decoration-color: #491019;
}
.brand-red blockquote.quotes {
  color: #491019;
}
.brand-red blockquote.quotes::before {
  color: #491019;
}
.brand-red .brand-bg {
  background: #9D2235;
  color: white;
}
.brand-red .brand-bg a,
.brand-red .brand-bg a:link,
.brand-red .brand-bg a:visited {
  color: white !important;
}
.brand-red .brand-bg a:hover,
.brand-red .brand-bg a:focus,
.brand-red .brand-bg a:active {
  color: #cccccc !important;
}
.brand-red .brand-bg .popover button.close {
  color: white !important;
}
.brand-red .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-red .brand-bg a:hover .new-window-link,
.brand-red .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-red .brand-bg .btn-link {
  color: white !important;
}
.brand-red .brand-bg .btn-link:hover,
.brand-red .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-red .brand-bg a,
.brand-red .brand-bg a:link,
.brand-red .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-red .brand-bg a:hover,
.brand-red .brand-bg a:focus,
.brand-red .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-red .brand-text {
  color: #9D2235;
}
.brand-red .brand-text a,
.brand-red .brand-text a:link,
.brand-red .brand-text a:visited {
  color: #9D2235 !important;
}
.brand-red .brand-text a:hover,
.brand-red .brand-text a:focus,
.brand-red .brand-text a:active {
  color: #491019 !important;
}
.brand-red .brand-text .popover button.close {
  color: #9D2235 !important;
}
.brand-red .brand-text .popover button.close:hover {
  color: #491019 !important;
}
.brand-red .brand-text a:hover .new-window-link,
.brand-red .brand-text a:hover .insecure-link {
  color: #491019 !important;
}
.brand-red .brand-text .btn-link {
  color: #9D2235 !important;
}
.brand-red .brand-text .btn-link:hover,
.brand-red .brand-text .btn-link:focus {
  color: #491019 !important;
}
.brand-red .brand-text a,
.brand-red .brand-text a:link,
.brand-red .brand-text a:visited {
  -webkit-text-decoration-color: #ba6472 !important;
  text-decoration-color: #ba6472 !important;
}
.brand-red .brand-text a:hover,
.brand-red .brand-text a:focus,
.brand-red .brand-text a:active {
  -webkit-text-decoration-color: #491019 !important;
  text-decoration-color: #491019 !important;
}
.brand-red .brand-border {
  border-color: #9D2235;
}
.brand-red .btn-brand {
  color: white;
  background-color: #9D2235;
  border-color: #881d2e;
  color: white !important;
}
.brand-red .btn-brand:focus,
.brand-red .btn-brand.focus {
  color: white;
  background-color: #731927;
  border-color: #1f070b;
}
.brand-red .btn-brand:hover {
  color: white;
  background-color: #731927;
  border-color: #56131d;
}
.brand-red .btn-brand:active,
.brand-red .btn-brand.active,
.open > .dropdown-toggle.brand-red .btn-brand {
  color: white;
  background-color: #731927;
  background-image: none;
  border-color: #56131d;
}
.brand-red .btn-brand:active:hover,
.brand-red .btn-brand.active:hover,
.open > .dropdown-toggle.brand-red .btn-brand:hover,
.brand-red .btn-brand:active:focus,
.brand-red .btn-brand.active:focus,
.open > .dropdown-toggle.brand-red .btn-brand:focus,
.brand-red .btn-brand:active.focus,
.brand-red .btn-brand.active.focus,
.open > .dropdown-toggle.brand-red .btn-brand.focus {
  color: white;
  background-color: #56131d;
  border-color: #1f070b;
}
.brand-red .btn-brand.disabled:hover,
.brand-red .btn-brand[disabled]:hover,
fieldset[disabled] .brand-red .btn-brand:hover,
.brand-red .btn-brand.disabled:focus,
.brand-red .btn-brand[disabled]:focus,
fieldset[disabled] .brand-red .btn-brand:focus,
.brand-red .btn-brand.disabled.focus,
.brand-red .btn-brand[disabled].focus,
fieldset[disabled] .brand-red .btn-brand.focus {
  background-color: #9D2235;
  border-color: #881d2e;
}
.brand-red .btn-brand .badge {
  color: #9D2235;
  background-color: white;
}
.brand-red .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-red .id7-utility-masthead {
  background-color: #ba6472;
}
.brand-red .id7-utility-masthead::after {
  background-color: #9D2235;
}
body.id7-point-1 .brand-red .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-red .id7-navigation .navbar-primary {
  background-color: #9D2235;
}
.brand-red .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-red .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-red .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #d6a2aa;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-red .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #7e1b2a;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-red .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-red .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #d6a2aa;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-red .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-red .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #7e1b2a;
}
.brand-red .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-red .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-red .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-red .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-red .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #7e1b2a;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #9D2235;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #7e1b2a;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #7e1b2a;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #7e1b2a;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #7e1b2a;
  background-image: none;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #d6a2aa;
}
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-red .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #7e1b2a;
}
.brand-red .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .btn-link:hover,
.brand-red .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-red .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-red .id7-navigation .navbar-primary .btn-link:hover,
.brand-red .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-red .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-red .navbar-primary .navbar-brand {
  background-color: #9D2235;
}
.brand-red .navbar-secondary {
  background-color: #d6a2aa;
}
.brand-red .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-red .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-red .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-red .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-red .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-red .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-red .navbar-secondary .navbar-nav > li:hover,
.brand-red .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #e2bec4;
}
.brand-red .navbar-secondary .navbar-nav > li > a,
.brand-red .navbar-secondary .navbar-nav > li > a:hover,
.brand-red .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-red .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-red .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-red .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-red .navbar-secondary .navbar-nav > .disabled,
.brand-red .navbar-secondary .navbar-nav > .disabled:hover,
.brand-red .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #e2bec4;
}
.brand-red .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-red .navbar-secondary .navbar-toggle:hover,
.brand-red .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-red .navbar-secondary .navbar-nav > .open,
.brand-red .navbar-secondary .navbar-nav > .open:hover,
.brand-red .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #e2bec4;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #d6a2aa;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #ab8288;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #ab8288;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #e2bec4;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #e2bec4;
  background-image: none;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-red .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #e2bec4;
}
.brand-red .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-red .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-red .navbar-secondary .btn-link {
  color: #202020;
}
.brand-red .navbar-secondary .btn-link:hover,
.brand-red .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-red .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-red .navbar-secondary .btn-link:hover,
.brand-red .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-red .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-red .navbar-secondary .navbar-nav > li::after {
  color: #9D2235;
}
.brand-red .navbar-secondary .navbar-nav > li:first-child {
  background-color: #9D2235;
  color: white;
}
.brand-red .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #9D2235;
}
.brand-red .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #9D2235;
}
.brand-red .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-red .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-red .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-red .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-red .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-red .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-red .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-red .navbar-tertiary .navbar-nav > li:hover,
.brand-red .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red .navbar-tertiary .navbar-nav > li > a,
.brand-red .navbar-tertiary .navbar-nav > li > a:hover,
.brand-red .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-red .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-red .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-red .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-red .navbar-tertiary .navbar-nav > .disabled,
.brand-red .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-red .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-red .navbar-tertiary .navbar-toggle:hover,
.brand-red .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-red .navbar-tertiary .navbar-nav > .open,
.brand-red .navbar-tertiary .navbar-nav > .open:hover,
.brand-red .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-red .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-red .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-red .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-red .navbar-tertiary .btn-link:hover,
.brand-red .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-red .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-red .navbar-tertiary .btn-link:hover,
.brand-red .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-red .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-red.bordered .id7-left-border,
.brand-red.bordered .id7-right-border {
  border-color: rgba(157, 34, 53, 0.3);
}
.brand-red .id7-page-footer .id7-app-footer {
  background: #9D2235;
  color: white;
}
.brand-red .id7-page-footer .id7-app-footer h1,
.brand-red .id7-page-footer .id7-app-footer h2,
.brand-red .id7-page-footer .id7-app-footer h3,
.brand-red .id7-page-footer .id7-app-footer h4,
.brand-red .id7-page-footer .id7-app-footer h5,
.brand-red .id7-page-footer .id7-app-footer h6,
.brand-red .id7-page-footer .id7-app-footer .h1,
.brand-red .id7-page-footer .id7-app-footer .h2,
.brand-red .id7-page-footer .id7-app-footer .h3,
.brand-red .id7-page-footer .id7-app-footer .h4,
.brand-red .id7-page-footer .id7-app-footer .h5,
.brand-red .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-red .id7-page-footer .id7-app-footer a,
.brand-red .id7-page-footer .id7-app-footer a:link,
.brand-red .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-red .id7-page-footer .id7-app-footer a:hover,
.brand-red .id7-page-footer .id7-app-footer a:focus,
.brand-red .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-red .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-red .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-red .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-red .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-red .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-red .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-red .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-red .id7-page-footer .id7-app-footer a,
.brand-red .id7-page-footer .id7-app-footer a:link,
.brand-red .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-red .id7-page-footer .id7-app-footer a:hover,
.brand-red .id7-page-footer .id7-app-footer a:focus,
.brand-red .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-red .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #9D2235;
}
.brand-red .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-red .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-red .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-red .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-red .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-red .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-red .id7-search .fa,
.brand-red .id7-search .fas {
  color: #9D2235;
  color: rgba(157, 34, 53, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-red .id7-search .form-control:hover + .fa,
.brand-red .id7-search .typeahead:hover + .fa,
.brand-red .id7-search .fa:hover,
.brand-red .id7-search .form-control:hover + .fas,
.brand-red .id7-search .typeahead:hover + .fas,
.brand-red .id7-search .fas:hover {
  color: #9D2235;
}
.brand-red .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #9D2235;
  color: white;
}
.brand-red .form-control:focus {
  border-color: #9D2235;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(157, 34, 53, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(157, 34, 53, 0.6);
}
.brand-red .carousel .carousel-indicators li::after {
  color: #9D2235;
  color: rgba(157, 34, 53, 0.3);
}
.brand-red .carousel .carousel-indicators li.active::after {
  color: #9D2235;
}
@media (max-width: 767px) {
  .brand-red .boxstyle_.box1,
  .brand-red .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-red .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-red .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-red .boxstyle_.box1,
.brand-red .boxstyle-xs.box1,
.brand-red .boxstyle-sm.box1,
.brand-red .boxstyle-md.box1,
.brand-red .boxstyle-lg.box1 {
  border: 0;
  background: #f0dee1;
  color: #202020;
}
@media print {
  .brand-red .boxstyle_.box1,
  .brand-red .boxstyle-xs.box1,
  .brand-red .boxstyle-sm.box1,
  .brand-red .boxstyle-md.box1,
  .brand-red .boxstyle-lg.box1 {
    border: 1px solid #f0dee1;
  }
}
.brand-red .boxstyle_.box1 h1,
.brand-red .boxstyle-xs.box1 h1,
.brand-red .boxstyle-sm.box1 h1,
.brand-red .boxstyle-md.box1 h1,
.brand-red .boxstyle-lg.box1 h1,
.brand-red .boxstyle_.box1 h2,
.brand-red .boxstyle-xs.box1 h2,
.brand-red .boxstyle-sm.box1 h2,
.brand-red .boxstyle-md.box1 h2,
.brand-red .boxstyle-lg.box1 h2,
.brand-red .boxstyle_.box1 h3,
.brand-red .boxstyle-xs.box1 h3,
.brand-red .boxstyle-sm.box1 h3,
.brand-red .boxstyle-md.box1 h3,
.brand-red .boxstyle-lg.box1 h3,
.brand-red .boxstyle_.box1 h4,
.brand-red .boxstyle-xs.box1 h4,
.brand-red .boxstyle-sm.box1 h4,
.brand-red .boxstyle-md.box1 h4,
.brand-red .boxstyle-lg.box1 h4,
.brand-red .boxstyle_.box1 h5,
.brand-red .boxstyle-xs.box1 h5,
.brand-red .boxstyle-sm.box1 h5,
.brand-red .boxstyle-md.box1 h5,
.brand-red .boxstyle-lg.box1 h5,
.brand-red .boxstyle_.box1 h6,
.brand-red .boxstyle-xs.box1 h6,
.brand-red .boxstyle-sm.box1 h6,
.brand-red .boxstyle-md.box1 h6,
.brand-red .boxstyle-lg.box1 h6,
.brand-red .boxstyle_.box1 .h1,
.brand-red .boxstyle-xs.box1 .h1,
.brand-red .boxstyle-sm.box1 .h1,
.brand-red .boxstyle-md.box1 .h1,
.brand-red .boxstyle-lg.box1 .h1,
.brand-red .boxstyle_.box1 .h2,
.brand-red .boxstyle-xs.box1 .h2,
.brand-red .boxstyle-sm.box1 .h2,
.brand-red .boxstyle-md.box1 .h2,
.brand-red .boxstyle-lg.box1 .h2,
.brand-red .boxstyle_.box1 .h3,
.brand-red .boxstyle-xs.box1 .h3,
.brand-red .boxstyle-sm.box1 .h3,
.brand-red .boxstyle-md.box1 .h3,
.brand-red .boxstyle-lg.box1 .h3,
.brand-red .boxstyle_.box1 .h4,
.brand-red .boxstyle-xs.box1 .h4,
.brand-red .boxstyle-sm.box1 .h4,
.brand-red .boxstyle-md.box1 .h4,
.brand-red .boxstyle-lg.box1 .h4,
.brand-red .boxstyle_.box1 .h5,
.brand-red .boxstyle-xs.box1 .h5,
.brand-red .boxstyle-sm.box1 .h5,
.brand-red .boxstyle-md.box1 .h5,
.brand-red .boxstyle-lg.box1 .h5,
.brand-red .boxstyle_.box1 .h6,
.brand-red .boxstyle-xs.box1 .h6,
.brand-red .boxstyle-sm.box1 .h6,
.brand-red .boxstyle-md.box1 .h6,
.brand-red .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-red .boxstyle_.box1 blockquote.quotes,
.brand-red .boxstyle-xs.box1 blockquote.quotes,
.brand-red .boxstyle-sm.box1 blockquote.quotes,
.brand-red .boxstyle-md.box1 blockquote.quotes,
.brand-red .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-red .boxstyle_.box1 a,
.brand-red .boxstyle-xs.box1 a,
.brand-red .boxstyle-sm.box1 a,
.brand-red .boxstyle-md.box1 a,
.brand-red .boxstyle-lg.box1 a,
.brand-red .boxstyle_.box1 a:link,
.brand-red .boxstyle-xs.box1 a:link,
.brand-red .boxstyle-sm.box1 a:link,
.brand-red .boxstyle-md.box1 a:link,
.brand-red .boxstyle-lg.box1 a:link,
.brand-red .boxstyle_.box1 a:visited,
.brand-red .boxstyle-xs.box1 a:visited,
.brand-red .boxstyle-sm.box1 a:visited,
.brand-red .boxstyle-md.box1 a:visited,
.brand-red .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-red .boxstyle_.box1 a:hover,
.brand-red .boxstyle-xs.box1 a:hover,
.brand-red .boxstyle-sm.box1 a:hover,
.brand-red .boxstyle-md.box1 a:hover,
.brand-red .boxstyle-lg.box1 a:hover,
.brand-red .boxstyle_.box1 a:focus,
.brand-red .boxstyle-xs.box1 a:focus,
.brand-red .boxstyle-sm.box1 a:focus,
.brand-red .boxstyle-md.box1 a:focus,
.brand-red .boxstyle-lg.box1 a:focus,
.brand-red .boxstyle_.box1 a:active,
.brand-red .boxstyle-xs.box1 a:active,
.brand-red .boxstyle-sm.box1 a:active,
.brand-red .boxstyle-md.box1 a:active,
.brand-red .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-red .boxstyle_.box1 .popover button.close,
.brand-red .boxstyle-xs.box1 .popover button.close,
.brand-red .boxstyle-sm.box1 .popover button.close,
.brand-red .boxstyle-md.box1 .popover button.close,
.brand-red .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-red .boxstyle_.box1 .popover button.close:hover,
.brand-red .boxstyle-xs.box1 .popover button.close:hover,
.brand-red .boxstyle-sm.box1 .popover button.close:hover,
.brand-red .boxstyle-md.box1 .popover button.close:hover,
.brand-red .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-red .boxstyle_.box1 a:hover .new-window-link,
.brand-red .boxstyle-xs.box1 a:hover .new-window-link,
.brand-red .boxstyle-sm.box1 a:hover .new-window-link,
.brand-red .boxstyle-md.box1 a:hover .new-window-link,
.brand-red .boxstyle-lg.box1 a:hover .new-window-link,
.brand-red .boxstyle_.box1 a:hover .insecure-link,
.brand-red .boxstyle-xs.box1 a:hover .insecure-link,
.brand-red .boxstyle-sm.box1 a:hover .insecure-link,
.brand-red .boxstyle-md.box1 a:hover .insecure-link,
.brand-red .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-red .boxstyle_.box1 .btn-link,
.brand-red .boxstyle-xs.box1 .btn-link,
.brand-red .boxstyle-sm.box1 .btn-link,
.brand-red .boxstyle-md.box1 .btn-link,
.brand-red .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-red .boxstyle_.box1 .btn-link:hover,
.brand-red .boxstyle-xs.box1 .btn-link:hover,
.brand-red .boxstyle-sm.box1 .btn-link:hover,
.brand-red .boxstyle-md.box1 .btn-link:hover,
.brand-red .boxstyle-lg.box1 .btn-link:hover,
.brand-red .boxstyle_.box1 .btn-link:focus,
.brand-red .boxstyle-xs.box1 .btn-link:focus,
.brand-red .boxstyle-sm.box1 .btn-link:focus,
.brand-red .boxstyle-md.box1 .btn-link:focus,
.brand-red .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-red .boxstyle_.box1 a,
.brand-red .boxstyle-xs.box1 a,
.brand-red .boxstyle-sm.box1 a,
.brand-red .boxstyle-md.box1 a,
.brand-red .boxstyle-lg.box1 a,
.brand-red .boxstyle_.box1 a:link,
.brand-red .boxstyle-xs.box1 a:link,
.brand-red .boxstyle-sm.box1 a:link,
.brand-red .boxstyle-md.box1 a:link,
.brand-red .boxstyle-lg.box1 a:link,
.brand-red .boxstyle_.box1 a:visited,
.brand-red .boxstyle-xs.box1 a:visited,
.brand-red .boxstyle-sm.box1 a:visited,
.brand-red .boxstyle-md.box1 a:visited,
.brand-red .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-red .boxstyle_.box1 a:hover,
.brand-red .boxstyle-xs.box1 a:hover,
.brand-red .boxstyle-sm.box1 a:hover,
.brand-red .boxstyle-md.box1 a:hover,
.brand-red .boxstyle-lg.box1 a:hover,
.brand-red .boxstyle_.box1 a:focus,
.brand-red .boxstyle-xs.box1 a:focus,
.brand-red .boxstyle-sm.box1 a:focus,
.brand-red .boxstyle-md.box1 a:focus,
.brand-red .boxstyle-lg.box1 a:focus,
.brand-red .boxstyle_.box1 a:active,
.brand-red .boxstyle-xs.box1 a:active,
.brand-red .boxstyle-sm.box1 a:active,
.brand-red .boxstyle-md.box1 a:active,
.brand-red .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-red .boxstyle_.box2,
  .brand-red .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-red .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-red .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-red .boxstyle_.box2,
.brand-red .boxstyle-xs.box2,
.brand-red .boxstyle-sm.box2,
.brand-red .boxstyle-md.box2,
.brand-red .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-red .boxstyle_.box2,
  .brand-red .boxstyle-xs.box2,
  .brand-red .boxstyle-sm.box2,
  .brand-red .boxstyle-md.box2,
  .brand-red .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-red .boxstyle_.box2 h1,
.brand-red .boxstyle-xs.box2 h1,
.brand-red .boxstyle-sm.box2 h1,
.brand-red .boxstyle-md.box2 h1,
.brand-red .boxstyle-lg.box2 h1,
.brand-red .boxstyle_.box2 h2,
.brand-red .boxstyle-xs.box2 h2,
.brand-red .boxstyle-sm.box2 h2,
.brand-red .boxstyle-md.box2 h2,
.brand-red .boxstyle-lg.box2 h2,
.brand-red .boxstyle_.box2 h3,
.brand-red .boxstyle-xs.box2 h3,
.brand-red .boxstyle-sm.box2 h3,
.brand-red .boxstyle-md.box2 h3,
.brand-red .boxstyle-lg.box2 h3,
.brand-red .boxstyle_.box2 h4,
.brand-red .boxstyle-xs.box2 h4,
.brand-red .boxstyle-sm.box2 h4,
.brand-red .boxstyle-md.box2 h4,
.brand-red .boxstyle-lg.box2 h4,
.brand-red .boxstyle_.box2 h5,
.brand-red .boxstyle-xs.box2 h5,
.brand-red .boxstyle-sm.box2 h5,
.brand-red .boxstyle-md.box2 h5,
.brand-red .boxstyle-lg.box2 h5,
.brand-red .boxstyle_.box2 h6,
.brand-red .boxstyle-xs.box2 h6,
.brand-red .boxstyle-sm.box2 h6,
.brand-red .boxstyle-md.box2 h6,
.brand-red .boxstyle-lg.box2 h6,
.brand-red .boxstyle_.box2 .h1,
.brand-red .boxstyle-xs.box2 .h1,
.brand-red .boxstyle-sm.box2 .h1,
.brand-red .boxstyle-md.box2 .h1,
.brand-red .boxstyle-lg.box2 .h1,
.brand-red .boxstyle_.box2 .h2,
.brand-red .boxstyle-xs.box2 .h2,
.brand-red .boxstyle-sm.box2 .h2,
.brand-red .boxstyle-md.box2 .h2,
.brand-red .boxstyle-lg.box2 .h2,
.brand-red .boxstyle_.box2 .h3,
.brand-red .boxstyle-xs.box2 .h3,
.brand-red .boxstyle-sm.box2 .h3,
.brand-red .boxstyle-md.box2 .h3,
.brand-red .boxstyle-lg.box2 .h3,
.brand-red .boxstyle_.box2 .h4,
.brand-red .boxstyle-xs.box2 .h4,
.brand-red .boxstyle-sm.box2 .h4,
.brand-red .boxstyle-md.box2 .h4,
.brand-red .boxstyle-lg.box2 .h4,
.brand-red .boxstyle_.box2 .h5,
.brand-red .boxstyle-xs.box2 .h5,
.brand-red .boxstyle-sm.box2 .h5,
.brand-red .boxstyle-md.box2 .h5,
.brand-red .boxstyle-lg.box2 .h5,
.brand-red .boxstyle_.box2 .h6,
.brand-red .boxstyle-xs.box2 .h6,
.brand-red .boxstyle-sm.box2 .h6,
.brand-red .boxstyle-md.box2 .h6,
.brand-red .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-red .boxstyle_.box2 blockquote.quotes,
.brand-red .boxstyle-xs.box2 blockquote.quotes,
.brand-red .boxstyle-sm.box2 blockquote.quotes,
.brand-red .boxstyle-md.box2 blockquote.quotes,
.brand-red .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-red .boxstyle_.box2 a,
.brand-red .boxstyle-xs.box2 a,
.brand-red .boxstyle-sm.box2 a,
.brand-red .boxstyle-md.box2 a,
.brand-red .boxstyle-lg.box2 a,
.brand-red .boxstyle_.box2 a:link,
.brand-red .boxstyle-xs.box2 a:link,
.brand-red .boxstyle-sm.box2 a:link,
.brand-red .boxstyle-md.box2 a:link,
.brand-red .boxstyle-lg.box2 a:link,
.brand-red .boxstyle_.box2 a:visited,
.brand-red .boxstyle-xs.box2 a:visited,
.brand-red .boxstyle-sm.box2 a:visited,
.brand-red .boxstyle-md.box2 a:visited,
.brand-red .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-red .boxstyle_.box2 a:hover,
.brand-red .boxstyle-xs.box2 a:hover,
.brand-red .boxstyle-sm.box2 a:hover,
.brand-red .boxstyle-md.box2 a:hover,
.brand-red .boxstyle-lg.box2 a:hover,
.brand-red .boxstyle_.box2 a:focus,
.brand-red .boxstyle-xs.box2 a:focus,
.brand-red .boxstyle-sm.box2 a:focus,
.brand-red .boxstyle-md.box2 a:focus,
.brand-red .boxstyle-lg.box2 a:focus,
.brand-red .boxstyle_.box2 a:active,
.brand-red .boxstyle-xs.box2 a:active,
.brand-red .boxstyle-sm.box2 a:active,
.brand-red .boxstyle-md.box2 a:active,
.brand-red .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-red .boxstyle_.box2 .popover button.close,
.brand-red .boxstyle-xs.box2 .popover button.close,
.brand-red .boxstyle-sm.box2 .popover button.close,
.brand-red .boxstyle-md.box2 .popover button.close,
.brand-red .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-red .boxstyle_.box2 .popover button.close:hover,
.brand-red .boxstyle-xs.box2 .popover button.close:hover,
.brand-red .boxstyle-sm.box2 .popover button.close:hover,
.brand-red .boxstyle-md.box2 .popover button.close:hover,
.brand-red .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-red .boxstyle_.box2 a:hover .new-window-link,
.brand-red .boxstyle-xs.box2 a:hover .new-window-link,
.brand-red .boxstyle-sm.box2 a:hover .new-window-link,
.brand-red .boxstyle-md.box2 a:hover .new-window-link,
.brand-red .boxstyle-lg.box2 a:hover .new-window-link,
.brand-red .boxstyle_.box2 a:hover .insecure-link,
.brand-red .boxstyle-xs.box2 a:hover .insecure-link,
.brand-red .boxstyle-sm.box2 a:hover .insecure-link,
.brand-red .boxstyle-md.box2 a:hover .insecure-link,
.brand-red .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-red .boxstyle_.box2 .btn-link,
.brand-red .boxstyle-xs.box2 .btn-link,
.brand-red .boxstyle-sm.box2 .btn-link,
.brand-red .boxstyle-md.box2 .btn-link,
.brand-red .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-red .boxstyle_.box2 .btn-link:hover,
.brand-red .boxstyle-xs.box2 .btn-link:hover,
.brand-red .boxstyle-sm.box2 .btn-link:hover,
.brand-red .boxstyle-md.box2 .btn-link:hover,
.brand-red .boxstyle-lg.box2 .btn-link:hover,
.brand-red .boxstyle_.box2 .btn-link:focus,
.brand-red .boxstyle-xs.box2 .btn-link:focus,
.brand-red .boxstyle-sm.box2 .btn-link:focus,
.brand-red .boxstyle-md.box2 .btn-link:focus,
.brand-red .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-red .boxstyle_.box2 a,
.brand-red .boxstyle-xs.box2 a,
.brand-red .boxstyle-sm.box2 a,
.brand-red .boxstyle-md.box2 a,
.brand-red .boxstyle-lg.box2 a,
.brand-red .boxstyle_.box2 a:link,
.brand-red .boxstyle-xs.box2 a:link,
.brand-red .boxstyle-sm.box2 a:link,
.brand-red .boxstyle-md.box2 a:link,
.brand-red .boxstyle-lg.box2 a:link,
.brand-red .boxstyle_.box2 a:visited,
.brand-red .boxstyle-xs.box2 a:visited,
.brand-red .boxstyle-sm.box2 a:visited,
.brand-red .boxstyle-md.box2 a:visited,
.brand-red .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-red .boxstyle_.box2 a:hover,
.brand-red .boxstyle-xs.box2 a:hover,
.brand-red .boxstyle-sm.box2 a:hover,
.brand-red .boxstyle-md.box2 a:hover,
.brand-red .boxstyle-lg.box2 a:hover,
.brand-red .boxstyle_.box2 a:focus,
.brand-red .boxstyle-xs.box2 a:focus,
.brand-red .boxstyle-sm.box2 a:focus,
.brand-red .boxstyle-md.box2 a:focus,
.brand-red .boxstyle-lg.box2 a:focus,
.brand-red .boxstyle_.box2 a:active,
.brand-red .boxstyle-xs.box2 a:active,
.brand-red .boxstyle-sm.box2 a:active,
.brand-red .boxstyle-md.box2 a:active,
.brand-red .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-red .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #9D2235;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-red .boxstyle_.box3,
  .brand-red .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #9D2235;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-red .boxstyle-md.box3 {
    background: none;
    border: 1px solid #9D2235;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-red .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #9D2235;
    color: inherit;
  }
}
.brand-red .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-red .boxstyle_.box4,
  .brand-red .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-red .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-red .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-red .boxstyle_.box5,
  .brand-red .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-red .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-red .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-red .boxstyle_.box5,
.brand-red .boxstyle-xs.box5,
.brand-red .boxstyle-sm.box5,
.brand-red .boxstyle-md.box5,
.brand-red .boxstyle-lg.box5 {
  border: 0;
  background: #9D2235;
  color: white;
}
@media print {
  .brand-red .boxstyle_.box5,
  .brand-red .boxstyle-xs.box5,
  .brand-red .boxstyle-sm.box5,
  .brand-red .boxstyle-md.box5,
  .brand-red .boxstyle-lg.box5 {
    border: 1px solid #9D2235;
  }
}
.brand-red .boxstyle_.box5 h1,
.brand-red .boxstyle-xs.box5 h1,
.brand-red .boxstyle-sm.box5 h1,
.brand-red .boxstyle-md.box5 h1,
.brand-red .boxstyle-lg.box5 h1,
.brand-red .boxstyle_.box5 h2,
.brand-red .boxstyle-xs.box5 h2,
.brand-red .boxstyle-sm.box5 h2,
.brand-red .boxstyle-md.box5 h2,
.brand-red .boxstyle-lg.box5 h2,
.brand-red .boxstyle_.box5 h3,
.brand-red .boxstyle-xs.box5 h3,
.brand-red .boxstyle-sm.box5 h3,
.brand-red .boxstyle-md.box5 h3,
.brand-red .boxstyle-lg.box5 h3,
.brand-red .boxstyle_.box5 h4,
.brand-red .boxstyle-xs.box5 h4,
.brand-red .boxstyle-sm.box5 h4,
.brand-red .boxstyle-md.box5 h4,
.brand-red .boxstyle-lg.box5 h4,
.brand-red .boxstyle_.box5 h5,
.brand-red .boxstyle-xs.box5 h5,
.brand-red .boxstyle-sm.box5 h5,
.brand-red .boxstyle-md.box5 h5,
.brand-red .boxstyle-lg.box5 h5,
.brand-red .boxstyle_.box5 h6,
.brand-red .boxstyle-xs.box5 h6,
.brand-red .boxstyle-sm.box5 h6,
.brand-red .boxstyle-md.box5 h6,
.brand-red .boxstyle-lg.box5 h6,
.brand-red .boxstyle_.box5 .h1,
.brand-red .boxstyle-xs.box5 .h1,
.brand-red .boxstyle-sm.box5 .h1,
.brand-red .boxstyle-md.box5 .h1,
.brand-red .boxstyle-lg.box5 .h1,
.brand-red .boxstyle_.box5 .h2,
.brand-red .boxstyle-xs.box5 .h2,
.brand-red .boxstyle-sm.box5 .h2,
.brand-red .boxstyle-md.box5 .h2,
.brand-red .boxstyle-lg.box5 .h2,
.brand-red .boxstyle_.box5 .h3,
.brand-red .boxstyle-xs.box5 .h3,
.brand-red .boxstyle-sm.box5 .h3,
.brand-red .boxstyle-md.box5 .h3,
.brand-red .boxstyle-lg.box5 .h3,
.brand-red .boxstyle_.box5 .h4,
.brand-red .boxstyle-xs.box5 .h4,
.brand-red .boxstyle-sm.box5 .h4,
.brand-red .boxstyle-md.box5 .h4,
.brand-red .boxstyle-lg.box5 .h4,
.brand-red .boxstyle_.box5 .h5,
.brand-red .boxstyle-xs.box5 .h5,
.brand-red .boxstyle-sm.box5 .h5,
.brand-red .boxstyle-md.box5 .h5,
.brand-red .boxstyle-lg.box5 .h5,
.brand-red .boxstyle_.box5 .h6,
.brand-red .boxstyle-xs.box5 .h6,
.brand-red .boxstyle-sm.box5 .h6,
.brand-red .boxstyle-md.box5 .h6,
.brand-red .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-red .boxstyle_.box5 blockquote.quotes,
.brand-red .boxstyle-xs.box5 blockquote.quotes,
.brand-red .boxstyle-sm.box5 blockquote.quotes,
.brand-red .boxstyle-md.box5 blockquote.quotes,
.brand-red .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-red .boxstyle_.box5 a,
.brand-red .boxstyle-xs.box5 a,
.brand-red .boxstyle-sm.box5 a,
.brand-red .boxstyle-md.box5 a,
.brand-red .boxstyle-lg.box5 a,
.brand-red .boxstyle_.box5 a:link,
.brand-red .boxstyle-xs.box5 a:link,
.brand-red .boxstyle-sm.box5 a:link,
.brand-red .boxstyle-md.box5 a:link,
.brand-red .boxstyle-lg.box5 a:link,
.brand-red .boxstyle_.box5 a:visited,
.brand-red .boxstyle-xs.box5 a:visited,
.brand-red .boxstyle-sm.box5 a:visited,
.brand-red .boxstyle-md.box5 a:visited,
.brand-red .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-red .boxstyle_.box5 a:hover,
.brand-red .boxstyle-xs.box5 a:hover,
.brand-red .boxstyle-sm.box5 a:hover,
.brand-red .boxstyle-md.box5 a:hover,
.brand-red .boxstyle-lg.box5 a:hover,
.brand-red .boxstyle_.box5 a:focus,
.brand-red .boxstyle-xs.box5 a:focus,
.brand-red .boxstyle-sm.box5 a:focus,
.brand-red .boxstyle-md.box5 a:focus,
.brand-red .boxstyle-lg.box5 a:focus,
.brand-red .boxstyle_.box5 a:active,
.brand-red .boxstyle-xs.box5 a:active,
.brand-red .boxstyle-sm.box5 a:active,
.brand-red .boxstyle-md.box5 a:active,
.brand-red .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-red .boxstyle_.box5 .popover button.close,
.brand-red .boxstyle-xs.box5 .popover button.close,
.brand-red .boxstyle-sm.box5 .popover button.close,
.brand-red .boxstyle-md.box5 .popover button.close,
.brand-red .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-red .boxstyle_.box5 .popover button.close:hover,
.brand-red .boxstyle-xs.box5 .popover button.close:hover,
.brand-red .boxstyle-sm.box5 .popover button.close:hover,
.brand-red .boxstyle-md.box5 .popover button.close:hover,
.brand-red .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-red .boxstyle_.box5 a:hover .new-window-link,
.brand-red .boxstyle-xs.box5 a:hover .new-window-link,
.brand-red .boxstyle-sm.box5 a:hover .new-window-link,
.brand-red .boxstyle-md.box5 a:hover .new-window-link,
.brand-red .boxstyle-lg.box5 a:hover .new-window-link,
.brand-red .boxstyle_.box5 a:hover .insecure-link,
.brand-red .boxstyle-xs.box5 a:hover .insecure-link,
.brand-red .boxstyle-sm.box5 a:hover .insecure-link,
.brand-red .boxstyle-md.box5 a:hover .insecure-link,
.brand-red .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-red .boxstyle_.box5 .btn-link,
.brand-red .boxstyle-xs.box5 .btn-link,
.brand-red .boxstyle-sm.box5 .btn-link,
.brand-red .boxstyle-md.box5 .btn-link,
.brand-red .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-red .boxstyle_.box5 .btn-link:hover,
.brand-red .boxstyle-xs.box5 .btn-link:hover,
.brand-red .boxstyle-sm.box5 .btn-link:hover,
.brand-red .boxstyle-md.box5 .btn-link:hover,
.brand-red .boxstyle-lg.box5 .btn-link:hover,
.brand-red .boxstyle_.box5 .btn-link:focus,
.brand-red .boxstyle-xs.box5 .btn-link:focus,
.brand-red .boxstyle-sm.box5 .btn-link:focus,
.brand-red .boxstyle-md.box5 .btn-link:focus,
.brand-red .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-red .boxstyle_.box5 a,
.brand-red .boxstyle-xs.box5 a,
.brand-red .boxstyle-sm.box5 a,
.brand-red .boxstyle-md.box5 a,
.brand-red .boxstyle-lg.box5 a,
.brand-red .boxstyle_.box5 a:link,
.brand-red .boxstyle-xs.box5 a:link,
.brand-red .boxstyle-sm.box5 a:link,
.brand-red .boxstyle-md.box5 a:link,
.brand-red .boxstyle-lg.box5 a:link,
.brand-red .boxstyle_.box5 a:visited,
.brand-red .boxstyle-xs.box5 a:visited,
.brand-red .boxstyle-sm.box5 a:visited,
.brand-red .boxstyle-md.box5 a:visited,
.brand-red .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-red .boxstyle_.box5 a:hover,
.brand-red .boxstyle-xs.box5 a:hover,
.brand-red .boxstyle-sm.box5 a:hover,
.brand-red .boxstyle-md.box5 a:hover,
.brand-red .boxstyle-lg.box5 a:hover,
.brand-red .boxstyle_.box5 a:focus,
.brand-red .boxstyle-xs.box5 a:focus,
.brand-red .boxstyle-sm.box5 a:focus,
.brand-red .boxstyle-md.box5 a:focus,
.brand-red .boxstyle-lg.box5 a:focus,
.brand-red .boxstyle_.box5 a:active,
.brand-red .boxstyle-xs.box5 a:active,
.brand-red .boxstyle-sm.box5 a:active,
.brand-red .boxstyle-md.box5 a:active,
.brand-red .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-red .pagination > li a,
.brand-red .pagination > li span {
  color: #9D2235;
}
.brand-red .pagination > li.active > a,
.brand-red .pagination > li.active > span:not(.sr-only),
.brand-red .pagination > li.active > a:hover,
.brand-red .pagination > li.active > span:not(.sr-only):hover,
.brand-red .pagination > li.active > a:focus,
.brand-red .pagination > li.active > span:not(.sr-only):focus {
  background-color: #9D2235;
  border-color: #9D2235;
  color: white;
}
.brand-red .nav-tabs {
  border-bottom-color: #ce919a;
}
.brand-red .nav-tabs > li > a {
  background-color: #ebd3d7;
  border-color: #ce919a;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-red .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #c47a86;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-red .nav-tabs > li > a:hover,
.brand-red .nav-tabs > li > a:focus {
  background-color: #f5e9eb;
  border-color: #d8a7ae #d8a7ae #ce919a;
}
.brand-red .nav-tabs > li > a:hover::before,
.brand-red .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-red .nav-tabs > li.open > a,
.brand-red .nav-tabs > li.open > a:hover,
.brand-red .nav-tabs > li.open > a:focus {
  background-color: #f5e9eb;
  border-color: #d8a7ae #d8a7ae #ce919a;
}
.brand-red .nav-tabs > li.open > a::before,
.brand-red .nav-tabs > li.open > a:hover::before,
.brand-red .nav-tabs > li.open > a:focus::before {
  background: #9D2235;
  width: 100%;
}
.brand-red .nav-tabs > li.active > a,
.brand-red .nav-tabs > li.active > a:hover,
.brand-red .nav-tabs > li.active > a:focus {
  border-color: #ce919a #ce919a transparent;
}
.brand-red .nav-tabs > li.active > a::before,
.brand-red .nav-tabs > li.active > a:hover::before,
.brand-red .nav-tabs > li.active > a:focus::before {
  background: #9D2235;
  width: 100%;
}
.brand-red .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-red .nav-tabs > li.disabled > a:hover,
.brand-red .nav-tabs > li.disabled > a:focus {
  background-color: #ebd3d7;
}
.brand-red .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-red .nav-pills > li.active > a,
.brand-red .nav-pills > li.active > a:hover,
.brand-red .nav-pills > li.active > a:focus {
  background-color: #9D2235;
  color: white;
}
.brand-red .list-group-item.active,
.brand-red .list-group-item.active:hover,
.brand-red .list-group-item.active:focus {
  background-color: #9D2235;
  border-color: #9D2235;
  color: white;
}
.brand-red .id7-koan-spinner__neon--top,
.brand-red .id7-koan-spinner__neon--bottom {
  stroke: #9D2235;
}
.brand-red-bright h1,
.brand-red-bright h2,
.brand-red-bright h3,
.brand-red-bright h4,
.brand-red-bright h5,
.brand-red-bright h6,
.brand-red-bright .h1,
.brand-red-bright .h2,
.brand-red-bright .h3,
.brand-red-bright .h4,
.brand-red-bright .h5,
.brand-red-bright .h6 {
  color: #CB333B;
}
.brand-red-bright a,
.brand-red-bright a:link,
.brand-red-bright a:visited {
  color: #CB333B;
}
.brand-red-bright a:hover,
.brand-red-bright a:focus,
.brand-red-bright a:active {
  color: #791f23;
}
.brand-red-bright .popover button.close {
  color: #CB333B;
}
.brand-red-bright .popover button.close:hover {
  color: #791f23;
}
.brand-red-bright a:hover .new-window-link,
.brand-red-bright a:hover .insecure-link {
  color: #791f23;
}
.brand-red-bright .btn-link {
  color: #CB333B;
}
.brand-red-bright .btn-link:hover,
.brand-red-bright .btn-link:focus {
  color: #791f23;
}
.brand-red-bright a,
.brand-red-bright a:link,
.brand-red-bright a:visited {
  -webkit-text-decoration-color: #db7076;
  text-decoration-color: #db7076;
}
.brand-red-bright a:hover,
.brand-red-bright a:focus,
.brand-red-bright a:active {
  -webkit-text-decoration-color: #791f23;
  text-decoration-color: #791f23;
}
.brand-red-bright blockquote.quotes {
  color: #791f23;
}
.brand-red-bright blockquote.quotes::before {
  color: #791f23;
}
.brand-red-bright .brand-bg {
  background: #CB333B;
  color: white;
}
.brand-red-bright .brand-bg a,
.brand-red-bright .brand-bg a:link,
.brand-red-bright .brand-bg a:visited {
  color: white !important;
}
.brand-red-bright .brand-bg a:hover,
.brand-red-bright .brand-bg a:focus,
.brand-red-bright .brand-bg a:active {
  color: #cccccc !important;
}
.brand-red-bright .brand-bg .popover button.close {
  color: white !important;
}
.brand-red-bright .brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-red-bright .brand-bg a:hover .new-window-link,
.brand-red-bright .brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-red-bright .brand-bg .btn-link {
  color: white !important;
}
.brand-red-bright .brand-bg .btn-link:hover,
.brand-red-bright .brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-red-bright .brand-bg a,
.brand-red-bright .brand-bg a:link,
.brand-red-bright .brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-red-bright .brand-bg a:hover,
.brand-red-bright .brand-bg a:focus,
.brand-red-bright .brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-red-bright .brand-text {
  color: #CB333B;
}
.brand-red-bright .brand-text a,
.brand-red-bright .brand-text a:link,
.brand-red-bright .brand-text a:visited {
  color: #CB333B !important;
}
.brand-red-bright .brand-text a:hover,
.brand-red-bright .brand-text a:focus,
.brand-red-bright .brand-text a:active {
  color: #791f23 !important;
}
.brand-red-bright .brand-text .popover button.close {
  color: #CB333B !important;
}
.brand-red-bright .brand-text .popover button.close:hover {
  color: #791f23 !important;
}
.brand-red-bright .brand-text a:hover .new-window-link,
.brand-red-bright .brand-text a:hover .insecure-link {
  color: #791f23 !important;
}
.brand-red-bright .brand-text .btn-link {
  color: #CB333B !important;
}
.brand-red-bright .brand-text .btn-link:hover,
.brand-red-bright .brand-text .btn-link:focus {
  color: #791f23 !important;
}
.brand-red-bright .brand-text a,
.brand-red-bright .brand-text a:link,
.brand-red-bright .brand-text a:visited {
  -webkit-text-decoration-color: #db7076 !important;
  text-decoration-color: #db7076 !important;
}
.brand-red-bright .brand-text a:hover,
.brand-red-bright .brand-text a:focus,
.brand-red-bright .brand-text a:active {
  -webkit-text-decoration-color: #791f23 !important;
  text-decoration-color: #791f23 !important;
}
.brand-red-bright .brand-border {
  border-color: #CB333B;
}
.brand-red-bright .btn-brand {
  color: white;
  background-color: #CB333B;
  border-color: #b72e35;
  color: white !important;
}
.brand-red-bright .btn-brand:focus,
.brand-red-bright .btn-brand.focus {
  color: white;
  background-color: #a2292f;
  border-color: #511417;
}
.brand-red-bright .btn-brand:hover {
  color: white;
  background-color: #a2292f;
  border-color: #862227;
}
.brand-red-bright .btn-brand:active,
.brand-red-bright .btn-brand.active,
.open > .dropdown-toggle.brand-red-bright .btn-brand {
  color: white;
  background-color: #a2292f;
  background-image: none;
  border-color: #862227;
}
.brand-red-bright .btn-brand:active:hover,
.brand-red-bright .btn-brand.active:hover,
.open > .dropdown-toggle.brand-red-bright .btn-brand:hover,
.brand-red-bright .btn-brand:active:focus,
.brand-red-bright .btn-brand.active:focus,
.open > .dropdown-toggle.brand-red-bright .btn-brand:focus,
.brand-red-bright .btn-brand:active.focus,
.brand-red-bright .btn-brand.active.focus,
.open > .dropdown-toggle.brand-red-bright .btn-brand.focus {
  color: white;
  background-color: #862227;
  border-color: #511417;
}
.brand-red-bright .btn-brand.disabled:hover,
.brand-red-bright .btn-brand[disabled]:hover,
fieldset[disabled] .brand-red-bright .btn-brand:hover,
.brand-red-bright .btn-brand.disabled:focus,
.brand-red-bright .btn-brand[disabled]:focus,
fieldset[disabled] .brand-red-bright .btn-brand:focus,
.brand-red-bright .btn-brand.disabled.focus,
.brand-red-bright .btn-brand[disabled].focus,
fieldset[disabled] .brand-red-bright .btn-brand.focus {
  background-color: #CB333B;
  border-color: #b72e35;
}
.brand-red-bright .btn-brand .badge {
  color: #CB333B;
  background-color: white;
}
.brand-red-bright .btn-brand:not(.btn-link) {
  text-decoration: none;
}
.brand-red-bright .id7-utility-masthead {
  background-color: #db7076;
}
.brand-red-bright .id7-utility-masthead::after {
  background-color: #CB333B;
}
body.id7-point-1 .brand-red-bright .id7-utility-masthead::after {
  background-color: transparent;
}
.brand-red-bright .id7-navigation .navbar-primary {
  background-color: #CB333B;
}
.brand-red-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-red-bright .id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #e9a9ad;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li:hover,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #a2292f;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li > a,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #e9a9ad;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > .disabled,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #a2292f;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-toggle:hover,
.brand-red-bright .id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > .open,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > .open:hover,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #a2292f;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #CB333B;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #a2292f;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #a2292f;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #a2292f;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #a2292f;
  background-image: none;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #e9a9ad;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-red-bright .id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #a2292f;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .btn-link {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-red-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-red-bright .id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .brand-red-bright .id7-navigation .navbar-primary .btn-link:hover,
.brand-red-bright .id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .brand-red-bright .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.brand-red-bright .navbar-primary .navbar-brand {
  background-color: #CB333B;
}
.brand-red-bright .navbar-secondary {
  background-color: #e9a9ad;
}
.brand-red-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-red-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-red-bright .navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-red-bright .navbar-secondary .navbar-text {
  color: #202020;
}
.brand-red-bright .navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-red-bright .navbar-secondary .navbar-nav > li {
  color: #202020;
}
.brand-red-bright .navbar-secondary .navbar-nav > li:hover,
.brand-red-bright .navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #f0c3c5;
}
.brand-red-bright .navbar-secondary .navbar-nav > li > a,
.brand-red-bright .navbar-secondary .navbar-nav > li > a:hover,
.brand-red-bright .navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-red-bright .navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-red-bright .navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-red-bright .navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-red-bright .navbar-secondary .navbar-nav > .disabled,
.brand-red-bright .navbar-secondary .navbar-nav > .disabled:hover,
.brand-red-bright .navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #f0c3c5;
}
.brand-red-bright .navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-red-bright .navbar-secondary .navbar-toggle:hover,
.brand-red-bright .navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-red-bright .navbar-secondary .navbar-nav > .open,
.brand-red-bright .navbar-secondary .navbar-nav > .open:hover,
.brand-red-bright .navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #f0c3c5;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #e9a9ad;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #bb878a;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #bb878a;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #f0c3c5;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #f0c3c5;
  background-image: none;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-red-bright .navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #f0c3c5;
}
.brand-red-bright .navbar-secondary .navbar-link {
  color: #202020;
}
.brand-red-bright .navbar-secondary .navbar-link:hover {
  color: #202020;
}
.brand-red-bright .navbar-secondary .btn-link {
  color: #202020;
}
.brand-red-bright .navbar-secondary .btn-link:hover,
.brand-red-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-red-bright .navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .brand-red-bright .navbar-secondary .btn-link:hover,
.brand-red-bright .navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .brand-red-bright .navbar-secondary .btn-link:focus {
  color: #202020;
}
.brand-red-bright .navbar-secondary .navbar-nav > li::after {
  color: #CB333B;
}
.brand-red-bright .navbar-secondary .navbar-nav > li:first-child {
  background-color: #CB333B;
  color: white;
}
.brand-red-bright .navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #CB333B;
}
.brand-red-bright .navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #CB333B;
}
.brand-red-bright .navbar-tertiary {
  background-color: #f8f8f8;
}
.brand-red-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.brand-red-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.brand-red-bright .navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .navbar-text {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.brand-red-bright .navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .navbar-nav > li:hover,
.brand-red-bright .navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red-bright .navbar-tertiary .navbar-nav > li > a,
.brand-red-bright .navbar-tertiary .navbar-nav > li > a:hover,
.brand-red-bright .navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.brand-red-bright .navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.brand-red-bright .navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.brand-red-bright .navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.brand-red-bright .navbar-tertiary .navbar-nav > .disabled,
.brand-red-bright .navbar-tertiary .navbar-nav > .disabled:hover,
.brand-red-bright .navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red-bright .navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.brand-red-bright .navbar-tertiary .navbar-toggle:hover,
.brand-red-bright .navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.brand-red-bright .navbar-tertiary .navbar-nav > .open,
.brand-red-bright .navbar-tertiary .navbar-nav > .open:hover,
.brand-red-bright .navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.brand-red-bright .navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.brand-red-bright .navbar-tertiary .navbar-link {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .btn-link {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .btn-link:hover,
.brand-red-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-red-bright .navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .brand-red-bright .navbar-tertiary .btn-link:hover,
.brand-red-bright .navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .brand-red-bright .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.brand-red-bright.bordered .id7-left-border,
.brand-red-bright.bordered .id7-right-border {
  border-color: rgba(203, 51, 59, 0.3);
}
.brand-red-bright .id7-page-footer .id7-app-footer {
  background: #CB333B;
  color: white;
}
.brand-red-bright .id7-page-footer .id7-app-footer h1,
.brand-red-bright .id7-page-footer .id7-app-footer h2,
.brand-red-bright .id7-page-footer .id7-app-footer h3,
.brand-red-bright .id7-page-footer .id7-app-footer h4,
.brand-red-bright .id7-page-footer .id7-app-footer h5,
.brand-red-bright .id7-page-footer .id7-app-footer h6,
.brand-red-bright .id7-page-footer .id7-app-footer .h1,
.brand-red-bright .id7-page-footer .id7-app-footer .h2,
.brand-red-bright .id7-page-footer .id7-app-footer .h3,
.brand-red-bright .id7-page-footer .id7-app-footer .h4,
.brand-red-bright .id7-page-footer .id7-app-footer .h5,
.brand-red-bright .id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.brand-red-bright .id7-page-footer .id7-app-footer a,
.brand-red-bright .id7-page-footer .id7-app-footer a:link,
.brand-red-bright .id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.brand-red-bright .id7-page-footer .id7-app-footer a:hover,
.brand-red-bright .id7-page-footer .id7-app-footer a:focus,
.brand-red-bright .id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.brand-red-bright .id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.brand-red-bright .id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.brand-red-bright .id7-page-footer .id7-app-footer a:hover .new-window-link,
.brand-red-bright .id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.brand-red-bright .id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.brand-red-bright .id7-page-footer .id7-app-footer .btn-link:hover,
.brand-red-bright .id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.brand-red-bright .id7-page-footer .id7-app-footer a,
.brand-red-bright .id7-page-footer .id7-app-footer a:link,
.brand-red-bright .id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-red-bright .id7-page-footer .id7-app-footer a:hover,
.brand-red-bright .id7-page-footer .id7-app-footer a:focus,
.brand-red-bright .id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-red-bright .id7-page-footer .id7-horizontal-divider .divider {
  stroke: #CB333B;
}
.brand-red-bright .id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.brand-red-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.brand-red-bright .id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.brand-red-bright .id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.brand-red-bright .id7-search .fa,
.brand-red-bright .id7-search .fas {
  color: #CB333B;
  color: rgba(203, 51, 59, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.brand-red-bright .id7-search .form-control:hover + .fa,
.brand-red-bright .id7-search .typeahead:hover + .fa,
.brand-red-bright .id7-search .fa:hover,
.brand-red-bright .id7-search .form-control:hover + .fas,
.brand-red-bright .id7-search .typeahead:hover + .fas,
.brand-red-bright .id7-search .fas:hover {
  color: #CB333B;
}
.brand-red-bright .id7-search .typeahead.dropdown-menu > li.active > a {
  background: #CB333B;
  color: white;
}
.brand-red-bright .form-control:focus {
  border-color: #CB333B;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(203, 51, 59, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(203, 51, 59, 0.6);
}
.brand-red-bright .carousel .carousel-indicators li::after {
  color: #CB333B;
  color: rgba(203, 51, 59, 0.3);
}
.brand-red-bright .carousel .carousel-indicators li.active::after {
  color: #CB333B;
}
@media (max-width: 767px) {
  .brand-red-bright .boxstyle_.box1,
  .brand-red-bright .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-red-bright .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-red-bright .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-red-bright .boxstyle_.box1,
.brand-red-bright .boxstyle-xs.box1,
.brand-red-bright .boxstyle-sm.box1,
.brand-red-bright .boxstyle-md.box1,
.brand-red-bright .boxstyle-lg.box1 {
  border: 0;
  background: #f7e0e2;
  color: #202020;
}
@media print {
  .brand-red-bright .boxstyle_.box1,
  .brand-red-bright .boxstyle-xs.box1,
  .brand-red-bright .boxstyle-sm.box1,
  .brand-red-bright .boxstyle-md.box1,
  .brand-red-bright .boxstyle-lg.box1 {
    border: 1px solid #f7e0e2;
  }
}
.brand-red-bright .boxstyle_.box1 h1,
.brand-red-bright .boxstyle-xs.box1 h1,
.brand-red-bright .boxstyle-sm.box1 h1,
.brand-red-bright .boxstyle-md.box1 h1,
.brand-red-bright .boxstyle-lg.box1 h1,
.brand-red-bright .boxstyle_.box1 h2,
.brand-red-bright .boxstyle-xs.box1 h2,
.brand-red-bright .boxstyle-sm.box1 h2,
.brand-red-bright .boxstyle-md.box1 h2,
.brand-red-bright .boxstyle-lg.box1 h2,
.brand-red-bright .boxstyle_.box1 h3,
.brand-red-bright .boxstyle-xs.box1 h3,
.brand-red-bright .boxstyle-sm.box1 h3,
.brand-red-bright .boxstyle-md.box1 h3,
.brand-red-bright .boxstyle-lg.box1 h3,
.brand-red-bright .boxstyle_.box1 h4,
.brand-red-bright .boxstyle-xs.box1 h4,
.brand-red-bright .boxstyle-sm.box1 h4,
.brand-red-bright .boxstyle-md.box1 h4,
.brand-red-bright .boxstyle-lg.box1 h4,
.brand-red-bright .boxstyle_.box1 h5,
.brand-red-bright .boxstyle-xs.box1 h5,
.brand-red-bright .boxstyle-sm.box1 h5,
.brand-red-bright .boxstyle-md.box1 h5,
.brand-red-bright .boxstyle-lg.box1 h5,
.brand-red-bright .boxstyle_.box1 h6,
.brand-red-bright .boxstyle-xs.box1 h6,
.brand-red-bright .boxstyle-sm.box1 h6,
.brand-red-bright .boxstyle-md.box1 h6,
.brand-red-bright .boxstyle-lg.box1 h6,
.brand-red-bright .boxstyle_.box1 .h1,
.brand-red-bright .boxstyle-xs.box1 .h1,
.brand-red-bright .boxstyle-sm.box1 .h1,
.brand-red-bright .boxstyle-md.box1 .h1,
.brand-red-bright .boxstyle-lg.box1 .h1,
.brand-red-bright .boxstyle_.box1 .h2,
.brand-red-bright .boxstyle-xs.box1 .h2,
.brand-red-bright .boxstyle-sm.box1 .h2,
.brand-red-bright .boxstyle-md.box1 .h2,
.brand-red-bright .boxstyle-lg.box1 .h2,
.brand-red-bright .boxstyle_.box1 .h3,
.brand-red-bright .boxstyle-xs.box1 .h3,
.brand-red-bright .boxstyle-sm.box1 .h3,
.brand-red-bright .boxstyle-md.box1 .h3,
.brand-red-bright .boxstyle-lg.box1 .h3,
.brand-red-bright .boxstyle_.box1 .h4,
.brand-red-bright .boxstyle-xs.box1 .h4,
.brand-red-bright .boxstyle-sm.box1 .h4,
.brand-red-bright .boxstyle-md.box1 .h4,
.brand-red-bright .boxstyle-lg.box1 .h4,
.brand-red-bright .boxstyle_.box1 .h5,
.brand-red-bright .boxstyle-xs.box1 .h5,
.brand-red-bright .boxstyle-sm.box1 .h5,
.brand-red-bright .boxstyle-md.box1 .h5,
.brand-red-bright .boxstyle-lg.box1 .h5,
.brand-red-bright .boxstyle_.box1 .h6,
.brand-red-bright .boxstyle-xs.box1 .h6,
.brand-red-bright .boxstyle-sm.box1 .h6,
.brand-red-bright .boxstyle-md.box1 .h6,
.brand-red-bright .boxstyle-lg.box1 .h6 {
  color: #202020;
}
.brand-red-bright .boxstyle_.box1 blockquote.quotes,
.brand-red-bright .boxstyle-xs.box1 blockquote.quotes,
.brand-red-bright .boxstyle-sm.box1 blockquote.quotes,
.brand-red-bright .boxstyle-md.box1 blockquote.quotes,
.brand-red-bright .boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.brand-red-bright .boxstyle_.box1 a,
.brand-red-bright .boxstyle-xs.box1 a,
.brand-red-bright .boxstyle-sm.box1 a,
.brand-red-bright .boxstyle-md.box1 a,
.brand-red-bright .boxstyle-lg.box1 a,
.brand-red-bright .boxstyle_.box1 a:link,
.brand-red-bright .boxstyle-xs.box1 a:link,
.brand-red-bright .boxstyle-sm.box1 a:link,
.brand-red-bright .boxstyle-md.box1 a:link,
.brand-red-bright .boxstyle-lg.box1 a:link,
.brand-red-bright .boxstyle_.box1 a:visited,
.brand-red-bright .boxstyle-xs.box1 a:visited,
.brand-red-bright .boxstyle-sm.box1 a:visited,
.brand-red-bright .boxstyle-md.box1 a:visited,
.brand-red-bright .boxstyle-lg.box1 a:visited {
  color: #202020;
}
.brand-red-bright .boxstyle_.box1 a:hover,
.brand-red-bright .boxstyle-xs.box1 a:hover,
.brand-red-bright .boxstyle-sm.box1 a:hover,
.brand-red-bright .boxstyle-md.box1 a:hover,
.brand-red-bright .boxstyle-lg.box1 a:hover,
.brand-red-bright .boxstyle_.box1 a:focus,
.brand-red-bright .boxstyle-xs.box1 a:focus,
.brand-red-bright .boxstyle-sm.box1 a:focus,
.brand-red-bright .boxstyle-md.box1 a:focus,
.brand-red-bright .boxstyle-lg.box1 a:focus,
.brand-red-bright .boxstyle_.box1 a:active,
.brand-red-bright .boxstyle-xs.box1 a:active,
.brand-red-bright .boxstyle-sm.box1 a:active,
.brand-red-bright .boxstyle-md.box1 a:active,
.brand-red-bright .boxstyle-lg.box1 a:active {
  color: #000000;
}
.brand-red-bright .boxstyle_.box1 .popover button.close,
.brand-red-bright .boxstyle-xs.box1 .popover button.close,
.brand-red-bright .boxstyle-sm.box1 .popover button.close,
.brand-red-bright .boxstyle-md.box1 .popover button.close,
.brand-red-bright .boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.brand-red-bright .boxstyle_.box1 .popover button.close:hover,
.brand-red-bright .boxstyle-xs.box1 .popover button.close:hover,
.brand-red-bright .boxstyle-sm.box1 .popover button.close:hover,
.brand-red-bright .boxstyle-md.box1 .popover button.close:hover,
.brand-red-bright .boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.brand-red-bright .boxstyle_.box1 a:hover .new-window-link,
.brand-red-bright .boxstyle-xs.box1 a:hover .new-window-link,
.brand-red-bright .boxstyle-sm.box1 a:hover .new-window-link,
.brand-red-bright .boxstyle-md.box1 a:hover .new-window-link,
.brand-red-bright .boxstyle-lg.box1 a:hover .new-window-link,
.brand-red-bright .boxstyle_.box1 a:hover .insecure-link,
.brand-red-bright .boxstyle-xs.box1 a:hover .insecure-link,
.brand-red-bright .boxstyle-sm.box1 a:hover .insecure-link,
.brand-red-bright .boxstyle-md.box1 a:hover .insecure-link,
.brand-red-bright .boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.brand-red-bright .boxstyle_.box1 .btn-link,
.brand-red-bright .boxstyle-xs.box1 .btn-link,
.brand-red-bright .boxstyle-sm.box1 .btn-link,
.brand-red-bright .boxstyle-md.box1 .btn-link,
.brand-red-bright .boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.brand-red-bright .boxstyle_.box1 .btn-link:hover,
.brand-red-bright .boxstyle-xs.box1 .btn-link:hover,
.brand-red-bright .boxstyle-sm.box1 .btn-link:hover,
.brand-red-bright .boxstyle-md.box1 .btn-link:hover,
.brand-red-bright .boxstyle-lg.box1 .btn-link:hover,
.brand-red-bright .boxstyle_.box1 .btn-link:focus,
.brand-red-bright .boxstyle-xs.box1 .btn-link:focus,
.brand-red-bright .boxstyle-sm.box1 .btn-link:focus,
.brand-red-bright .boxstyle-md.box1 .btn-link:focus,
.brand-red-bright .boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.brand-red-bright .boxstyle_.box1 a,
.brand-red-bright .boxstyle-xs.box1 a,
.brand-red-bright .boxstyle-sm.box1 a,
.brand-red-bright .boxstyle-md.box1 a,
.brand-red-bright .boxstyle-lg.box1 a,
.brand-red-bright .boxstyle_.box1 a:link,
.brand-red-bright .boxstyle-xs.box1 a:link,
.brand-red-bright .boxstyle-sm.box1 a:link,
.brand-red-bright .boxstyle-md.box1 a:link,
.brand-red-bright .boxstyle-lg.box1 a:link,
.brand-red-bright .boxstyle_.box1 a:visited,
.brand-red-bright .boxstyle-xs.box1 a:visited,
.brand-red-bright .boxstyle-sm.box1 a:visited,
.brand-red-bright .boxstyle-md.box1 a:visited,
.brand-red-bright .boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-red-bright .boxstyle_.box1 a:hover,
.brand-red-bright .boxstyle-xs.box1 a:hover,
.brand-red-bright .boxstyle-sm.box1 a:hover,
.brand-red-bright .boxstyle-md.box1 a:hover,
.brand-red-bright .boxstyle-lg.box1 a:hover,
.brand-red-bright .boxstyle_.box1 a:focus,
.brand-red-bright .boxstyle-xs.box1 a:focus,
.brand-red-bright .boxstyle-sm.box1 a:focus,
.brand-red-bright .boxstyle-md.box1 a:focus,
.brand-red-bright .boxstyle-lg.box1 a:focus,
.brand-red-bright .boxstyle_.box1 a:active,
.brand-red-bright .boxstyle-xs.box1 a:active,
.brand-red-bright .boxstyle-sm.box1 a:active,
.brand-red-bright .boxstyle-md.box1 a:active,
.brand-red-bright .boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .brand-red-bright .boxstyle_.box2,
  .brand-red-bright .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-red-bright .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-red-bright .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-red-bright .boxstyle_.box2,
.brand-red-bright .boxstyle-xs.box2,
.brand-red-bright .boxstyle-sm.box2,
.brand-red-bright .boxstyle-md.box2,
.brand-red-bright .boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .brand-red-bright .boxstyle_.box2,
  .brand-red-bright .boxstyle-xs.box2,
  .brand-red-bright .boxstyle-sm.box2,
  .brand-red-bright .boxstyle-md.box2,
  .brand-red-bright .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.brand-red-bright .boxstyle_.box2 h1,
.brand-red-bright .boxstyle-xs.box2 h1,
.brand-red-bright .boxstyle-sm.box2 h1,
.brand-red-bright .boxstyle-md.box2 h1,
.brand-red-bright .boxstyle-lg.box2 h1,
.brand-red-bright .boxstyle_.box2 h2,
.brand-red-bright .boxstyle-xs.box2 h2,
.brand-red-bright .boxstyle-sm.box2 h2,
.brand-red-bright .boxstyle-md.box2 h2,
.brand-red-bright .boxstyle-lg.box2 h2,
.brand-red-bright .boxstyle_.box2 h3,
.brand-red-bright .boxstyle-xs.box2 h3,
.brand-red-bright .boxstyle-sm.box2 h3,
.brand-red-bright .boxstyle-md.box2 h3,
.brand-red-bright .boxstyle-lg.box2 h3,
.brand-red-bright .boxstyle_.box2 h4,
.brand-red-bright .boxstyle-xs.box2 h4,
.brand-red-bright .boxstyle-sm.box2 h4,
.brand-red-bright .boxstyle-md.box2 h4,
.brand-red-bright .boxstyle-lg.box2 h4,
.brand-red-bright .boxstyle_.box2 h5,
.brand-red-bright .boxstyle-xs.box2 h5,
.brand-red-bright .boxstyle-sm.box2 h5,
.brand-red-bright .boxstyle-md.box2 h5,
.brand-red-bright .boxstyle-lg.box2 h5,
.brand-red-bright .boxstyle_.box2 h6,
.brand-red-bright .boxstyle-xs.box2 h6,
.brand-red-bright .boxstyle-sm.box2 h6,
.brand-red-bright .boxstyle-md.box2 h6,
.brand-red-bright .boxstyle-lg.box2 h6,
.brand-red-bright .boxstyle_.box2 .h1,
.brand-red-bright .boxstyle-xs.box2 .h1,
.brand-red-bright .boxstyle-sm.box2 .h1,
.brand-red-bright .boxstyle-md.box2 .h1,
.brand-red-bright .boxstyle-lg.box2 .h1,
.brand-red-bright .boxstyle_.box2 .h2,
.brand-red-bright .boxstyle-xs.box2 .h2,
.brand-red-bright .boxstyle-sm.box2 .h2,
.brand-red-bright .boxstyle-md.box2 .h2,
.brand-red-bright .boxstyle-lg.box2 .h2,
.brand-red-bright .boxstyle_.box2 .h3,
.brand-red-bright .boxstyle-xs.box2 .h3,
.brand-red-bright .boxstyle-sm.box2 .h3,
.brand-red-bright .boxstyle-md.box2 .h3,
.brand-red-bright .boxstyle-lg.box2 .h3,
.brand-red-bright .boxstyle_.box2 .h4,
.brand-red-bright .boxstyle-xs.box2 .h4,
.brand-red-bright .boxstyle-sm.box2 .h4,
.brand-red-bright .boxstyle-md.box2 .h4,
.brand-red-bright .boxstyle-lg.box2 .h4,
.brand-red-bright .boxstyle_.box2 .h5,
.brand-red-bright .boxstyle-xs.box2 .h5,
.brand-red-bright .boxstyle-sm.box2 .h5,
.brand-red-bright .boxstyle-md.box2 .h5,
.brand-red-bright .boxstyle-lg.box2 .h5,
.brand-red-bright .boxstyle_.box2 .h6,
.brand-red-bright .boxstyle-xs.box2 .h6,
.brand-red-bright .boxstyle-sm.box2 .h6,
.brand-red-bright .boxstyle-md.box2 .h6,
.brand-red-bright .boxstyle-lg.box2 .h6 {
  color: #202020;
}
.brand-red-bright .boxstyle_.box2 blockquote.quotes,
.brand-red-bright .boxstyle-xs.box2 blockquote.quotes,
.brand-red-bright .boxstyle-sm.box2 blockquote.quotes,
.brand-red-bright .boxstyle-md.box2 blockquote.quotes,
.brand-red-bright .boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.brand-red-bright .boxstyle_.box2 a,
.brand-red-bright .boxstyle-xs.box2 a,
.brand-red-bright .boxstyle-sm.box2 a,
.brand-red-bright .boxstyle-md.box2 a,
.brand-red-bright .boxstyle-lg.box2 a,
.brand-red-bright .boxstyle_.box2 a:link,
.brand-red-bright .boxstyle-xs.box2 a:link,
.brand-red-bright .boxstyle-sm.box2 a:link,
.brand-red-bright .boxstyle-md.box2 a:link,
.brand-red-bright .boxstyle-lg.box2 a:link,
.brand-red-bright .boxstyle_.box2 a:visited,
.brand-red-bright .boxstyle-xs.box2 a:visited,
.brand-red-bright .boxstyle-sm.box2 a:visited,
.brand-red-bright .boxstyle-md.box2 a:visited,
.brand-red-bright .boxstyle-lg.box2 a:visited {
  color: #202020;
}
.brand-red-bright .boxstyle_.box2 a:hover,
.brand-red-bright .boxstyle-xs.box2 a:hover,
.brand-red-bright .boxstyle-sm.box2 a:hover,
.brand-red-bright .boxstyle-md.box2 a:hover,
.brand-red-bright .boxstyle-lg.box2 a:hover,
.brand-red-bright .boxstyle_.box2 a:focus,
.brand-red-bright .boxstyle-xs.box2 a:focus,
.brand-red-bright .boxstyle-sm.box2 a:focus,
.brand-red-bright .boxstyle-md.box2 a:focus,
.brand-red-bright .boxstyle-lg.box2 a:focus,
.brand-red-bright .boxstyle_.box2 a:active,
.brand-red-bright .boxstyle-xs.box2 a:active,
.brand-red-bright .boxstyle-sm.box2 a:active,
.brand-red-bright .boxstyle-md.box2 a:active,
.brand-red-bright .boxstyle-lg.box2 a:active {
  color: #000000;
}
.brand-red-bright .boxstyle_.box2 .popover button.close,
.brand-red-bright .boxstyle-xs.box2 .popover button.close,
.brand-red-bright .boxstyle-sm.box2 .popover button.close,
.brand-red-bright .boxstyle-md.box2 .popover button.close,
.brand-red-bright .boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.brand-red-bright .boxstyle_.box2 .popover button.close:hover,
.brand-red-bright .boxstyle-xs.box2 .popover button.close:hover,
.brand-red-bright .boxstyle-sm.box2 .popover button.close:hover,
.brand-red-bright .boxstyle-md.box2 .popover button.close:hover,
.brand-red-bright .boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.brand-red-bright .boxstyle_.box2 a:hover .new-window-link,
.brand-red-bright .boxstyle-xs.box2 a:hover .new-window-link,
.brand-red-bright .boxstyle-sm.box2 a:hover .new-window-link,
.brand-red-bright .boxstyle-md.box2 a:hover .new-window-link,
.brand-red-bright .boxstyle-lg.box2 a:hover .new-window-link,
.brand-red-bright .boxstyle_.box2 a:hover .insecure-link,
.brand-red-bright .boxstyle-xs.box2 a:hover .insecure-link,
.brand-red-bright .boxstyle-sm.box2 a:hover .insecure-link,
.brand-red-bright .boxstyle-md.box2 a:hover .insecure-link,
.brand-red-bright .boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.brand-red-bright .boxstyle_.box2 .btn-link,
.brand-red-bright .boxstyle-xs.box2 .btn-link,
.brand-red-bright .boxstyle-sm.box2 .btn-link,
.brand-red-bright .boxstyle-md.box2 .btn-link,
.brand-red-bright .boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.brand-red-bright .boxstyle_.box2 .btn-link:hover,
.brand-red-bright .boxstyle-xs.box2 .btn-link:hover,
.brand-red-bright .boxstyle-sm.box2 .btn-link:hover,
.brand-red-bright .boxstyle-md.box2 .btn-link:hover,
.brand-red-bright .boxstyle-lg.box2 .btn-link:hover,
.brand-red-bright .boxstyle_.box2 .btn-link:focus,
.brand-red-bright .boxstyle-xs.box2 .btn-link:focus,
.brand-red-bright .boxstyle-sm.box2 .btn-link:focus,
.brand-red-bright .boxstyle-md.box2 .btn-link:focus,
.brand-red-bright .boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.brand-red-bright .boxstyle_.box2 a,
.brand-red-bright .boxstyle-xs.box2 a,
.brand-red-bright .boxstyle-sm.box2 a,
.brand-red-bright .boxstyle-md.box2 a,
.brand-red-bright .boxstyle-lg.box2 a,
.brand-red-bright .boxstyle_.box2 a:link,
.brand-red-bright .boxstyle-xs.box2 a:link,
.brand-red-bright .boxstyle-sm.box2 a:link,
.brand-red-bright .boxstyle-md.box2 a:link,
.brand-red-bright .boxstyle-lg.box2 a:link,
.brand-red-bright .boxstyle_.box2 a:visited,
.brand-red-bright .boxstyle-xs.box2 a:visited,
.brand-red-bright .boxstyle-sm.box2 a:visited,
.brand-red-bright .boxstyle-md.box2 a:visited,
.brand-red-bright .boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.brand-red-bright .boxstyle_.box2 a:hover,
.brand-red-bright .boxstyle-xs.box2 a:hover,
.brand-red-bright .boxstyle-sm.box2 a:hover,
.brand-red-bright .boxstyle-md.box2 a:hover,
.brand-red-bright .boxstyle-lg.box2 a:hover,
.brand-red-bright .boxstyle_.box2 a:focus,
.brand-red-bright .boxstyle-xs.box2 a:focus,
.brand-red-bright .boxstyle-sm.box2 a:focus,
.brand-red-bright .boxstyle-md.box2 a:focus,
.brand-red-bright .boxstyle-lg.box2 a:focus,
.brand-red-bright .boxstyle_.box2 a:active,
.brand-red-bright .boxstyle-xs.box2 a:active,
.brand-red-bright .boxstyle-sm.box2 a:active,
.brand-red-bright .boxstyle-md.box2 a:active,
.brand-red-bright .boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.brand-red-bright .boxstyle-xs.box3 {
  background: none;
  border: 1px solid #CB333B;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-red-bright .boxstyle_.box3,
  .brand-red-bright .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #CB333B;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-red-bright .boxstyle-md.box3 {
    background: none;
    border: 1px solid #CB333B;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-red-bright .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #CB333B;
    color: inherit;
  }
}
.brand-red-bright .boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .brand-red-bright .boxstyle_.box4,
  .brand-red-bright .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .brand-red-bright .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .brand-red-bright .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .brand-red-bright .boxstyle_.box5,
  .brand-red-bright .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .brand-red-bright .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .brand-red-bright .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.brand-red-bright .boxstyle_.box5,
.brand-red-bright .boxstyle-xs.box5,
.brand-red-bright .boxstyle-sm.box5,
.brand-red-bright .boxstyle-md.box5,
.brand-red-bright .boxstyle-lg.box5 {
  border: 0;
  background: #CB333B;
  color: white;
}
@media print {
  .brand-red-bright .boxstyle_.box5,
  .brand-red-bright .boxstyle-xs.box5,
  .brand-red-bright .boxstyle-sm.box5,
  .brand-red-bright .boxstyle-md.box5,
  .brand-red-bright .boxstyle-lg.box5 {
    border: 1px solid #CB333B;
  }
}
.brand-red-bright .boxstyle_.box5 h1,
.brand-red-bright .boxstyle-xs.box5 h1,
.brand-red-bright .boxstyle-sm.box5 h1,
.brand-red-bright .boxstyle-md.box5 h1,
.brand-red-bright .boxstyle-lg.box5 h1,
.brand-red-bright .boxstyle_.box5 h2,
.brand-red-bright .boxstyle-xs.box5 h2,
.brand-red-bright .boxstyle-sm.box5 h2,
.brand-red-bright .boxstyle-md.box5 h2,
.brand-red-bright .boxstyle-lg.box5 h2,
.brand-red-bright .boxstyle_.box5 h3,
.brand-red-bright .boxstyle-xs.box5 h3,
.brand-red-bright .boxstyle-sm.box5 h3,
.brand-red-bright .boxstyle-md.box5 h3,
.brand-red-bright .boxstyle-lg.box5 h3,
.brand-red-bright .boxstyle_.box5 h4,
.brand-red-bright .boxstyle-xs.box5 h4,
.brand-red-bright .boxstyle-sm.box5 h4,
.brand-red-bright .boxstyle-md.box5 h4,
.brand-red-bright .boxstyle-lg.box5 h4,
.brand-red-bright .boxstyle_.box5 h5,
.brand-red-bright .boxstyle-xs.box5 h5,
.brand-red-bright .boxstyle-sm.box5 h5,
.brand-red-bright .boxstyle-md.box5 h5,
.brand-red-bright .boxstyle-lg.box5 h5,
.brand-red-bright .boxstyle_.box5 h6,
.brand-red-bright .boxstyle-xs.box5 h6,
.brand-red-bright .boxstyle-sm.box5 h6,
.brand-red-bright .boxstyle-md.box5 h6,
.brand-red-bright .boxstyle-lg.box5 h6,
.brand-red-bright .boxstyle_.box5 .h1,
.brand-red-bright .boxstyle-xs.box5 .h1,
.brand-red-bright .boxstyle-sm.box5 .h1,
.brand-red-bright .boxstyle-md.box5 .h1,
.brand-red-bright .boxstyle-lg.box5 .h1,
.brand-red-bright .boxstyle_.box5 .h2,
.brand-red-bright .boxstyle-xs.box5 .h2,
.brand-red-bright .boxstyle-sm.box5 .h2,
.brand-red-bright .boxstyle-md.box5 .h2,
.brand-red-bright .boxstyle-lg.box5 .h2,
.brand-red-bright .boxstyle_.box5 .h3,
.brand-red-bright .boxstyle-xs.box5 .h3,
.brand-red-bright .boxstyle-sm.box5 .h3,
.brand-red-bright .boxstyle-md.box5 .h3,
.brand-red-bright .boxstyle-lg.box5 .h3,
.brand-red-bright .boxstyle_.box5 .h4,
.brand-red-bright .boxstyle-xs.box5 .h4,
.brand-red-bright .boxstyle-sm.box5 .h4,
.brand-red-bright .boxstyle-md.box5 .h4,
.brand-red-bright .boxstyle-lg.box5 .h4,
.brand-red-bright .boxstyle_.box5 .h5,
.brand-red-bright .boxstyle-xs.box5 .h5,
.brand-red-bright .boxstyle-sm.box5 .h5,
.brand-red-bright .boxstyle-md.box5 .h5,
.brand-red-bright .boxstyle-lg.box5 .h5,
.brand-red-bright .boxstyle_.box5 .h6,
.brand-red-bright .boxstyle-xs.box5 .h6,
.brand-red-bright .boxstyle-sm.box5 .h6,
.brand-red-bright .boxstyle-md.box5 .h6,
.brand-red-bright .boxstyle-lg.box5 .h6 {
  color: white;
}
.brand-red-bright .boxstyle_.box5 blockquote.quotes,
.brand-red-bright .boxstyle-xs.box5 blockquote.quotes,
.brand-red-bright .boxstyle-sm.box5 blockquote.quotes,
.brand-red-bright .boxstyle-md.box5 blockquote.quotes,
.brand-red-bright .boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.brand-red-bright .boxstyle_.box5 a,
.brand-red-bright .boxstyle-xs.box5 a,
.brand-red-bright .boxstyle-sm.box5 a,
.brand-red-bright .boxstyle-md.box5 a,
.brand-red-bright .boxstyle-lg.box5 a,
.brand-red-bright .boxstyle_.box5 a:link,
.brand-red-bright .boxstyle-xs.box5 a:link,
.brand-red-bright .boxstyle-sm.box5 a:link,
.brand-red-bright .boxstyle-md.box5 a:link,
.brand-red-bright .boxstyle-lg.box5 a:link,
.brand-red-bright .boxstyle_.box5 a:visited,
.brand-red-bright .boxstyle-xs.box5 a:visited,
.brand-red-bright .boxstyle-sm.box5 a:visited,
.brand-red-bright .boxstyle-md.box5 a:visited,
.brand-red-bright .boxstyle-lg.box5 a:visited {
  color: white;
}
.brand-red-bright .boxstyle_.box5 a:hover,
.brand-red-bright .boxstyle-xs.box5 a:hover,
.brand-red-bright .boxstyle-sm.box5 a:hover,
.brand-red-bright .boxstyle-md.box5 a:hover,
.brand-red-bright .boxstyle-lg.box5 a:hover,
.brand-red-bright .boxstyle_.box5 a:focus,
.brand-red-bright .boxstyle-xs.box5 a:focus,
.brand-red-bright .boxstyle-sm.box5 a:focus,
.brand-red-bright .boxstyle-md.box5 a:focus,
.brand-red-bright .boxstyle-lg.box5 a:focus,
.brand-red-bright .boxstyle_.box5 a:active,
.brand-red-bright .boxstyle-xs.box5 a:active,
.brand-red-bright .boxstyle-sm.box5 a:active,
.brand-red-bright .boxstyle-md.box5 a:active,
.brand-red-bright .boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.brand-red-bright .boxstyle_.box5 .popover button.close,
.brand-red-bright .boxstyle-xs.box5 .popover button.close,
.brand-red-bright .boxstyle-sm.box5 .popover button.close,
.brand-red-bright .boxstyle-md.box5 .popover button.close,
.brand-red-bright .boxstyle-lg.box5 .popover button.close {
  color: white;
}
.brand-red-bright .boxstyle_.box5 .popover button.close:hover,
.brand-red-bright .boxstyle-xs.box5 .popover button.close:hover,
.brand-red-bright .boxstyle-sm.box5 .popover button.close:hover,
.brand-red-bright .boxstyle-md.box5 .popover button.close:hover,
.brand-red-bright .boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.brand-red-bright .boxstyle_.box5 a:hover .new-window-link,
.brand-red-bright .boxstyle-xs.box5 a:hover .new-window-link,
.brand-red-bright .boxstyle-sm.box5 a:hover .new-window-link,
.brand-red-bright .boxstyle-md.box5 a:hover .new-window-link,
.brand-red-bright .boxstyle-lg.box5 a:hover .new-window-link,
.brand-red-bright .boxstyle_.box5 a:hover .insecure-link,
.brand-red-bright .boxstyle-xs.box5 a:hover .insecure-link,
.brand-red-bright .boxstyle-sm.box5 a:hover .insecure-link,
.brand-red-bright .boxstyle-md.box5 a:hover .insecure-link,
.brand-red-bright .boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.brand-red-bright .boxstyle_.box5 .btn-link,
.brand-red-bright .boxstyle-xs.box5 .btn-link,
.brand-red-bright .boxstyle-sm.box5 .btn-link,
.brand-red-bright .boxstyle-md.box5 .btn-link,
.brand-red-bright .boxstyle-lg.box5 .btn-link {
  color: white;
}
.brand-red-bright .boxstyle_.box5 .btn-link:hover,
.brand-red-bright .boxstyle-xs.box5 .btn-link:hover,
.brand-red-bright .boxstyle-sm.box5 .btn-link:hover,
.brand-red-bright .boxstyle-md.box5 .btn-link:hover,
.brand-red-bright .boxstyle-lg.box5 .btn-link:hover,
.brand-red-bright .boxstyle_.box5 .btn-link:focus,
.brand-red-bright .boxstyle-xs.box5 .btn-link:focus,
.brand-red-bright .boxstyle-sm.box5 .btn-link:focus,
.brand-red-bright .boxstyle-md.box5 .btn-link:focus,
.brand-red-bright .boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.brand-red-bright .boxstyle_.box5 a,
.brand-red-bright .boxstyle-xs.box5 a,
.brand-red-bright .boxstyle-sm.box5 a,
.brand-red-bright .boxstyle-md.box5 a,
.brand-red-bright .boxstyle-lg.box5 a,
.brand-red-bright .boxstyle_.box5 a:link,
.brand-red-bright .boxstyle-xs.box5 a:link,
.brand-red-bright .boxstyle-sm.box5 a:link,
.brand-red-bright .boxstyle-md.box5 a:link,
.brand-red-bright .boxstyle-lg.box5 a:link,
.brand-red-bright .boxstyle_.box5 a:visited,
.brand-red-bright .boxstyle-xs.box5 a:visited,
.brand-red-bright .boxstyle-sm.box5 a:visited,
.brand-red-bright .boxstyle-md.box5 a:visited,
.brand-red-bright .boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.brand-red-bright .boxstyle_.box5 a:hover,
.brand-red-bright .boxstyle-xs.box5 a:hover,
.brand-red-bright .boxstyle-sm.box5 a:hover,
.brand-red-bright .boxstyle-md.box5 a:hover,
.brand-red-bright .boxstyle-lg.box5 a:hover,
.brand-red-bright .boxstyle_.box5 a:focus,
.brand-red-bright .boxstyle-xs.box5 a:focus,
.brand-red-bright .boxstyle-sm.box5 a:focus,
.brand-red-bright .boxstyle-md.box5 a:focus,
.brand-red-bright .boxstyle-lg.box5 a:focus,
.brand-red-bright .boxstyle_.box5 a:active,
.brand-red-bright .boxstyle-xs.box5 a:active,
.brand-red-bright .boxstyle-sm.box5 a:active,
.brand-red-bright .boxstyle-md.box5 a:active,
.brand-red-bright .boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.brand-red-bright .pagination > li a,
.brand-red-bright .pagination > li span {
  color: #CB333B;
}
.brand-red-bright .pagination > li.active > a,
.brand-red-bright .pagination > li.active > span:not(.sr-only),
.brand-red-bright .pagination > li.active > a:hover,
.brand-red-bright .pagination > li.active > span:not(.sr-only):hover,
.brand-red-bright .pagination > li.active > a:focus,
.brand-red-bright .pagination > li.active > span:not(.sr-only):focus {
  background-color: #CB333B;
  border-color: #CB333B;
  color: white;
}
.brand-red-bright .nav-tabs {
  border-bottom-color: #e5999d;
}
.brand-red-bright .nav-tabs > li > a {
  background-color: #f5d6d8;
  border-color: #e5999d;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-red-bright .nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #e08589;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.brand-red-bright .nav-tabs > li > a:hover,
.brand-red-bright .nav-tabs > li > a:focus {
  background-color: #faebeb;
  border-color: #eaadb1 #eaadb1 #e5999d;
}
.brand-red-bright .nav-tabs > li > a:hover::before,
.brand-red-bright .nav-tabs > li > a:focus::before {
  width: 100%;
}
.brand-red-bright .nav-tabs > li.open > a,
.brand-red-bright .nav-tabs > li.open > a:hover,
.brand-red-bright .nav-tabs > li.open > a:focus {
  background-color: #faebeb;
  border-color: #eaadb1 #eaadb1 #e5999d;
}
.brand-red-bright .nav-tabs > li.open > a::before,
.brand-red-bright .nav-tabs > li.open > a:hover::before,
.brand-red-bright .nav-tabs > li.open > a:focus::before {
  background: #CB333B;
  width: 100%;
}
.brand-red-bright .nav-tabs > li.active > a,
.brand-red-bright .nav-tabs > li.active > a:hover,
.brand-red-bright .nav-tabs > li.active > a:focus {
  border-color: #e5999d #e5999d transparent;
}
.brand-red-bright .nav-tabs > li.active > a::before,
.brand-red-bright .nav-tabs > li.active > a:hover::before,
.brand-red-bright .nav-tabs > li.active > a:focus::before {
  background: #CB333B;
  width: 100%;
}
.brand-red-bright .nav-tabs > li.disabled > a {
  color: #555;
}
.brand-red-bright .nav-tabs > li.disabled > a:hover,
.brand-red-bright .nav-tabs > li.disabled > a:focus {
  background-color: #f5d6d8;
}
.brand-red-bright .nav-tabs > li.disabled > a::before {
  background: transparent;
}
.brand-red-bright .nav-pills > li.active > a,
.brand-red-bright .nav-pills > li.active > a:hover,
.brand-red-bright .nav-pills > li.active > a:focus {
  background-color: #CB333B;
  color: white;
}
.brand-red-bright .list-group-item.active,
.brand-red-bright .list-group-item.active:hover,
.brand-red-bright .list-group-item.active:focus {
  background-color: #CB333B;
  border-color: #CB333B;
  color: white;
}
.brand-red-bright .id7-koan-spinner__neon--top,
.brand-red-bright .id7-koan-spinner__neon--bottom {
  stroke: #CB333B;
}
